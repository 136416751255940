import { HttpBackend, HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorHandlerService } from '@app/core';
import { EnvironmentService } from '@app/core/services/environment.service';
import { StyleSpecification } from 'maplibre-gl';
import { BehaviorSubject, catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaptilerService {
  private static readonly BASE_URL = 'https://api.maptiler.com/maps/';
  readonly #handler = inject(HttpBackend);
  readonly #httpBackend = new HttpClient(this.#handler);
  readonly #environmentService = inject(EnvironmentService);
  readonly #errorHandlerService = inject(ErrorHandlerService);
  readonly #destroyRef = inject(DestroyRef);
  public static readonly INITIAL_STYLE_SPECIFICATION: StyleSpecification = {
    version: 8,
    sources: {},
    layers: [],
  };

  public static readonly FREE_MAP_STYLE: StyleSpecification = {
    version: 8,
    glyphs: 'https://fonts.openmaptiles.org/{fontstack}/{range}.pbf',

    sources: {
      osm: {
        type: 'raster',
        tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
        attribution: '&copy; OpenStreetMap Contributors',
        maxzoom: 19,
      },
    },
    layers: [
      {
        id: 'osm',
        type: 'raster',
        source: 'osm',
      },
    ],
  };

  readonly #satelliteTilesUrl: string = `https://api.maptiler.com/tiles/satellite-v2/tiles.json?key=${this.#environmentService.mapTilerToken}`;

  #isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  #style$ = new BehaviorSubject<StyleSpecification>(
    MaptilerService.INITIAL_STYLE_SPECIFICATION
  );

  public loadStyle(): void {
    this.#isLoading$.next(true);

    this.#httpBackend
      .get<StyleSpecification>(
        `${MaptilerService.BASE_URL}${this.#environmentService.mapTilerBaseMapId}/style.json?key=${this.#environmentService.mapTilerToken}`
      )
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError(error => this.#errorHandlerService.handleError(error))
      )
      .subscribe(style => {
        this.#isLoading$.next(false);
        this.#style$.next(style);
      });
  }

  public loadFreeMapStyle(): void {
    this.#style$.next(MaptilerService.FREE_MAP_STYLE);
  }

  public get isLoading$(): BehaviorSubject<boolean> {
    return this.#isLoading$;
  }

  public get style$(): Observable<StyleSpecification> {
    return this.#style$;
  }

  public get satelliteTilesUrl(): string {
    return this.#satelliteTilesUrl;
  }
}
