import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconLabelComponent } from '../icon-label/icon-label.component';

@Component({
  selector: 'app-action-button',
  standalone: true,
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  imports: [MatTooltipModule, IconLabelComponent],
})
export class ActionButtonComponent {
  @Input()
  public icon = '';

  @Input()
  public label = '';

  @Input()
  public link: string | null = null;

  @Input()
  public tooltip = '';

  @Input()
  public sideMenu = false;
}
