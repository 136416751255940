<div data-testid="project-card" *ngIf="project" class="project-card">
  <app-project-card-header
    [input]="project"
    (deleteProject)="onProjectDeletion($event)"
    (updateProject)="onUpdateDeletion($event)" />

  <div class="project-card-body">
    <div class="grid-element grid-element-large">
      <p class="grid-element-title">{{ 'COMMON.PROJECT_NAME' | translate }}</p>
      <p class="grid-element-value">{{ project.name }}</p>
    </div>

    <div class="project-card-body-grid">
      <div class="map-grid-element">
        <div class="map-wrapper">
          <app-map-small
            *ngIf="project.lastSavedLocation"
            [center]="coordinates"></app-map-small>
        </div>
      </div>
      <div class="grid-element">
        <p class="grid-element-title">{{ 'COMMON.LOCATIONS' | translate }}</p>
        <p class="grid-element-value">
          {{ project.totalLocations }}
        </p>
      </div>
    </div>
  </div>

  <app-project-card-footer [project]="project" />
</div>
