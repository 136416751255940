import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NetworkOperatorDto, Spot } from '@app/shared';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';

export class DummyResponse {
  public value!: number[];
}

@Injectable({
  providedIn: 'root',
})
export class ResponsibilityService {
  readonly #httpClient = inject(HttpClient);
  readonly #environment = inject(EnvironmentService);

  public loadNetworkOperatorInfo(spot: Spot): Observable<NetworkOperatorDto[]> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append(
      'postalCode',
      spot.address?.postcode || ''
    );

    return this.#httpClient.get<NetworkOperatorDto[]>(
      `${this.#environment.apiUrl}/responsibility/network-operators`,
      {
        params: queryParams,
      }
    );
  }
}
