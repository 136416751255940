@if (form && countryControl) {
  <form [formGroup]="form">
    <mat-form-field color="accent">
      <mat-select
        [attr.data-testid]="FORM_NAME.COUNTRY_CODE"
        [formControl]="countryControl"
        panelClass="country-panel">
        <div class="country-grid">
          @for (
            option of selectOptions();
            track option.displayTextTranslationIdentifier
          ) {
            <mat-option
              [value]="option.countryCode"
              [attr.data-testid]="'mat-option ' + option.countryCode"
              class="option">
              <img
                [ngSrc]="option.imageURL"
                class="option-image"
                [alt]="
                  'SEARCH_CARD.COUNTRY_SELECT_IMAGE_ALT'
                    | translate
                      : {
                          countryName:
                            option.displayTextTranslationIdentifier | translate
                        }
                "
                height="65"
                width="100" />
              <span class="option-text">{{
                option.displayTextTranslationIdentifier | translate
              }}</span>
            </mat-option>
          }
        </div>
      </mat-select>
    </mat-form-field>
  </form>
}
