import { formatCurrency } from '@angular/common';

export class CurrencyFormatter {
  public static formatCurrencyThousand(
    value: number,
    language: string
  ): string {
    return `${formatCurrency(
      value / 1000,
      language,
      '',
      undefined,
      '1.1-1'
    )}k €`;
  }

  public static readonly formatCurrencyShort = (value: number): string =>
    CurrencyFormatter.formatCurrency(value, true);

  public static formatCurrency(value: number, shortFormatting = false): string {
    if (shortFormatting) {
      return `${formatCurrency(value / 1000, 'de', '', undefined, '1.0-1')}k €`;
    }

    return `${formatCurrency(value, 'de', '', undefined, '1.0-0')} €`;
  }
}
