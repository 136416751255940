<mgl-map
  class="map-small"
  [style]="style!"
  [zoom]="[14]"
  [attributionControl]="false"
  [interactive]="false"
  [center]="center">
  <mgl-marker [lngLat]="center">
    <div class="marker"></div>
  </mgl-marker>
</mgl-map>
