import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import {
  NominatimService,
  RestrictionService,
  SearchService,
  UserService,
} from '@app/core';
import { DEFAULT_RANK_BY } from '@app/map/components/sidebar/components/spots-found/components/spots-ranking';
import {
  APP_ROUTES,
  COUNTRY_CODE,
  GeocodingResponseDto,
  ISO3166_2_LVL4,
  RadiusOptionsPipe,
  TooltipPipe,
  TooltipShowDelayPipe,
} from '@app/shared';
import { SearchFormValue } from '@app/shared/search-card/search-form-values';
import { SearchValuesSortComparer, TOOLTIP } from '@app/shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { isNil, omitBy } from 'lodash-es';
import {
  catchError,
  debounceTime,
  filter,
  Observable,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { AmountOfChargingStationsFormComponent } from './components/amount-of-charging-stations-form/amount-of-charging-stations-form.component';
import { CityOrAreaFormComponent } from './components/city-or-area-form/city-or-area-form.component';
import { CountrySelectionOption } from './components/country-selection/country-selection-option';
import { CountrySelectionComponent } from './components/country-selection/country-selection.component';
import { MaxCapacityFormComponent } from './components/max-capacity-form/max-capacity-form.component';
import { RadiusFormComponent } from './components/radius-form/radius-form.component';
import { RestrictedAreaFormComponent } from './components/restricted-area-form/restricted-area-form.component';
import { CountrySelectionOptionMapper } from './country-selection-option-mapper';
import { resetCityOrArea } from './custom-validator';
import { EnrichedGeocodingResponseDto } from './enriched-geocoding-response';
import { FORM_NAME } from './form-name.enum';
import { SearchFormConfig } from './search-form-config';

@Component({
  selector: 'app-search-card',
  standalone: true,
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSliderModule,
    AmountOfChargingStationsFormComponent,
    MaxCapacityFormComponent,
    RadiusFormComponent,
    CityOrAreaFormComponent,
    MatProgressBarModule,
    MatTooltipModule,
    TooltipPipe,
    TooltipShowDelayPipe,
    RestrictedAreaFormComponent,
    RadiusOptionsPipe,
    CountrySelectionComponent,
  ],
})
export class SearchCardComponent implements OnInit {
  @Input() public size?: 'normal' | 'small' = 'normal';
  @Input() public headline?: string;
  @Input() public headlineTooltip?: string;
  @Input() public headlineTooltipPosition: TooltipPosition = 'below';
  @Input() public headlineTooltipShowDelay = 0;
  @Input() public buttonText!: string;
  @Input() public lastSubmittedSearch$?: Observable<SearchFormValue | null>;
  @Input() public freeToPlay = false;
  @Output() public submitButtonClicked = new EventEmitter<SearchFormValue>();

  public COUNTRY_SELECTION_OPTIONS: Signal<CountrySelectionOption[]> = computed(
    () => {
      const allowedRegions = this.#userService.allowedRegions();
      const radiusRestrictions = this.#restrictionService.restrictedAreas;
      if (radiusRestrictions() && radiusRestrictions()!.length > 0) {
        return [
          CountrySelectionOptionMapper.INSTANCE.mapFromArea(
            radiusRestrictions()![0]
          ),
        ];
      }
      const mappedOptionsDistinct: Map<COUNTRY_CODE, CountrySelectionOption> =
        new Map();
      allowedRegions.forEach(region => {
        const option = CountrySelectionOptionMapper.INSTANCE.map(region);
        mappedOptionsDistinct.set(option.countryCode, option);
      });
      return Array.from(mappedOptionsDistinct.values());
    }
  );

  readonly #destroyRef = inject(DestroyRef);
  readonly #fb = inject(FormBuilder);
  readonly #router = inject(Router);
  readonly #geocodingService = inject(NominatimService);
  readonly #searchService = inject(SearchService);
  readonly #restrictionService = inject(RestrictionService);
  readonly #userService = inject(UserService);
  readonly radiusRestrictions = this.#restrictionService.radiusRestrictions;
  readonly isRestrictedByRadius = this.#restrictionService.isRestricted;

  private static readonly AMOUNT_OF_GEOCODING_OPTIONS = 4;

  public readonly TT = TOOLTIP;
  public searchForm!: FormGroup;
  public readonly FORM_NAME = FORM_NAME;
  public cityAreaOptions: EnrichedGeocodingResponseDto[] = [];
  public geolocationSearch$: Subject<string> = new Subject();
  public maxRadius: number | undefined;
  public searchFormConfig = SearchFormConfig.INSTANCE;

  constructor() {
    this.searchFormConfig = SearchFormConfig.INSTANCE;

    const formGroupDefinition = {
      [FORM_NAME.AMOUNT_OF_CHARGING_STATIONS]: this.#fb.group({
        [FORM_NAME.AMOUNT_OF_CHARGING_STATIONS]: [
          this.searchFormConfig.AMOUNT_OF_CHARGING_STATIONS.DEFAULT_VALUE,
          [
            Validators.required,
            Validators.min(
              this.searchFormConfig.AMOUNT_OF_CHARGING_STATIONS.MIN
            ),
            Validators.max(
              this.searchFormConfig.AMOUNT_OF_CHARGING_STATIONS.MAX
            ),
          ],
        ],
      }),
      [FORM_NAME.MAX_CAPACITY_IN_KW]: this.#fb.group({
        [FORM_NAME.MAX_CAPACITY_IN_KW]: [
          this.searchFormConfig.MAX_CAPACITY_IN_KW.DEFAULT_VALUE,
          [
            Validators.required,
            Validators.min(this.searchFormConfig.MAX_CAPACITY_IN_KW.MIN),
            Validators.max(this.searchFormConfig.MAX_CAPACITY_IN_KW.MAX),
          ],
        ],
      }),
      [FORM_NAME.RADIUS_IN_METER]: this.#fb.group({
        [FORM_NAME.RADIUS_IN_METER]: [undefined, [Validators.required]],
      }),
      [FORM_NAME.CITY_OR_AREA]: this.#fb.group({
        [FORM_NAME.CITY_OR_AREA]: [undefined, [Validators.required]],
      }),
      [FORM_NAME.COUNTRY_CODE]: this.#fb.group({
        [FORM_NAME.COUNTRY_CODE]: [undefined, [Validators.required]],
      }),
      [FORM_NAME.RANK_BY]: this.#fb.group({
        [FORM_NAME.RANK_BY]: [DEFAULT_RANK_BY, [Validators.required]],
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    this.searchForm = this.#fb.group(formGroupDefinition, {
      validators: resetCityOrArea(),
    });
  }

  public ngOnInit(): void {
    if (this.#userService.allowedRegions().length > 0) {
      const option = CountrySelectionOptionMapper.INSTANCE.map(
        this.#userService.allowedRegions()[0]
      );
      this.searchForm
        .get(FORM_NAME.COUNTRY_CODE)
        ?.get(FORM_NAME.COUNTRY_CODE)
        ?.setValue(option.countryCode);
    }

    this.#initMaxRadius();
    if (this.radiusRestrictions()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.searchForm
        .get(FORM_NAME.CITY_OR_AREA)!
        .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(() => {
          this.#initMaxRadius();
        });

      this.#updateFormByName(
        FORM_NAME.CITY_OR_AREA,
        0,
        this.#userService.isDemo()
      );
      this.searchForm.updateValueAndValidity();
    }

    this.#searchService.lastSubmittedSearch$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(searchValues => !isNil(searchValues))
      )
      .subscribe((searchValues: SearchFormValue | null) => {
        if (searchValues) {
          Object.keys(searchValues)
            .sort(SearchValuesSortComparer.compare)
            .forEach(key => {
              this.#updateFormByName(
                key as FORM_NAME,
                searchValues[key as FORM_NAME]
              );
            });
        }
      });

    this.geolocationSearch$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        debounceTime(500),
        switchMap(searchValue => {
          const countryCode = this.searchForm
            .get(FORM_NAME.COUNTRY_CODE)
            ?.get(FORM_NAME.COUNTRY_CODE)?.value;
          return this.#geocodingService.search(searchValue, countryCode).pipe(
            catchError(() => {
              return [];
            })
          );
        }),
        tap((geocodingResults: GeocodingResponseDto[]) =>
          geocodingResults?.slice(
            0,
            SearchCardComponent.AMOUNT_OF_GEOCODING_OPTIONS
          )
        )
      )
      .subscribe((geocodingResults: GeocodingResponseDto[]) => {
        const countries: ISO3166_2_LVL4[] = this.#userService
          .allowedRegions()
          .map(allowedRegion => allowedRegion.region as ISO3166_2_LVL4);

        this.cityAreaOptions = geocodingResults.map(result => {
          return {
            ...result,
            isRestricted: !countries.includes(result.address['ISO3166-2-lvl4']),
          };
        });
      });
  }

  #initMaxRadius() {
    if (this.radiusRestrictions()) {
      const radiusRestrictions =
        this.radiusRestrictions()?.areaDetails?.[
          Number(
            this.searchForm
              .get(FORM_NAME.CITY_OR_AREA)
              ?.get(FORM_NAME.CITY_OR_AREA)?.value
          )
        ];

      this.maxRadius = radiusRestrictions?.maxRadius;

      this.#setFormControlValueAndDisable(
        FORM_NAME.MAX_CAPACITY_IN_KW,
        radiusRestrictions?.locationMaxKW
      );

      this.#setFormControlValueAndDisable(
        FORM_NAME.AMOUNT_OF_CHARGING_STATIONS,
        radiusRestrictions?.chargingStations
      );

      if (this.#userService.isDemo()) {
        this.#updateFormByName(FORM_NAME.RADIUS_IN_METER, this.maxRadius, true);
        this.searchForm
          .get(FORM_NAME.COUNTRY_CODE)
          ?.get(FORM_NAME.COUNTRY_CODE)
          ?.setValue(
            this.#userService.subscriptionPlan()?.details?.info?.areas[0]
              ?.countryCode
          );
        this.searchForm.updateValueAndValidity();
      }
    } else {
      this.maxRadius = this.searchFormConfig.MAX_DEFAULT_RADIUS;
    }
  }

  #setFormControlValueAndDisable(
    formName: FORM_NAME,
    radiusRestrictionValue: number | undefined
  ) {
    const formGroup = this.searchForm.get(formName) as FormGroup;

    const formControl = formGroup.get(formName);
    if (radiusRestrictionValue && formControl) {
      formControl.setValue(radiusRestrictionValue);
      formGroup.disable();
    } else {
      formGroup.enable();
    }
  }

  #updateFormByName(
    name: FORM_NAME,
    newValue: string | number | GeocodingResponseDto | undefined,
    disable = false,
    enable = false
  ): void {
    const control = this.searchForm.get(name)?.get(name);
    control?.setValue(newValue);

    if (disable) {
      control?.disable();
    }

    if (enable) {
      control?.enable();
    }
  }

  public onSubmit() {
    const formValues = this.#flattenFormValues(false);

    this.submitButtonClicked.emit(formValues);
    this.#searchService.lastSubmittedSearch = formValues;

    const route = !this.#userService.isFreeUser()
      ? `/${APP_ROUTES.MAP}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`
      : `/${APP_ROUTES.FREE_TO_PLAY}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`;

    this.#router.navigate([route], {
      queryParams: omitBy(this.#flattenFormValues(true), isNil),
      queryParamsHandling: 'merge',
    });
  }

  #flattenFormValues(isUrlFlattening: boolean): SearchFormValue {
    const temp = {
      [FORM_NAME.RADIUS_IN_METER]: this.searchForm
        .get(FORM_NAME.RADIUS_IN_METER)
        ?.get(FORM_NAME.RADIUS_IN_METER)?.value,
      [FORM_NAME.AMOUNT_OF_CHARGING_STATIONS]: this.searchForm
        .get(FORM_NAME.AMOUNT_OF_CHARGING_STATIONS)
        ?.get(FORM_NAME.AMOUNT_OF_CHARGING_STATIONS)?.value,
      [FORM_NAME.MAX_CAPACITY_IN_KW]: this.searchForm
        .get(FORM_NAME.MAX_CAPACITY_IN_KW)
        ?.get(FORM_NAME.MAX_CAPACITY_IN_KW)?.value,
      [FORM_NAME.RANK_BY]: this.searchForm
        .get(FORM_NAME.RANK_BY)
        ?.get(FORM_NAME.RANK_BY)?.value,
      [FORM_NAME.COUNTRY_CODE]: this.searchForm
        .get(FORM_NAME.COUNTRY_CODE)
        ?.get(FORM_NAME.COUNTRY_CODE)?.value,
    };

    if (isUrlFlattening) {
      const cityOrAreaValue = this.isRestrictedByRadius()
        ? this.searchForm
            .get(FORM_NAME.CITY_OR_AREA)
            ?.get(FORM_NAME.CITY_OR_AREA)?.value
        : this.searchForm
            .get(FORM_NAME.CITY_OR_AREA)
            ?.get(FORM_NAME.CITY_OR_AREA)?.value.id;

      return {
        ...temp,
        [FORM_NAME.CITY_OR_AREA]: cityOrAreaValue,
      };
    }

    return {
      ...temp,
      [FORM_NAME.CITY_OR_AREA]: this.searchForm
        .get(FORM_NAME.CITY_OR_AREA)
        ?.get(FORM_NAME.CITY_OR_AREA)?.value,
    };
  }

  public addMeasurementUnit(value: number): string {
    return `${value} kW`;
  }
}
