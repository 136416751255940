import { LayerWrapperGroup, LayerWrapperOrGroup } from '@app/map';

export class LayerGroupDiscriminator {
  private static readonly LAYER_GROUP_DISCRIMINATOR = 'groupName';

  public static isGroup(item: LayerWrapperOrGroup): item is LayerWrapperGroup {
    if (item) {
      return LayerGroupDiscriminator.LAYER_GROUP_DISCRIMINATOR in item;
    }
    return false;
  }
}
