import { Injectable, inject } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { EnvironmentService } from './services';

@Injectable()
export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  #environemtService = inject(EnvironmentService);
  handle(params: MissingTranslationHandlerParams) {
    if (!this.#environemtService.production) {
      // eslint-disable-next-line no-console
      console.error(`Missing translation for key: ${params.key}`);
    }
    return params.key;
  }
}
