import { MatDialogConfig } from '@angular/material/dialog';

export class DialogConfigFactory {
  public static INSTANCE = new DialogConfigFactory();

  public buildDialogConfig(
    dialogConfigName: 'small' | 'medium' | 'large',
    disableClose: boolean = true
  ): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = disableClose;

    switch (dialogConfigName) {
      case 'small':
        dialogConfig.maxWidth = '329px';
        break;
      case 'medium':
        dialogConfig.maxWidth = '600px';
        break;
      case 'large':
        dialogConfig.maxWidth = '650px';
        dialogConfig.width = '650px';
        break;
      default:
        dialogConfig.maxWidth = '329px';
        break;
    }

    return dialogConfig;
  }
}
