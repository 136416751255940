import { computed, inject, Injectable } from '@angular/core';
import { UserService } from '@app/core';
import { SubscriptionPlanName } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  readonly #userService = inject(UserService);

  public importEnabled = computed(() => {
    return (
      this.#userService.subscriptionPlan()?.name !==
        SubscriptionPlanName.LIGHT &&
      this.#userService.subscriptionPlan()?.name !== SubscriptionPlanName.DEMO
    );
  });
}
