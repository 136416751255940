<a
  *ngIf="labelTranslationIdentifier"
  [ngClass]="{
    'link-button--small': size === 'small',
    'link-button--normal': size === 'normal'
  }"
  class="link-button"
  mat-icon-button
  data-testid="link-button"
  [routerLink]="'/' + link">
  <mat-icon class="footer-icon" fontSet="material-symbols-outlined"
    >arrow_forward
  </mat-icon>
  {{ labelTranslationIdentifier | translate }}</a
>
