import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-value-box',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './copy-value-box.component.html',
  styleUrls: ['./copy-value-box.component.scss'],
})
export class CopyValueBoxComponent {
  readonly #clipboard = inject(Clipboard);
  @Input({ required: true }) value!: string;
  @Input() public disabled = false;
  public icon: string = 'content_copy';

  public copyValue(): void {
    this.#clipboard.copy(this.value);
    this.icon = 'done';
  }
}
