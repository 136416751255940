<div data-testid="create-or-select-project-dialog">
  <app-dialog-header
    [headlineToolTip]="
      'PROJECT_CREATE_OR_SELECT_DIALOG.TITLE_TOOLTIP' | translate
    "
    (closeButtonClicked)="closeDialog()">
    {{ 'PROJECT_CREATE_OR_SELECT_DIALOG.TITLE' | translate }}
  </app-dialog-header>

  <mat-progress-bar
    [ngClass]="{ 'loading-bar-hidden': isLoading === false }"
    mode="indeterminate"
    color="accent"
    data-testid="create-or-select-loading-indicator" />

  <mat-dialog-content>
    <div>
      <div *ngIf="projects.length > 0">
        <p class="form-label">
          {{ 'PROJECT_CREATE_OR_SELECT_DIALOG.SAVE_TO' | translate }}
        </p>
        <form
          [formGroup]="projectSelectionForm"
          data-testid="select-project-form">
          <mat-form-field color="accent">
            <mat-label>{{
              'PROJECT_CREATE_OR_SELECT_DIALOG.SELECT_PROJECT' | translate
            }}</mat-label>
            <mat-select formControlName="project">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let project of projects" [value]="project.id">
                {{ project.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <form [formGroup]="projectForm">
        <p class="form-label">
          {{ 'PROJECT_CREATE_OR_SELECT_DIALOG.CREATE_NEW_PROJECT' | translate }}
        </p>

        <mat-form-field color="accent">
          <mat-label>{{ 'COMMON.PROJECT_NAME' | translate }}</mat-label>
          <input
            data-testid="create-new-project-input"
            matInput
            formControlName="name"
            tabindex="-1"
            type="text" />
          <mat-error *ngIf="projectForm.invalid">
            {{ projectForm | projectFromError | async }}</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      (click)="closeDialog()"
      [disabled]="isLoading"
      tabindex="-1"
      mat-stroked-button
      color="primary"
      class="small">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
    <button
      (click)="save()"
      id="save-button"
      [disabled]="projectForm.invalid || isLoading"
      tabindex="-1"
      mat-flat-button
      color="primary"
      class="small"
      data-testid="create-or-select-save-btn">
      {{ 'COMMON.SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
