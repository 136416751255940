import { CommonModule } from '@angular/common';
import { Component, inject, Signal } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingService } from '@app/core';

@Component({
  selector: 'app-global-loading-indicator',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './global-loading-indicator.component.html',
  styleUrls: ['./global-loading-indicator.component.scss'],
})
export class GlobalLoadingIndicatorComponent {
  readonly #loadingService = inject(LoadingService);

  public isLoading: Signal<boolean> = this.#loadingService.isLoading;
}
