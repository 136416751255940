import { inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@app/core/services/environment.service';
import { Observable } from 'rxjs';
import { ProjectWithLocationDto, SharingDetailsDto } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class ProjectSharingService {
  readonly #handler = inject(HttpBackend);
  readonly #httpClientBackend = new HttpClient(this.#handler);
  readonly #httpClient = inject(HttpClient);
  readonly #environment = inject(EnvironmentService);
  readonly #BASE_URL = `${this.#environment.apiUrl}/projects`;

  public loadSharingDetails(
    projectId: string
  ): Observable<SharingDetailsDto | null> {
    const url = `${this.#BASE_URL}/${projectId}/share`;
    return this.#httpClient.get<SharingDetailsDto>(url);
  }

  public loadProjectBySharingId(
    shareId: string,
    expand?: boolean
  ): Observable<ProjectWithLocationDto> {
    const url = `${this.#BASE_URL}/shared/${shareId}`;
    const params: { expand: boolean } = { expand: expand ?? false };
    return this.#httpClientBackend.get<ProjectWithLocationDto>(url, { params });
  }

  public createSharedProject(projectId: string) {
    const url = `${this.#BASE_URL}/${projectId}/share`;
    return this.#httpClient.post<SharingDetailsDto>(url, projectId);
  }

  public deleteSharingDetails(projectId: string): Observable<unknown> {
    const url = `${this.#BASE_URL}/${projectId}/share`;
    return this.#httpClient.delete<string>(url);
  }
}
