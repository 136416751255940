<mat-toolbar color="primary" data-testid="navbar">
  <div class="navigation-main-wrapper">
    <div
      class="main-left"
      data-testid="main-left"
      *ngIf="isNavigationDisplayed(); else sharedProjectView">
      <a [routerLink]="'/' + APP_ROUTES.HOME">
        <img
          ngSrc="./assets/eon-one-logo.svg"
          alt="E.ON One Overview"
          height="24"
          width="70" />
      </a>
      <a class="app-name" [routerLink]="'/' + APP_ROUTES.HOME">
        <h4>SpotOne</h4>
      </a>
      <app-subscription-plan-info />
      <app-share-project-btn
        data-testid="share-project-btn"
        [projectId]="projectId"
        *ngIf="isShareProjectBtnDisplayed()" />
    </div>
    <ng-template #sharedProjectView>
      <img
        ngSrc="./assets/eon-one-logo.svg"
        alt="E.ON One Overview"
        height="24"
        width="70" />
      <div class="app-name">
        <h4>SpotOne</h4>
      </div>
    </ng-template>
    <div
      class="main"
      [ngClass]="{ 'not-authorized': !isNavigationDisplayed() }"
      data-testid="main">
      <ng-container *ngTemplateOutlet="mainNavigationLinks" />
      <ng-container
        *ngTemplateOutlet="mainActions; context: { sideMenu: false }" />
    </div>
    <app-burger-button
      *ngIf="isNavigationDisplayed()"
      class="menu-button"
      (activeChange)="drawer.toggle()"
      [(active)]="drawer.opened" />
  </div>
</mat-toolbar>

<mat-drawer-container class="drawer-container">
  <mat-drawer class="mobile-menu" #drawer>
    <div class="mobile-menu-content">
      <div>
        <ng-container *ngTemplateOutlet="mainNavigationLinks" />
      </div>

      <div class="actions-container">
        <ng-container
          *ngTemplateOutlet="mainActions; context: { sideMenu: true }" />
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content class="drawer-content">
    <ng-content />
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #mainActions let-sideMenu="sideMenu">
  @if (isFreeUser()) {
    <app-action-menu-button
      class="action"
      icon="language"
      [isInSideMenu]="true">
      <mat-radio-group class="language-group" aria-label="Select a language">
        <mat-radio-button
          *ngFor="let language of languages"
          class="language-option"
          color="accent"
          (change)="setLanguage(language)"
          [checked]="language === selectedLanguage"
          [value]="language"
          >{{ 'LANGUAGES.' + language.toUpperCase() | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </app-action-menu-button>
  } @else {
    <app-action-menu-button
      class="action"
      icon="language"
      [isInSideMenu]="sideMenu"
      label="{{ 'NAVBAR.LANGUAGE' | translate }}">
      <mat-radio-group class="language-group" aria-label="Select a language">
        <mat-radio-button
          *ngFor="let language of languages"
          class="language-option"
          color="accent"
          (change)="setLanguage(language)"
          [checked]="language === selectedLanguage"
          [value]="language"
          >{{ 'LANGUAGES.' + language.toUpperCase() | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </app-action-menu-button>
  }

  @if (isNavigationDisplayed()) {
    <app-action-button
      class="action"
      icon="apps"
      tooltip="{{ 'NAVBAR.GOTO_SERVICE_PORTAL' | translate }}"
      label="{{ 'NAVBAR.SERVICE_PORTAL' | translate }}"
      [link]="getServicePortalLink()" />

    <app-action-menu-button
      data-testid="profile-menu"
      class="action"
      icon="person"
      label="{{ user()?.email }}"
      [isInSideMenu]="sideMenu">
      <button
        [attr.aria-label]="'NAVBAR.SETTINGS' | translate"
        mat-menu-item
        (click)="openSettings()"
        data-testid="settings">
        <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
        <span>{{ 'NAVBAR.SETTINGS' | translate }}</span>
      </button>

      <button
        [attr.aria-label]="'COMMON.LOGOUT' | translate"
        color="primary"
        class="logout-button"
        mat-flat-button
        (click)="logout()">
        {{ 'COMMON.LOGOUT' | translate }}
      </button>
    </app-action-menu-button>
  }
</ng-template>

<ng-template #mainNavigationLinks>
  <div class="navigation-main-link-container">
    @if (isFreeUser()) {
      <app-navigation-main-link-2
        href="https://one.eon.com"
        text="NAVBAR.EON_ONE" />
    }

    @if (
      router.isActive('/error', true) === false &&
      user() &&
      isNavigationDisplayed()
    ) {
      <app-navigation-main-link
        [link]="'/' + APP_ROUTES.HOME"
        text="NAVBAR.HOME" />
      <app-navigation-main-link
        [link]="'/' + APP_ROUTES.MAP"
        text="COMMON.MAP" />
    }
  </div>
</ng-template>
