export interface ConfigValueNumber {
  MIN: number;
  MAX: number;
  DEFAULT_VALUE?: number;
}
type reducedConfig = Omit<ConfigValueNumber, 'DEFAULT_VALUE'>;
export interface ConfigValueSlider extends reducedConfig {
  DEFAULT_VALUE_MIN?: number;
  DEFAULT_VALUE_MAX?: number;
}
export interface ConfigValueNumbers {
  values: number[];
  DEFAULT_VALUE?: number;
}

export class SearchFormConfig {
  public static readonly INSTANCE = new SearchFormConfig();

  public readonly RADIUS_OPTION_VALUES = [
    1000, 2000, 3000, 4000, 5000, 10000, 15000,
  ];

  public readonly AMOUNT_OF_CHARGING_STATIONS = {
    MIN: 1,
    MAX: 1000,
    DEFAULT_VALUE: undefined,
  };

  public readonly MAX_CAPACITY_IN_KW = {
    MAX: 10000,
    MIN: 22,
    DEFAULT_VALUE: undefined,
  };

  get MAX_DEFAULT_RADIUS(): number {
    return this.RADIUS_OPTION_VALUES[this.RADIUS_OPTION_VALUES.length - 1];
  }
}
