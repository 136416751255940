import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ErrorHandlerService, ProjectService } from '@app/core';
import { TranslateModule } from '@ngx-translate/core';
import { catchError } from 'rxjs';
// noinspection ES6PreferShortImport
import { DialogBase } from '../dialog-base.class';
// noinspection ES6PreferShortImport
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
// noinspection ES6PreferShortImport
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DIALOG_EVENT } from '@app/shared/dialogs/dialog-event.enum';
import { ProjectFromErrorPipe } from '@app/shared/pipes';
// noinspection ES6PreferShortImport
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogData } from '../dialog-data.interface';
import { ProjectDto } from '@app/shared';

@Component({
  selector: 'app-create-or-select-project-dialog',
  standalone: true,
  templateUrl: './create-or-select-project-dialog.component.html',
  styleUrls: ['./create-or-select-project-dialog.component.scss'],
  imports: [
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    NgIf,
    MatSelectModule,
    AsyncPipe,
    NgFor,
    MatProgressBarModule,
    NgClass,
    ProjectFromErrorPipe,
    DialogHeaderComponent,
  ],
})
export class CreateOrSelectProjectDialogComponent
  extends DialogBase
  implements OnInit
{
  #destroyRef = inject(DestroyRef);
  public isLoading = false;
  public projectForm: FormGroup;
  public projectSelectionForm: FormGroup;
  public initialData: DialogData;
  public projects: ProjectDto[] = [];

  readonly #fb = inject(FormBuilder);
  readonly #projectService = inject(ProjectService);
  readonly errorHandlerService = inject(ErrorHandlerService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public override data: DialogData,
    public override readonly dialogRef: MatDialogRef<CreateOrSelectProjectDialogComponent>
  ) {
    super(dialogRef, data);
    this.initialData = data;

    this.projectSelectionForm = this.#fb.group({
      project: [undefined, []],
    });

    this.projectForm = this.#fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(
            CreateOrSelectProjectDialogComponent.MAX_LENGTH_FOR_PROJECT_NAME
          ),
        ],
      ],
    });

    this.projectSelectionForm.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(values => {
        if (values.project) {
          this.projectForm.reset();
          this.projectForm.disable();
        } else {
          this.projectForm.enable();
        }
      });
  }

  public ngOnInit(): void {
    this.isLoading = true;

    this.#projectService
      .getProjects()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: (projects: ProjectDto[]) => {
          this.projects = projects;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  public save(): void {
    const name = this.projectForm.value.name;

    if (name) {
      this.isLoading = true;
      this.#projectService
        .createProject({ name })
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          catchError(error => this.errorHandlerService.handleError(error))
        )
        .subscribe({
          next: (newProject: ProjectDto) => {
            this.data = {
              project: {
                ...newProject,
              },
              event: DIALOG_EVENT.CREATE,
            };
            this.isLoading = false;
            this.closeDialog();
          },
          error: () => {
            this.isLoading = false;
            this.closeDialog();
          },
        });
    } else {
      this.data = {
        project: this.projects.find(
          project => project.id === this.projectSelectionForm.value.project
        ) as ProjectDto,
        event: DIALOG_EVENT.SELECT,
      };
      this.closeDialog();
    }
  }
}
