import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class ContentsquareAnalyticsService {
  private trackingSubscription: Subscription | undefined;
  #router = inject(Router);
  #environment = inject(EnvironmentService);

  public startTracking(): void {
    this.#configureTracking();
    this.trackingSubscription = this.#router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event =>
        this.#configureTracking((event as NavigationEnd).urlAfterRedirects)
      );
  }

  #configureTracking(pathName: string = window.location.pathname): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const uxa = (window as any)._uxa || [];
    (window as any)._uxa = uxa;
    const csConf = typeof (window as any).CS_CONF;
    /* eslint-enable @typescript-eslint/no-explicit-any */

    if (csConf === 'undefined') {
      const csEvent = [
        'setPath',
        window.location.pathname + window.location.hash.replace('#', '?__'),
      ];
      uxa.push(csEvent);
      const mt = document.createElement('script');
      mt.type = 'text/javascript';
      mt.async = true;
      mt.id = 'contentsquare-script';
      mt.src = `//t.contentsquare.net/uxa/${this.#environment.contentSquareId}.js`;
      document.getElementsByTagName('head')[0].appendChild(mt);
    } else {
      const csEvent = [
        'trackPageview',
        pathName + window.location.hash.replace('#', '?__'),
      ];
      uxa.push(csEvent);
    }

    uxa.push(['setCustomVariable', 1, 'app', 'spotone']);
  }

  public stopTracking(): void {
    document.getElementById('contentsquare-script')?.remove();
    this.trackingSubscription?.unsubscribe();
  }
}
