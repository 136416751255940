import { ListFormMetaBase } from '@app/shared/list-form/interfaces/list-form-meta-base';
import { Validators } from '@angular/forms';
import { LIST_FORM_META_TYPE } from '@app/shared/list-form/list-form-meta-type.enum';

export class ListFormMetaSelect extends ListFormMetaBase {
  private readonly _options;
  public constructor(
    labelTranslationIdentifier: string,
    formControlName: string,
    options: string[],
    validators?: Validators[]
  ) {
    super(
      labelTranslationIdentifier,
      formControlName,
      LIST_FORM_META_TYPE.SELECT,
      validators
    );

    this._options = options;
  }

  public get options(): string[] {
    return this._options;
  }
}
