<div class="flex-list">
  <form [formGroup]="form">
    <div class="flex-list__form">
      <ng-container [formArrayName]="FORM_GROUP_NAME">
        <ng-container
          *ngFor="
            let filtersForm of listForm.controls;
            let i = index;
            let first = first;
            let last = last
          ">
          <div class="flex-list__container">
            @for (name of controlNames; track name) {
              @if (formNameByName.get(name)!.isDisplayed) {
                <div
                  (click)="addNewForm(last)"
                  class="flex-list__element"
                  [formGroup]="filtersForm">
                  <ng-container [ngSwitch]="formNameByName.get(name)!.type">
                    <mat-form-field *ngSwitchCase="'number'" color="accent">
                      <mat-label>
                        {{
                          formNameByName.get(name)!.labelTranslationIdentifier
                            | translate
                        }}
                      </mat-label>
                      <input
                        matInput
                        [formControlName]="name"
                        type="number"
                        tabindex="-1" />

                      <mat-error *ngIf="filtersForm?.get(name)?.invalid">
                        <span
                          *ngIf="filtersForm?.get(name)?.errors?.['required']">
                          {{ 'COMMON.REQUIRED_FIELD' | translate }}
                        </span>
                        <span *ngIf="filtersForm?.get(name)?.errors?.['min']">
                          {{
                            'SEARCH_CARD.MIN_LENGTH_ERROR'
                              | translate
                                : {
                                    minLength:
                                      filtersForm?.get(name)?.errors?.['min']
                                        .min
                                  }
                          }}
                        </span>
                        <span *ngIf="filtersForm?.get(name)?.errors?.['max']">
                          {{
                            'SEARCH_CARD.MAX_LENGTH_ERROR'
                              | translate
                                : {
                                    maxLength:
                                      filtersForm?.get(name)?.errors?.['max']
                                        .max
                                  }
                          }}
                        </span>
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngSwitchCase="'select'" color="accent">
                      <mat-label>
                        {{
                          formNameByName.get(name)!.labelTranslationIdentifier
                            | translate
                        }}
                      </mat-label>
                      <mat-select [formControlName]="name" tabindex="-1">
                        <mat-option
                          *ngFor="
                            let form of (
                              formNameByName.get(name) | toListFormMetaSelect
                            ).options
                          "
                          [value]="form">
                          {{ form }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="filtersForm?.get(name)?.invalid">
                        <span
                          *ngIf="filtersForm?.get(name)?.errors?.['required']">
                          {{ 'COMMON.REQUIRED_FIELD' | translate }}
                        </span>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>
                </div>
              }
            }

            <div [ngClass]="{ 'flex-list__action--hidden': first }">
              <button
                (click)="deleteForm(i)"
                [disabled]="first"
                tabindex="-1"
                mat-icon-button>
                <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>
