import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core';
import { BaseGeocodingService } from '@app/core/services/base-geocoding.service';
import { catchError, map, Observable } from 'rxjs';
import {
  COUNTRY_CODE,
  GeocodingResponseDto,
  ISO3166_2_LVL4,
  NominatimResponseDto,
} from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class NominatimService implements BaseGeocodingService {
  public static readonly ADDRESS_DETAILS = 1;

  public static readonly DEFAULT_FORMAT = 'json';

  public static readonly NOMINATIM_API_BASE_URL =
    'https://nominatim.openstreetmap.org/';

  #httpClient: HttpClient;
  constructor(private handler: HttpBackend) {
    this.#httpClient = new HttpClient(handler);
  }

  readonly #errorHandlerService = inject(ErrorHandlerService);

  public search(
    query: string,
    countryCode: COUNTRY_CODE
  ): Observable<GeocodingResponseDto[]> {
    let params = new HttpParams();

    params = params.set('q', query);
    params = params.set('format', NominatimService.DEFAULT_FORMAT);
    params = params.set(
      'addressdetails',
      NominatimService.ADDRESS_DETAILS.toString()
    );
    params = params.set('countrycodes', countryCode.toString());

    return this.#httpClient
      .get<
        NominatimResponseDto[]
      >(`${NominatimService.NOMINATIM_API_BASE_URL}search?`, { params })
      .pipe(map(response => this.mapToGeocodingResponse(response)));
  }

  public searchByID(id: string): Observable<GeocodingResponseDto | undefined> {
    return this.#httpClient
      .get<
        NominatimResponseDto[]
      >(`${NominatimService.NOMINATIM_API_BASE_URL}lookup?osm_ids=${id}&format=${NominatimService.DEFAULT_FORMAT}`)
      .pipe(
        catchError(error => this.#errorHandlerService.handleError(error)),
        map((response: NominatimResponseDto[]) =>
          response.filter(geocodingResponse => !!geocodingResponse.osm_type)
        ),
        map(response => this.mapToGeocodingResponse(response)),
        map((response: GeocodingResponseDto[]) => {
          if (response.length > 0) {
            return response[0];
          }
          return undefined;
        })
      );
  }

  public searchByCoordinates(
    latitude: number,
    longitude: number
  ): Observable<GeocodingResponseDto> {
    return this.#httpClient
      .get<NominatimResponseDto>(
        `${NominatimService.NOMINATIM_API_BASE_URL}reverse?lat=${latitude}&lon=${longitude}&format=${NominatimService.DEFAULT_FORMAT}`
      )
      .pipe(
        catchError(error => this.#errorHandlerService.handleError(error)),
        map(response => this.mapToGeocodingResponseDto(response))
      );
  }

  private mapToGeocodingResponse(
    response: NominatimResponseDto[]
  ): GeocodingResponseDto[] {
    return response
      .filter(nominatimDto => !!nominatimDto.osm_type)
      .map(nominatimDto => {
        return this.mapToGeocodingResponseDto(nominatimDto);
      });
  }

  private mapToGeocodingResponseDto(
    nominatimDto: NominatimResponseDto
  ): GeocodingResponseDto {
    return {
      id: `${[...nominatimDto.osm_type].slice(0, 1)[0]}${nominatimDto.osm_id}`,
      displayName: nominatimDto.display_name,
      latitude: nominatimDto.lat,
      longitude: nominatimDto.lon,
      importance: nominatimDto.importance,
      address: {
        countryCode: nominatimDto.address?.country_code,
        ['ISO3166-2-lvl4']: nominatimDto.address?.[
          'ISO3166-2-lvl4'
        ] as ISO3166_2_LVL4,
        road: nominatimDto.address?.road,
      },
    };
  }
}
