import { FORM_NAME } from '../search-card';

export class SearchValuesSortComparer {
  static readonly #order: Map<FORM_NAME, number> = new Map([
    [FORM_NAME.COUNTRY_CODE, 0],
    [FORM_NAME.CITY_OR_AREA, 1],
    [FORM_NAME.RADIUS_IN_METER, 2],
    [FORM_NAME.AMOUNT_OF_CHARGING_STATIONS, 3],
    [FORM_NAME.MAX_CAPACITY_IN_KW, 4],
    [FORM_NAME.RANK_BY, 5],
  ]);

  public static compare(a: string, b: string): number {
    const aKey = a as FORM_NAME;
    const bKey = b as FORM_NAME;
    if (
      !SearchValuesSortComparer.#order.has(aKey) &&
      !SearchValuesSortComparer.#order.has(bKey)
    ) {
      return 0;
    }
    if (!SearchValuesSortComparer.#order.has(aKey)) {
      return 1;
    }
    if (!SearchValuesSortComparer.#order.has(bKey)) {
      return -1;
    }
    return (
      SearchValuesSortComparer.#order.get(aKey)! -
      SearchValuesSortComparer.#order.get(bKey)!
    );
  }
}
