import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { ErrorHandlerService, ProjectService } from '@app/core';
import { DIALOG_EVENT } from '@app/shared/dialogs/dialog-event.enum';
import { ProjectFromErrorPipe } from '@app/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { catchError } from 'rxjs';
import { DialogBase } from '../dialog-base.class';
import { DialogData } from '../dialog-data.interface';

@Component({
  selector: 'app-update-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    ProjectFromErrorPipe,
  ],
  templateUrl: './update-project-dialog.component.html',
  styleUrls: [],
})
export class UpdateProjectDialogComponent extends DialogBase {
  readonly #destroyRef = inject(DestroyRef);
  public isLoading = false;
  public projectForm: FormGroup;
  public initialData: DialogData;
  readonly #fb = inject(FormBuilder);
  readonly #projectService = inject(ProjectService);
  readonly #errorHandlerService = inject(ErrorHandlerService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public override data: DialogData,
    public override readonly dialogRef: MatDialogRef<UpdateProjectDialogComponent>
  ) {
    super(dialogRef, data);
    this.initialData = data;
    this.projectForm = this.#fb.group({
      name: [
        this.data.project.name,
        [
          Validators.required,
          Validators.maxLength(DialogBase.MAX_LENGTH_FOR_PROJECT_NAME),
        ],
      ],
    });
  }

  public updateProject(): void {
    const name = this.projectForm.value.name;

    this.#projectService
      .updateProject(this.initialData.project.id, {
        name,
      })
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError(error => this.#errorHandlerService.handleError(error))
      )
      .subscribe({
        next: () => {
          this.data = {
            project: {
              ...this.data.project,
              name: this.projectForm.get('name')?.value,
            },
            event: DIALOG_EVENT.UPDATE,
          };
          this.closeDialog();
        },
        error: () => {
          this.isLoading = false;
          this.closeDialog();
        },
      });
  }
}
