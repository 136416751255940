import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-header',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
  ],
  templateUrl: './dialog-header.component.html',
  styleUrls: [],
})
export class DialogHeaderComponent {
  @Input() public headlineToolTip = '';
  @Output() public closeButtonClicked = new EventEmitter<never>();

  public onCloseButtonClick(): void {
    this.closeButtonClicked.emit();
  }
}
