<ng-container *ngIf="radiusFormGroup">
  <form [formGroup]="radiusFormGroup">
    <mat-form-field color="accent">
      <mat-label>{{ 'SEARCH_CARD.RADIUS_OF' | translate }}</mat-label>
      <mat-select
        [placeholder]="'SEARCH_CARD.RADIUS_OF' | translate"
        [formControlName]="FORM_NAME.RADIUS_IN_METER"
        [attr.data-testid]="FORM_NAME.RADIUS_IN_METER">
        <mat-option *ngFor="let radius of radiusOptions" [value]="radius">{{
          'SEARCH_CARD.RADIUS_KM' | translate: { radius: radius / 1000 }
        }}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          radiusFormGroup?.invalid &&
          (radiusFormGroup?.dirty || radiusFormGroup?.touched)
        ">
        <span
          *ngIf="
            radiusFormGroup.get(FORM_NAME.RADIUS_IN_METER)?.errors?.['required']
          ">
          {{ 'COMMON.REQUIRED_FIELD' | translate }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>
</ng-container>
