import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentService, ErrorHandlerService } from '@app/core';
import { catchError, Observable } from 'rxjs';
import {
  LocationDto,
  LocationEstimationRequestDto,
  LocationImportDto,
  LocationImportStatusDto,
  LocationRecommendationDto,
  LocationRecommendationRequestDto,
  LocationRequestDto,
  StationDto,
} from '@app/shared';
import { RecommendationDtoWrapper } from '@app/map';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public static readonly DUMMY_ID_FOR_ESTIMATION = '-1';

  private readonly BASE_URL!: string;

  readonly #httpClient = inject(HttpClient);
  readonly #environment = inject(EnvironmentService);
  readonly #errorHandlerService = inject(ErrorHandlerService);

  constructor() {
    this.BASE_URL = `${this.#environment.apiUrl}/locations`;
  }

  public loadEstimation(
    request: LocationEstimationRequestDto
  ): Observable<RecommendationDtoWrapper> {
    return this.#httpClient.post<RecommendationDtoWrapper>(
      `${this.BASE_URL}/estimations`,
      request
    );
  }

  public createLocation(request: LocationRequestDto): Observable<LocationDto> {
    return this.#httpClient.post<LocationDto>(this.BASE_URL, request);
  }

  public importLocations(
    projectName: string,
    file: File
  ): Observable<LocationImportDto> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('projectName', projectName);

    return this.#httpClient.post<LocationImportDto>(
      `${this.BASE_URL}/import`,
      formData
    );
  }

  public loadLocationImportStatus(
    locationImport: LocationImportDto
  ): Observable<LocationImportStatusDto> {
    return this.#httpClient
      .get<LocationImportStatusDto>(
        `${this.BASE_URL}/import/status/${locationImport.importId}`
      )
      .pipe(
        catchError(error => {
          return this.#errorHandlerService.handleError(error);
        })
      );
  }

  public updateLocationStation(
    locationId: string,
    stations: StationDto[]
  ): Observable<LocationDto> {
    const url = `${this.BASE_URL}/${locationId}/manage-stations`;
    return this.#httpClient.put<LocationDto>(url, stations);
  }

  public loadLocations(projectId: string) {
    return this.#httpClient.get<LocationDto[]>(this.BASE_URL, {
      params: {
        // eslint-disable-next-line camelcase
        project_id: projectId,
      },
    });
  }

  public deleteLocation(id: string): Observable<boolean> {
    return this.#httpClient.delete<boolean>(`${this.BASE_URL}/${id}`);
  }

  public loadRecommendations(request: LocationRecommendationRequestDto) {
    return this.#httpClient.post<LocationRecommendationDto[]>(
      `${this.BASE_URL}/recommendations`,
      request
    );
  }
}
