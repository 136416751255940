import { Area } from './area.dto';

export enum SubscriptionPlanName {
  LIGHT = 'LIGHT',
  ENTERPRISE = 'ENTERPRISE',
  DEMO = 'DEMO',
}
export interface SubscriptionPlanInfo {
  areas: Area[];
}

export interface AllowedRegion {
  id: string;
  region: string;
  // TODO: check if typing possible
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  geoBoundary: any;
}
export interface SubscriptionPlanDetails {
  info?: SubscriptionPlanInfo;
  allowedRegions?: AllowedRegion[];
}

export interface SubscriptionPlan {
  name: SubscriptionPlanName;
  details?: SubscriptionPlanDetails;
  expired: boolean;
  expiry: Date;
}
