<ng-container *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <mat-form-field color="accent">
      <mat-label>{{
        'SEARCH_CARD.AREA_OR_CITY_OR_ADDRESS' | translate
      }}</mat-label>
      <mat-select
        [formControlName]="FORM_NAME.CITY_OR_AREA"
        [placeholder]="'SEARCH_CARD.AREA_OR_CITY_OR_ADDRESS' | translate">
        <mat-option
          *ngFor="
            let area of user()?.subscriptionPlan?.details?.info?.areas;
            let i = index
          "
          [value]="i"
          >{{ area.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</ng-container>
