;
(function () {
  var data = {
      BD: {
        name: "Bangladesh",
        sub: {
          "BD-E": {
            type: "Division",
            name: "Rajshahi"
          },
          "BD-41": {
            type: "District",
            name: "Netrakona"
          },
          "BD-G": {
            type: "Division",
            name: "Sylhet"
          },
          "BD-38": {
            type: "District",
            name: "Moulvibazar"
          },
          "BD-A": {
            type: "Division",
            name: "Barisal"
          },
          "BD-C": {
            type: "Division",
            name: "Dhaka"
          },
          "BD-B": {
            type: "Division",
            name: "Chittagong"
          },
          "BD-D": {
            type: "Division",
            name: "Khulna"
          },
          "BD-46": {
            type: "District",
            name: "Nilphamari"
          },
          "BD-59": {
            type: "District",
            name: "Sirajganj"
          },
          "BD-55": {
            type: "District",
            name: "Rangpur"
          },
          "BD-47": {
            type: "District",
            name: "Noakhali"
          },
          "BD-60": {
            type: "District",
            name: "Sylhet"
          },
          "BD-49": {
            type: "District",
            name: "Pabna"
          },
          "BD-62": {
            type: "District",
            name: "Shariatpur"
          },
          "BD-63": {
            type: "District",
            name: "Tangail"
          },
          "BD-64": {
            type: "District",
            name: "Thakurgaon"
          },
          "BD-22": {
            type: "District",
            name: "Jessore"
          },
          "BD-36": {
            type: "District",
            name: "Madaripur"
          },
          "BD-61": {
            type: "District",
            name: "Sunamganj"
          },
          "BD-23": {
            type: "District",
            name: "Jhenaidah"
          },
          "BD-06": {
            type: "District",
            name: "Barisal"
          },
          "BD-07": {
            type: "District",
            name: "Bhola"
          },
          "BD-04": {
            type: "District",
            name: "Brahmanbaria"
          },
          "BD-05": {
            type: "District",
            name: "Bagerhat"
          },
          "BD-02": {
            type: "District",
            name: "Barguna"
          },
          "BD-03": {
            type: "District",
            name: "Bogra"
          },
          "BD-01": {
            type: "District",
            name: "Bandarban"
          },
          "BD-24": {
            type: "District",
            name: "Jaipurhat"
          },
          "BD-25": {
            type: "District",
            name: "Jhalakati"
          },
          "BD-26": {
            type: "District",
            name: "Kishoreganj"
          },
          "BD-27": {
            type: "District",
            name: "Khulna"
          },
          "BD-20": {
            type: "District",
            name: "Habiganj"
          },
          "BD-21": {
            type: "District",
            name: "Jamalpur"
          },
          "BD-08": {
            type: "District",
            name: "Comilla"
          },
          "BD-09": {
            type: "District",
            name: "Chandpur"
          },
          "BD-35": {
            type: "District",
            name: "Munshiganj"
          },
          "BD-54": {
            type: "District",
            name: "Rajshahi"
          },
          "BD-33": {
            type: "District",
            name: "Manikganj"
          },
          "BD-58": {
            type: "District",
            name: "Satkhira"
          },
          "BD-F": {
            type: "Division",
            name: "Rangpur"
          },
          "BD-32": {
            type: "District",
            name: "Lalmonirhat"
          },
          "BD-31": {
            type: "District",
            name: "Lakshmipur"
          },
          "BD-53": {
            type: "District",
            name: "Rajbari"
          },
          "BD-30": {
            type: "District",
            name: "Kushtia"
          },
          "BD-28": {
            type: "District",
            name: "Kurigram"
          },
          "BD-44": {
            type: "District",
            name: "Natore"
          },
          "BD-48": {
            type: "District",
            name: "Naogaon"
          },
          "BD-29": {
            type: "District",
            name: "Khagrachari"
          },
          "BD-15": {
            type: "District",
            name: "Faridpur"
          },
          "BD-14": {
            type: "District",
            name: "Dinajpur"
          },
          "BD-17": {
            type: "District",
            name: "Gopalganj"
          },
          "BD-16": {
            type: "District",
            name: "Feni"
          },
          "BD-11": {
            type: "District",
            name: "Cox's Bazar"
          },
          "BD-10": {
            type: "District",
            name: "Chittagong"
          },
          "BD-13": {
            type: "District",
            name: "Dhaka"
          },
          "BD-12": {
            type: "District",
            name: "Chuadanga"
          },
          "BD-51": {
            type: "District",
            name: "Patuakhali"
          },
          "BD-50": {
            type: "District",
            name: "Pirojpur"
          },
          "BD-39": {
            type: "District",
            name: "Meherpur"
          },
          "BD-34": {
            type: "District",
            name: "Mymensingh"
          },
          "BD-19": {
            type: "District",
            name: "Gaibandha"
          },
          "BD-18": {
            type: "District",
            name: "Gazipur"
          },
          "BD-57": {
            type: "District",
            name: "Sherpur"
          },
          "BD-52": {
            type: "District",
            name: "Panchagarh"
          },
          "BD-42": {
            type: "District",
            name: "Narsingdi"
          },
          "BD-45": {
            type: "District",
            name: "Nawabganj"
          },
          "BD-43": {
            type: "District",
            name: "Narail"
          },
          "BD-37": {
            type: "District",
            name: "Magura"
          },
          "BD-40": {
            type: "District",
            name: "Narayanganj"
          },
          "BD-56": {
            type: "District",
            name: "Rangamati"
          }
        }
      },
      BE: {
        name: "Belgium",
        sub: {
          "BE-VWV": {
            type: "Province",
            name: "West-Vlaanderen"
          },
          "BE-VAN": {
            type: "Province",
            name: "Antwerpen"
          },
          "BE-VLG": {
            type: "Region",
            name: "Vlaams Gewest"
          },
          "BE-VLI": {
            type: "Province",
            name: "Limburg"
          },
          "BE-WBR": {
            type: "Province",
            name: "Brabant wallon"
          },
          "BE-VBR": {
            type: "Province",
            name: "Vlaams-Brabant"
          },
          "BE-VOV": {
            type: "Province",
            name: "Oost-Vlaanderen"
          },
          "BE-WLG": {
            type: "Province",
            name: "Liège"
          },
          "BE-WLX": {
            type: "Province",
            name: "Luxembourg"
          },
          "BE-WHT": {
            type: "Province",
            name: "Hainaut"
          },
          "BE-WAL": {
            type: "Region",
            name: "wallonne, Région"
          },
          "BE-BRU": {
            type: "Region",
            name: "Brussels Hoofdstedelijk Gewest"
          },
          "BE-WNA": {
            type: "Province",
            name: "Namur"
          }
        }
      },
      BF: {
        name: "Burkina Faso",
        sub: {
          "BF-SEN": {
            type: "Province",
            name: "Séno"
          },
          "BF-KEN": {
            type: "Province",
            name: "Kénédougou"
          },
          "BF-BLK": {
            type: "Province",
            name: "Boulkiemdé"
          },
          "BF-NAM": {
            type: "Province",
            name: "Namentenga"
          },
          "BF-NAO": {
            type: "Province",
            name: "Nahouri"
          },
          "BF-BLG": {
            type: "Province",
            name: "Boulgou"
          },
          "BF-KOP": {
            type: "Province",
            name: "Koulpélogo"
          },
          "BF-KAD": {
            type: "Province",
            name: "Kadiogo"
          },
          "BF-01": {
            type: "Region",
            name: "Boucle du Mouhoun"
          },
          "BF-02": {
            type: "Region",
            name: "Cascades"
          },
          "BF-03": {
            type: "Region",
            name: "Centre"
          },
          "BF-04": {
            type: "Region",
            name: "Centre-Est"
          },
          "BF-05": {
            type: "Region",
            name: "Centre-Nord"
          },
          "BF-06": {
            type: "Region",
            name: "Centre-Ouest"
          },
          "BF-07": {
            type: "Region",
            name: "Centre-Sud"
          },
          "BF-08": {
            type: "Region",
            name: "Est"
          },
          "BF-09": {
            type: "Region",
            name: "Hauts-Bassins"
          },
          "BF-YAT": {
            type: "Province",
            name: "Yatenga"
          },
          "BF-PAS": {
            type: "Province",
            name: "Passoré"
          },
          "BF-YAG": {
            type: "Province",
            name: "Yagha"
          },
          "BF-IOB": {
            type: "Province",
            name: "Ioba"
          },
          "BF-GNA": {
            type: "Province",
            name: "Gnagna"
          },
          "BF-PON": {
            type: "Province",
            name: "Poni"
          },
          "BF-HOU": {
            type: "Province",
            name: "Houet"
          },
          "BF-LER": {
            type: "Province",
            name: "Léraba"
          },
          "BF-KMD": {
            type: "Province",
            name: "Komondjari"
          },
          "BF-SMT": {
            type: "Province",
            name: "Sanmatenga"
          },
          "BF-ZON": {
            type: "Province",
            name: "Zondoma"
          },
          "BF-MOU": {
            type: "Province",
            name: "Mouhoun"
          },
          "BF-COM": {
            type: "Province",
            name: "Comoé"
          },
          "BF-TUI": {
            type: "Province",
            name: "Tui"
          },
          "BF-SIS": {
            type: "Province",
            name: "Sissili"
          },
          "BF-GAN": {
            type: "Province",
            name: "Ganzourgou"
          },
          "BF-BGR": {
            type: "Province",
            name: "Bougouriba"
          },
          "BF-SOR": {
            type: "Province",
            name: "Sourou"
          },
          "BF-ZIR": {
            type: "Province",
            name: "Ziro"
          },
          "BF-KOW": {
            type: "Province",
            name: "Kourwéogo"
          },
          "BF-SOM": {
            type: "Province",
            name: "Soum"
          },
          "BF-KOT": {
            type: "Province",
            name: "Kouritenga"
          },
          "BF-13": {
            type: "Region",
            name: "Sud-Ouest"
          },
          "BF-12": {
            type: "Region",
            name: "Sahel"
          },
          "BF-11": {
            type: "Region",
            name: "Plateau-Central"
          },
          "BF-10": {
            type: "Region",
            name: "Nord"
          },
          "BF-ZOU": {
            type: "Province",
            name: "Zoundwéogo"
          },
          "BF-LOR": {
            type: "Province",
            name: "Loroum"
          },
          "BF-BAL": {
            type: "Province",
            name: "Balé"
          },
          "BF-BAM": {
            type: "Province",
            name: "Bam"
          },
          "BF-BAN": {
            type: "Province",
            name: "Banwa"
          },
          "BF-OUB": {
            type: "Province",
            name: "Oubritenga"
          },
          "BF-OUD": {
            type: "Province",
            name: "Oudalan"
          },
          "BF-NAY": {
            type: "Province",
            name: "Nayala"
          },
          "BF-NOU": {
            type: "Province",
            name: "Noumbiel"
          },
          "BF-KOS": {
            type: "Province",
            name: "Kossi"
          },
          "BF-TAP": {
            type: "Province",
            name: "Tapoa"
          },
          "BF-BAZ": {
            type: "Province",
            name: "Bazèga"
          },
          "BF-SNG": {
            type: "Province",
            name: "Sanguié"
          },
          "BF-KMP": {
            type: "Province",
            name: "Kompienga"
          },
          "BF-GOU": {
            type: "Province",
            name: "Gourma"
          }
        }
      },
      BG: {
        name: "Bulgaria",
        sub: {
          "BG-14": {
            type: "Region",
            name: "Pernik"
          },
          "BG-15": {
            type: "Region",
            name: "Pleven"
          },
          "BG-16": {
            type: "Region",
            name: "Plovdiv"
          },
          "BG-17": {
            type: "Region",
            name: "Razgrad"
          },
          "BG-10": {
            type: "Region",
            name: "Kyustendil"
          },
          "BG-11": {
            type: "Region",
            name: "Lovech"
          },
          "BG-12": {
            type: "Region",
            name: "Montana"
          },
          "BG-13": {
            type: "Region",
            name: "Pazardzhik"
          },
          "BG-18": {
            type: "Region",
            name: "Ruse"
          },
          "BG-19": {
            type: "Region",
            name: "Silistra"
          },
          "BG-25": {
            type: "Region",
            name: "Targovishte"
          },
          "BG-24": {
            type: "Region",
            name: "Stara Zagora"
          },
          "BG-27": {
            type: "Region",
            name: "Shumen"
          },
          "BG-07": {
            type: "Region",
            name: "Gabrovo"
          },
          "BG-06": {
            type: "Region",
            name: "Vratsa"
          },
          "BG-23": {
            type: "Region",
            name: "Sofia"
          },
          "BG-04": {
            type: "Region",
            name: "Veliko Tarnovo"
          },
          "BG-09": {
            type: "Region",
            name: "Kardzhali"
          },
          "BG-08": {
            type: "Region",
            name: "Dobrich"
          },
          "BG-28": {
            type: "Region",
            name: "Yambol"
          },
          "BG-03": {
            type: "Region",
            name: "Varna"
          },
          "BG-02": {
            type: "Region",
            name: "Burgas"
          },
          "BG-01": {
            type: "Region",
            name: "Blagoevgrad"
          },
          "BG-26": {
            type: "Region",
            name: "Haskovo"
          },
          "BG-21": {
            type: "Region",
            name: "Smolyan"
          },
          "BG-20": {
            type: "Region",
            name: "Sliven"
          },
          "BG-05": {
            type: "Region",
            name: "Vidin"
          },
          "BG-22": {
            type: "Region",
            name: "Sofia"
          }
        }
      },
      BA: {
        name: "Bosnia and Herzegovina",
        sub: {
          "BA-BRC": {
            type: "District with special status",
            name: "Brčko distrikt"
          },
          "BA-10": {
            type: "Canton",
            name: "Kanton br. 10"
          },
          "BA-BIH": {
            type: "Entity",
            name: "Federacija Bosne i Hercegovine"
          },
          "BA-09": {
            type: "Canton",
            name: "Kanton Sarajevo"
          },
          "BA-08": {
            type: "Canton",
            name: "Zapadnohercegovačka županija"
          },
          "BA-SRP": {
            type: "Entity",
            name: "Republika Srpska"
          },
          "BA-05": {
            type: "Canton",
            name: "Bosansko-podrinjska županija"
          },
          "BA-04": {
            type: "Canton",
            name: "Zeničko-dobojska županija"
          },
          "BA-07": {
            type: "Canton",
            name: "Hercegovačko-neretvanska županija"
          },
          "BA-06": {
            type: "Canton",
            name: "Srednjobosanska županija"
          },
          "BA-01": {
            type: "Canton",
            name: "Unsko-sanska županija"
          },
          "BA-03": {
            type: "Canton",
            name: "Tuzlanska županija"
          },
          "BA-02": {
            type: "Canton",
            name: "Posavska županija"
          }
        }
      },
      BB: {
        name: "Barbados",
        sub: {
          "BB-11": {
            type: "Parish",
            name: "Saint Thomas"
          },
          "BB-10": {
            type: "Parish",
            name: "Saint Philip"
          },
          "BB-08": {
            type: "Parish",
            name: "Saint Michael"
          },
          "BB-09": {
            type: "Parish",
            name: "Saint Peter"
          },
          "BB-04": {
            type: "Parish",
            name: "Saint James"
          },
          "BB-05": {
            type: "Parish",
            name: "Saint John"
          },
          "BB-06": {
            type: "Parish",
            name: "Saint Joseph"
          },
          "BB-07": {
            type: "Parish",
            name: "Saint Lucy"
          },
          "BB-01": {
            type: "Parish",
            name: "Christ Church"
          },
          "BB-02": {
            type: "Parish",
            name: "Saint Andrew"
          },
          "BB-03": {
            type: "Parish",
            name: "Saint George"
          }
        }
      },
      WF: {
        name: "Wallis & Futuna Islands",
        sub: {
          "WF-WF": {
            type: "Country",
            name: "Wallis & Futuna Islands"
          }
        }
      },
      BL: {
        name: "St. Barthélemy",
        sub: {
          "BL-BL": {
            type: "Country",
            name: "St. Barthélemy"
          }
        }
      },
      BM: {
        name: "Bermuda",
        sub: {
          "BM-BM": {
            type: "Country",
            name: "Bermuda"
          }
        }
      },
      BN: {
        name: "Brunei Darussalam",
        sub: {
          "BN-TE": {
            type: "District",
            name: "Temburong"
          },
          "BN-BM": {
            type: "District",
            name: "Brunei-Muara"
          },
          "BN-TU": {
            type: "District",
            name: "Tutong"
          },
          "BN-BE": {
            type: "District",
            name: "Belait"
          }
        }
      },
      BO: {
        name: "Bolivia",
        sub: {
          "BO-B": {
            type: "Department",
            name: "El Beni"
          },
          "BO-C": {
            type: "Department",
            name: "Cochabamba"
          },
          "BO-N": {
            type: "Department",
            name: "Pando"
          },
          "BO-O": {
            type: "Department",
            name: "Oruro"
          },
          "BO-L": {
            type: "Department",
            name: "La Paz"
          },
          "BO-H": {
            type: "Department",
            name: "Chuquisaca"
          },
          "BO-T": {
            type: "Department",
            name: "Tarija"
          },
          "BO-S": {
            type: "Department",
            name: "Santa Cruz"
          },
          "BO-P": {
            type: "Department",
            name: "Potosí"
          }
        }
      },
      BH: {
        name: "Bahrain",
        sub: {
          "BH-13": {
            type: "Governorate",
            name: "Al Manāmah"
          },
          "BH-15": {
            type: "Governorate",
            name: "Al Muḩarraq"
          },
          "BH-14": {
            type: "Governorate",
            name: "Al Janūbīyah"
          },
          "BH-17": {
            type: "Governorate",
            name: "Ash Shamālīyah"
          },
          "BH-16": {
            type: "Governorate",
            name: "Al Wusţá"
          }
        }
      },
      BI: {
        name: "Burundi",
        sub: {
          "BI-BB": {
            type: "Province",
            name: "Bubanza"
          },
          "BI-MA": {
            type: "Province",
            name: "Makamba"
          },
          "BI-CI": {
            type: "Province",
            name: "Cibitoke"
          },
          "BI-KR": {
            type: "Province",
            name: "Karuzi"
          },
          "BI-NG": {
            type: "Province",
            name: "Ngozi"
          },
          "BI-RY": {
            type: "Province",
            name: "Ruyigi"
          },
          "BI-RT": {
            type: "Province",
            name: "Rutana"
          },
          "BI-CA": {
            type: "Province",
            name: "Cankuzo"
          },
          "BI-BM": {
            type: "Province",
            name: "Bujumbura Mairie"
          },
          "BI-BL": {
            type: "Province",
            name: "Bujumbura Rural"
          },
          "BI-BR": {
            type: "Province",
            name: "Bururi"
          },
          "BI-MW": {
            type: "Province",
            name: "Mwaro"
          },
          "BI-KI": {
            type: "Province",
            name: "Kirundo"
          },
          "BI-MU": {
            type: "Province",
            name: "Muramvya"
          },
          "BI-GI": {
            type: "Province",
            name: "Gitega"
          },
          "BI-MY": {
            type: "Province",
            name: "Muyinga"
          },
          "BI-KY": {
            type: "Province",
            name: "Kayanza"
          }
        }
      },
      BJ: {
        name: "Benin",
        sub: {
          "BJ-AK": {
            type: "Department",
            name: "Atakora"
          },
          "BJ-BO": {
            type: "Department",
            name: "Borgou"
          },
          "BJ-CO": {
            type: "Department",
            name: "Collines"
          },
          "BJ-AL": {
            type: "Department",
            name: "Alibori"
          },
          "BJ-MO": {
            type: "Department",
            name: "Mono"
          },
          "BJ-LI": {
            type: "Department",
            name: "Littoral"
          },
          "BJ-ZO": {
            type: "Department",
            name: "Zou"
          },
          "BJ-OU": {
            type: "Department",
            name: "Ouémé"
          },
          "BJ-PL": {
            type: "Department",
            name: "Plateau"
          },
          "BJ-DO": {
            type: "Department",
            name: "Donga"
          },
          "BJ-AQ": {
            type: "Department",
            name: "Atlantique"
          },
          "BJ-KO": {
            type: "Department",
            name: "Kouffo"
          }
        }
      },
      BT: {
        name: "Bhutan",
        sub: {
          "BT-33": {
            type: "District",
            name: "Bumthang"
          },
          "BT-42": {
            type: "District",
            name: "Monggar"
          },
          "BT-GA": {
            type: "District",
            name: "Gasa"
          },
          "BT-32": {
            type: "District",
            name: "Trongsa"
          },
          "BT-34": {
            type: "District",
            name: "Zhemgang"
          },
          "BT-45": {
            type: "District",
            name: "Samdrup Jongkha"
          },
          "BT-15": {
            type: "District",
            name: "Thimphu"
          },
          "BT-14": {
            type: "District",
            name: "Samtse"
          },
          "BT-31": {
            type: "District",
            name: "Sarpang"
          },
          "BT-TY": {
            type: "District",
            name: "Trashi Yangtse"
          },
          "BT-11": {
            type: "District",
            name: "Paro"
          },
          "BT-44": {
            type: "District",
            name: "Lhuentse"
          },
          "BT-13": {
            type: "District",
            name: "Ha"
          },
          "BT-12": {
            type: "District",
            name: "Chhukha"
          },
          "BT-24": {
            type: "District",
            name: "Wangdue Phodrang"
          },
          "BT-43": {
            type: "District",
            name: "Pemagatshel"
          },
          "BT-41": {
            type: "District",
            name: "Trashigang"
          },
          "BT-21": {
            type: "District",
            name: "Tsirang"
          },
          "BT-22": {
            type: "District",
            name: "Dagana"
          },
          "BT-23": {
            type: "District",
            name: "Punakha"
          }
        }
      },
      JM: {
        name: "Jamaica",
        sub: {
          "JM-12": {
            type: "Parish",
            name: "Manchester"
          },
          "JM-14": {
            type: "Parish",
            name: "Saint Catherine"
          },
          "JM-11": {
            type: "Parish",
            name: "Saint Elizabeth"
          },
          "JM-10": {
            type: "Parish",
            name: "Westmoreland"
          },
          "JM-01": {
            type: "Parish",
            name: "Kingston"
          },
          "JM-13": {
            type: "Parish",
            name: "Clarendon"
          },
          "JM-03": {
            type: "Parish",
            name: "Saint Thomas"
          },
          "JM-02": {
            type: "Parish",
            name: "Saint Andrew"
          },
          "JM-05": {
            type: "Parish",
            name: "Saint Mary"
          },
          "JM-04": {
            type: "Parish",
            name: "Portland"
          },
          "JM-07": {
            type: "Parish",
            name: "Trelawny"
          },
          "JM-06": {
            type: "Parish",
            name: "Saint Ann"
          },
          "JM-09": {
            type: "Parish",
            name: "Hanover"
          },
          "JM-08": {
            type: "Parish",
            name: "Saint James"
          }
        }
      },
      BV: {
        name: "Bouvet Island",
        sub: {
          "BV-BV": {
            type: "Country",
            name: "Bouvet Island"
          }
        }
      },
      BW: {
        name: "Botswana",
        sub: {
          "BW-KW": {
            type: "District",
            name: "Kweneng"
          },
          "BW-JW": {
            type: "Town",
            name: "Jwaneng"
          },
          "BW-FR": {
            type: "City",
            name: "Francistown"
          },
          "BW-CH": {
            type: "District",
            name: "Chobe"
          },
          "BW-SO": {
            type: "District",
            name: "Southern"
          },
          "BW-LO": {
            type: "Town",
            name: "Lobatse"
          },
          "BW-ST": {
            type: "Town",
            name: "Sowa Town"
          },
          "BW-CE": {
            type: "District",
            name: "Central"
          },
          "BW-NW": {
            type: "District",
            name: "North West"
          },
          "BW-KG": {
            type: "District",
            name: "Kgalagadi"
          },
          "BW-GA": {
            type: "City",
            name: "Gaborone"
          },
          "BW-SP": {
            type: "Town",
            name: "Selibe Phikwe"
          },
          "BW-GH": {
            type: "District",
            name: "Ghanzi"
          },
          "BW-SE": {
            type: "District",
            name: "South East"
          },
          "BW-NE": {
            type: "District",
            name: "North East"
          },
          "BW-KL": {
            type: "District",
            name: "Kgatleng"
          }
        }
      },
      WS: {
        name: "Samoa",
        sub: {
          "WS-AA": {
            type: "District",
            name: "A'ana"
          },
          "WS-VF": {
            type: "District",
            name: "Va'a-o-Fonoti"
          },
          "WS-SA": {
            type: "District",
            name: "Satupa'itea"
          },
          "WS-FA": {
            type: "District",
            name: "Fa'asaleleaga"
          },
          "WS-VS": {
            type: "District",
            name: "Vaisigano"
          },
          "WS-AL": {
            type: "District",
            name: "Aiga-i-le-Tai"
          },
          "WS-GI": {
            type: "District",
            name: "Gagaifomauga"
          },
          "WS-PA": {
            type: "District",
            name: "Palauli"
          },
          "WS-AT": {
            type: "District",
            name: "Atua"
          },
          "WS-TU": {
            type: "District",
            name: "Tuamasaga"
          },
          "WS-GE": {
            type: "District",
            name: "Gaga'emauga"
          }
        }
      },
      BQ: {
        name: "Bonaire, Sint Eustatius and Saba",
        sub: {
          "BQ-SE": {
            type: "Special municipality",
            name: "Sint Eustatius"
          },
          "BQ-BO": {
            type: "Special municipality",
            name: "Bonaire"
          },
          "BQ-SA": {
            type: "Special municipality",
            name: "Saba"
          }
        }
      },
      BR: {
        name: "Brazil",
        sub: {
          "BR-RJ": {
            type: "State",
            name: "Rio de Janeiro"
          },
          "BR-BA": {
            type: "State",
            name: "Bahia"
          },
          "BR-SE": {
            type: "State",
            name: "Sergipe"
          },
          "BR-DF": {
            type: "Federal district",
            name: "Distrito Federal"
          },
          "BR-SP": {
            type: "State",
            name: "São Paulo"
          },
          "BR-SC": {
            type: "State",
            name: "Santa Catarina"
          },
          "BR-RR": {
            type: "State",
            name: "Roraima"
          },
          "BR-RS": {
            type: "State",
            name: "Rio Grande do Sul"
          },
          "BR-AP": {
            type: "State",
            name: "Amapá"
          },
          "BR-CE": {
            type: "State",
            name: "Ceará"
          },
          "BR-GO": {
            type: "State",
            name: "Goiás"
          },
          "BR-AM": {
            type: "State",
            name: "Amazonas"
          },
          "BR-AL": {
            type: "State",
            name: "Alagoas"
          },
          "BR-AC": {
            type: "State",
            name: "Acre"
          },
          "BR-PI": {
            type: "State",
            name: "Piauí"
          },
          "BR-RN": {
            type: "State",
            name: "Rio Grande do Norte"
          },
          "BR-RO": {
            type: "State",
            name: "Rondônia"
          },
          "BR-MT": {
            type: "State",
            name: "Mato Grosso"
          },
          "BR-MS": {
            type: "State",
            name: "Mato Grosso do Sul"
          },
          "BR-PA": {
            type: "State",
            name: "Pará"
          },
          "BR-PB": {
            type: "State",
            name: "Paraíba"
          },
          "BR-ES": {
            type: "State",
            name: "Espírito Santo"
          },
          "BR-PR": {
            type: "State",
            name: "Paraná"
          },
          "BR-PE": {
            type: "State",
            name: "Pernambuco"
          },
          "BR-MG": {
            type: "State",
            name: "Minas Gerais"
          },
          "BR-MA": {
            type: "State",
            name: "Maranhão"
          },
          "BR-TO": {
            type: "State",
            name: "Tocantins"
          }
        }
      },
      BS: {
        name: "Bahamas",
        sub: {
          "BS-SA": {
            type: "District",
            name: "South Andros"
          },
          "BS-EX": {
            type: "District",
            name: "Exuma"
          },
          "BS-CS": {
            type: "District",
            name: "Central Andros"
          },
          "BS-CK": {
            type: "District",
            name: "Crooked Island and Long Cay"
          },
          "BS-CI": {
            type: "District",
            name: "Cat Island"
          },
          "BS-GC": {
            type: "District",
            name: "Grand Cay"
          },
          "BS-EG": {
            type: "District",
            name: "East Grand Bahama"
          },
          "BS-CE": {
            type: "District",
            name: "Central Eleuthera"
          },
          "BS-RI": {
            type: "District",
            name: "Ragged Island"
          },
          "BS-RC": {
            type: "District",
            name: "Rum Cay"
          },
          "BS-AK": {
            type: "District",
            name: "Acklins"
          },
          "BS-MG": {
            type: "District",
            name: "Mayaguana"
          },
          "BS-IN": {
            type: "District",
            name: "Inagua"
          },
          "BS-MC": {
            type: "District",
            name: "Mangrove Cay"
          },
          "BS-MI": {
            type: "District",
            name: "Moore's Island"
          },
          "BS-BY": {
            type: "District",
            name: "Berry Islands"
          },
          "BS-FP": {
            type: "District",
            name: "City of Freeport"
          },
          "BS-BP": {
            type: "District",
            name: "Black Point"
          },
          "BS-LI": {
            type: "District",
            name: "Long Island"
          },
          "BS-BI": {
            type: "District",
            name: "Bimini"
          },
          "BS-WG": {
            type: "District",
            name: "West Grand Bahama"
          },
          "BS-NO": {
            type: "District",
            name: "North Abaco"
          },
          "BS-SO": {
            type: "District",
            name: "South Abaco"
          },
          "BS-NE": {
            type: "District",
            name: "North Eleuthera"
          },
          "BS-HT": {
            type: "District",
            name: "Hope Town"
          },
          "BS-SE": {
            type: "District",
            name: "South Eleuthera"
          },
          "BS-HI": {
            type: "District",
            name: "Harbour Island"
          },
          "BS-SS": {
            type: "District",
            name: "San Salvador"
          },
          "BS-SW": {
            type: "District",
            name: "Spanish Wells"
          },
          "BS-NS": {
            type: "District",
            name: "North Andros"
          },
          "BS-CO": {
            type: "District",
            name: "Central Abaco"
          }
        }
      },
      JE: {
        name: "Jersey",
        sub: {
          "JE-JE": {
            type: "Country",
            name: "Jersey"
          }
        }
      },
      BY: {
        name: "Belarus",
        sub: {
          "BY-HM": {
            type: "City",
            name: "Gorod Minsk"
          },
          "BY-BR": {
            type: "Oblast",
            name: "Bresckaja voblasć"
          },
          "BY-HO": {
            type: "Oblast",
            name: "Gomel'skaja oblast'"
          },
          "BY-MA": {
            type: "Oblast",
            name: "Mahilioŭskaja voblasć"
          },
          "BY-MI": {
            type: "Oblast",
            name: "Minskaja oblast'"
          },
          "BY-VI": {
            type: "Oblast",
            name: "Viciebskaja voblasć"
          },
          "BY-HR": {
            type: "Oblast",
            name: "Grodnenskaja oblast'"
          }
        }
      },
      BZ: {
        name: "Belize",
        sub: {
          "BZ-CY": {
            type: "District",
            name: "Cayo"
          },
          "BZ-CZL": {
            type: "District",
            name: "Corozal"
          },
          "BZ-SC": {
            type: "District",
            name: "Stann Creek"
          },
          "BZ-BZ": {
            type: "District",
            name: "Belize"
          },
          "BZ-TOL": {
            type: "District",
            name: "Toledo"
          },
          "BZ-OW": {
            type: "District",
            name: "Orange Walk"
          }
        }
      },
      RU: {
        name: "Russia",
        sub: {
          "RU-PNZ": {
            type: "Administrative region",
            name: "Penzenskaja oblast'"
          },
          "RU-KRS": {
            type: "Administrative region",
            name: "Kurskaja oblast'"
          },
          "RU-ULY": {
            type: "Administrative region",
            name: "Ul'janovskaja oblast'"
          },
          "RU-BEL": {
            type: "Administrative region",
            name: "Belgorodskaja oblast'"
          },
          "RU-SAK": {
            type: "Administrative region",
            name: "Sahalinskaja oblast'"
          },
          "RU-KYA": {
            type: "Administrative territory",
            name: "Krasnojarskij kraj"
          },
          "RU-STA": {
            type: "Administrative territory",
            name: "Stavropol'skij kraj"
          },
          "RU-IVA": {
            type: "Administrative region",
            name: "Ivanovskaja oblast'"
          },
          "RU-LIP": {
            type: "Administrative region",
            name: "Lipeckaja oblast'"
          },
          "RU-AST": {
            type: "Administrative region",
            name: "Astrahanskaja oblast'"
          },
          "RU-CE": {
            type: "Republic",
            name: "Čečenskaja Respublika"
          },
          "RU-KHA": {
            type: "Administrative territory",
            name: "Habarovskij kraj"
          },
          "RU-ORE": {
            type: "Administrative region",
            name: "Orenburgskaja oblast'"
          },
          "RU-VLG": {
            type: "Administrative region",
            name: "Vologodskaja oblast'"
          },
          "RU-YAR": {
            type: "Administrative region",
            name: "Jaroslavskaja oblast'"
          },
          "RU-NGR": {
            type: "Administrative region",
            name: "Novgorodskaja oblast'"
          },
          "RU-KLU": {
            type: "Administrative region",
            name: "Kaluzhskaya oblast'"
          },
          "RU-OMS": {
            type: "Administrative region",
            name: "Omskaja oblast'"
          },
          "RU-IRK": {
            type: "Administrative region",
            name: "Irkutskaja oblast'"
          },
          "RU-ORL": {
            type: "Administrative region",
            name: "Orlovskaja oblast'"
          },
          "RU-DA": {
            type: "Republic",
            name: "Dagestan, Respublika"
          },
          "RU-PRI": {
            type: "Administrative territory",
            name: "Primorskij kraj"
          },
          "RU-SAM": {
            type: "Administrative region",
            name: "Samarskaja oblast'"
          },
          "RU-SAR": {
            type: "Administrative region",
            name: "Saratovskaja oblast'"
          },
          "RU-KOS": {
            type: "Administrative region",
            name: "Kostromskaja oblast'"
          },
          "RU-SPE": {
            type: "Autonomous city",
            name: "Sankt-Peterburg"
          },
          "RU-NIZ": {
            type: "Administrative region",
            name: "Nižegorodskaja oblast'"
          },
          "RU-SA": {
            type: "Republic",
            name: "Saha, Respublika"
          },
          "RU-KHM": {
            type: "Autonomous district",
            name: "Hanty-Mansijskij avtonomnyj okrug"
          },
          "RU-LEN": {
            type: "Administrative region",
            name: "Leningradskaja oblast'"
          },
          "RU-CHE": {
            type: "Administrative region",
            name: "Čeljabinskaja oblast'"
          },
          "RU-BA": {
            type: "Republic",
            name: "Bashkortostan, Respublika"
          },
          "RU-SE": {
            type: "Republic",
            name: "Severnaja Osetija, Respublika"
          },
          "RU-MOS": {
            type: "Administrative region",
            name: "Moskovskaja oblast'"
          },
          "RU-YAN": {
            type: "Autonomous district",
            name: "Jamalo-Neneckij avtonomnyj okrug"
          },
          "RU-KGD": {
            type: "Administrative region",
            name: "Kaliningradskaja oblast'"
          },
          "RU-MOW": {
            type: "Autonomous city",
            name: "Moskva"
          },
          "RU-KAM": {
            type: "Administrative territory",
            name: "Kamčatskij kraj"
          },
          "RU-ARK": {
            type: "Administrative region",
            name: "Arhangel'skaja oblast'"
          },
          "RU-BU": {
            type: "Republic",
            name: "Burjatija, Respublika"
          },
          "RU-KEM": {
            type: "Administrative region",
            name: "Kemerovskaja oblast'"
          },
          "RU-CHU": {
            type: "Autonomous district",
            name: "Chukotskiy avtonomnyy okrug"
          },
          "RU-UD": {
            type: "Republic",
            name: "Udmurtskaja Respublika"
          },
          "RU-KGN": {
            type: "Administrative region",
            name: "Kurganskaja oblast'"
          },
          "RU-TUL": {
            type: "Administrative region",
            name: "Tul'skaja oblast'"
          },
          "RU-KIR": {
            type: "Administrative region",
            name: "Kirovskaja oblast'"
          },
          "RU-KR": {
            type: "Republic",
            name: "Karelija, Respublika"
          },
          "RU-ME": {
            type: "Republic",
            name: "Marij Èl, Respublika"
          },
          "RU-IN": {
            type: "Republic",
            name: "Ingušetija, Respublika"
          },
          "RU-MAG": {
            type: "Administrative region",
            name: "Magadanskaja oblast'"
          },
          "RU-MO": {
            type: "Republic",
            name: "Mordovija, Respublika"
          },
          "RU-TA": {
            type: "Republic",
            name: "Tatarstan, Respublika"
          },
          "RU-SVE": {
            type: "Administrative region",
            name: "Sverdlovskaja oblast'"
          },
          "RU-RYA": {
            type: "Administrative region",
            name: "Rjazanskaja oblast'"
          },
          "RU-ZAB": {
            type: "Administrative territory",
            name: "Zabajkal'skij kraj"
          },
          "RU-NEN": {
            type: "Autonomous district",
            name: "Neneckij avtonomnyj okrug"
          },
          "RU-KB": {
            type: "Republic",
            name: "Kabardino-Balkarskaja Respublika"
          },
          "RU-ALT": {
            type: "Administrative territory",
            name: "Altajskij kraj"
          },
          "RU-TY": {
            type: "Republic",
            name: "Tyva, Respublika"
          },
          "RU-MUR": {
            type: "Administrative region",
            name: "Murmanskaja oblast'"
          },
          "RU-VOR": {
            type: "Administrative region",
            name: "Voronezhskaya oblast'"
          },
          "RU-PSK": {
            type: "Administrative region",
            name: "Pskovskaja oblast'"
          },
          "RU-TVE": {
            type: "Administrative region",
            name: "Tverskaja oblast'"
          },
          "RU-VGG": {
            type: "Administrative region",
            name: "Volgogradskaja oblast'"
          },
          "RU-KK": {
            type: "Republic",
            name: "Hakasija, Respublika"
          },
          "RU-KL": {
            type: "Republic",
            name: "Kalmykija, Respublika"
          },
          "RU-TOM": {
            type: "Administrative region",
            name: "Tomskaja oblast'"
          },
          "RU-KO": {
            type: "Republic",
            name: "Komi, Respublika"
          },
          "RU-TYU": {
            type: "Administrative region",
            name: "Tjumenskaja oblast'"
          },
          "RU-TAM": {
            type: "Administrative region",
            name: "Tambovskaja oblast'"
          },
          "RU-NVS": {
            type: "Administrative region",
            name: "Novosibirskaja oblast'"
          },
          "RU-AD": {
            type: "Republic",
            name: "Adygeja, Respublika"
          },
          "RU-PER": {
            type: "Administrative territory",
            name: "Permskij kraj"
          },
          "RU-ROS": {
            type: "Administrative region",
            name: "Rostovskaja oblast'"
          },
          "RU-AMU": {
            type: "Administrative region",
            name: "Amurskaja oblast'"
          },
          "RU-AL": {
            type: "Republic",
            name: "Altaj, Respublika"
          },
          "RU-KC": {
            type: "Republic",
            name: "Karačaevo-Čerkesskaja Respublika"
          },
          "RU-KDA": {
            type: "Administrative territory",
            name: "Krasnodarskij kraj"
          },
          "RU-YEV": {
            type: "Autonomous region",
            name: "Evrejskaja avtonomnaja oblast'"
          },
          "RU-VLA": {
            type: "Administrative region",
            name: "Vladimirskaja oblast'"
          },
          "RU-BRY": {
            type: "Administrative region",
            name: "Brjanskaja oblast'"
          },
          "RU-SMO": {
            type: "Administrative region",
            name: "Smolenskaja oblast'"
          },
          "RU-CU": {
            type: "Republic",
            name: "Chuvashskaya Respublika"
          }
        }
      },
      RW: {
        name: "Rwanda",
        sub: {
          "RW-03": {
            type: "Province",
            name: "Nord"
          },
          "RW-02": {
            type: "Province",
            name: "Est"
          },
          "RW-01": {
            type: "Town council",
            name: "Ville de Kigal"
          },
          "RW-05": {
            type: "Province",
            name: "Sud"
          },
          "RW-04": {
            type: "Province",
            name: "Ouest"
          }
        }
      },
      RS: {
        name: "Serbia",
        sub: {
          "RS-07": {
            type: "District",
            name: "Sremski okrug"
          },
          "RS-06": {
            type: "District",
            name: "Južnobački okrug"
          },
          "RS-05": {
            type: "District",
            name: "Zapadnobački okrug"
          },
          "RS-04": {
            type: "District",
            name: "Južnobanatski okrug"
          },
          "RS-03": {
            type: "District",
            name: "Severnobanatski okrug"
          },
          "RS-02": {
            type: "District",
            name: "Srednjebanatski okrug"
          },
          "RS-01": {
            type: "District",
            name: "Severnobački okrug"
          },
          "RS-00": {
            type: "City",
            name: "Beograd"
          },
          "RS-09": {
            type: "District",
            name: "Kolubarski okrug"
          },
          "RS-08": {
            type: "District",
            name: "Mačvanski okrug"
          },
          "RS-29": {
            type: "District",
            name: "Kosovsko-Pomoravski okrug"
          },
          "RS-28": {
            type: "District",
            name: "Kosovsko-Mitrovački okrug"
          },
          "RS-21": {
            type: "District",
            name: "Toplički okrug"
          },
          "RS-20": {
            type: "District",
            name: "Nišavski okrug"
          },
          "RS-23": {
            type: "District",
            name: "Jablanički okrug"
          },
          "RS-22": {
            type: "District",
            name: "Pirotski okrug"
          },
          "RS-25": {
            type: "District",
            name: "Kosovski okrug"
          },
          "RS-24": {
            type: "District",
            name: "Pčinjski okrug"
          },
          "RS-27": {
            type: "District",
            name: "Prizrenski okrug"
          },
          "RS-26": {
            type: "District",
            name: "Pećki okrug"
          },
          "RS-VO": {
            type: "Autonomous province",
            name: "Vojvodina"
          },
          "RS-10": {
            type: "District",
            name: "Podunavski okrug"
          },
          "RS-11": {
            type: "District",
            name: "Braničevski okrug"
          },
          "RS-12": {
            type: "District",
            name: "Šumadijski okrug"
          },
          "RS-13": {
            type: "District",
            name: "Pomoravski okrug"
          },
          "RS-14": {
            type: "District",
            name: "Borski okrug"
          },
          "RS-15": {
            type: "District",
            name: "Zaječarski okrug"
          },
          "RS-16": {
            type: "District",
            name: "Zlatiborski okrug"
          },
          "RS-17": {
            type: "District",
            name: "Moravički okrug"
          },
          "RS-18": {
            type: "District",
            name: "Raški okrug"
          },
          "RS-19": {
            type: "District",
            name: "Rasinski okrug"
          },
          "RS-KM": {
            type: "Autonomous province",
            name: "Kosovo-Metohija"
          }
        }
      },
      TL: {
        name: "East Timor",
        sub: {
          "TL-ER": {
            type: "District",
            name: "Ermera"
          },
          "TL-LA": {
            type: "District",
            name: "Lautém"
          },
          "TL-OE": {
            type: "District",
            name: "Oecussi"
          },
          "TL-AN": {
            type: "District",
            name: "Ainaro"
          },
          "TL-AL": {
            type: "District",
            name: "Aileu"
          },
          "TL-MF": {
            type: "District",
            name: "Manufahi"
          },
          "TL-MT": {
            type: "District",
            name: "Manatuto"
          },
          "TL-VI": {
            type: "District",
            name: "Vikeke"
          },
          "TL-BO": {
            type: "District",
            name: "Bobonaro"
          },
          "TL-CO": {
            type: "District",
            name: "Cova Lima"
          },
          "TL-BA": {
            type: "District",
            name: "Baucau"
          },
          "TL-LI": {
            type: "District",
            name: "Likisá"
          },
          "TL-DI": {
            type: "District",
            name: "Díli"
          }
        }
      },
      RE: {
        name: "Reunion",
        sub: {
          "RE-RE": {
            type: "Country",
            name: "Reunion"
          }
        }
      },
      TM: {
        name: "Turkmenistan",
        sub: {
          "TM-S": {
            type: "City",
            name: "Aşgabat"
          },
          "TM-L": {
            type: "Region",
            name: "Lebap"
          },
          "TM-M": {
            type: "Region",
            name: "Mary"
          },
          "TM-D": {
            type: "Region",
            name: "Daşoguz"
          },
          "TM-B": {
            type: "Region",
            name: "Balkan"
          },
          "TM-A": {
            type: "Region",
            name: "Ahal"
          }
        }
      },
      TJ: {
        name: "Tajikistan",
        sub: {
          "TJ-GB": {
            type: "Autonomous region",
            name: "Kŭhistoni Badakhshon"
          },
          "TJ-SU": {
            type: "Region",
            name: "Sughd"
          },
          "TJ-DU": {
            type: "Capital territory",
            name: "Dushanbe"
          },
          "TJ-KT": {
            type: "Region",
            name: "Khatlon"
          }
        }
      },
      RO: {
        name: "Romania",
        sub: {
          "RO-SB": {
            type: "Department",
            name: "Sibiu"
          },
          "RO-DB": {
            type: "Department",
            name: "Dâmboviţa"
          },
          "RO-SM": {
            type: "Department",
            name: "Satu Mare"
          },
          "RO-SJ": {
            type: "Department",
            name: "Sălaj"
          },
          "RO-DJ": {
            type: "Department",
            name: "Dolj"
          },
          "RO-HD": {
            type: "Department",
            name: "Hunedoara"
          },
          "RO-SV": {
            type: "Department",
            name: "Suceava"
          },
          "RO-B": {
            type: "Municipality",
            name: "Bucureşti"
          },
          "RO-HR": {
            type: "Department",
            name: "Harghita"
          },
          "RO-VS": {
            type: "Department",
            name: "Vaslui"
          },
          "RO-NT": {
            type: "Department",
            name: "Neamţ"
          },
          "RO-CV": {
            type: "Department",
            name: "Covasna"
          },
          "RO-CT": {
            type: "Department",
            name: "Constanţa"
          },
          "RO-CS": {
            type: "Department",
            name: "Caraş-Severin"
          },
          "RO-GR": {
            type: "Department",
            name: "Giurgiu"
          },
          "RO-VN": {
            type: "Department",
            name: "Vrancea"
          },
          "RO-AR": {
            type: "Department",
            name: "Arad"
          },
          "RO-GJ": {
            type: "Department",
            name: "Gorj"
          },
          "RO-GL": {
            type: "Department",
            name: "Galaţi"
          },
          "RO-CL": {
            type: "Department",
            name: "Călăraşi"
          },
          "RO-AG": {
            type: "Department",
            name: "Argeş"
          },
          "RO-CJ": {
            type: "Department",
            name: "Cluj"
          },
          "RO-AB": {
            type: "Department",
            name: "Alba"
          },
          "RO-PH": {
            type: "Department",
            name: "Prahova"
          },
          "RO-TR": {
            type: "Department",
            name: "Teleorman"
          },
          "RO-OT": {
            type: "Department",
            name: "Olt"
          },
          "RO-IS": {
            type: "Department",
            name: "Iaşi"
          },
          "RO-VL": {
            type: "Department",
            name: "Vâlcea"
          },
          "RO-MS": {
            type: "Department",
            name: "Mureş"
          },
          "RO-MH": {
            type: "Department",
            name: "Mehedinţi"
          },
          "RO-IF": {
            type: "Department",
            name: "Ilfov"
          },
          "RO-MM": {
            type: "Department",
            name: "Maramureş"
          },
          "RO-IL": {
            type: "Department",
            name: "Ialomiţa"
          },
          "RO-TM": {
            type: "Department",
            name: "Timiş"
          },
          "RO-TL": {
            type: "Department",
            name: "Tulcea"
          },
          "RO-BT": {
            type: "Department",
            name: "Botoşani"
          },
          "RO-BV": {
            type: "Department",
            name: "Braşov"
          },
          "RO-BR": {
            type: "Department",
            name: "Brăila"
          },
          "RO-BZ": {
            type: "Department",
            name: "Buzău"
          },
          "RO-BC": {
            type: "Department",
            name: "Bacău"
          },
          "RO-BN": {
            type: "Department",
            name: "Bistriţa-Năsăud"
          },
          "RO-BH": {
            type: "Department",
            name: "Bihor"
          }
        }
      },
      TK: {
        name: "Tokelau",
        sub: {
          "TK-TK": {
            type: "Country",
            name: "Tokelau"
          }
        }
      },
      GW: {
        name: "Guinea-Bissau",
        sub: {
          "GW-BL": {
            type: "Region",
            name: "Bolama"
          },
          "GW-BM": {
            type: "Region",
            name: "Biombo"
          },
          "GW-CA": {
            type: "Region",
            name: "Cacheu"
          },
          "GW-QU": {
            type: "Region",
            name: "Quinara"
          },
          "GW-S": {
            type: "Province",
            name: "Sul"
          },
          "GW-OI": {
            type: "Region",
            name: "Oio"
          },
          "GW-L": {
            type: "Province",
            name: "Leste"
          },
          "GW-N": {
            type: "Province",
            name: "Norte"
          },
          "GW-BA": {
            type: "Region",
            name: "Bafatá"
          },
          "GW-TO": {
            type: "Region",
            name: "Tombali"
          },
          "GW-GA": {
            type: "Region",
            name: "Gabú"
          },
          "GW-BS": {
            type: "Autonomous sector",
            name: "Bissau"
          }
        }
      },
      GU: {
        name: "Guam",
        sub: {
          "GU-GU": {
            type: "Country",
            name: "Guam"
          }
        }
      },
      GT: {
        name: "Guatemala",
        sub: {
          "GT-SO": {
            type: "Department",
            name: "Sololá"
          },
          "GT-SM": {
            type: "Department",
            name: "San Marcos"
          },
          "GT-JA": {
            type: "Department",
            name: "Jalapa"
          },
          "GT-BV": {
            type: "Department",
            name: "Baja Verapaz"
          },
          "GT-QZ": {
            type: "Department",
            name: "Quetzaltenango"
          },
          "GT-SA": {
            type: "Department",
            name: "Sacatepéquez"
          },
          "GT-JU": {
            type: "Department",
            name: "Jutiapa"
          },
          "GT-HU": {
            type: "Department",
            name: "Huehuetenango"
          },
          "GT-QC": {
            type: "Department",
            name: "Quiché"
          },
          "GT-SU": {
            type: "Department",
            name: "Suchitepéquez"
          },
          "GT-SR": {
            type: "Department",
            name: "Santa Rosa"
          },
          "GT-ZA": {
            type: "Department",
            name: "Zacapa"
          },
          "GT-RE": {
            type: "Department",
            name: "Retalhuleu"
          },
          "GT-PE": {
            type: "Department",
            name: "Petén"
          },
          "GT-CQ": {
            type: "Department",
            name: "Chiquimula"
          },
          "GT-TO": {
            type: "Department",
            name: "Totonicapán"
          },
          "GT-CM": {
            type: "Department",
            name: "Chimaltenango"
          },
          "GT-IZ": {
            type: "Department",
            name: "Izabal"
          },
          "GT-PR": {
            type: "Department",
            name: "El Progreso"
          },
          "GT-AV": {
            type: "Department",
            name: "Alta Verapaz"
          },
          "GT-GU": {
            type: "Department",
            name: "Guatemala"
          },
          "GT-ES": {
            type: "Department",
            name: "Escuintla"
          }
        }
      },
      GS: {
        name: "S.Georgia & S.Sandwich Islands",
        sub: {
          "GS-GS": {
            type: "Country",
            name: "S.Georgia & S.Sandwich Islands"
          }
        }
      },
      GR: {
        name: "Greece",
        sub: {
          "GR-85": {
            type: "Department",
            name: "Chíos"
          },
          "GR-84": {
            type: "Department",
            name: "Sámos"
          },
          "GR-64": {
            type: "Department",
            name: "Chalkidikí"
          },
          "GR-81": {
            type: "Department",
            name: "Dodekánisa"
          },
          "GR-83": {
            type: "Department",
            name: "Lésvos"
          },
          "GR-54": {
            type: "Department",
            name: "Thessaloníki"
          },
          "GR-A": {
            type: "Administrative region",
            name: "Anatolikí Makedonía kai Thráki"
          },
          "GR-G": {
            type: "Administrative region",
            name: "Dytikí Elláda"
          },
          "GR-B": {
            type: "Administrative region",
            name: "Kentrikí Makedonía"
          },
          "GR-73": {
            type: "Department",
            name: "Rodópi"
          },
          "GR-J": {
            type: "Administrative region",
            name: "Peloponnísos"
          },
          "GR-I": {
            type: "Administrative region",
            name: "Attikí"
          },
          "GR-34": {
            type: "Department",
            name: "Préveza"
          },
          "GR-53": {
            type: "Department",
            name: "Imathía"
          },
          "GR-51": {
            type: "Department",
            name: "Grevená"
          },
          "GR-56": {
            type: "Department",
            name: "Kastoriá"
          },
          "GR-31": {
            type: "Department",
            name: "Árta"
          },
          "GR-32": {
            type: "Department",
            name: "Thesprotía"
          },
          "GR-33": {
            type: "Department",
            name: "Ioánnina"
          },
          "GR-71": {
            type: "Department",
            name: "Évros"
          },
          "GR-58": {
            type: "Department",
            name: "Kozáni"
          },
          "GR-59": {
            type: "Department",
            name: "Pélla"
          },
          "GR-16": {
            type: "Department",
            name: "Lakonía"
          },
          "GR-17": {
            type: "Department",
            name: "Messinía"
          },
          "GR-14": {
            type: "Department",
            name: "Ileía"
          },
          "GR-15": {
            type: "Department",
            name: "Korinthía"
          },
          "GR-12": {
            type: "Department",
            name: "Arkadía"
          },
          "GR-13": {
            type: "Department",
            name: "Achaḯa"
          },
          "GR-11": {
            type: "Department",
            name: "Argolída"
          },
          "GR-52": {
            type: "Department",
            name: "Dráma"
          },
          "GR-94": {
            type: "Department",
            name: "Chaniá"
          },
          "GR-92": {
            type: "Department",
            name: "Lasíthi"
          },
          "GR-93": {
            type: "Department",
            name: "Rethýmnis"
          },
          "GR-91": {
            type: "Department",
            name: "Irakleío"
          },
          "GR-L": {
            type: "Administrative region",
            name: "Notío Aigaío"
          },
          "GR-A1": {
            type: "Department",
            name: "Attikí"
          },
          "GR-05": {
            type: "Department",
            name: "Evrytanía"
          },
          "GR-04": {
            type: "Department",
            name: "Évvoia"
          },
          "GR-82": {
            type: "Department",
            name: "Kykládes"
          },
          "GR-07": {
            type: "Department",
            name: "Fokída"
          },
          "GR-57": {
            type: "Department",
            name: "Kilkís"
          },
          "GR-41": {
            type: "Department",
            name: "Kardítsa"
          },
          "GR-M": {
            type: "Administrative region",
            name: "Krítí"
          },
          "GR-43": {
            type: "Department",
            name: "Magnisía"
          },
          "GR-42": {
            type: "Department",
            name: "Lárisa"
          },
          "GR-H": {
            type: "Administrative region",
            name: "Stereá Elláda"
          },
          "GR-44": {
            type: "Department",
            name: "Tríkala"
          },
          "GR-69": {
            type: "Self-governed part",
            name: "Ágion Óros"
          },
          "GR-K": {
            type: "Administrative region",
            name: "Voreío Aigaío"
          },
          "GR-D": {
            type: "Administrative region",
            name: "Ípeiros"
          },
          "GR-E": {
            type: "Administrative region",
            name: "Thessalía"
          },
          "GR-F": {
            type: "Administrative region",
            name: "Ionía Nísia"
          },
          "GR-55": {
            type: "Department",
            name: "Kavála"
          },
          "GR-63": {
            type: "Department",
            name: "Flórina"
          },
          "GR-62": {
            type: "Department",
            name: "Sérres"
          },
          "GR-61": {
            type: "Department",
            name: "Piería"
          },
          "GR-C": {
            type: "Administrative region",
            name: "Dytikí Makedonía"
          },
          "GR-23": {
            type: "Department",
            name: "Kefallinía"
          },
          "GR-22": {
            type: "Department",
            name: "Kérkyra"
          },
          "GR-21": {
            type: "Department",
            name: "Zákynthos"
          },
          "GR-06": {
            type: "Department",
            name: "Fthiótida"
          },
          "GR-01": {
            type: "Department",
            name: "Aitoloakarnanía"
          },
          "GR-72": {
            type: "Department",
            name: "Xánthi"
          },
          "GR-03": {
            type: "Department",
            name: "Voiotía"
          },
          "GR-24": {
            type: "Department",
            name: "Lefkáda"
          }
        }
      },
      GQ: {
        name: "Equatorial Guinea",
        sub: {
          "GQ-LI": {
            type: "Province",
            name: "Litoral"
          },
          "GQ-C": {
            type: "Region",
            name: "Região Continental"
          },
          "GQ-BN": {
            type: "Province",
            name: "Bioko Nord"
          },
          "GQ-AN": {
            type: "Province",
            name: "Annobón"
          },
          "GQ-I": {
            type: "Region",
            name: "Região Insular"
          },
          "GQ-KN": {
            type: "Province",
            name: "Kié-Ntem"
          },
          "GQ-BS": {
            type: "Province",
            name: "Bioko Sud"
          },
          "GQ-CS": {
            type: "Province",
            name: "Centro Sud"
          },
          "GQ-WN": {
            type: "Province",
            name: "Wele-Nzas"
          }
        }
      },
      GP: {
        name: "Guadeloupe",
        sub: {
          "GP-GP": {
            type: "Country",
            name: "Guadeloupe"
          }
        }
      },
      JP: {
        name: "Japan",
        sub: {
          "JP-37": {
            type: "Prefecture",
            name: "Kagawa"
          },
          "JP-36": {
            type: "Prefecture",
            name: "Tokushima"
          },
          "JP-35": {
            type: "Prefecture",
            name: "Yamaguchi"
          },
          "JP-34": {
            type: "Prefecture",
            name: "Hiroshima"
          },
          "JP-33": {
            type: "Prefecture",
            name: "Okayama"
          },
          "JP-32": {
            type: "Prefecture",
            name: "Shimane"
          },
          "JP-31": {
            type: "Prefecture",
            name: "Tottori"
          },
          "JP-30": {
            type: "Prefecture",
            name: "Wakayama"
          },
          "JP-39": {
            type: "Prefecture",
            name: "Kochi"
          },
          "JP-38": {
            type: "Prefecture",
            name: "Ehime"
          },
          "JP-19": {
            type: "Prefecture",
            name: "Yamanashi"
          },
          "JP-18": {
            type: "Prefecture",
            name: "Fukui"
          },
          "JP-11": {
            type: "Prefecture",
            name: "Saitama"
          },
          "JP-10": {
            type: "Prefecture",
            name: "Gunma"
          },
          "JP-13": {
            type: "Prefecture",
            name: "Tôkyô"
          },
          "JP-12": {
            type: "Prefecture",
            name: "Chiba"
          },
          "JP-15": {
            type: "Prefecture",
            name: "Niigata"
          },
          "JP-14": {
            type: "Prefecture",
            name: "Kanagawa"
          },
          "JP-17": {
            type: "Prefecture",
            name: "Ishikawa"
          },
          "JP-16": {
            type: "Prefecture",
            name: "Toyama"
          },
          "JP-20": {
            type: "Prefecture",
            name: "Nagano"
          },
          "JP-21": {
            type: "Prefecture",
            name: "Gifu"
          },
          "JP-22": {
            type: "Prefecture",
            name: "Shizuoka"
          },
          "JP-23": {
            type: "Prefecture",
            name: "Aichi"
          },
          "JP-24": {
            type: "Prefecture",
            name: "Mie"
          },
          "JP-25": {
            type: "Prefecture",
            name: "Shiga"
          },
          "JP-26": {
            type: "Prefecture",
            name: "Kyoto"
          },
          "JP-27": {
            type: "Prefecture",
            name: "Osaka"
          },
          "JP-28": {
            type: "Prefecture",
            name: "Hyogo"
          },
          "JP-29": {
            type: "Prefecture",
            name: "Nara"
          },
          "JP-46": {
            type: "Prefecture",
            name: "Kagoshima"
          },
          "JP-47": {
            type: "Prefecture",
            name: "Okinawa"
          },
          "JP-08": {
            type: "Prefecture",
            name: "Ibaraki"
          },
          "JP-09": {
            type: "Prefecture",
            name: "Tochigi"
          },
          "JP-42": {
            type: "Prefecture",
            name: "Nagasaki"
          },
          "JP-43": {
            type: "Prefecture",
            name: "Kumamoto"
          },
          "JP-40": {
            type: "Prefecture",
            name: "Fukuoka"
          },
          "JP-41": {
            type: "Prefecture",
            name: "Saga"
          },
          "JP-02": {
            type: "Prefecture",
            name: "Aomori"
          },
          "JP-03": {
            type: "Prefecture",
            name: "Iwate"
          },
          "JP-01": {
            type: "Prefecture",
            name: "Hokkaido"
          },
          "JP-06": {
            type: "Prefecture",
            name: "Yamagata"
          },
          "JP-07": {
            type: "Prefecture",
            name: "Fukushima"
          },
          "JP-04": {
            type: "Prefecture",
            name: "Miyagi"
          },
          "JP-05": {
            type: "Prefecture",
            name: "Akita"
          },
          "JP-44": {
            type: "Prefecture",
            name: "Oita"
          },
          "JP-45": {
            type: "Prefecture",
            name: "Miyazaki"
          }
        }
      },
      GY: {
        name: "Guyana",
        sub: {
          "GY-UT": {
            type: "Region",
            name: "Upper Takutu-Upper Essequibo"
          },
          "GY-MA": {
            type: "Region",
            name: "Mahaica-Berbice"
          },
          "GY-PM": {
            type: "Region",
            name: "Pomeroon-Supenaam"
          },
          "GY-UD": {
            type: "Region",
            name: "Upper Demerara-Berbice"
          },
          "GY-ES": {
            type: "Region",
            name: "Essequibo Islands-West Demerara"
          },
          "GY-EB": {
            type: "Region",
            name: "East Berbice-Corentyne"
          },
          "GY-PT": {
            type: "Region",
            name: "Potaro-Siparuni"
          },
          "GY-CU": {
            type: "Region",
            name: "Cuyuni-Mazaruni"
          },
          "GY-BA": {
            type: "Region",
            name: "Barima-Waini"
          },
          "GY-DE": {
            type: "Region",
            name: "Demerara-Mahaica"
          }
        }
      },
      GG: {
        name: "Guernsey",
        sub: {
          "GG-GG": {
            type: "Country",
            name: "Guernsey"
          }
        }
      },
      GF: {
        name: "French Guiana",
        sub: {
          "GF-GF": {
            type: "Country",
            name: "French Guiana"
          }
        }
      },
      GE: {
        name: "Georgia",
        sub: {
          "GE-GU": {
            type: "Region",
            name: "Guria"
          },
          "GE-SK": {
            type: "Region",
            name: "Shida Kartli"
          },
          "GE-SJ": {
            type: "Region",
            name: "Samtskhe-Javakheti"
          },
          "GE-AB": {
            type: "Autonomous republic",
            name: "Abkhazia"
          },
          "GE-MM": {
            type: "Region",
            name: "Mtskheta-Mtianeti"
          },
          "GE-AJ": {
            type: "Autonomous republic",
            name: "Ajaria"
          },
          "GE-TB": {
            type: "City",
            name: "Tbilisi"
          },
          "GE-KA": {
            type: "Region",
            name: "K'akheti"
          },
          "GE-IM": {
            type: "Region",
            name: "Imereti"
          },
          "GE-SZ": {
            type: "Region",
            name: "Samegrelo-Zemo Svaneti"
          },
          "GE-KK": {
            type: "Region",
            name: "Kvemo Kartli"
          },
          "GE-RL": {
            type: "Region",
            name: "Rach'a-Lechkhumi-Kvemo Svaneti"
          }
        }
      },
      GD: {
        name: "Grenada",
        sub: {
          "GD-03": {
            type: "Parish",
            name: "Saint George"
          },
          "GD-02": {
            type: "Parish",
            name: "Saint David"
          },
          "GD-01": {
            type: "Parish",
            name: "Saint Andrew"
          },
          "GD-06": {
            type: "Parish",
            name: "Saint Patrick"
          },
          "GD-05": {
            type: "Parish",
            name: "Saint Mark"
          },
          "GD-04": {
            type: "Parish",
            name: "Saint John"
          },
          "GD-10": {
            type: "Dependency",
            name: "Southern Grenadine Islands"
          }
        }
      },
      GB: {
        name: "United Kingdom",
        sub: {
          "GB-TRF": {
            type: "Metropolitan district",
            name: "Trafford"
          },
          "GB-WRX": {
            type: "Unitary authority",
            name: "Wrexham"
          },
          "GB-BEN": {
            type: "London borough",
            name: "Brent"
          },
          "GB-HRW": {
            type: "London borough",
            name: "Harrow"
          },
          "GB-WRT": {
            type: "Unitary authority",
            name: "Warrington"
          },
          "GB-LBH": {
            type: "London borough",
            name: "Lambeth"
          },
          "GB-DAL": {
            type: "Unitary authority",
            name: "Darlington"
          },
          "GB-WRL": {
            type: "Metropolitan district",
            name: "Wirral"
          },
          "GB-MYL": {
            type: "District council area",
            name: "Moyle"
          },
          "GB-UKM": {
            type: "Nation",
            name: "United Kingdom"
          },
          "GB-BEX": {
            type: "London borough",
            name: "Bexley"
          },
          "GB-BUR": {
            type: "Metropolitan district",
            name: "Bury"
          },
          "GB-KIR": {
            type: "Metropolitan district",
            name: "Kirklees"
          },
          "GB-FER": {
            type: "District council area",
            name: "Fermanagh"
          },
          "GB-NBL": {
            type: "Unitary authority",
            name: "Northumberland"
          },
          "GB-DRY": {
            type: "District council area",
            name: "Derry"
          },
          "GB-CKF": {
            type: "District council area",
            name: "Carrickfergus"
          },
          "GB-ERY": {
            type: "Unitary authority",
            name: "East Riding of Yorkshire"
          },
          "GB-CMA": {
            type: "Two-tier county",
            name: "Cumbria"
          },
          "GB-HCK": {
            type: "London borough",
            name: "Hackney"
          },
          "GB-CRY": {
            type: "London borough",
            name: "Croydon"
          },
          "GB-ABE": {
            type: "Council area",
            name: "Aberdeen City"
          },
          "GB-ABD": {
            type: "Council area",
            name: "Aberdeenshire"
          },
          "GB-CKT": {
            type: "District council area",
            name: "Cookstown"
          },
          "GB-ERW": {
            type: "Council area",
            name: "East Renfrewshire"
          },
          "GB-HIL": {
            type: "London borough",
            name: "Hillingdon"
          },
          "GB-NLN": {
            type: "Unitary authority",
            name: "North Lincolnshire"
          },
          "GB-WSX": {
            type: "Two-tier county",
            name: "West Sussex"
          },
          "GB-BIR": {
            type: "Metropolitan district",
            name: "Birmingham"
          },
          "GB-WBK": {
            type: "Unitary authority",
            name: "West Berkshire"
          },
          "GB-EDU": {
            type: "Council area",
            name: "East Dunbartonshire"
          },
          "GB-WSM": {
            type: "London borough",
            name: "Westminster"
          },
          "GB-STS": {
            type: "Two-tier county",
            name: "Staffordshire"
          },
          "GB-HRT": {
            type: "Two-tier county",
            name: "Hertfordshire"
          },
          "GB-LSB": {
            type: "District council area",
            name: "Lisburn"
          },
          "GB-EDH": {
            type: "Council area",
            name: "Edinburgh, City of"
          },
          "GB-HRY": {
            type: "London borough",
            name: "Haringey"
          },
          "GB-LAN": {
            type: "Two-tier county",
            name: "Lancashire"
          },
          "GB-RIC": {
            type: "London borough",
            name: "Richmond upon Thames"
          },
          "GB-LRN": {
            type: "District council area",
            name: "Larne"
          },
          "GB-ZET": {
            type: "Council area",
            name: "Shetland Islands"
          },
          "GB-ELS": {
            type: "Council area",
            name: "Eilean Siar"
          },
          "GB-NTA": {
            type: "District council area",
            name: "Newtownabbey"
          },
          "GB-SRY": {
            type: "Two-tier county",
            name: "Surrey"
          },
          "GB-BMH": {
            type: "Unitary authority",
            name: "Bournemouth"
          },
          "GB-CHE": {
            type: "Unitary authority",
            name: "Cheshire East"
          },
          "GB-FLN": {
            type: "Unitary authority",
            name: "Flintshire"
          },
          "GB-NET": {
            type: "Metropolitan district",
            name: "Newcastle upon Tyne"
          },
          "GB-NTH": {
            type: "Two-tier county",
            name: "Northamptonshire"
          },
          "GB-NTT": {
            type: "Two-tier county",
            name: "Nottinghamshire"
          },
          "GB-NTL": {
            type: "Unitary authority",
            name: "Neath Port Talbot"
          },
          "GB-ESX": {
            type: "Two-tier county",
            name: "East Sussex"
          },
          "GB-KTT": {
            type: "London borough",
            name: "Kingston upon Thames"
          },
          "GB-MRT": {
            type: "London borough",
            name: "Merton"
          },
          "GB-OXF": {
            type: "Two-tier county",
            name: "Oxfordshire"
          },
          "GB-MRY": {
            type: "Council area",
            name: "Moray"
          },
          "GB-ESS": {
            type: "Two-tier county",
            name: "Essex"
          },
          "GB-CHW": {
            type: "Unitary authority",
            name: "Cheshire West and Chester"
          },
          "GB-SOS": {
            type: "Unitary authority",
            name: "Southend-on-Sea"
          },
          "GB-PEM": {
            type: "Unitary authority",
            name: "Pembrokeshire"
          },
          "GB-NTY": {
            type: "Metropolitan district",
            name: "North Tyneside"
          },
          "GB-NEL": {
            type: "Unitary authority",
            name: "North East Lincolnshire"
          },
          "GB-HAL": {
            type: "Unitary authority",
            name: "Halton"
          },
          "GB-RFW": {
            type: "Council area",
            name: "Renfrewshire"
          },
          "GB-LUT": {
            type: "Unitary authority",
            name: "Luton"
          },
          "GB-WOK": {
            type: "Unitary authority",
            name: "Wokingham"
          },
          "GB-WOR": {
            type: "Two-tier county",
            name: "Worcestershire"
          },
          "GB-HLD": {
            type: "Council area",
            name: "Highland"
          },
          "GB-MFT": {
            type: "District council area",
            name: "Magherafelt"
          },
          "GB-NSM": {
            type: "Unitary authority",
            name: "North Somerset"
          },
          "GB-LDS": {
            type: "Metropolitan district",
            name: "Leeds"
          },
          "GB-KWL": {
            type: "Metropolitan district",
            name: "Knowsley"
          },
          "GB-SOM": {
            type: "Two-tier county",
            name: "Somerset"
          },
          "GB-DEN": {
            type: "Unitary authority",
            name: "Denbighshire"
          },
          "GB-BST": {
            type: "Unitary authority",
            name: "Bristol, City of"
          },
          "GB-EAL": {
            type: "London borough",
            name: "Ealing"
          },
          "GB-RDB": {
            type: "London borough",
            name: "Redbridge"
          },
          "GB-CSR": {
            type: "District council area",
            name: "Castlereagh"
          },
          "GB-RDG": {
            type: "Unitary authority",
            name: "Reading"
          },
          "GB-EAW": {
            type: "Nation",
            name: "England and Wales"
          },
          "GB-CMD": {
            type: "London borough",
            name: "Camden"
          },
          "GB-MON": {
            type: "Unitary authority",
            name: "Monmouthshire"
          },
          "GB-OLD": {
            type: "Metropolitan district",
            name: "Oldham"
          },
          "GB-CON": {
            type: "Unitary authority",
            name: "Cornwall"
          },
          "GB-CMN": {
            type: "Unitary authority",
            name: "Carmarthenshire"
          },
          "GB-EAY": {
            type: "Council area",
            name: "East Ayrshire"
          },
          "GB-HEF": {
            type: "Unitary authority",
            name: "Herefordshire"
          },
          "GB-ROT": {
            type: "Metropolitan district",
            name: "Rotherham"
          },
          "GB-NAY": {
            type: "Council area",
            name: "North Ayrshire"
          },
          "GB-BBD": {
            type: "Unitary authority",
            name: "Blackburn with Darwen"
          },
          "GB-TOF": {
            type: "Unitary authority",
            name: "Torfaen"
          },
          "GB-WDU": {
            type: "Council area",
            name: "West Dunbartonshire"
          },
          "GB-WLL": {
            type: "Metropolitan district",
            name: "Walsall"
          },
          "GB-WLN": {
            type: "Council area",
            name: "West Lothian"
          },
          "GB-GRE": {
            type: "London borough",
            name: "Greenwich"
          },
          "GB-MIK": {
            type: "Unitary authority",
            name: "Milton Keynes"
          },
          "GB-KEC": {
            type: "London borough",
            name: "Kensington and Chelsea"
          },
          "GB-MAN": {
            type: "Metropolitan district",
            name: "Manchester"
          },
          "GB-WLV": {
            type: "Metropolitan district",
            name: "Wolverhampton"
          },
          "GB-WLS": {
            type: "Country",
            name: "Wales"
          },
          "GB-ELN": {
            type: "Council area",
            name: "East Lothian"
          },
          "GB-CBF": {
            type: "Unitary authority",
            name: "Central Bedfordshire"
          },
          "GB-STH": {
            type: "Unitary authority",
            name: "Southampton"
          },
          "GB-KEN": {
            type: "Two-tier county",
            name: "Kent"
          },
          "GB-STY": {
            type: "Metropolitan district",
            name: "South Tyneside"
          },
          "GB-LCE": {
            type: "Unitary authority",
            name: "Leicester"
          },
          "GB-ARM": {
            type: "District council area",
            name: "Armagh"
          },
          "GB-BKM": {
            type: "Two-tier county",
            name: "Buckinghamshire"
          },
          "GB-SLG": {
            type: "Unitary authority",
            name: "Slough"
          },
          "GB-SLF": {
            type: "Metropolitan district",
            name: "Salford"
          },
          "GB-ORK": {
            type: "Council area",
            name: "Orkney Islands"
          },
          "GB-ARD": {
            type: "District council area",
            name: "Ards"
          },
          "GB-SLK": {
            type: "Council area",
            name: "South Lanarkshire"
          },
          "GB-BRY": {
            type: "London borough",
            name: "Bromley"
          },
          "GB-STT": {
            type: "Unitary authority",
            name: "Stockton-on-Tees"
          },
          "GB-POL": {
            type: "Unitary authority",
            name: "Poole"
          },
          "GB-BRC": {
            type: "Unitary authority",
            name: "Bracknell Forest"
          },
          "GB-SHN": {
            type: "Metropolitan district",
            name: "St. Helens"
          },
          "GB-POW": {
            type: "Unitary authority",
            name: "Powys"
          },
          "GB-GBN": {
            type: "Nation",
            name: "Great Britain"
          },
          "GB-BRD": {
            type: "Metropolitan district",
            name: "Bradford"
          },
          "GB-CRF": {
            type: "Unitary authority",
            name: "Cardiff"
          },
          "GB-STB": {
            type: "District council area",
            name: "Strabane"
          },
          "GB-STE": {
            type: "Unitary authority",
            name: "Stoke-on-Trent"
          },
          "GB-DBY": {
            type: "Two-tier county",
            name: "Derbyshire"
          },
          "GB-STG": {
            type: "Council area",
            name: "Stirling"
          },
          "GB-AGB": {
            type: "Council area",
            name: "Argyll and Bute"
          },
          "GB-DEV": {
            type: "Two-tier county",
            name: "Devon"
          },
          "GB-WIL": {
            type: "Unitary authority",
            name: "Wiltshire"
          },
          "GB-DER": {
            type: "Unitary authority",
            name: "Derby"
          },
          "GB-HNS": {
            type: "London borough",
            name: "Hounslow"
          },
          "GB-SAW": {
            type: "Metropolitan district",
            name: "Sandwell"
          },
          "GB-PLY": {
            type: "Unitary authority",
            name: "Plymouth"
          },
          "GB-NIR": {
            type: "Province",
            name: "Northern Ireland"
          },
          "GB-THR": {
            type: "Unitary authority",
            name: "Thurrock"
          },
          "GB-BAS": {
            type: "Unitary authority",
            name: "Bath and North East Somerset"
          },
          "GB-FIF": {
            type: "Council area",
            name: "Fife"
          },
          "GB-AGY": {
            type: "Unitary authority",
            name: "Isle of Anglesey"
          },
          "GB-SAY": {
            type: "Council area",
            name: "South Ayrshire"
          },
          "GB-NFK": {
            type: "Two-tier county",
            name: "Norfolk"
          },
          "GB-TFW": {
            type: "Unitary authority",
            name: "Telford and Wrekin"
          },
          "GB-BNE": {
            type: "London borough",
            name: "Barnet"
          },
          "GB-BNB": {
            type: "District council area",
            name: "Banbridge"
          },
          "GB-TOB": {
            type: "Unitary authority",
            name: "Torbay"
          },
          "GB-CGN": {
            type: "Unitary authority",
            name: "Ceredigion"
          },
          "GB-BFS": {
            type: "District council area",
            name: "Belfast"
          },
          "GB-BNH": {
            type: "Unitary authority",
            name: "Brighton and Hove"
          },
          "GB-WAR": {
            type: "Two-tier county",
            name: "Warwickshire"
          },
          "GB-CGV": {
            type: "District council area",
            name: "Craigavon"
          },
          "GB-BNS": {
            type: "Metropolitan district",
            name: "Barnsley"
          },
          "GB-TWH": {
            type: "London borough",
            name: "Tower Hamlets"
          },
          "GB-ANS": {
            type: "Council area",
            name: "Angus"
          },
          "GB-FAL": {
            type: "Council area",
            name: "Falkirk"
          },
          "GB-NYK": {
            type: "Two-tier county",
            name: "North Yorkshire"
          },
          "GB-ANT": {
            type: "District council area",
            name: "Antrim"
          },
          "GB-SWD": {
            type: "Unitary authority",
            name: "Swindon"
          },
          "GB-IOS": {
            type: "Unitary authority",
            name: "Isles of Scilly"
          },
          "GB-WNM": {
            type: "Unitary authority",
            name: "Windsor and Maidenhead"
          },
          "GB-IOW": {
            type: "Unitary authority",
            name: "Isle of Wight"
          },
          "GB-GLS": {
            type: "Two-tier county",
            name: "Gloucestershire"
          },
          "GB-SFT": {
            type: "Metropolitan district",
            name: "Sefton"
          },
          "GB-IVC": {
            type: "Council area",
            name: "Inverclyde"
          },
          "GB-WND": {
            type: "London borough",
            name: "Wandsworth"
          },
          "GB-LEC": {
            type: "Two-tier county",
            name: "Leicestershire"
          },
          "GB-SWK": {
            type: "London borough",
            name: "Southwark"
          },
          "GB-YOR": {
            type: "Unitary authority",
            name: "York"
          },
          "GB-GLG": {
            type: "Council area",
            name: "Glasgow City"
          },
          "GB-RUT": {
            type: "Unitary authority",
            name: "Rutland"
          },
          "GB-HMF": {
            type: "London borough",
            name: "Hammersmith and Fulham"
          },
          "GB-SFK": {
            type: "Two-tier county",
            name: "Suffolk"
          },
          "GB-LEW": {
            type: "London borough",
            name: "Lewisham"
          },
          "GB-NYM": {
            type: "District council area",
            name: "Newry and Mourne"
          },
          "GB-DUD": {
            type: "Metropolitan district",
            name: "Dudley"
          },
          "GB-CLR": {
            type: "District council area",
            name: "Coleraine"
          },
          "GB-CWY": {
            type: "Unitary authority",
            name: "Conwy"
          },
          "GB-SND": {
            type: "Metropolitan district",
            name: "Sunderland"
          },
          "GB-BPL": {
            type: "Unitary authority",
            name: "Blackpool"
          },
          "GB-VGL": {
            type: "Unitary authority",
            name: "Vale of Glamorgan, The"
          },
          "GB-WFT": {
            type: "London borough",
            name: "Waltham Forest"
          },
          "GB-LND": {
            type: "City corporation",
            name: "London, City of"
          },
          "GB-CLD": {
            type: "Metropolitan district",
            name: "Calderdale"
          },
          "GB-GWN": {
            type: "Unitary authority",
            name: "Gwynedd"
          },
          "GB-DUR": {
            type: "Unitary authority",
            name: "Durham County"
          },
          "GB-SCT": {
            type: "Country",
            name: "Scotland"
          },
          "GB-CLK": {
            type: "Council area",
            name: "Clackmannanshire"
          },
          "GB-ENG": {
            type: "Country",
            name: "England"
          },
          "GB-MDW": {
            type: "Unitary authority",
            name: "Medway"
          },
          "GB-SCB": {
            type: "Council area",
            name: "Scottish Borders, The"
          },
          "GB-DNC": {
            type: "Metropolitan district",
            name: "Doncaster"
          },
          "GB-TAM": {
            type: "Metropolitan district",
            name: "Tameside"
          },
          "GB-WGN": {
            type: "Metropolitan district",
            name: "Wigan"
          },
          "GB-DGY": {
            type: "Council area",
            name: "Dumfries and Galloway"
          },
          "GB-PTE": {
            type: "Unitary authority",
            name: "Peterborough"
          },
          "GB-SKP": {
            type: "Metropolitan district",
            name: "Stockport"
          },
          "GB-NWP": {
            type: "Unitary authority",
            name: "Newport"
          },
          "GB-ISL": {
            type: "London borough",
            name: "Islington"
          },
          "GB-SGC": {
            type: "Unitary authority",
            name: "South Gloucestershire"
          },
          "GB-CAY": {
            type: "Unitary authority",
            name: "Caerphilly"
          },
          "GB-DGN": {
            type: "District council area",
            name: "Dungannon and South Tyrone"
          },
          "GB-WKF": {
            type: "Metropolitan district",
            name: "Wakefield"
          },
          "GB-NWM": {
            type: "London borough",
            name: "Newham"
          },
          "GB-MDB": {
            type: "Unitary authority",
            name: "Middlesbrough"
          },
          "GB-CAM": {
            type: "Two-tier county",
            name: "Cambridgeshire"
          },
          "GB-SWA": {
            type: "Unitary authority",
            name: "Swansea"
          },
          "GB-DOW": {
            type: "District council area",
            name: "Down"
          },
          "GB-BLA": {
            type: "District council area",
            name: "Ballymena"
          },
          "GB-HAV": {
            type: "London borough",
            name: "Havering"
          },
          "GB-DOR": {
            type: "Two-tier county",
            name: "Dorset"
          },
          "GB-GAT": {
            type: "Metropolitan district",
            name: "Gateshead"
          },
          "GB-POR": {
            type: "Unitary authority",
            name: "Portsmouth"
          },
          "GB-NLK": {
            type: "Council area",
            name: "North Lanarkshire"
          },
          "GB-LIV": {
            type: "Metropolitan district",
            name: "Liverpool"
          },
          "GB-LIN": {
            type: "Two-tier county",
            name: "Lincolnshire"
          },
          "GB-HAM": {
            type: "Two-tier county",
            name: "Hampshire"
          },
          "GB-BLY": {
            type: "District council area",
            name: "Ballymoney"
          },
          "GB-NDN": {
            type: "District council area",
            name: "North Down"
          },
          "GB-BDF": {
            type: "Unitary authority",
            name: "Bedford"
          },
          "GB-BDG": {
            type: "London borough",
            name: "Barking and Dagenham"
          },
          "GB-SOL": {
            type: "Metropolitan district",
            name: "Solihull"
          },
          "GB-OMH": {
            type: "District council area",
            name: "Omagh"
          },
          "GB-HPL": {
            type: "Unitary authority",
            name: "Hartlepool"
          },
          "GB-COV": {
            type: "Metropolitan district",
            name: "Coventry"
          },
          "GB-SHR": {
            type: "Unitary authority",
            name: "Shropshire"
          },
          "GB-PKN": {
            type: "Council area",
            name: "Perth and Kinross"
          },
          "GB-LMV": {
            type: "District council area",
            name: "Limavady"
          },
          "GB-RCH": {
            type: "Metropolitan district",
            name: "Rochdale"
          },
          "GB-SHF": {
            type: "Metropolitan district",
            name: "Sheffield"
          },
          "GB-NGM": {
            type: "Unitary authority",
            name: "Nottingham"
          },
          "GB-RCT": {
            type: "Unitary authority",
            name: "Rhondda, Cynon, Taff"
          },
          "GB-BOL": {
            type: "Metropolitan district",
            name: "Bolton"
          },
          "GB-BGW": {
            type: "Unitary authority",
            name: "Blaenau Gwent"
          },
          "GB-ENF": {
            type: "London borough",
            name: "Enfield"
          },
          "GB-DND": {
            type: "Council area",
            name: "Dundee City"
          },
          "GB-STN": {
            type: "London borough",
            name: "Sutton"
          },
          "GB-MLN": {
            type: "Council area",
            name: "Midlothian"
          },
          "GB-RCC": {
            type: "Unitary authority",
            name: "Redcar and Cleveland"
          },
          "GB-BGE": {
            type: "Unitary authority",
            name: "Bridgend"
          },
          "GB-KHL": {
            type: "Unitary authority",
            name: "Kingston upon Hull"
          },
          "GB-MTY": {
            type: "Unitary authority",
            name: "Merthyr Tydfil"
          }
        }
      },
      GA: {
        name: "Gabon",
        sub: {
          "GA-7": {
            type: "Province",
            name: "Ogooué-Lolo"
          },
          "GA-6": {
            type: "Province",
            name: "Ogooué-Ivindo"
          },
          "GA-5": {
            type: "Province",
            name: "Nyanga"
          },
          "GA-4": {
            type: "Province",
            name: "Ngounié"
          },
          "GA-3": {
            type: "Province",
            name: "Moyen-Ogooué"
          },
          "GA-2": {
            type: "Province",
            name: "Haut-Ogooué"
          },
          "GA-1": {
            type: "Province",
            name: "Estuaire"
          },
          "GA-9": {
            type: "Province",
            name: "Woleu-Ntem"
          },
          "GA-8": {
            type: "Province",
            name: "Ogooué-Maritime"
          }
        }
      },
      GN: {
        name: "Guinea",
        sub: {
          "GN-GU": {
            type: "Prefecture",
            name: "Guékédou"
          },
          "GN-YO": {
            type: "Prefecture",
            name: "Yomou"
          },
          "GN-TE": {
            type: "Prefecture",
            name: "Télimélé"
          },
          "GN-GA": {
            type: "Prefecture",
            name: "Gaoual"
          },
          "GN-PI": {
            type: "Prefecture",
            name: "Pita"
          },
          "GN-ML": {
            type: "Prefecture",
            name: "Mali"
          },
          "GN-MM": {
            type: "Prefecture",
            name: "Mamou"
          },
          "GN-DI": {
            type: "Prefecture",
            name: "Dinguiraye"
          },
          "GN-MD": {
            type: "Prefecture",
            name: "Mandiana"
          },
          "GN-KS": {
            type: "Prefecture",
            name: "Kissidougou"
          },
          "GN-MC": {
            type: "Prefecture",
            name: "Macenta"
          },
          "GN-CO": {
            type: "Prefecture",
            name: "Coyah"
          },
          "GN-LA": {
            type: "Prefecture",
            name: "Labé"
          },
          "GN-B": {
            type: "Administrative region",
            name: "Boké"
          },
          "GN-C": {
            type: "Governorate",
            name: "Conakry"
          },
          "GN-D": {
            type: "Administrative region",
            name: "Kindia"
          },
          "GN-F": {
            type: "Administrative region",
            name: "Faranah"
          },
          "GN-KO": {
            type: "Prefecture",
            name: "Kouroussa"
          },
          "GN-KA": {
            type: "Prefecture",
            name: "Kankan"
          },
          "GN-KB": {
            type: "Prefecture",
            name: "Koubia"
          },
          "GN-K": {
            type: "Administrative region",
            name: "Kankan"
          },
          "GN-KD": {
            type: "Prefecture",
            name: "Kindia"
          },
          "GN-KE": {
            type: "Prefecture",
            name: "Kérouané"
          },
          "GN-N": {
            type: "Administrative region",
            name: "Nzérékoré"
          },
          "GN-DU": {
            type: "Prefecture",
            name: "Dubréka"
          },
          "GN-FR": {
            type: "Prefecture",
            name: "Fria"
          },
          "GN-KN": {
            type: "Prefecture",
            name: "Koundara"
          },
          "GN-LO": {
            type: "Prefecture",
            name: "Lola"
          },
          "GN-FA": {
            type: "Prefecture",
            name: "Faranah"
          },
          "GN-DB": {
            type: "Prefecture",
            name: "Dabola"
          },
          "GN-DL": {
            type: "Prefecture",
            name: "Dalaba"
          },
          "GN-FO": {
            type: "Prefecture",
            name: "Forécariah"
          },
          "GN-BF": {
            type: "Prefecture",
            name: "Boffa"
          },
          "GN-BE": {
            type: "Prefecture",
            name: "Beyla"
          },
          "GN-LE": {
            type: "Prefecture",
            name: "Lélouma"
          },
          "GN-L": {
            type: "Administrative region",
            name: "Labé"
          },
          "GN-BK": {
            type: "Prefecture",
            name: "Boké"
          },
          "GN-M": {
            type: "Administrative region",
            name: "Mamou"
          },
          "GN-NZ": {
            type: "Prefecture",
            name: "Nzérékoré"
          },
          "GN-SI": {
            type: "Prefecture",
            name: "Siguiri"
          },
          "GN-TO": {
            type: "Prefecture",
            name: "Tougué"
          }
        }
      },
      GM: {
        name: "Gambia",
        sub: {
          "GM-N": {
            type: "Division",
            name: "North Bank"
          },
          "GM-M": {
            type: "Division",
            name: "Central River"
          },
          "GM-L": {
            type: "Division",
            name: "Lower River"
          },
          "GM-B": {
            type: "City",
            name: "Banjul"
          },
          "GM-W": {
            type: "Division",
            name: "Western"
          },
          "GM-U": {
            type: "Division",
            name: "Upper River"
          }
        }
      },
      GL: {
        name: "Greenland",
        sub: {
          "GL-QE": {
            type: "Municipality",
            name: "Qeqqata Kommunia"
          },
          "GL-KU": {
            type: "Municipality",
            name: "Kommune Kujalleq"
          },
          "GL-QA": {
            type: "Municipality",
            name: "Qaasuitsup Kommunia"
          },
          "GL-SM": {
            type: "Municipality",
            name: "Kommuneqarfik Sermersooq"
          }
        }
      },
      GI: {
        name: "Gibraltar",
        sub: {
          "GI-GI": {
            type: "Country",
            name: "Gibraltar"
          }
        }
      },
      GH: {
        name: "Ghana",
        sub: {
          "GH-AH": {
            type: "Region",
            name: "Ashanti"
          },
          "GH-BA": {
            type: "Region",
            name: "Brong-Ahafo"
          },
          "GH-UW": {
            type: "Region",
            name: "Upper West"
          },
          "GH-AA": {
            type: "Region",
            name: "Greater Accra"
          },
          "GH-UE": {
            type: "Region",
            name: "Upper East"
          },
          "GH-CP": {
            type: "Region",
            name: "Central"
          },
          "GH-EP": {
            type: "Region",
            name: "Eastern"
          },
          "GH-NP": {
            type: "Region",
            name: "Northern"
          },
          "GH-TV": {
            type: "Region",
            name: "Volta"
          },
          "GH-WP": {
            type: "Region",
            name: "Western"
          }
        }
      },
      OM: {
        name: "Oman",
        sub: {
          "OM-WU": {
            type: "Region",
            name: "Al Wusţá"
          },
          "OM-ZU": {
            type: "Governorate",
            name: "Z̧ufār"
          },
          "OM-BA": {
            type: "Region",
            name: "Al Bāţinah"
          },
          "OM-DA": {
            type: "Region",
            name: "Ad Dākhilīyah"
          },
          "OM-MU": {
            type: "Governorate",
            name: "Musandam"
          },
          "OM-SH": {
            type: "Region",
            name: "Ash Sharqīyah"
          },
          "OM-ZA": {
            type: "Region",
            name: "Az̧ Z̧āhirah"
          },
          "OM-MA": {
            type: "Governorate",
            name: "Masqaţ"
          },
          "OM-BU": {
            type: "Governorate",
            name: "Al Buraymī"
          }
        }
      },
      TN: {
        name: "Tunisia",
        sub: {
          "TN-61": {
            type: "Governorate",
            name: "Sfax"
          },
          "TN-14": {
            type: "Governorate",
            name: "La Manouba"
          },
          "TN-82": {
            type: "Governorate",
            name: "Medenine"
          },
          "TN-83": {
            type: "Governorate",
            name: "Tataouine"
          },
          "TN-81": {
            type: "Governorate",
            name: "Gabès"
          },
          "TN-42": {
            type: "Governorate",
            name: "Kasserine"
          },
          "TN-43": {
            type: "Governorate",
            name: "Sidi Bouzid"
          },
          "TN-41": {
            type: "Governorate",
            name: "Kairouan"
          },
          "TN-21": {
            type: "Governorate",
            name: "Nabeul"
          },
          "TN-22": {
            type: "Governorate",
            name: "Zaghouan"
          },
          "TN-23": {
            type: "Governorate",
            name: "Bizerte"
          },
          "TN-12": {
            type: "Governorate",
            name: "Ariana"
          },
          "TN-73": {
            type: "Governorate",
            name: "Kebili"
          },
          "TN-72": {
            type: "Governorate",
            name: "Tozeur"
          },
          "TN-71": {
            type: "Governorate",
            name: "Gafsa"
          },
          "TN-33": {
            type: "Governorate",
            name: "Le Kef"
          },
          "TN-32": {
            type: "Governorate",
            name: "Jendouba"
          },
          "TN-31": {
            type: "Governorate",
            name: "Béja"
          },
          "TN-11": {
            type: "Governorate",
            name: "Tunis"
          },
          "TN-13": {
            type: "Governorate",
            name: "Ben Arous"
          },
          "TN-34": {
            type: "Governorate",
            name: "Siliana"
          },
          "TN-51": {
            type: "Governorate",
            name: "Sousse"
          },
          "TN-53": {
            type: "Governorate",
            name: "Mahdia"
          },
          "TN-52": {
            type: "Governorate",
            name: "Monastir"
          }
        }
      },
      JO: {
        name: "Jordan",
        sub: {
          "JO-AT": {
            type: "Governorate",
            name: "Aţ Ţafīlah"
          },
          "JO-MA": {
            type: "Governorate",
            name: "Al Mafraq"
          },
          "JO-MD": {
            type: "Governorate",
            name: "Mādabā"
          },
          "JO-AQ": {
            type: "Governorate",
            name: "Al ʽAqabah"
          },
          "JO-MN": {
            type: "Governorate",
            name: "Ma'ān"
          },
          "JO-BA": {
            type: "Governorate",
            name: "Al Balqā'"
          },
          "JO-AZ": {
            type: "Governorate",
            name: "Az Zarqā'"
          },
          "JO-JA": {
            type: "Governorate",
            name: "Jarash"
          },
          "JO-IR": {
            type: "Governorate",
            name: "Irbid"
          },
          "JO-KA": {
            type: "Governorate",
            name: "Al Karak"
          },
          "JO-AM": {
            type: "Governorate",
            name: "‘Ammān"
          },
          "JO-AJ": {
            type: "Governorate",
            name: "ʽAjlūn"
          }
        }
      },
      HR: {
        name: "Croatia",
        sub: {
          "HR-16": {
            type: "County",
            name: "Vukovarsko-srijemska županija"
          },
          "HR-19": {
            type: "County",
            name: "Dubrovačko-neretvanska županija"
          },
          "HR-18": {
            type: "County",
            name: "Istarska županija"
          },
          "HR-08": {
            type: "County",
            name: "Primorsko-goranska županija"
          },
          "HR-09": {
            type: "County",
            name: "Ličko-senjska županija"
          },
          "HR-06": {
            type: "County",
            name: "Koprivničko-križevačka županija"
          },
          "HR-07": {
            type: "County",
            name: "Bjelovarsko-bilogorska županija"
          },
          "HR-04": {
            type: "County",
            name: "Karlovačka županija"
          },
          "HR-05": {
            type: "County",
            name: "Varaždinska županija"
          },
          "HR-02": {
            type: "County",
            name: "Krapinsko-zagorska županija"
          },
          "HR-03": {
            type: "County",
            name: "Sisačko-moslavačka županija"
          },
          "HR-13": {
            type: "County",
            name: "Zadarska županija"
          },
          "HR-01": {
            type: "County",
            name: "Zagrebačka županija"
          },
          "HR-15": {
            type: "County",
            name: "Šibensko-kninska županija"
          },
          "HR-20": {
            type: "County",
            name: "Međimurska županija"
          },
          "HR-21": {
            type: "City",
            name: "Grad Zagreb"
          },
          "HR-14": {
            type: "County",
            name: "Osječko-baranjska županija"
          },
          "HR-12": {
            type: "County",
            name: "Brodsko-posavska županija"
          },
          "HR-10": {
            type: "County",
            name: "Virovitičko-podravska županija"
          },
          "HR-17": {
            type: "County",
            name: "Splitsko-dalmatinska županija"
          },
          "HR-11": {
            type: "County",
            name: "Požeško-slavonska županija"
          }
        }
      },
      HT: {
        name: "Haiti",
        sub: {
          "HT-CE": {
            type: "Department",
            name: "Centre"
          },
          "HT-OU": {
            type: "Department",
            name: "Lwès"
          },
          "HT-GA": {
            type: "Department",
            name: "Grandans"
          },
          "HT-NI": {
            type: "Department",
            name: "Nip"
          },
          "HT-NO": {
            type: "Department",
            name: "Nòdwès"
          },
          "HT-SD": {
            type: "Department",
            name: "Sid"
          },
          "HT-SE": {
            type: "Department",
            name: "Sidès"
          },
          "HT-AR": {
            type: "Department",
            name: "Artibonite"
          },
          "HT-ND": {
            type: "Department",
            name: "Nò"
          },
          "HT-NE": {
            type: "Department",
            name: "Nòdès"
          }
        }
      },
      HU: {
        name: "Hungary",
        sub: {
          "HU-HE": {
            type: "County",
            name: "Heves"
          },
          "HU-BU": {
            type: "Capital city",
            name: "Budapest"
          },
          "HU-HB": {
            type: "County",
            name: "Hajdú-Bihar"
          },
          "HU-FE": {
            type: "County",
            name: "Fejér"
          },
          "HU-SN": {
            type: "City of county right",
            name: "Sopron"
          },
          "HU-BZ": {
            type: "County",
            name: "Borsod-Abaúj-Zemplén"
          },
          "HU-DE": {
            type: "City of county right",
            name: "Debrecen"
          },
          "HU-BA": {
            type: "County",
            name: "Baranya"
          },
          "HU-HV": {
            type: "City of county right",
            name: "Hódmezővásárhely"
          },
          "HU-BC": {
            type: "City of county right",
            name: "Békéscsaba"
          },
          "HU-BE": {
            type: "County",
            name: "Békés"
          },
          "HU-BK": {
            type: "County",
            name: "Bács-Kiskun"
          },
          "HU-DU": {
            type: "City of county right",
            name: "Dunaújváros"
          },
          "HU-SF": {
            type: "City of county right",
            name: "Székesfehérvár"
          },
          "HU-SD": {
            type: "City of county right",
            name: "Szeged"
          },
          "HU-SK": {
            type: "City of county right",
            name: "Szolnok"
          },
          "HU-SH": {
            type: "City of county right",
            name: "Szombathely"
          },
          "HU-KV": {
            type: "City of county right",
            name: "Kaposvár"
          },
          "HU-SO": {
            type: "County",
            name: "Somogy"
          },
          "HU-SS": {
            type: "City of county right",
            name: "Szekszárd"
          },
          "HU-MI": {
            type: "City of county right",
            name: "Miskolc"
          },
          "HU-ST": {
            type: "City of county right",
            name: "Salgótarján"
          },
          "HU-KM": {
            type: "City of county right",
            name: "Kecskemét"
          },
          "HU-SZ": {
            type: "County",
            name: "Szabolcs-Szatmár-Bereg"
          },
          "HU-KE": {
            type: "County",
            name: "Komárom-Esztergom"
          },
          "HU-CS": {
            type: "County",
            name: "Csongrád"
          },
          "HU-ER": {
            type: "City of county right",
            name: "Érd"
          },
          "HU-ZA": {
            type: "County",
            name: "Zala"
          },
          "HU-ZE": {
            type: "City of county right",
            name: "Zalaegerszeg"
          },
          "HU-VM": {
            type: "City of county right",
            name: "Veszprém"
          },
          "HU-EG": {
            type: "City of county right",
            name: "Eger"
          },
          "HU-TO": {
            type: "County",
            name: "Tolna"
          },
          "HU-GY": {
            type: "City of county right",
            name: "Győr"
          },
          "HU-VE": {
            type: "County",
            name: "Veszprém"
          },
          "HU-TB": {
            type: "City of county right",
            name: "Tatabánya"
          },
          "HU-VA": {
            type: "County",
            name: "Vas"
          },
          "HU-GS": {
            type: "County",
            name: "Győr-Moson-Sopron"
          },
          "HU-PE": {
            type: "County",
            name: "Pest"
          },
          "HU-NY": {
            type: "City of county right",
            name: "Nyíregyháza"
          },
          "HU-JN": {
            type: "County",
            name: "Jász-Nagykun-Szolnok"
          },
          "HU-NO": {
            type: "County",
            name: "Nógrád"
          },
          "HU-PS": {
            type: "City of county right",
            name: "Pécs"
          },
          "HU-NK": {
            type: "City of county right",
            name: "Nagykanizsa"
          }
        }
      },
      HK: {
        name: "Hong Kong",
        sub: {
          "HK-HK": {
            type: "Country",
            name: "Hong Kong"
          }
        }
      },
      HN: {
        name: "Honduras",
        sub: {
          "HN-OC": {
            type: "Department",
            name: "Ocotepeque"
          },
          "HN-CM": {
            type: "Department",
            name: "Comayagua"
          },
          "HN-CL": {
            type: "Department",
            name: "Colón"
          },
          "HN-YO": {
            type: "Department",
            name: "Yoro"
          },
          "HN-CH": {
            type: "Department",
            name: "Choluteca"
          },
          "HN-FM": {
            type: "Department",
            name: "Francisco Morazán"
          },
          "HN-IB": {
            type: "Department",
            name: "Islas de la Bahía"
          },
          "HN-EP": {
            type: "Department",
            name: "El Paraíso"
          },
          "HN-OL": {
            type: "Department",
            name: "Olancho"
          },
          "HN-LE": {
            type: "Department",
            name: "Lempira"
          },
          "HN-IN": {
            type: "Department",
            name: "Intibucá"
          },
          "HN-AT": {
            type: "Department",
            name: "Atlántida"
          },
          "HN-LP": {
            type: "Department",
            name: "La Paz"
          },
          "HN-VA": {
            type: "Department",
            name: "Valle"
          },
          "HN-SB": {
            type: "Department",
            name: "Santa Bárbara"
          },
          "HN-GD": {
            type: "Department",
            name: "Gracias a Dios"
          },
          "HN-CR": {
            type: "Department",
            name: "Cortés"
          },
          "HN-CP": {
            type: "Department",
            name: "Copán"
          }
        }
      },
      HM: {
        name: "Heard & McDonald Islands",
        sub: {
          "HM-HM": {
            type: "Country",
            name: "Heard & McDonald Islands"
          }
        }
      },
      VE: {
        name: "Venezuela",
        sub: {
          "VE-L": {
            type: "State",
            name: "Mérida"
          },
          "VE-M": {
            type: "State",
            name: "Miranda"
          },
          "VE-N": {
            type: "State",
            name: "Monagas"
          },
          "VE-O": {
            type: "State",
            name: "Nueva Esparta"
          },
          "VE-H": {
            type: "State",
            name: "Cojedes"
          },
          "VE-I": {
            type: "State",
            name: "Falcón"
          },
          "VE-J": {
            type: "State",
            name: "Guárico"
          },
          "VE-K": {
            type: "State",
            name: "Lara"
          },
          "VE-D": {
            type: "State",
            name: "Aragua"
          },
          "VE-E": {
            type: "State",
            name: "Barinas"
          },
          "VE-F": {
            type: "State",
            name: "Bolívar"
          },
          "VE-G": {
            type: "State",
            name: "Carabobo"
          },
          "VE-A": {
            type: "Capital district",
            name: "Distrito Capital"
          },
          "VE-B": {
            type: "State",
            name: "Anzoátegui"
          },
          "VE-C": {
            type: "State",
            name: "Apure"
          },
          "VE-X": {
            type: "State",
            name: "Vargas"
          },
          "VE-Y": {
            type: "State",
            name: "Delta Amacuro"
          },
          "VE-Z": {
            type: "State",
            name: "Amazonas"
          },
          "VE-T": {
            type: "State",
            name: "Trujillo"
          },
          "VE-U": {
            type: "State",
            name: "Yaracuy"
          },
          "VE-V": {
            type: "State",
            name: "Zulia"
          },
          "VE-W": {
            type: "Federal dependency",
            name: "Dependencias Federales"
          },
          "VE-P": {
            type: "State",
            name: "Portuguesa"
          },
          "VE-R": {
            type: "State",
            name: "Sucre"
          },
          "VE-S": {
            type: "State",
            name: "Táchira"
          }
        }
      },
      PR: {
        name: "Puerto Rico",
        sub: {
          "PR-PR": {
            type: "Country",
            name: "Puerto Rico"
          }
        }
      },
      PS: {
        name: "Palestine",
        sub: {
          "PS-SLT": {
            type: "Governorate",
            name: "Salfit"
          },
          "PS-HBN": {
            type: "Governorate",
            name: "Al Khalīl"
          },
          "PS-RBH": {
            type: "Governorate",
            name: "Ramallah"
          },
          "PS-QQA": {
            type: "Governorate",
            name: "Qalqilya"
          },
          "PS-TBS": {
            type: "Governorate",
            name: "Tubas"
          },
          "PS-JEN": {
            type: "Governorate",
            name: "Janīn"
          },
          "PS-JEM": {
            type: "Governorate",
            name: "Al Quds"
          },
          "PS-KYS": {
            type: "Governorate",
            name: "Khan Yunis"
          },
          "PS-DEB": {
            type: "Governorate",
            name: "Dayr al Balaḩ"
          },
          "PS-BTH": {
            type: "Governorate",
            name: "Bayt Laḩm"
          },
          "PS-JRH": {
            type: "Governorate",
            name: "Arīḩā wa al Aghwār"
          },
          "PS-GZA": {
            type: "Governorate",
            name: "Gaza"
          },
          "PS-NBS": {
            type: "Governorate",
            name: "Nablus"
          },
          "PS-RFH": {
            type: "Governorate",
            name: "Rafah"
          },
          "PS-TKM": {
            type: "Governorate",
            name: "Tulkarm"
          },
          "PS-NGZ": {
            type: "Governorate",
            name: "North Gaza"
          }
        }
      },
      PW: {
        name: "Palau",
        sub: {
          "PW-222": {
            type: "State",
            name: "Ngardmau"
          },
          "PW-050": {
            type: "State",
            name: "Hatobohei"
          },
          "PW-218": {
            type: "State",
            name: "Ngarchelong"
          },
          "PW-226": {
            type: "State",
            name: "Ngchesar"
          },
          "PW-227": {
            type: "State",
            name: "Ngeremlengui"
          },
          "PW-224": {
            type: "State",
            name: "Ngatpang"
          },
          "PW-100": {
            type: "State",
            name: "Kayangel"
          },
          "PW-212": {
            type: "State",
            name: "Melekeok"
          },
          "PW-228": {
            type: "State",
            name: "Ngiwal"
          },
          "PW-150": {
            type: "State",
            name: "Koror"
          },
          "PW-214": {
            type: "State",
            name: "Ngaraard"
          },
          "PW-370": {
            type: "State",
            name: "Sonsorol"
          },
          "PW-004": {
            type: "State",
            name: "Airai"
          },
          "PW-002": {
            type: "State",
            name: "Aimeliik"
          },
          "PW-010": {
            type: "State",
            name: "Angaur"
          },
          "PW-350": {
            type: "State",
            name: "Peleliu"
          }
        }
      },
      PT: {
        name: "Portugal",
        sub: {
          "PT-13": {
            type: "District",
            name: "Porto"
          },
          "PT-12": {
            type: "District",
            name: "Portalegre"
          },
          "PT-11": {
            type: "District",
            name: "Lisboa"
          },
          "PT-10": {
            type: "District",
            name: "Leiria"
          },
          "PT-08": {
            type: "District",
            name: "Faro"
          },
          "PT-09": {
            type: "District",
            name: "Guarda"
          },
          "PT-15": {
            type: "District",
            name: "Setúbal"
          },
          "PT-14": {
            type: "District",
            name: "Santarém"
          },
          "PT-04": {
            type: "District",
            name: "Bragança"
          },
          "PT-05": {
            type: "District",
            name: "Castelo Branco"
          },
          "PT-06": {
            type: "District",
            name: "Coimbra"
          },
          "PT-07": {
            type: "District",
            name: "Évora"
          },
          "PT-01": {
            type: "District",
            name: "Aveiro"
          },
          "PT-02": {
            type: "District",
            name: "Beja"
          },
          "PT-03": {
            type: "District",
            name: "Braga"
          },
          "PT-20": {
            type: "Autonomous region",
            name: "Região Autónoma dos Açores"
          },
          "PT-18": {
            type: "District",
            name: "Viseu"
          },
          "PT-17": {
            type: "District",
            name: "Vila Real"
          },
          "PT-30": {
            type: "Autonomous region",
            name: "Região Autónoma da Madeira"
          },
          "PT-16": {
            type: "District",
            name: "Viana do Castelo"
          }
        }
      },
      KN: {
        name: "Saint Kitts And Nevis",
        sub: {
          "KN-05": {
            type: "Parish",
            name: "Saint James Windward"
          },
          "KN-04": {
            type: "Parish",
            name: "Saint George Gingerland"
          },
          "KN-07": {
            type: "Parish",
            name: "Saint John Figtree"
          },
          "KN-06": {
            type: "Parish",
            name: "Saint John Capisterre"
          },
          "KN-01": {
            type: "Parish",
            name: "Christ Church Nichola Town"
          },
          "KN-10": {
            type: "Parish",
            name: "Saint Paul Charlestown"
          },
          "KN-03": {
            type: "Parish",
            name: "Saint George Basseterre"
          },
          "KN-02": {
            type: "Parish",
            name: "Saint Anne Sandy Point"
          },
          "KN-N": {
            type: "State",
            name: "Nevis"
          },
          "KN-11": {
            type: "Parish",
            name: "Saint Peter Basseterre"
          },
          "KN-09": {
            type: "Parish",
            name: "Saint Paul Capisterre"
          },
          "KN-08": {
            type: "Parish",
            name: "Saint Mary Cayon"
          },
          "KN-K": {
            type: "State",
            name: "Saint Kitts"
          },
          "KN-15": {
            type: "Parish",
            name: "Trinity Palmetto Point"
          },
          "KN-12": {
            type: "Parish",
            name: "Saint Thomas Lowland"
          },
          "KN-13": {
            type: "Parish",
            name: "Saint Thomas Middle Island"
          }
        }
      },
      PY: {
        name: "Paraguay",
        sub: {
          "PY-19": {
            type: "Department",
            name: "Boquerón"
          },
          "PY-14": {
            type: "Department",
            name: "Canindeyú"
          },
          "PY-15": {
            type: "Department",
            name: "Presidente Hayes"
          },
          "PY-16": {
            type: "Department",
            name: "Alto Paraguay"
          },
          "PY-10": {
            type: "Department",
            name: "Alto Paraná"
          },
          "PY-11": {
            type: "Department",
            name: "Central"
          },
          "PY-12": {
            type: "Department",
            name: "Ñeembucú"
          },
          "PY-13": {
            type: "Department",
            name: "Amambay"
          },
          "PY-6": {
            type: "Department",
            name: "Caazapá"
          },
          "PY-7": {
            type: "Department",
            name: "Itapúa"
          },
          "PY-4": {
            type: "Department",
            name: "Guairá"
          },
          "PY-5": {
            type: "Department",
            name: "Caaguazú"
          },
          "PY-2": {
            type: "Department",
            name: "San Pedro"
          },
          "PY-3": {
            type: "Department",
            name: "Cordillera"
          },
          "PY-1": {
            type: "Department",
            name: "Concepción"
          },
          "PY-ASU": {
            type: "Capital",
            name: "Asunción"
          },
          "PY-8": {
            type: "Department",
            name: "Misiones"
          },
          "PY-9": {
            type: "Department",
            name: "Paraguarí"
          }
        }
      },
      IQ: {
        name: "Iraq",
        sub: {
          "IQ-NA": {
            type: "Governorate",
            name: "An Najaf"
          },
          "IQ-AN": {
            type: "Governorate",
            name: "Al Anbār"
          },
          "IQ-WA": {
            type: "Governorate",
            name: "Wāsiţ"
          },
          "IQ-SD": {
            type: "Governorate",
            name: "Şalāḩ ad Dīn"
          },
          "IQ-MU": {
            type: "Governorate",
            name: "Al Muthanná"
          },
          "IQ-DA": {
            type: "Governorate",
            name: "Dahūk"
          },
          "IQ-SU": {
            type: "Governorate",
            name: "As Sulaymānīyah"
          },
          "IQ-KA": {
            type: "Governorate",
            name: "Karbalā'"
          },
          "IQ-BG": {
            type: "Governorate",
            name: "Baghdād"
          },
          "IQ-NI": {
            type: "Governorate",
            name: "Nīnawá"
          },
          "IQ-BA": {
            type: "Governorate",
            name: "Al Başrah"
          },
          "IQ-BB": {
            type: "Governorate",
            name: "Bābil"
          },
          "IQ-DQ": {
            type: "Governorate",
            name: "Dhī Qār"
          },
          "IQ-MA": {
            type: "Governorate",
            name: "Maysān"
          },
          "IQ-KI": {
            type: "Governorate",
            name: "Kirkūk"
          },
          "IQ-DI": {
            type: "Governorate",
            name: "Diyālá"
          },
          "IQ-QA": {
            type: "Governorate",
            name: "Al Qādisīyah"
          },
          "IQ-AR": {
            type: "Governorate",
            name: "Arbīl"
          }
        }
      },
      PA: {
        name: "Panama",
        sub: {
          "PA-NB": {
            type: "Indigenous region",
            name: "Ngöbe-Buglé"
          },
          "PA-8": {
            type: "Province",
            name: "Panamá"
          },
          "PA-9": {
            type: "Province",
            name: "Veraguas"
          },
          "PA-6": {
            type: "Province",
            name: "Herrera"
          },
          "PA-7": {
            type: "Province",
            name: "Los Santos"
          },
          "PA-4": {
            type: "Province",
            name: "Chiriquí"
          },
          "PA-5": {
            type: "Province",
            name: "Darién"
          },
          "PA-2": {
            type: "Province",
            name: "Coclé"
          },
          "PA-3": {
            type: "Province",
            name: "Colón"
          },
          "PA-1": {
            type: "Province",
            name: "Bocas del Toro"
          },
          "PA-10": {
            type: "Province",
            name: "Panamá Oeste"
          },
          "PA-EM": {
            type: "Indigenous region",
            name: "Emberá"
          },
          "PA-KY": {
            type: "Indigenous region",
            name: "Kuna Yala"
          }
        }
      },
      PF: {
        name: "French Polynesia",
        sub: {
          "PF-PF": {
            type: "Country",
            name: "French Polynesia"
          }
        }
      },
      PG: {
        name: "Papua New Guinea",
        sub: {
          "PG-WBK": {
            type: "Province",
            name: "West New Britain"
          },
          "PG-NCD": {
            type: "District",
            name: "National Capital District"
          },
          "PG-MPM": {
            type: "Province",
            name: "Madang"
          },
          "PG-MPL": {
            type: "Province",
            name: "Morobe"
          },
          "PG-MRL": {
            type: "Province",
            name: "Manus"
          },
          "PG-EHG": {
            type: "Province",
            name: "Eastern Highlands"
          },
          "PG-ESW": {
            type: "Province",
            name: "East Sepik"
          },
          "PG-SAN": {
            type: "Province",
            name: "West Sepik"
          },
          "PG-NIK": {
            type: "Province",
            name: "New Ireland"
          },
          "PG-JWK": {
            type: "Province",
            name: "Jiwaka"
          },
          "PG-MBA": {
            type: "Province",
            name: "Milne Bay"
          },
          "PG-HLA": {
            type: "Province",
            name: "Hela"
          },
          "PG-WPD": {
            type: "Province",
            name: "Western"
          },
          "PG-NSB": {
            type: "Autonomous region",
            name: "Bougainville"
          },
          "PG-EPW": {
            type: "Province",
            name: "Enga"
          },
          "PG-CPM": {
            type: "Province",
            name: "Central"
          },
          "PG-CPK": {
            type: "Province",
            name: "Chimbu"
          },
          "PG-NPP": {
            type: "Province",
            name: "Northern"
          },
          "PG-WHM": {
            type: "Province",
            name: "Western Highlands"
          },
          "PG-GPK": {
            type: "Province",
            name: "Gulf"
          },
          "PG-EBR": {
            type: "Province",
            name: "East New Britain"
          },
          "PG-SHM": {
            type: "Province",
            name: "Southern Highlands"
          }
        }
      },
      PE: {
        name: "Peru",
        sub: {
          "PE-PIU": {
            type: "Region",
            name: "Piura"
          },
          "PE-ARE": {
            type: "Region",
            name: "Arequipa"
          },
          "PE-APU": {
            type: "Region",
            name: "Apurímac"
          },
          "PE-JUN": {
            type: "Region",
            name: "Hunin"
          },
          "PE-LMA": {
            type: "Municipality",
            name: "Lima hatun llaqta"
          },
          "PE-LOR": {
            type: "Region",
            name: "Loreto"
          },
          "PE-LIM": {
            type: "Region",
            name: "Lima"
          },
          "PE-AMA": {
            type: "Region",
            name: "Amarumayu"
          },
          "PE-LAM": {
            type: "Region",
            name: "Lambayeque"
          },
          "PE-LAL": {
            type: "Region",
            name: "La Libertad"
          },
          "PE-HUC": {
            type: "Region",
            name: "Huánuco"
          },
          "PE-CUS": {
            type: "Region",
            name: "Cusco"
          },
          "PE-PUN": {
            type: "Region",
            name: "Puno"
          },
          "PE-HUV": {
            type: "Region",
            name: "Huancavelica"
          },
          "PE-MOQ": {
            type: "Region",
            name: "Moquegua"
          },
          "PE-TAC": {
            type: "Region",
            name: "Tacna"
          },
          "PE-AYA": {
            type: "Region",
            name: "Ayacucho"
          },
          "PE-UCA": {
            type: "Region",
            name: "Ucayali"
          },
          "PE-ICA": {
            type: "Region",
            name: "Ica"
          },
          "PE-SAM": {
            type: "Region",
            name: "San Martin"
          },
          "PE-ANC": {
            type: "Region",
            name: "Ancash"
          },
          "PE-PAS": {
            type: "Region",
            name: "Pasco"
          },
          "PE-CAL": {
            type: "Region",
            name: "El Callao"
          },
          "PE-TUM": {
            type: "Region",
            name: "Tumbes"
          },
          "PE-CAJ": {
            type: "Region",
            name: "Cajamarca"
          },
          "PE-MDD": {
            type: "Region",
            name: "Madre de Dios"
          }
        }
      },
      PK: {
        name: "Pakistan",
        sub: {
          "PK-IS": {
            type: "Federal capital territory",
            name: "Islāmābād"
          },
          "PK-TA": {
            type: "Territory",
            name: "Federally Administered Tribal Areas"
          },
          "PK-BA": {
            type: "Province",
            name: "Balochistan"
          },
          "PK-KP": {
            type: "Province",
            name: "Khaībar Pakhtūnkhwā"
          },
          "PK-PB": {
            type: "Province",
            name: "Panjāb"
          },
          "PK-JK": {
            type: "Pakistan administered area",
            name: "Azad Kashmir"
          },
          "PK-SD": {
            type: "Province",
            name: "Sindh"
          },
          "PK-GB": {
            type: "Pakistan administered area",
            name: "Gilgit-Baltistān"
          }
        }
      },
      PH: {
        name: "Philippines",
        sub: {
          "PH-08": {
            type: "Region",
            name: "Eastern Visayas"
          },
          "PH-09": {
            type: "Region",
            name: "Rehiyon ng Tangway ng Sambuwangga"
          },
          "PH-00": {
            type: "Region",
            name: "National Capital Region"
          },
          "PH-01": {
            type: "Region",
            name: "Ilocos"
          },
          "PH-02": {
            type: "Region",
            name: "Cagayan Valley"
          },
          "PH-03": {
            type: "Region",
            name: "Central Luzon"
          },
          "PH-05": {
            type: "Region",
            name: "Bicol"
          },
          "PH-06": {
            type: "Region",
            name: "Rehiyon ng Kanlurang Bisaya"
          },
          "PH-07": {
            type: "Region",
            name: "Central Visayas"
          },
          "PH-SUR": {
            type: "Province",
            name: "Surigao del Sur"
          },
          "PH-SLU": {
            type: "Province",
            name: "Sulu"
          },
          "PH-DAV": {
            type: "Province",
            name: "Davao del Norte"
          },
          "PH-CAM": {
            type: "Province",
            name: "Camiguin"
          },
          "PH-CAN": {
            type: "Province",
            name: "Camarines Norte"
          },
          "PH-BUL": {
            type: "Province",
            name: "Bulacan"
          },
          "PH-CAG": {
            type: "Province",
            name: "Cagayan"
          },
          "PH-BOH": {
            type: "Province",
            name: "Bohol"
          },
          "PH-CAP": {
            type: "Province",
            name: "Capiz"
          },
          "PH-CAS": {
            type: "Province",
            name: "Camarines Sur"
          },
          "PH-CAT": {
            type: "Province",
            name: "Catanduanes"
          },
          "PH-CAV": {
            type: "Province",
            name: "Cavite"
          },
          "PH-BIL": {
            type: "Province",
            name: "Biliran"
          },
          "PH-SLE": {
            type: "Province",
            name: "Katimogang Leyte"
          },
          "PH-RIZ": {
            type: "Province",
            name: "Risal"
          },
          "PH-COM": {
            type: "Province",
            name: "Compostela Valley"
          },
          "PH-SCO": {
            type: "Province",
            name: "South Cotabato"
          },
          "PH-ZSI": {
            type: "Province",
            name: "Sambuwangga Sibugay"
          },
          "PH-ILS": {
            type: "Province",
            name: "Ilocos Sur"
          },
          "PH-AGS": {
            type: "Province",
            name: "Agusan del Sur"
          },
          "PH-ILI": {
            type: "Province",
            name: "Iloilo"
          },
          "PH-WSA": {
            type: "Province",
            name: "Samar"
          },
          "PH-PLW": {
            type: "Province",
            name: "Palawan"
          },
          "PH-KAL": {
            type: "Province",
            name: "Kalinga"
          },
          "PH-ZAS": {
            type: "Province",
            name: "Timog Sambuwangga"
          },
          "PH-ZAN": {
            type: "Province",
            name: "Hilagang Sambuwangga"
          },
          "PH-ALB": {
            type: "Province",
            name: "Albay"
          },
          "PH-NUE": {
            type: "Province",
            name: "Nueva Ecija"
          },
          "PH-NUV": {
            type: "Province",
            name: "Nueva Vizcaya"
          },
          "PH-SUK": {
            type: "Province",
            name: "Sultan Kudarat"
          },
          "PH-TAW": {
            type: "Province",
            name: "Tawi-Tawi"
          },
          "PH-TAR": {
            type: "Province",
            name: "Tarlac"
          },
          "PH-AKL": {
            type: "Province",
            name: "Aklan"
          },
          "PH-BEN": {
            type: "Province",
            name: "Benget"
          },
          "PH-DIN": {
            type: "Province",
            name: "Dinagat Islands"
          },
          "PH-DAS": {
            type: "Province",
            name: "Davao del Sur"
          },
          "PH-ROM": {
            type: "Province",
            name: "Romblon"
          },
          "PH-ISA": {
            type: "Province",
            name: "Isabela"
          },
          "PH-NER": {
            type: "Province",
            name: "Negros Oriental"
          },
          "PH-LAS": {
            type: "Province",
            name: "Lanao del Sur"
          },
          "PH-LAN": {
            type: "Province",
            name: "Hilagang Lanaw"
          },
          "PH-NEC": {
            type: "Province",
            name: "Kanlurang Negros"
          },
          "PH-LAG": {
            type: "Province",
            name: "Laguna"
          },
          "PH-SUN": {
            type: "Province",
            name: "Hilagang Surigaw"
          },
          "PH-AUR": {
            type: "Province",
            name: "Aurora"
          },
          "PH-MSR": {
            type: "Province",
            name: "Misamis Oriental"
          },
          "PH-ABR": {
            type: "Province",
            name: "Abra"
          },
          "PH-MSC": {
            type: "Province",
            name: "Kanlurang Misamis"
          },
          "PH-40": {
            type: "Region",
            name: "Calabarzon"
          },
          "PH-41": {
            type: "Region",
            name: "Mimaropa"
          },
          "PH-EAS": {
            type: "Province",
            name: "Eastern Samar"
          },
          "PH-BTN": {
            type: "Province",
            name: "Batanes"
          },
          "PH-BTG": {
            type: "Province",
            name: "Batangas"
          },
          "PH-QUI": {
            type: "Province",
            name: "Kirino"
          },
          "PH-CEB": {
            type: "Province",
            name: "Cebu"
          },
          "PH-APA": {
            type: "Province",
            name: "Apayao"
          },
          "PH-SAR": {
            type: "Province",
            name: "Sarangani"
          },
          "PH-QUE": {
            type: "Province",
            name: "Keson"
          },
          "PH-IFU": {
            type: "Province",
            name: "Ifugao"
          },
          "PH-MAS": {
            type: "Province",
            name: "Masbate"
          },
          "PH-SOR": {
            type: "Province",
            name: "Sorsogon"
          },
          "PH-NCO": {
            type: "Province",
            name: "Cotabato"
          },
          "PH-ZMB": {
            type: "Province",
            name: "Sambales"
          },
          "PH-MAG": {
            type: "Province",
            name: "Magindanaw"
          },
          "PH-MAD": {
            type: "Province",
            name: "Marinduke"
          },
          "PH-BUK": {
            type: "Province",
            name: "Bukidnon"
          },
          "PH-PAN": {
            type: "Province",
            name: "Pangasinan"
          },
          "PH-PAM": {
            type: "Province",
            name: "Pampanga"
          },
          "PH-GUI": {
            type: "Province",
            name: "Gimaras"
          },
          "PH-NSA": {
            type: "Province",
            name: "Hilagang Samar"
          },
          "PH-MDC": {
            type: "Province",
            name: "Kanlurang Mindoro"
          },
          "PH-MDR": {
            type: "Province",
            name: "Mindoro Oriental"
          },
          "PH-LUN": {
            type: "Province",
            name: "La Union"
          },
          "PH-BAS": {
            type: "Province",
            name: "Basilan"
          },
          "PH-BAN": {
            type: "Province",
            name: "Bataan"
          },
          "PH-MOU": {
            type: "Province",
            name: "Lalawigang Bulubundukin"
          },
          "PH-15": {
            type: "Region",
            name: "Cordillera Administrative Region"
          },
          "PH-14": {
            type: "Region",
            name: "Autonomous Region in Muslim Mindanao"
          },
          "PH-13": {
            type: "Region",
            name: "Caraga"
          },
          "PH-12": {
            type: "Region",
            name: "Rehiyon ng Soccsksargen"
          },
          "PH-11": {
            type: "Region",
            name: "Davao"
          },
          "PH-10": {
            type: "Region",
            name: "Northern Mindanao"
          },
          "PH-SIG": {
            type: "Province",
            name: "Sikihor"
          },
          "PH-AGN": {
            type: "Province",
            name: "Agusan del Norte"
          },
          "PH-ILN": {
            type: "Province",
            name: "Hilagang Iloko"
          },
          "PH-DAO": {
            type: "Province",
            name: "Davao Oriental"
          },
          "PH-LEY": {
            type: "Province",
            name: "Leyte"
          },
          "PH-ANT": {
            type: "Province",
            name: "Antike"
          }
        }
      },
      PN: {
        name: "Pitcairn",
        sub: {
          "PN-PN": {
            type: "Country",
            name: "Pitcairn"
          }
        }
      },
      PL: {
        name: "Poland",
        sub: {
          "PL-SL": {
            type: "Province",
            name: "Śląskie"
          },
          "PL-LU": {
            type: "Province",
            name: "Lubelskie"
          },
          "PL-SK": {
            type: "Province",
            name: "Świętokrzyskie"
          },
          "PL-WP": {
            type: "Province",
            name: "Wielkopolskie"
          },
          "PL-ZP": {
            type: "Province",
            name: "Zachodniopomorskie"
          },
          "PL-MZ": {
            type: "Province",
            name: "Mazowieckie"
          },
          "PL-PD": {
            type: "Province",
            name: "Podlaskie"
          },
          "PL-LB": {
            type: "Province",
            name: "Lubuskie"
          },
          "PL-LD": {
            type: "Province",
            name: "Łódzkie"
          },
          "PL-KP": {
            type: "Province",
            name: "Kujawsko-pomorskie"
          },
          "PL-MA": {
            type: "Province",
            name: "Małopolskie"
          },
          "PL-PM": {
            type: "Province",
            name: "Pomorskie"
          },
          "PL-OP": {
            type: "Province",
            name: "Opolskie"
          },
          "PL-DS": {
            type: "Province",
            name: "Dolnośląskie"
          },
          "PL-WN": {
            type: "Province",
            name: "Warmińsko-mazurskie"
          },
          "PL-PK": {
            type: "Province",
            name: "Podkarpackie"
          }
        }
      },
      PM: {
        name: "St. Pierre & Miquelon",
        sub: {
          "PM-PM": {
            type: "Country",
            name: "St. Pierre & Miquelon"
          }
        }
      },
      ZM: {
        name: "Zambia",
        sub: {
          "ZM-01": {
            type: "Province",
            name: "Western"
          },
          "ZM-03": {
            type: "Province",
            name: "Eastern"
          },
          "ZM-02": {
            type: "Province",
            name: "Central"
          },
          "ZM-05": {
            type: "Province",
            name: "Northern"
          },
          "ZM-04": {
            type: "Province",
            name: "Luapula"
          },
          "ZM-07": {
            type: "Province",
            name: "Southern"
          },
          "ZM-06": {
            type: "Province",
            name: "North-Western"
          },
          "ZM-09": {
            type: "Province",
            name: "Lusaka"
          },
          "ZM-08": {
            type: "Province",
            name: "Copperbelt"
          },
          "ZM-10": {
            type: "Province",
            name: "Muchinga"
          }
        }
      },
      EH: {
        name: "Western Sahara",
        sub: {
          "EH-EH": {
            type: "Country",
            name: "Western Sahara"
          }
        }
      },
      EE: {
        name: "Estonia",
        sub: {
          "EE-86": {
            type: "County",
            name: "Võrumaa"
          },
          "EE-39": {
            type: "County",
            name: "Hiiumaa"
          },
          "EE-74": {
            type: "County",
            name: "Saaremaa"
          },
          "EE-44": {
            type: "County",
            name: "Ida-Virumaa"
          },
          "EE-70": {
            type: "County",
            name: "Raplamaa"
          },
          "EE-37": {
            type: "County",
            name: "Harjumaa"
          },
          "EE-51": {
            type: "County",
            name: "Järvamaa"
          },
          "EE-84": {
            type: "County",
            name: "Viljandimaa"
          },
          "EE-82": {
            type: "County",
            name: "Valgamaa"
          },
          "EE-57": {
            type: "County",
            name: "Läänemaa"
          },
          "EE-78": {
            type: "County",
            name: "Tartumaa"
          },
          "EE-59": {
            type: "County",
            name: "Lääne-Virumaa"
          },
          "EE-49": {
            type: "County",
            name: "Jõgevamaa"
          },
          "EE-65": {
            type: "County",
            name: "Põlvamaa"
          },
          "EE-67": {
            type: "County",
            name: "Pärnumaa"
          }
        }
      },
      EG: {
        name: "Egypt",
        sub: {
          "EG-DT": {
            type: "Governorate",
            name: "Dumyāţ"
          },
          "EG-SIN": {
            type: "Governorate",
            name: "Shamāl Sīnā'"
          },
          "EG-BA": {
            type: "Governorate",
            name: "Al Baḩr al Aḩmar"
          },
          "EG-BH": {
            type: "Governorate",
            name: "Al Buḩayrah"
          },
          "EG-DK": {
            type: "Governorate",
            name: "Ad Daqahlīyah"
          },
          "EG-SHG": {
            type: "Governorate",
            name: "Sūhāj"
          },
          "EG-MT": {
            type: "Governorate",
            name: "Maţrūḩ"
          },
          "EG-KN": {
            type: "Governorate",
            name: "Qinā"
          },
          "EG-KB": {
            type: "Governorate",
            name: "Al Qalyūbīyah"
          },
          "EG-MN": {
            type: "Governorate",
            name: "Al Minyā"
          },
          "EG-GH": {
            type: "Governorate",
            name: "Al Gharbīyah"
          },
          "EG-AST": {
            type: "Governorate",
            name: "Asyūţ"
          },
          "EG-SHR": {
            type: "Governorate",
            name: "Ash Sharqīyah"
          },
          "EG-ASN": {
            type: "Governorate",
            name: "Aswān"
          },
          "EG-IS": {
            type: "Governorate",
            name: "Al Ismā'īlīyah"
          },
          "EG-GZ": {
            type: "Governorate",
            name: "Al Jīzah"
          },
          "EG-C": {
            type: "Governorate",
            name: "Al Qāhirah"
          },
          "EG-SUZ": {
            type: "Governorate",
            name: "As Suways"
          },
          "EG-ALX": {
            type: "Governorate",
            name: "Al Iskandarīyah"
          },
          "EG-BNS": {
            type: "Governorate",
            name: "Banī Suwayf"
          },
          "EG-LX": {
            type: "Governorate",
            name: "Al Uqşur"
          },
          "EG-MNF": {
            type: "Governorate",
            name: "Al Minūfīyah"
          },
          "EG-JS": {
            type: "Governorate",
            name: "Janūb Sīnā'"
          },
          "EG-WAD": {
            type: "Governorate",
            name: "Al Wādī al Jadīd"
          },
          "EG-KFS": {
            type: "Governorate",
            name: "Kafr ash Shaykh"
          },
          "EG-FYM": {
            type: "Governorate",
            name: "Al Fayyūm"
          },
          "EG-PTS": {
            type: "Governorate",
            name: "Būr Sa‘īd"
          }
        }
      },
      ZA: {
        name: "South Africa",
        sub: {
          "ZA-NL": {
            type: "Province",
            name: "GaZulu-Natala"
          },
          "ZA-GT": {
            type: "Province",
            name: "Gauteng"
          },
          "ZA-EC": {
            type: "Province",
            name: "Eastern Cape"
          },
          "ZA-NC": {
            type: "Province",
            name: "iTlhagwini-Kapa"
          },
          "ZA-LP": {
            type: "Province",
            name: "Limpopo"
          },
          "ZA-MP": {
            type: "Province",
            name: "iMpumalanga"
          },
          "ZA-WC": {
            type: "Province",
            name: "iTjhingalanga-Kapa"
          },
          "ZA-NW": {
            type: "Province",
            name: "Bokone Bophirima"
          },
          "ZA-FS": {
            type: "Province",
            name: "Foreisetata"
          }
        }
      },
      EC: {
        name: "Ecuador",
        sub: {
          "EC-SE": {
            type: "Province",
            name: "Santa Elena"
          },
          "EC-SD": {
            type: "Province",
            name: "Santo Domingo de los Tsáchilas"
          },
          "EC-Z": {
            type: "Province",
            name: "Zamora-Chinchipe"
          },
          "EC-Y": {
            type: "Province",
            name: "Pastaza"
          },
          "EC-X": {
            type: "Province",
            name: "Cotopaxi"
          },
          "EC-W": {
            type: "Province",
            name: "Galápagos"
          },
          "EC-U": {
            type: "Province",
            name: "Sucumbíos"
          },
          "EC-T": {
            type: "Province",
            name: "Tungurahua"
          },
          "EC-S": {
            type: "Province",
            name: "Morona-Santiago"
          },
          "EC-R": {
            type: "Province",
            name: "Los Ríos"
          },
          "EC-P": {
            type: "Province",
            name: "Pichincha"
          },
          "EC-O": {
            type: "Province",
            name: "El Oro"
          },
          "EC-N": {
            type: "Province",
            name: "Napo"
          },
          "EC-M": {
            type: "Province",
            name: "Manabí"
          },
          "EC-L": {
            type: "Province",
            name: "Loja"
          },
          "EC-I": {
            type: "Province",
            name: "Imbabura"
          },
          "EC-H": {
            type: "Province",
            name: "Chimborazo"
          },
          "EC-G": {
            type: "Province",
            name: "Guayas"
          },
          "EC-F": {
            type: "Province",
            name: "Cañar"
          },
          "EC-E": {
            type: "Province",
            name: "Esmeraldas"
          },
          "EC-D": {
            type: "Province",
            name: "Orellana"
          },
          "EC-C": {
            type: "Province",
            name: "Carchi"
          },
          "EC-B": {
            type: "Province",
            name: "Bolívar"
          },
          "EC-A": {
            type: "Province",
            name: "Azuay"
          }
        }
      },
      IT: {
        name: "Italy",
        sub: {
          "IT-BA": {
            type: "Province",
            name: "Bari"
          },
          "IT-TR": {
            type: "Province",
            name: "Terni"
          },
          "IT-BG": {
            type: "Province",
            name: "Bergamo"
          },
          "IT-BI": {
            type: "Province",
            name: "Biella"
          },
          "IT-BO": {
            type: "Province",
            name: "Bologna"
          },
          "IT-BN": {
            type: "Province",
            name: "Benevento"
          },
          "IT-BL": {
            type: "Province",
            name: "Belluno"
          },
          "IT-BS": {
            type: "Province",
            name: "Brescia"
          },
          "IT-BR": {
            type: "Province",
            name: "Brindisi"
          },
          "IT-BT": {
            type: "Province",
            name: "Barletta-Andria-Trani"
          },
          "IT-BZ": {
            type: "Province",
            name: "Bolzano"
          },
          "IT-23": {
            type: "Region",
            name: "Val d'Aoste"
          },
          "IT-21": {
            type: "Region",
            name: "Piemonte"
          },
          "IT-25": {
            type: "Region",
            name: "Lombardia"
          },
          "IT-RC": {
            type: "Province",
            name: "Reggio Calabria"
          },
          "IT-RA": {
            type: "Province",
            name: "Ravenna"
          },
          "IT-RG": {
            type: "Province",
            name: "Ragusa"
          },
          "IT-RE": {
            type: "Province",
            name: "Reggio Emilia"
          },
          "IT-RI": {
            type: "Province",
            name: "Rieti"
          },
          "IT-RO": {
            type: "Province",
            name: "Rovigo"
          },
          "IT-RN": {
            type: "Province",
            name: "Rimini"
          },
          "IT-RM": {
            type: "Province",
            name: "Roma"
          },
          "IT-AG": {
            type: "Province",
            name: "Agrigento"
          },
          "IT-AN": {
            type: "Province",
            name: "Ancona"
          },
          "IT-AO": {
            type: "Province",
            name: "Aosta"
          },
          "IT-AL": {
            type: "Province",
            name: "Alessandria"
          },
          "IT-IS": {
            type: "Province",
            name: "Isernia"
          },
          "IT-AV": {
            type: "Province",
            name: "Avellino"
          },
          "IT-AT": {
            type: "Province",
            name: "Asti"
          },
          "IT-IM": {
            type: "Province",
            name: "Imperia"
          },
          "IT-AR": {
            type: "Province",
            name: "Arezzo"
          },
          "IT-AP": {
            type: "Province",
            name: "Ascoli Piceno"
          },
          "IT-AQ": {
            type: "Province",
            name: "L'Aquila"
          },
          "IT-FG": {
            type: "Province",
            name: "Foggia"
          },
          "IT-34": {
            type: "Region",
            name: "Veneto"
          },
          "IT-36": {
            type: "Region",
            name: "Friuli-Venezia Giulia"
          },
          "IT-FE": {
            type: "Province",
            name: "Ferrara"
          },
          "IT-32": {
            type: "Region",
            name: "Trentino-Alto Adige"
          },
          "IT-VE": {
            type: "Province",
            name: "Venezia"
          },
          "IT-LC": {
            type: "Province",
            name: "Lecco"
          },
          "IT-LE": {
            type: "Province",
            name: "Lecce"
          },
          "IT-LI": {
            type: "Province",
            name: "Livorno"
          },
          "IT-TP": {
            type: "Province",
            name: "Trapani"
          },
          "IT-TS": {
            type: "Province",
            name: "Trieste"
          },
          "IT-42": {
            type: "Region",
            name: "Liguria"
          },
          "IT-45": {
            type: "Region",
            name: "Emilia-Romagna"
          },
          "IT-LO": {
            type: "Province",
            name: "Lodi"
          },
          "IT-TV": {
            type: "Province",
            name: "Treviso"
          },
          "IT-LU": {
            type: "Province",
            name: "Lucca"
          },
          "IT-LT": {
            type: "Province",
            name: "Latina"
          },
          "IT-TO": {
            type: "Province",
            name: "Torino"
          },
          "IT-TN": {
            type: "Province",
            name: "Trento"
          },
          "IT-TA": {
            type: "Province",
            name: "Taranto"
          },
          "IT-TE": {
            type: "Province",
            name: "Teramo"
          },
          "IT-CL": {
            type: "Province",
            name: "Caltanissetta"
          },
          "IT-CN": {
            type: "Province",
            name: "Cuneo"
          },
          "IT-CO": {
            type: "Province",
            name: "Como"
          },
          "IT-CH": {
            type: "Province",
            name: "Chieti"
          },
          "IT-CI": {
            type: "Province",
            name: "Carbonia-Iglesias"
          },
          "IT-CE": {
            type: "Province",
            name: "Caserta"
          },
          "IT-CA": {
            type: "Province",
            name: "Cagliari"
          },
          "IT-CB": {
            type: "Province",
            name: "Campobasso"
          },
          "IT-CZ": {
            type: "Province",
            name: "Catanzaro"
          },
          "IT-CT": {
            type: "Province",
            name: "Catania"
          },
          "IT-CR": {
            type: "Province",
            name: "Cremona"
          },
          "IT-CS": {
            type: "Province",
            name: "Cosenza"
          },
          "IT-52": {
            type: "Region",
            name: "Toscana"
          },
          "IT-SV": {
            type: "Province",
            name: "Savona"
          },
          "IT-SP": {
            type: "Province",
            name: "La Spezia"
          },
          "IT-57": {
            type: "Region",
            name: "Marche"
          },
          "IT-SR": {
            type: "Province",
            name: "Siracusa"
          },
          "IT-55": {
            type: "Region",
            name: "Umbria"
          },
          "IT-SO": {
            type: "Province",
            name: "Sondrio"
          },
          "IT-SI": {
            type: "Province",
            name: "Siena"
          },
          "IT-KR": {
            type: "Province",
            name: "Crotone"
          },
          "IT-SA": {
            type: "Province",
            name: "Salerno"
          },
          "IT-VR": {
            type: "Province",
            name: "Verona"
          },
          "IT-FR": {
            type: "Province",
            name: "Frosinone"
          },
          "IT-67": {
            type: "Region",
            name: "Molise"
          },
          "IT-65": {
            type: "Region",
            name: "Abruzzo"
          },
          "IT-FC": {
            type: "Province",
            name: "Forlì-Cesena"
          },
          "IT-62": {
            type: "Region",
            name: "Lazio"
          },
          "IT-FM": {
            type: "Province",
            name: "Fermo"
          },
          "IT-FI": {
            type: "Province",
            name: "Firenze"
          },
          "IT-NO": {
            type: "Province",
            name: "Novara"
          },
          "IT-NA": {
            type: "Province",
            name: "Napoli"
          },
          "IT-NU": {
            type: "Province",
            name: "Nuoro"
          },
          "IT-EN": {
            type: "Province",
            name: "Enna"
          },
          "IT-72": {
            type: "Region",
            name: "Campania"
          },
          "IT-75": {
            type: "Region",
            name: "Puglia"
          },
          "IT-77": {
            type: "Region",
            name: "Basilicata"
          },
          "IT-78": {
            type: "Region",
            name: "Calabria"
          },
          "IT-MB": {
            type: "Province",
            name: "Monza e Brianza"
          },
          "IT-MC": {
            type: "Province",
            name: "Macerata"
          },
          "IT-ME": {
            type: "Province",
            name: "Messina"
          },
          "IT-MI": {
            type: "Province",
            name: "Milano"
          },
          "IT-MN": {
            type: "Province",
            name: "Mantova"
          },
          "IT-MO": {
            type: "Province",
            name: "Modena"
          },
          "IT-MS": {
            type: "Province",
            name: "Massa-Carrara"
          },
          "IT-MT": {
            type: "Province",
            name: "Matera"
          },
          "IT-UD": {
            type: "Province",
            name: "Udine"
          },
          "IT-88": {
            type: "Region",
            name: "Sardegna"
          },
          "IT-SS": {
            type: "Province",
            name: "Sassari"
          },
          "IT-82": {
            type: "Region",
            name: "Sicilia"
          },
          "IT-PU": {
            type: "Province",
            name: "Pesaro e Urbino"
          },
          "IT-PT": {
            type: "Province",
            name: "Pistoia"
          },
          "IT-PV": {
            type: "Province",
            name: "Pavia"
          },
          "IT-PR": {
            type: "Province",
            name: "Parma"
          },
          "IT-PZ": {
            type: "Province",
            name: "Potenza"
          },
          "IT-PE": {
            type: "Province",
            name: "Pescara"
          },
          "IT-PD": {
            type: "Province",
            name: "Padova"
          },
          "IT-PG": {
            type: "Province",
            name: "Perugia"
          },
          "IT-PA": {
            type: "Province",
            name: "Palermo"
          },
          "IT-PC": {
            type: "Province",
            name: "Piacenza"
          },
          "IT-PO": {
            type: "Province",
            name: "Prato"
          },
          "IT-PN": {
            type: "Province",
            name: "Pordenone"
          },
          "IT-PI": {
            type: "Province",
            name: "Pisa"
          },
          "IT-GR": {
            type: "Province",
            name: "Grosseto"
          },
          "IT-VC": {
            type: "Province",
            name: "Vercelli"
          },
          "IT-VB": {
            type: "Province",
            name: "Verbano-Cusio-Ossola"
          },
          "IT-VA": {
            type: "Province",
            name: "Varese"
          },
          "IT-VI": {
            type: "Province",
            name: "Vicenza"
          },
          "IT-VV": {
            type: "Province",
            name: "Vibo Valentia"
          },
          "IT-VT": {
            type: "Province",
            name: "Viterbo"
          },
          "IT-VS": {
            type: "Province",
            name: "Medio Campidano"
          },
          "IT-GE": {
            type: "Province",
            name: "Genova"
          },
          "IT-GO": {
            type: "Province",
            name: "Gorizia"
          },
          "IT-OG": {
            type: "Province",
            name: "Ogliastra"
          },
          "IT-OR": {
            type: "Province",
            name: "Oristano"
          },
          "IT-OT": {
            type: "Province",
            name: "Olbia-Tempio"
          }
        }
      },
      VN: {
        name: "Viet Nam",
        sub: {
          "VN-28": {
            type: "Province",
            name: "Kon Tum"
          },
          "VN-29": {
            type: "Province",
            name: "Quảng Ngãi"
          },
          "VN-26": {
            type: "Province",
            name: "Thừa Thiên-Huế"
          },
          "VN-27": {
            type: "Province",
            name: "Quảng Nam"
          },
          "VN-24": {
            type: "Province",
            name: "Quảng Bình"
          },
          "VN-25": {
            type: "Province",
            name: "Quảng Trị"
          },
          "VN-22": {
            type: "Province",
            name: "Nghệ An"
          },
          "VN-23": {
            type: "Province",
            name: "Hà Tĩnh"
          },
          "VN-20": {
            type: "Province",
            name: "Thái Bình"
          },
          "VN-21": {
            type: "Province",
            name: "Thanh Hóa"
          },
          "VN-72": {
            type: "Province",
            name: "Đắk Nông"
          },
          "VN-DN": {
            type: "Municipality",
            name: "Da Nang"
          },
          "VN-SG": {
            type: "Municipality",
            name: "Ho Chi Minh"
          },
          "VN-70": {
            type: "Province",
            name: "Vĩnh Phúc"
          },
          "VN-67": {
            type: "Province",
            name: "Nam Ðịnh"
          },
          "VN-49": {
            type: "Province",
            name: "Vĩnh Long"
          },
          "VN-63": {
            type: "Province",
            name: "Hà Nam"
          },
          "VN-HN": {
            type: "Municipality",
            name: "Ha Noi"
          },
          "VN-71": {
            type: "Province",
            name: "Điện Biên"
          },
          "VN-35": {
            type: "Province",
            name: "Lâm Ðồng"
          },
          "VN-73": {
            type: "Province",
            name: "Hậu Giang"
          },
          "VN-61": {
            type: "Province",
            name: "Hải Dương"
          },
          "VN-59": {
            type: "Province",
            name: "Cà Mau"
          },
          "VN-58": {
            type: "Province",
            name: "Bình Phước"
          },
          "VN-57": {
            type: "Province",
            name: "Bình Dương"
          },
          "VN-56": {
            type: "Province",
            name: "Bắc Ninh"
          },
          "VN-55": {
            type: "Province",
            name: "Bạc Liêu"
          },
          "VN-54": {
            type: "Province",
            name: "Bắc Giang"
          },
          "VN-53": {
            type: "Province",
            name: "Bắc Kạn"
          },
          "VN-52": {
            type: "Province",
            name: "Sóc Trăng"
          },
          "VN-51": {
            type: "Province",
            name: "Trà Vinh"
          },
          "VN-50": {
            type: "Province",
            name: "Bến Tre"
          },
          "VN-18": {
            type: "Province",
            name: "Ninh Bình"
          },
          "VN-39": {
            type: "Province",
            name: "Ðồng Nai"
          },
          "VN-13": {
            type: "Province",
            name: "Quảng Ninh"
          },
          "VN-34": {
            type: "Province",
            name: "Khánh Hòa"
          },
          "VN-37": {
            type: "Province",
            name: "Tây Ninh"
          },
          "VN-36": {
            type: "Province",
            name: "Ninh Thuận"
          },
          "VN-31": {
            type: "Province",
            name: "Bình Định"
          },
          "VN-30": {
            type: "Province",
            name: "Gia Lai"
          },
          "VN-33": {
            type: "Province",
            name: "Đắk Lắk"
          },
          "VN-14": {
            type: "Province",
            name: "Hòa Bình"
          },
          "VN-CT": {
            type: "Municipality",
            name: "Can Tho"
          },
          "VN-32": {
            type: "Province",
            name: "Phú Yên"
          },
          "VN-69": {
            type: "Province",
            name: "Thái Nguyên"
          },
          "VN-HP": {
            type: "Municipality",
            name: "Hai Phong"
          },
          "VN-45": {
            type: "Province",
            name: "Ðồng Tháp"
          },
          "VN-04": {
            type: "Province",
            name: "Cao Bằng"
          },
          "VN-05": {
            type: "Province",
            name: "Sơn La"
          },
          "VN-06": {
            type: "Province",
            name: "Yên Bái"
          },
          "VN-07": {
            type: "Province",
            name: "Tuyên Quang"
          },
          "VN-66": {
            type: "Province",
            name: "Hưng Yên"
          },
          "VN-01": {
            type: "Province",
            name: "Lai Châu"
          },
          "VN-02": {
            type: "Province",
            name: "Lào Cai"
          },
          "VN-03": {
            type: "Province",
            name: "Hà Giang"
          },
          "VN-40": {
            type: "Province",
            name: "Bình Thuận"
          },
          "VN-41": {
            type: "Province",
            name: "Long An"
          },
          "VN-68": {
            type: "Province",
            name: "Phú Thọ"
          },
          "VN-43": {
            type: "Province",
            name: "Bà Rịa - Vũng Tàu"
          },
          "VN-44": {
            type: "Province",
            name: "An Giang"
          },
          "VN-09": {
            type: "Province",
            name: "Lạng Sơn"
          },
          "VN-46": {
            type: "Province",
            name: "Tiền Giang"
          },
          "VN-47": {
            type: "Province",
            name: "Kiến Giang"
          }
        }
      },
      SB: {
        name: "Solomon Islands",
        sub: {
          "SB-TE": {
            type: "Province",
            name: "Temotu"
          },
          "SB-IS": {
            type: "Province",
            name: "Isabel"
          },
          "SB-ML": {
            type: "Province",
            name: "Malaita"
          },
          "SB-MK": {
            type: "Province",
            name: "Makira-Ulawa"
          },
          "SB-CH": {
            type: "Province",
            name: "Choiseul"
          },
          "SB-GU": {
            type: "Province",
            name: "Guadalcanal"
          },
          "SB-CT": {
            type: "Capital territory",
            name: "Capital Territory"
          },
          "SB-RB": {
            type: "Province",
            name: "Rennell and Bellona"
          },
          "SB-WE": {
            type: "Province",
            name: "Western"
          },
          "SB-CE": {
            type: "Province",
            name: "Central"
          }
        }
      },
      ET: {
        name: "Ethiopia",
        sub: {
          "ET-BE": {
            type: "State",
            name: "Benshangul-Gumaz"
          },
          "ET-OR": {
            type: "State",
            name: "Oromia"
          },
          "ET-TI": {
            type: "State",
            name: "Tigrai"
          },
          "ET-HA": {
            type: "State",
            name: "Harari People"
          },
          "ET-DD": {
            type: "Administration",
            name: "Dire Dawa"
          },
          "ET-AM": {
            type: "State",
            name: "Amara"
          },
          "ET-AA": {
            type: "Administration",
            name: "Addis Ababa"
          },
          "ET-AF": {
            type: "State",
            name: "Afar"
          },
          "ET-GA": {
            type: "State",
            name: "Gambēla Hizboch"
          },
          "ET-SN": {
            type: "State",
            name: "Southern Nations, Nationalities and Peoples"
          },
          "ET-SO": {
            type: "State",
            name: "Somali"
          }
        }
      },
      SO: {
        name: "Somalia",
        sub: {
          "SO-SO": {
            type: "Region",
            name: "Sool"
          },
          "SO-BK": {
            type: "Region",
            name: "Bakool"
          },
          "SO-WO": {
            type: "Region",
            name: "Woqooyi Galbeed"
          },
          "SO-BN": {
            type: "Region",
            name: "Banaadir"
          },
          "SO-TO": {
            type: "Region",
            name: "Togdheer"
          },
          "SO-MU": {
            type: "Region",
            name: "Mudug"
          },
          "SO-BY": {
            type: "Region",
            name: "Bay"
          },
          "SO-HI": {
            type: "Region",
            name: "Hiiraan"
          },
          "SO-JD": {
            type: "Region",
            name: "Jubbada Dhexe"
          },
          "SO-SA": {
            type: "Region",
            name: "Sanaag"
          },
          "SO-AW": {
            type: "Region",
            name: "Awdal"
          },
          "SO-SH": {
            type: "Region",
            name: "Shabeellaha Hoose"
          },
          "SO-JH": {
            type: "Region",
            name: "Jubbada Hoose"
          },
          "SO-NU": {
            type: "Region",
            name: "Nugaal"
          },
          "SO-GA": {
            type: "Region",
            name: "Galguduud"
          },
          "SO-SD": {
            type: "Region",
            name: "Shabeellaha Dhexe"
          },
          "SO-BR": {
            type: "Region",
            name: "Bari"
          },
          "SO-GE": {
            type: "Region",
            name: "Gedo"
          }
        }
      },
      ZW: {
        name: "Zimbabwe",
        sub: {
          "ZW-BU": {
            type: "Province",
            name: "Bulawayo"
          },
          "ZW-HA": {
            type: "Province",
            name: "Harare"
          },
          "ZW-MS": {
            type: "Province",
            name: "Matabeleland South"
          },
          "ZW-MV": {
            type: "Province",
            name: "Masvingo"
          },
          "ZW-MW": {
            type: "Province",
            name: "Mashonaland West"
          },
          "ZW-MI": {
            type: "Province",
            name: "Midlands"
          },
          "ZW-MN": {
            type: "Province",
            name: "Matabeleland North"
          },
          "ZW-MA": {
            type: "Province",
            name: "Manicaland"
          },
          "ZW-MC": {
            type: "Province",
            name: "Mashonaland Central"
          },
          "ZW-ME": {
            type: "Province",
            name: "Mashonaland East"
          }
        }
      },
      SA: {
        name: "Saudi Arabia",
        sub: {
          "SA-08": {
            type: "Province",
            name: "Al Ḩudūd ash Shamālīyah"
          },
          "SA-09": {
            type: "Province",
            name: "Jāzān"
          },
          "SA-14": {
            type: "Province",
            name: "'Asīr"
          },
          "SA-01": {
            type: "Province",
            name: "Ar Riyāḑ"
          },
          "SA-02": {
            type: "Province",
            name: "Makkah al Mukarramah"
          },
          "SA-03": {
            type: "Province",
            name: "Al Madīnah al Munawwarah"
          },
          "SA-04": {
            type: "Province",
            name: "Ash Sharqīyah"
          },
          "SA-05": {
            type: "Province",
            name: "Al Qaşīm"
          },
          "SA-06": {
            type: "Province",
            name: "Ḩā'il"
          },
          "SA-07": {
            type: "Province",
            name: "Tabūk"
          },
          "SA-11": {
            type: "Province",
            name: "Al Bāḩah"
          },
          "SA-10": {
            type: "Province",
            name: "Najrān"
          },
          "SA-12": {
            type: "Province",
            name: "Al Jawf"
          }
        }
      },
      ES: {
        name: "Spain",
        sub: {
          "ES-NA": {
            type: "Province",
            name: "Nafarroa*"
          },
          "ES-PM": {
            type: "Province",
            name: "Balears"
          },
          "ES-TF": {
            type: "Province",
            name: "Santa Cruz de Tenerife"
          },
          "ES-B": {
            type: "Province",
            name: "Barcelona"
          },
          "ES-A": {
            type: "Province",
            name: "Alacant*"
          },
          "ES-ZA": {
            type: "Province",
            name: "Zamora"
          },
          "ES-MU": {
            type: "Province",
            name: "Murcia"
          },
          "ES-VI": {
            type: "Province",
            name: "Álava"
          },
          "ES-Z": {
            type: "Province",
            name: "Zaragoza"
          },
          "ES-O": {
            type: "Province",
            name: "Asturias"
          },
          "ES-IB": {
            type: "Autonomous community",
            name: "Illes Balears"
          },
          "ES-M": {
            type: "Province",
            name: "Madrid"
          },
          "ES-L": {
            type: "Province",
            name: "Lleida"
          },
          "ES-J": {
            type: "Province",
            name: "Jaén"
          },
          "ES-H": {
            type: "Province",
            name: "Huelva"
          },
          "ES-AS": {
            type: "Autonomous community",
            name: "Asturias, Principado de"
          },
          "ES-AR": {
            type: "Autonomous community",
            name: "Aragón"
          },
          "ES-C": {
            type: "Province",
            name: "A Coruña"
          },
          "ES-AV": {
            type: "Province",
            name: "Ávila"
          },
          "ES-CS": {
            type: "Province",
            name: "Castelló*"
          },
          "ES-CR": {
            type: "Province",
            name: "Ciudad Real"
          },
          "ES-CM": {
            type: "Autonomous community",
            name: "Castilla-La Mancha"
          },
          "ES-CL": {
            type: "Autonomous community",
            name: "Castilla y León"
          },
          "ES-CO": {
            type: "Province",
            name: "Córdoba"
          },
          "ES-CN": {
            type: "Autonomous community",
            name: "Canarias"
          },
          "ES-MC": {
            type: "Autonomous community",
            name: "Murcia, Región de"
          },
          "ES-AN": {
            type: "Autonomous community",
            name: "Andalucía"
          },
          "ES-MA": {
            type: "Province",
            name: "Málaga"
          },
          "ES-AL": {
            type: "Province",
            name: "Almería"
          },
          "ES-CE": {
            type: "Autonomous city in north africa",
            name: "Ceuta"
          },
          "ES-AB": {
            type: "Province",
            name: "Albacete"
          },
          "ES-T": {
            type: "Province",
            name: "Tarragona"
          },
          "ES-ML": {
            type: "Autonomous city in north africa",
            name: "Melilla"
          },
          "ES-CA": {
            type: "Province",
            name: "Cádiz"
          },
          "ES-TE": {
            type: "Province",
            name: "Teruel"
          },
          "ES-CC": {
            type: "Province",
            name: "Cáceres"
          },
          "ES-CB": {
            type: "Autonomous community",
            name: "Cantabria"
          },
          "ES-GA": {
            type: "Autonomous community",
            name: "Galicia"
          },
          "ES-PO": {
            type: "Province",
            name: "Pontevedra"
          },
          "ES-GC": {
            type: "Province",
            name: "Las Palmas"
          },
          "ES-RI": {
            type: "Autonomous community",
            name: "La Rioja"
          },
          "ES-S": {
            type: "Province",
            name: "Cantabria"
          },
          "ES-GI": {
            type: "Province",
            name: "Girona"
          },
          "ES-TO": {
            type: "Province",
            name: "Toledo"
          },
          "ES-CT": {
            type: "Autonomous community",
            name: "Catalunya"
          },
          "ES-GR": {
            type: "Province",
            name: "Granada"
          },
          "ES-GU": {
            type: "Province",
            name: "Guadalajara"
          },
          "ES-MD": {
            type: "Autonomous community",
            name: "Madrid, Comunidad de"
          },
          "ES-EX": {
            type: "Autonomous community",
            name: "Extremadura"
          },
          "ES-PV": {
            type: "Autonomous community",
            name: "Euskal Herria"
          },
          "ES-V": {
            type: "Province",
            name: "Valencia"
          },
          "ES-VC": {
            type: "Autonomous community",
            name: "Valenciana, Comunidad"
          },
          "ES-SO": {
            type: "Province",
            name: "Soria"
          },
          "ES-OR": {
            type: "Province",
            name: "Ourense"
          },
          "ES-LU": {
            type: "Province",
            name: "Lugo"
          },
          "ES-SE": {
            type: "Province",
            name: "Sevilla"
          },
          "ES-NC": {
            type: "Autonomous community",
            name: "Nafarroako Foru Komunitatea*"
          },
          "ES-SG": {
            type: "Province",
            name: "Segovia"
          },
          "ES-BU": {
            type: "Province",
            name: "Burgos"
          },
          "ES-SA": {
            type: "Province",
            name: "Salamanca"
          },
          "ES-VA": {
            type: "Province",
            name: "Valladolid"
          },
          "ES-CU": {
            type: "Province",
            name: "Cuenca"
          },
          "ES-P": {
            type: "Province",
            name: "Palencia"
          },
          "ES-LE": {
            type: "Province",
            name: "León"
          },
          "ES-BI": {
            type: "Province",
            name: "Bizkaia*"
          },
          "ES-HU": {
            type: "Province",
            name: "Huesca"
          },
          "ES-LO": {
            type: "Province",
            name: "La Rioja"
          },
          "ES-SS": {
            type: "Province",
            name: "Gipuzkoa*"
          },
          "ES-BA": {
            type: "Province",
            name: "Badajoz"
          }
        }
      },
      ER: {
        name: "Eritrea",
        sub: {
          "ER-AN": {
            type: "Region",
            name: "Ansabā"
          },
          "ER-MA": {
            type: "Region",
            name: "Al Awsaţ"
          },
          "ER-DU": {
            type: "Region",
            name: "Al Janūbī"
          },
          "ER-DK": {
            type: "Region",
            name: "Debubawi K’eyyĭḥ Baḥri"
          },
          "ER-SK": {
            type: "Region",
            name: "Semienawi K’eyyĭḥ Baḥri"
          },
          "ER-GB": {
            type: "Region",
            name: "Gash-Barka"
          }
        }
      },
      ME: {
        name: "Montenegro",
        sub: {
          "ME-06": {
            type: "Municipality",
            name: "Cetinje"
          },
          "ME-07": {
            type: "Municipality",
            name: "Danilovgrad"
          },
          "ME-04": {
            type: "Municipality",
            name: "Bijelo Polje"
          },
          "ME-05": {
            type: "Municipality",
            name: "Budva"
          },
          "ME-02": {
            type: "Municipality",
            name: "Bar"
          },
          "ME-03": {
            type: "Municipality",
            name: "Berane"
          },
          "ME-01": {
            type: "Municipality",
            name: "Andrijevica"
          },
          "ME-20": {
            type: "Municipality",
            name: "Ulcinj"
          },
          "ME-21": {
            type: "Municipality",
            name: "Žabljak"
          },
          "ME-08": {
            type: "Municipality",
            name: "Herceg-Novi"
          },
          "ME-09": {
            type: "Municipality",
            name: "Kolašin"
          },
          "ME-15": {
            type: "Municipality",
            name: "Plužine"
          },
          "ME-14": {
            type: "Municipality",
            name: "Pljevlja"
          },
          "ME-17": {
            type: "Municipality",
            name: "Rožaje"
          },
          "ME-16": {
            type: "Municipality",
            name: "Podgorica"
          },
          "ME-11": {
            type: "Municipality",
            name: "Mojkovac"
          },
          "ME-10": {
            type: "Municipality",
            name: "Kotor"
          },
          "ME-13": {
            type: "Municipality",
            name: "Plav"
          },
          "ME-12": {
            type: "Municipality",
            name: "Nikšić"
          },
          "ME-19": {
            type: "Municipality",
            name: "Tivat"
          },
          "ME-18": {
            type: "Municipality",
            name: "Šavnik"
          },
          "ME-22": {
            type: "Municipality",
            name: "Gusinje"
          },
          "ME-23": {
            type: "Municipality",
            name: "Petnjica"
          }
        }
      },
      MD: {
        name: "Moldova",
        sub: {
          "MD-LE": {
            type: "District",
            name: "Leova"
          },
          "MD-SO": {
            type: "District",
            name: "Soroca"
          },
          "MD-TE": {
            type: "District",
            name: "Teleneşti"
          },
          "MD-IA": {
            type: "District",
            name: "Ialoveni"
          },
          "MD-TA": {
            type: "District",
            name: "Taraclia"
          },
          "MD-OC": {
            type: "District",
            name: "Ocniţa"
          },
          "MD-RI": {
            type: "District",
            name: "Rîşcani"
          },
          "MD-ED": {
            type: "District",
            name: "Edineţ"
          },
          "MD-RE": {
            type: "District",
            name: "Rezina"
          },
          "MD-OR": {
            type: "District",
            name: "Orhei"
          },
          "MD-CL": {
            type: "District",
            name: "Călăraşi"
          },
          "MD-CM": {
            type: "District",
            name: "Cimişlia"
          },
          "MD-GL": {
            type: "District",
            name: "Glodeni"
          },
          "MD-CA": {
            type: "District",
            name: "Cahul"
          },
          "MD-AN": {
            type: "District",
            name: "Anenii Noi"
          },
          "MD-UN": {
            type: "District",
            name: "Ungheni"
          },
          "MD-CR": {
            type: "District",
            name: "Criuleni"
          },
          "MD-CS": {
            type: "District",
            name: "Căuşeni"
          },
          "MD-CT": {
            type: "District",
            name: "Cantemir"
          },
          "MD-CU": {
            type: "City",
            name: "Chişinău"
          },
          "MD-HI": {
            type: "District",
            name: "Hînceşti"
          },
          "MD-DU": {
            type: "District",
            name: "Dubăsari"
          },
          "MD-NI": {
            type: "District",
            name: "Nisporeni"
          },
          "MD-ST": {
            type: "District",
            name: "Străşeni"
          },
          "MD-SV": {
            type: "District",
            name: "Ştefan Vodă"
          },
          "MD-DR": {
            type: "District",
            name: "Drochia"
          },
          "MD-SI": {
            type: "District",
            name: "Sîngerei"
          },
          "MD-DO": {
            type: "District",
            name: "Donduşeni"
          },
          "MD-SN": {
            type: "Territorial unit",
            name: "Stînga Nistrului, unitatea teritorială din"
          },
          "MD-FL": {
            type: "District",
            name: "Floreşti"
          },
          "MD-FA": {
            type: "District",
            name: "Făleşti"
          },
          "MD-SD": {
            type: "District",
            name: "Şoldăneşti"
          },
          "MD-GA": {
            type: "Autonomous territorial unit",
            name: "Găgăuzia, Unitatea teritorială autonomă"
          },
          "MD-BD": {
            type: "City",
            name: "Bender"
          },
          "MD-BA": {
            type: "City",
            name: "Bălţi"
          },
          "MD-BS": {
            type: "District",
            name: "Basarabeasca"
          },
          "MD-BR": {
            type: "District",
            name: "Briceni"
          }
        }
      },
      MG: {
        name: "Madagascar",
        sub: {
          "MG-A": {
            type: "Province",
            name: "Toamasina"
          },
          "MG-F": {
            type: "Province",
            name: "Fianarantsoa"
          },
          "MG-D": {
            type: "Province",
            name: "Antsiranana"
          },
          "MG-M": {
            type: "Province",
            name: "Mahajanga"
          },
          "MG-U": {
            type: "Province",
            name: "Toliara"
          },
          "MG-T": {
            type: "Province",
            name: "Antananarivo"
          }
        }
      },
      MF: {
        name: "St. Martin",
        sub: {
          "MF-MF": {
            type: "Country",
            name: "St. Martin"
          }
        }
      },
      MA: {
        name: "Morocco",
        sub: {
          "MA-RAB": {
            type: "Prefecture",
            name: "Rabat"
          },
          "MA-MMD": {
            type: "Prefecture",
            name: "Marrakech-Medina"
          },
          "MA-AOU": {
            type: "Prefecture",
            name: "Aousserd"
          },
          "MA-BEM": {
            type: "Province",
            name: "Beni Mellal"
          },
          "MA-CAS": {
            type: "Prefecture",
            name: "Casablanca"
          },
          "MA-TIZ": {
            type: "Province",
            name: "Tiznit"
          },
          "MA-SAF": {
            type: "Province",
            name: "Safi"
          },
          "MA-FIG": {
            type: "Province",
            name: "Figuig"
          },
          "MA-SAL": {
            type: "Prefecture",
            name: "Salé"
          },
          "MA-JDI": {
            type: "Province",
            name: "El Jadida"
          },
          "MA-LAR": {
            type: "Province",
            name: "Larache"
          },
          "MA-SKH": {
            type: "Prefecture",
            name: "Skhirate-Témara"
          },
          "MA-KHE": {
            type: "Province",
            name: "Khemisset"
          },
          "MA-LAA": {
            type: "Province",
            name: "Laâyoune"
          },
          "MA-GUE": {
            type: "Province",
            name: "Guelmim"
          },
          "MA-HAO": {
            type: "Province",
            name: "Al Haouz"
          },
          "MA-KHN": {
            type: "Province",
            name: "Khenifra"
          },
          "MA-KHO": {
            type: "Province",
            name: "Khouribga"
          },
          "MA-TAR": {
            type: "Province",
            name: "Taroudant"
          },
          "MA-ESM": {
            type: "Province",
            name: "Es Smara"
          },
          "MA-BER": {
            type: "Province",
            name: "Berkane"
          },
          "MA-BES": {
            type: "Province",
            name: "Ben Slimane"
          },
          "MA-ESI": {
            type: "Province",
            name: "Essaouira"
          },
          "MA-SET": {
            type: "Province",
            name: "Settat"
          },
          "MA-CHT": {
            type: "Province",
            name: "Chtouka-Ait Baha"
          },
          "MA-AZI": {
            type: "Province",
            name: "Azilal"
          },
          "MA-NOU": {
            type: "Province",
            name: "Nouaceur"
          },
          "MA-MOH": {
            type: "Prefecture",
            name: "Mohammadia"
          },
          "MA-MOU": {
            type: "Province",
            name: "Moulay Yacoub"
          },
          "MA-KEN": {
            type: "Province",
            name: "Kénitra"
          },
          "MA-11": {
            type: "Region",
            name: "Marrakech-Tensift-Al Haouz"
          },
          "MA-10": {
            type: "Region",
            name: "Doukkala-Abda"
          },
          "MA-13": {
            type: "Region",
            name: "Sous-Massa-Draa"
          },
          "MA-12": {
            type: "Region",
            name: "Tadla-Azilal"
          },
          "MA-15": {
            type: "Region",
            name: "Laâyoune-Boujdour-Sakia el Hamra"
          },
          "MA-14": {
            type: "Region",
            name: "Guelmim-Es Semara"
          },
          "MA-HAJ": {
            type: "Province",
            name: "El Hajeb"
          },
          "MA-16": {
            type: "Region",
            name: "Oued ed Dahab-Lagouira"
          },
          "MA-BOD": {
            type: "Province",
            name: "Boujdour"
          },
          "MA-SYB": {
            type: "Prefecture",
            name: "Sidi Youssef Ben Ali"
          },
          "MA-ZAG": {
            type: "Province",
            name: "Zagora"
          },
          "MA-CHE": {
            type: "Province",
            name: "Chefchaouen"
          },
          "MA-KES": {
            type: "Province",
            name: "Kelaat es Sraghna"
          },
          "MA-TAI": {
            type: "Province",
            name: "Taourirt"
          },
          "MA-JRA": {
            type: "Province",
            name: "Jrada"
          },
          "MA-MED": {
            type: "Province",
            name: "Médiouna"
          },
          "MA-TAO": {
            type: "Province",
            name: "Taounate"
          },
          "MA-TET": {
            type: "Prefecture",
            name: "Tétouan"
          },
          "MA-ASZ": {
            type: "Province",
            name: "Assa-Zag"
          },
          "MA-TNG": {
            type: "Prefecture",
            name: "Tanger-Assilah"
          },
          "MA-SEF": {
            type: "Province",
            name: "Sefrou"
          },
          "MA-MMN": {
            type: "Prefecture",
            name: "Marrakech-Menara"
          },
          "MA-TAZ": {
            type: "Province",
            name: "Taza"
          },
          "MA-INE": {
            type: "Prefecture",
            name: "Inezgane-Ait Melloul"
          },
          "MA-NAD": {
            type: "Province",
            name: "Nador"
          },
          "MA-CHI": {
            type: "Province",
            name: "Chichaoua"
          },
          "MA-ERR": {
            type: "Province",
            name: "Errachidia"
          },
          "MA-TNT": {
            type: "Province",
            name: "Tan-Tan"
          },
          "MA-TAT": {
            type: "Province",
            name: "Tata"
          },
          "MA-IFR": {
            type: "Province",
            name: "Ifrane"
          },
          "MA-09": {
            type: "Region",
            name: "Chaouia-Ouardigha"
          },
          "MA-SIK": {
            type: "Province",
            name: "Sidi Kacem"
          },
          "MA-OUJ": {
            type: "Prefecture",
            name: "Oujda-Angad"
          },
          "MA-FES": {
            type: "Prefecture",
            name: "Fès-Dar-Dbibegh"
          },
          "MA-OUD": {
            type: "Province",
            name: "Oued ed Dahab"
          },
          "MA-MEK": {
            type: "Prefecture",
            name: "Meknès"
          },
          "MA-OUA": {
            type: "Province",
            name: "Ouarzazate"
          },
          "MA-02": {
            type: "Region",
            name: "Gharb-Chrarda-Beni Hssen"
          },
          "MA-03": {
            type: "Region",
            name: "Taza-Al Hoceima-Taounate"
          },
          "MA-01": {
            type: "Region",
            name: "Tanger-Tétouan"
          },
          "MA-06": {
            type: "Region",
            name: "Meknès-Tafilalet"
          },
          "MA-07": {
            type: "Region",
            name: "Rabat-Salé-Zemmour-Zaer"
          },
          "MA-04": {
            type: "Region",
            name: "L'Oriental"
          },
          "MA-05": {
            type: "Region",
            name: "Fès-Boulemane"
          },
          "MA-08": {
            type: "Region",
            name: "Grand Casablanca"
          },
          "MA-AGD": {
            type: "Prefecture",
            name: "Agadir-Ida-Outanane"
          },
          "MA-BOM": {
            type: "Province",
            name: "Boulemane"
          },
          "MA-FAH": {
            type: "Prefecture",
            name: "Fahs-Beni Makada"
          },
          "MA-HOC": {
            type: "Province",
            name: "Al Hoceïma"
          }
        }
      },
      MC: {
        name: "Monaco",
        sub: {
          "MC-MO": {
            type: "Quarter",
            name: "Monaco-Ville"
          },
          "MC-VR": {
            type: "Quarter",
            name: "Vallon de la Rousse"
          },
          "MC-FO": {
            type: "Quarter",
            name: "Fontvieille"
          },
          "MC-SD": {
            type: "Quarter",
            name: "Sainte-Dévote"
          },
          "MC-GA": {
            type: "Quarter",
            name: "La Gare"
          },
          "MC-CL": {
            type: "Quarter",
            name: "La Colle"
          },
          "MC-CO": {
            type: "Quarter",
            name: "La Condamine"
          },
          "MC-SR": {
            type: "Quarter",
            name: "Saint-Roman"
          },
          "MC-MC": {
            type: "Quarter",
            name: "Monte-Carlo"
          },
          "MC-MA": {
            type: "Quarter",
            name: "Malbousquet"
          },
          "MC-MG": {
            type: "Quarter",
            name: "Moneghetti"
          },
          "MC-PH": {
            type: "Quarter",
            name: "Port-Hercule"
          },
          "MC-LA": {
            type: "Quarter",
            name: "Larvotto"
          },
          "MC-MU": {
            type: "Quarter",
            name: "Moulins"
          },
          "MC-JE": {
            type: "Quarter",
            name: "Jardin Exotique"
          },
          "MC-SO": {
            type: "Quarter",
            name: "La Source"
          },
          "MC-SP": {
            type: "Quarter",
            name: "Spélugues"
          }
        }
      },
      UZ: {
        name: "Uzbekistan",
        sub: {
          "UZ-QA": {
            type: "Region",
            name: "Qashqadaryo"
          },
          "UZ-FA": {
            type: "Region",
            name: "Farg‘ona"
          },
          "UZ-JI": {
            type: "Region",
            name: "Jizzax"
          },
          "UZ-TO": {
            type: "Region",
            name: "Toshkent"
          },
          "UZ-NW": {
            type: "Region",
            name: "Navoiy"
          },
          "UZ-SU": {
            type: "Region",
            name: "Surxondaryo"
          },
          "UZ-XO": {
            type: "Region",
            name: "Xorazm"
          },
          "UZ-QR": {
            type: "Republic",
            name: "Qoraqalpog‘iston Respublikasi"
          },
          "UZ-SI": {
            type: "Region",
            name: "Sirdaryo"
          },
          "UZ-TK": {
            type: "City",
            name: "Toshkent"
          },
          "UZ-NG": {
            type: "Region",
            name: "Namangan"
          },
          "UZ-SA": {
            type: "Region",
            name: "Samarqand"
          },
          "UZ-BU": {
            type: "Region",
            name: "Buxoro"
          },
          "UZ-AN": {
            type: "Region",
            name: "Andijon"
          }
        }
      },
      MM: {
        name: "Myanmar",
        sub: {
          "MM-16": {
            type: "State",
            name: "Rakhine"
          },
          "MM-18": {
            type: "Union territory",
            name: "Nay Pyi Taw"
          },
          "MM-06": {
            type: "Region",
            name: "Yangon"
          },
          "MM-07": {
            type: "Region",
            name: "Ayeyawady"
          },
          "MM-04": {
            type: "Region",
            name: "Mandalay"
          },
          "MM-05": {
            type: "Region",
            name: "Taninthayi"
          },
          "MM-02": {
            type: "Region",
            name: "Bago"
          },
          "MM-03": {
            type: "Region",
            name: "Magway"
          },
          "MM-13": {
            type: "State",
            name: "Kayin"
          },
          "MM-01": {
            type: "Region",
            name: "Sagaing"
          },
          "MM-15": {
            type: "State",
            name: "Mon"
          },
          "MM-14": {
            type: "State",
            name: "Chin"
          },
          "MM-11": {
            type: "State",
            name: "Kachin"
          },
          "MM-12": {
            type: "State",
            name: "Kayah"
          },
          "MM-17": {
            type: "State",
            name: "Shan"
          }
        }
      },
      ML: {
        name: "Mali",
        sub: {
          "ML-BKO": {
            type: "District",
            name: "Bamako"
          },
          "ML-1": {
            type: "Region",
            name: "Kayes"
          },
          "ML-2": {
            type: "Region",
            name: "Koulikoro"
          },
          "ML-3": {
            type: "Region",
            name: "Sikasso"
          },
          "ML-4": {
            type: "Region",
            name: "Ségou"
          },
          "ML-5": {
            type: "Region",
            name: "Mopti"
          },
          "ML-6": {
            type: "Region",
            name: "Tombouctou"
          },
          "ML-7": {
            type: "Region",
            name: "Gao"
          },
          "ML-8": {
            type: "Region",
            name: "Kidal"
          }
        }
      },
      MO: {
        name: "Macau",
        sub: {
          "MO-MO": {
            type: "Country",
            name: "Macau"
          }
        }
      },
      MN: {
        name: "Mongolia",
        sub: {
          "MN-073": {
            type: "Province",
            name: "Arhangay"
          },
          "MN-047": {
            type: "Province",
            name: "Töv"
          },
          "MN-039": {
            type: "Province",
            name: "Hentiy"
          },
          "MN-071": {
            type: "Province",
            name: "Bayan-Ölgiy"
          },
          "MN-059": {
            type: "Province",
            name: "Dundgovĭ"
          },
          "MN-057": {
            type: "Province",
            name: "Dzavhan"
          },
          "MN-055": {
            type: "Province",
            name: "Övörhangay"
          },
          "MN-053": {
            type: "Province",
            name: "Ömnögovĭ"
          },
          "MN-051": {
            type: "Province",
            name: "Sühbaatar"
          },
          "MN-035": {
            type: "Province",
            name: "Orhon"
          },
          "MN-037": {
            type: "Province",
            name: "Darhan uul"
          },
          "MN-1": {
            type: "Capital city",
            name: "Ulaanbaatar"
          },
          "MN-067": {
            type: "Province",
            name: "Bulgan"
          },
          "MN-064": {
            type: "Province",
            name: "Govĭ-Sümber"
          },
          "MN-065": {
            type: "Province",
            name: "Govĭ-Altay"
          },
          "MN-063": {
            type: "Province",
            name: "Dornogovĭ"
          },
          "MN-046": {
            type: "Province",
            name: "Uvs"
          },
          "MN-061": {
            type: "Province",
            name: "Dornod"
          },
          "MN-049": {
            type: "Province",
            name: "Selenge"
          },
          "MN-069": {
            type: "Province",
            name: "Bayanhongor"
          },
          "MN-041": {
            type: "Province",
            name: "Hövsgöl"
          },
          "MN-043": {
            type: "Province",
            name: "Hovd"
          }
        }
      },
      MH: {
        name: "Marshall Islands",
        sub: {
          "MH-RON": {
            type: "Municipality",
            name: "Rongelap"
          },
          "MH-KWA": {
            type: "Municipality",
            name: "Kwajalein"
          },
          "MH-MAL": {
            type: "Municipality",
            name: "Maloelap"
          },
          "MH-WTH": {
            type: "Municipality",
            name: "Wotho"
          },
          "MH-WTJ": {
            type: "Municipality",
            name: "Wotje"
          },
          "MH-L": {
            type: "Chain",
            name: "Ralik chain"
          },
          "MH-ENI": {
            type: "Municipality",
            name: "Enewetak and Ujelang"
          },
          "MH-UTI": {
            type: "Municipality",
            name: "Utrik"
          },
          "MH-T": {
            type: "Chain",
            name: "Ratak chain"
          },
          "MH-AUR": {
            type: "Municipality",
            name: "Aur"
          },
          "MH-JAL": {
            type: "Municipality",
            name: "Jaluit"
          },
          "MH-UJA": {
            type: "Municipality",
            name: "Ujae"
          },
          "MH-JAB": {
            type: "Municipality",
            name: "Jabat"
          },
          "MH-ALK": {
            type: "Municipality",
            name: "Ailuk"
          },
          "MH-NMU": {
            type: "Municipality",
            name: "Namu"
          },
          "MH-ALL": {
            type: "Municipality",
            name: "Ailinglaplap"
          },
          "MH-MEJ": {
            type: "Municipality",
            name: "Mejit"
          },
          "MH-LAE": {
            type: "Municipality",
            name: "Lae"
          },
          "MH-EBO": {
            type: "Municipality",
            name: "Ebon"
          },
          "MH-KIL": {
            type: "Municipality",
            name: "Bikini and Kili"
          },
          "MH-NMK": {
            type: "Municipality",
            name: "Namdrik"
          },
          "MH-ARN": {
            type: "Municipality",
            name: "Arno"
          },
          "MH-MAJ": {
            type: "Municipality",
            name: "Majuro"
          },
          "MH-MIL": {
            type: "Municipality",
            name: "Mili"
          },
          "MH-LIB": {
            type: "Municipality",
            name: "Lib"
          },
          "MH-LIK": {
            type: "Municipality",
            name: "Likiep"
          }
        }
      },
      MK: {
        name: "Macedonia, the Former Yugoslav Republic Of",
        sub: {
          "MK-36": {
            type: "Municipality",
            name: "Kavadarci"
          },
          "MK-45": {
            type: "Municipality",
            name: "Krivogaštani"
          },
          "MK-33": {
            type: "Municipality",
            name: "Zrnovci"
          },
          "MK-68": {
            type: "Municipality",
            name: "Saraj †"
          },
          "MK-31": {
            type: "Municipality",
            name: "Zajas"
          },
          "MK-65": {
            type: "Municipality",
            name: "Rankovce"
          },
          "MK-64": {
            type: "Municipality",
            name: "Radoviš"
          },
          "MK-30": {
            type: "Municipality",
            name: "Želino"
          },
          "MK-04": {
            type: "Municipality",
            name: "Bitola"
          },
          "MK-05": {
            type: "Municipality",
            name: "Bogdanci"
          },
          "MK-06": {
            type: "Municipality",
            name: "Bogovinje"
          },
          "MK-07": {
            type: "Municipality",
            name: "Bosilovo"
          },
          "MK-66": {
            type: "Municipality",
            name: "Resen"
          },
          "MK-01": {
            type: "Municipality",
            name: "Aerodrom †"
          },
          "MK-02": {
            type: "Municipality",
            name: "Aračinovo"
          },
          "MK-03": {
            type: "Municipality",
            name: "Berovo"
          },
          "MK-40": {
            type: "Municipality",
            name: "Kičevo"
          },
          "MK-41": {
            type: "Municipality",
            name: "Konče"
          },
          "MK-42": {
            type: "Municipality",
            name: "Kočani"
          },
          "MK-32": {
            type: "Municipality",
            name: "Zelenikovo"
          },
          "MK-08": {
            type: "Municipality",
            name: "Brvenica"
          },
          "MK-09": {
            type: "Municipality",
            name: "Butel †"
          },
          "MK-46": {
            type: "Municipality",
            name: "Kruševo"
          },
          "MK-47": {
            type: "Municipality",
            name: "Kumanovo"
          },
          "MK-43": {
            type: "Municipality",
            name: "Kratovo"
          },
          "MK-69": {
            type: "Municipality",
            name: "Sveti Nikole"
          },
          "MK-28": {
            type: "Municipality",
            name: "Drugovo"
          },
          "MK-29": {
            type: "Municipality",
            name: "Gjorče Petrov †"
          },
          "MK-26": {
            type: "Municipality",
            name: "Dojran"
          },
          "MK-27": {
            type: "Municipality",
            name: "Dolneni"
          },
          "MK-24": {
            type: "Municipality",
            name: "Demir Kapija"
          },
          "MK-25": {
            type: "Municipality",
            name: "Demir Hisar"
          },
          "MK-22": {
            type: "Municipality",
            name: "Debarca"
          },
          "MK-23": {
            type: "Municipality",
            name: "Delčevo"
          },
          "MK-20": {
            type: "Municipality",
            name: "Gradsko"
          },
          "MK-21": {
            type: "Municipality",
            name: "Debar"
          },
          "MK-62": {
            type: "Municipality",
            name: "Prilep"
          },
          "MK-74": {
            type: "Municipality",
            name: "Studeničani"
          },
          "MK-80": {
            type: "Municipality",
            name: "Čaška"
          },
          "MK-81": {
            type: "Municipality",
            name: "Češinovo-Obleševo"
          },
          "MK-82": {
            type: "Municipality",
            name: "Čučer Sandevo"
          },
          "MK-83": {
            type: "Municipality",
            name: "Štip"
          },
          "MK-78": {
            type: "Municipality",
            name: "Centar Župa"
          },
          "MK-71": {
            type: "Municipality",
            name: "Staro Nagoričane"
          },
          "MK-70": {
            type: "Municipality",
            name: "Sopište"
          },
          "MK-60": {
            type: "Municipality",
            name: "Pehčevo"
          },
          "MK-72": {
            type: "Municipality",
            name: "Struga"
          },
          "MK-75": {
            type: "Municipality",
            name: "Tearce"
          },
          "MK-73": {
            type: "Municipality",
            name: "Strumica"
          },
          "MK-59": {
            type: "Municipality",
            name: "Petrovec"
          },
          "MK-58": {
            type: "Municipality",
            name: "Ohrid"
          },
          "MK-57": {
            type: "Municipality",
            name: "Oslomej"
          },
          "MK-56": {
            type: "Municipality",
            name: "Novo Selo"
          },
          "MK-55": {
            type: "Municipality",
            name: "Novaci"
          },
          "MK-54": {
            type: "Municipality",
            name: "Negotino"
          },
          "MK-53": {
            type: "Municipality",
            name: "Mogila"
          },
          "MK-52": {
            type: "Municipality",
            name: "Makedonski Brod"
          },
          "MK-51": {
            type: "Municipality",
            name: "Makedonska Kamenica"
          },
          "MK-50": {
            type: "Municipality",
            name: "Mavrovo i Rostuša"
          },
          "MK-48": {
            type: "Municipality",
            name: "Lipkovo"
          },
          "MK-19": {
            type: "Municipality",
            name: "Gostivar"
          },
          "MK-18": {
            type: "Municipality",
            name: "Gevgelija"
          },
          "MK-39": {
            type: "Municipality",
            name: "Kisela Voda †"
          },
          "MK-38": {
            type: "Municipality",
            name: "Karpoš †"
          },
          "MK-44": {
            type: "Municipality",
            name: "Kriva Palanka"
          },
          "MK-49": {
            type: "Municipality",
            name: "Lozovo"
          },
          "MK-13": {
            type: "Municipality",
            name: "Veles"
          },
          "MK-12": {
            type: "Municipality",
            name: "Vevčani"
          },
          "MK-11": {
            type: "Municipality",
            name: "Vasilevo"
          },
          "MK-10": {
            type: "Municipality",
            name: "Valandovo"
          },
          "MK-17": {
            type: "Municipality",
            name: "Gazi Baba †"
          },
          "MK-16": {
            type: "Municipality",
            name: "Vrapčište"
          },
          "MK-15": {
            type: "Municipality",
            name: "Vraneštica"
          },
          "MK-14": {
            type: "Municipality",
            name: "Vinica"
          },
          "MK-35": {
            type: "Municipality",
            name: "Jegunovce"
          },
          "MK-84": {
            type: "Municipality",
            name: "Šuto Orizari †"
          },
          "MK-61": {
            type: "Municipality",
            name: "Plasnica"
          },
          "MK-79": {
            type: "Municipality",
            name: "Čair †"
          },
          "MK-34": {
            type: "Municipality",
            name: "Ilinden"
          },
          "MK-63": {
            type: "Municipality",
            name: "Probištip"
          },
          "MK-76": {
            type: "Municipality",
            name: "Tetovo"
          },
          "MK-77": {
            type: "Municipality",
            name: "Centar †"
          },
          "MK-37": {
            type: "Municipality",
            name: "Karbinci"
          },
          "MK-67": {
            type: "Municipality",
            name: "Rosoman"
          }
        }
      },
      MU: {
        name: "Mauritius",
        sub: {
          "MU-VP": {
            type: "City",
            name: "Vacoas-Phoenix"
          },
          "MU-CC": {
            type: "Dependency",
            name: "Cargados Carajos Shoals"
          },
          "MU-SA": {
            type: "District",
            name: "Savanne"
          },
          "MU-RO": {
            type: "Dependency",
            name: "Rodrigues Island"
          },
          "MU-CU": {
            type: "City",
            name: "Curepipe"
          },
          "MU-PA": {
            type: "District",
            name: "Pamplemousses"
          },
          "MU-PL": {
            type: "District",
            name: "Port Louis"
          },
          "MU-QB": {
            type: "City",
            name: "Quatre Bornes"
          },
          "MU-FL": {
            type: "District",
            name: "Flacq"
          },
          "MU-BR": {
            type: "City",
            name: "Beau Bassin-Rose Hill"
          },
          "MU-BL": {
            type: "District",
            name: "Black River"
          },
          "MU-PW": {
            type: "District",
            name: "Plaines Wilhems"
          },
          "MU-AG": {
            type: "Dependency",
            name: "Agalega Islands"
          },
          "MU-PU": {
            type: "City",
            name: "Port Louis"
          },
          "MU-RR": {
            type: "District",
            name: "Rivière du Rempart"
          },
          "MU-GP": {
            type: "District",
            name: "Grand Port"
          },
          "MU-MO": {
            type: "District",
            name: "Moka"
          }
        }
      },
      MT: {
        name: "Malta",
        sub: {
          "MT-58": {
            type: "Local council",
            name: "Ta’ Xbiex"
          },
          "MT-59": {
            type: "Local council",
            name: "Tarxien"
          },
          "MT-56": {
            type: "Local council",
            name: "Sliema"
          },
          "MT-57": {
            type: "Local council",
            name: "Swieqi"
          },
          "MT-54": {
            type: "Local council",
            name: "Santa Venera"
          },
          "MT-55": {
            type: "Local council",
            name: "Siġġiewi"
          },
          "MT-52": {
            type: "Local council",
            name: "Sannat"
          },
          "MT-53": {
            type: "Local council",
            name: "Saint Lucia’s"
          },
          "MT-50": {
            type: "Local council",
            name: "Saint Lawrence"
          },
          "MT-51": {
            type: "Local council",
            name: "Saint Paul’s Bay"
          },
          "MT-68": {
            type: "Local council",
            name: "Żurrieq"
          },
          "MT-60": {
            type: "Local council",
            name: "Valletta"
          },
          "MT-48": {
            type: "Local council",
            name: "Saint Julian’s"
          },
          "MT-38": {
            type: "Local council",
            name: "Naxxar"
          },
          "MT-67": {
            type: "Local council",
            name: "Żejtun"
          },
          "MT-39": {
            type: "Local council",
            name: "Paola"
          },
          "MT-01": {
            type: "Local council",
            name: "Attard"
          },
          "MT-63": {
            type: "Local council",
            name: "Xgħajra"
          },
          "MT-03": {
            type: "Local council",
            name: "Birgu"
          },
          "MT-02": {
            type: "Local council",
            name: "Balzan"
          },
          "MT-05": {
            type: "Local council",
            name: "Birżebbuġa"
          },
          "MT-04": {
            type: "Local council",
            name: "Birkirkara"
          },
          "MT-07": {
            type: "Local council",
            name: "Dingli"
          },
          "MT-06": {
            type: "Local council",
            name: "Bormla"
          },
          "MT-09": {
            type: "Local council",
            name: "Floriana"
          },
          "MT-08": {
            type: "Local council",
            name: "Fgura"
          },
          "MT-25": {
            type: "Local council",
            name: "Luqa"
          },
          "MT-24": {
            type: "Local council",
            name: "Lija"
          },
          "MT-23": {
            type: "Local council",
            name: "Kirkop"
          },
          "MT-22": {
            type: "Local council",
            name: "Kerċem"
          },
          "MT-21": {
            type: "Local council",
            name: "Kalkara"
          },
          "MT-20": {
            type: "Local council",
            name: "Isla"
          },
          "MT-46": {
            type: "Local council",
            name: "Rabat Malta"
          },
          "MT-49": {
            type: "Local council",
            name: "Saint John"
          },
          "MT-29": {
            type: "Local council",
            name: "Mdina"
          },
          "MT-45": {
            type: "Local council",
            name: "Rabat Gozo"
          },
          "MT-44": {
            type: "Local council",
            name: "Qrendi"
          },
          "MT-47": {
            type: "Local council",
            name: "Safi"
          },
          "MT-28": {
            type: "Local council",
            name: "Marsaxlokk"
          },
          "MT-41": {
            type: "Local council",
            name: "Pietà"
          },
          "MT-40": {
            type: "Local council",
            name: "Pembroke"
          },
          "MT-43": {
            type: "Local council",
            name: "Qormi"
          },
          "MT-42": {
            type: "Local council",
            name: "Qala"
          },
          "MT-27": {
            type: "Local council",
            name: "Marsaskala"
          },
          "MT-66": {
            type: "Local council",
            name: "Żebbuġ Malta"
          },
          "MT-65": {
            type: "Local council",
            name: "Żebbuġ Gozo"
          },
          "MT-26": {
            type: "Local council",
            name: "Marsa"
          },
          "MT-61": {
            type: "Local council",
            name: "Xagħra"
          },
          "MT-62": {
            type: "Local council",
            name: "Xewkija"
          },
          "MT-64": {
            type: "Local council",
            name: "Żabbar"
          },
          "MT-32": {
            type: "Local council",
            name: "Mosta"
          },
          "MT-33": {
            type: "Local council",
            name: "Mqabba"
          },
          "MT-12": {
            type: "Local council",
            name: "Gżira"
          },
          "MT-13": {
            type: "Local council",
            name: "Għajnsielem"
          },
          "MT-10": {
            type: "Local council",
            name: "Fontana"
          },
          "MT-11": {
            type: "Local council",
            name: "Gudja"
          },
          "MT-16": {
            type: "Local council",
            name: "Għasri"
          },
          "MT-17": {
            type: "Local council",
            name: "Għaxaq"
          },
          "MT-14": {
            type: "Local council",
            name: "Għarb"
          },
          "MT-15": {
            type: "Local council",
            name: "Għargħur"
          },
          "MT-30": {
            type: "Local council",
            name: "Mellieħa"
          },
          "MT-31": {
            type: "Local council",
            name: "Mġarr"
          },
          "MT-18": {
            type: "Local council",
            name: "Ħamrun"
          },
          "MT-19": {
            type: "Local council",
            name: "Iklin"
          },
          "MT-34": {
            type: "Local council",
            name: "Msida"
          },
          "MT-35": {
            type: "Local council",
            name: "Mtarfa"
          },
          "MT-36": {
            type: "Local council",
            name: "Munxar"
          },
          "MT-37": {
            type: "Local council",
            name: "Nadur"
          }
        }
      },
      MW: {
        name: "Malawi",
        sub: {
          "MW-CR": {
            type: "District",
            name: "Chiradzulu"
          },
          "MW-CT": {
            type: "District",
            name: "Chitipa"
          },
          "MW-CK": {
            type: "District",
            name: "Chikwawa"
          },
          "MW-KS": {
            type: "District",
            name: "Kasungu"
          },
          "MW-KR": {
            type: "District",
            name: "Karonga"
          },
          "MW-TH": {
            type: "District",
            name: "Thyolo"
          },
          "MW-NS": {
            type: "District",
            name: "Nsanje"
          },
          "MW-MC": {
            type: "District",
            name: "Mchinji"
          },
          "MW-MG": {
            type: "District",
            name: "Mangochi"
          },
          "MW-MZ": {
            type: "District",
            name: "Mzimba"
          },
          "MW-PH": {
            type: "District",
            name: "Phalombe"
          },
          "MW-MW": {
            type: "District",
            name: "Mwanza"
          },
          "MW-MU": {
            type: "District",
            name: "Mulanje"
          },
          "MW-RU": {
            type: "District",
            name: "Rumphi"
          },
          "MW-ZO": {
            type: "District",
            name: "Zomba"
          },
          "MW-DO": {
            type: "District",
            name: "Dowa"
          },
          "MW-BL": {
            type: "District",
            name: "Blantyre"
          },
          "MW-DE": {
            type: "District",
            name: "Dedza"
          },
          "MW-BA": {
            type: "District",
            name: "Balaka"
          },
          "MW-S": {
            type: "Region",
            name: "Chakumwera"
          },
          "MW-NK": {
            type: "District",
            name: "Nkhotakota"
          },
          "MW-NI": {
            type: "District",
            name: "Ntchisi"
          },
          "MW-SA": {
            type: "District",
            name: "Salima"
          },
          "MW-NE": {
            type: "District",
            name: "Neno"
          },
          "MW-NB": {
            type: "District",
            name: "Nkhata Bay"
          },
          "MW-LI": {
            type: "District",
            name: "Lilongwe"
          },
          "MW-C": {
            type: "Region",
            name: "Central Region"
          },
          "MW-MH": {
            type: "District",
            name: "Machinga"
          },
          "MW-NU": {
            type: "District",
            name: "Ntcheu"
          },
          "MW-N": {
            type: "Region",
            name: "Chakumpoto"
          },
          "MW-LK": {
            type: "District",
            name: "Likoma"
          }
        }
      },
      MV: {
        name: "Maldives",
        sub: {
          "MV-00": {
            type: "Administrative atoll",
            name: "Alifu Dhaalu"
          },
          "MV-23": {
            type: "Administrative atoll",
            name: "Haa Dhaalu"
          },
          "MV-NO": {
            type: "Province",
            name: "North"
          },
          "MV-NC": {
            type: "Province",
            name: "Medhu-Uthuru"
          },
          "MV-SU": {
            type: "Province",
            name: "Dhekunu"
          },
          "MV-08": {
            type: "Administrative atoll",
            name: "Kolhumadulu"
          },
          "MV-29": {
            type: "Administrative atoll",
            name: "Fuvammulah"
          },
          "MV-28": {
            type: "Administrative atoll",
            name: "Gaafu Dhaalu"
          },
          "MV-03": {
            type: "Administrative atoll",
            name: "Faadhippolhu"
          },
          "MV-02": {
            type: "Administrative atoll",
            name: "Alifu Alifu"
          },
          "MV-01": {
            type: "Administrative atoll",
            name: "Addu Atholhu"
          },
          "MV-26": {
            type: "Administrative atoll",
            name: "Kaafu"
          },
          "MV-07": {
            type: "Administrative atoll",
            name: "Haa Alifu"
          },
          "MV-20": {
            type: "Administrative atoll",
            name: "Baa"
          },
          "MV-05": {
            type: "Administrative atoll",
            name: "Hadhdhunmathi"
          },
          "MV-04": {
            type: "Administrative atoll",
            name: "Felidhu Atholhu"
          },
          "MV-MLE": {
            type: "Capital",
            name: "Maale"
          },
          "MV-SC": {
            type: "Province",
            name: "Medhu-Dhekunu"
          },
          "MV-UN": {
            type: "Province",
            name: "Mathi-Uthuru"
          },
          "MV-14": {
            type: "Administrative atoll",
            name: "Faafu"
          },
          "MV-17": {
            type: "Administrative atoll",
            name: "Dhaalu"
          },
          "MV-12": {
            type: "Administrative atoll",
            name: "Meemu"
          },
          "MV-13": {
            type: "Administrative atoll",
            name: "Maalhosmadulu Uthuruburi"
          },
          "MV-US": {
            type: "Province",
            name: "Mathi-Dhekunu"
          },
          "MV-CE": {
            type: "Province",
            name: "Central"
          },
          "MV-25": {
            type: "Administrative atoll",
            name: "Miladhunmadulu Dhekunuburi"
          },
          "MV-24": {
            type: "Administrative atoll",
            name: "Miladhunmadulu Uthuruburi"
          },
          "MV-27": {
            type: "Administrative atoll",
            name: "Gaafu Alifu"
          }
        }
      },
      MQ: {
        name: "Martinique",
        sub: {
          "MQ-MQ": {
            type: "Country",
            name: "Martinique"
          }
        }
      },
      MP: {
        name: "Northern Mariana Islands",
        sub: {
          "MP-MP": {
            type: "Country",
            name: "Northern Mariana Islands"
          }
        }
      },
      MS: {
        name: "Montserrat",
        sub: {
          "MS-MS": {
            type: "Country",
            name: "Montserrat"
          }
        }
      },
      MR: {
        name: "Mauritania",
        sub: {
          "MR-07": {
            type: "Region",
            name: "Adrar"
          },
          "MR-06": {
            type: "Region",
            name: "Trarza"
          },
          "MR-05": {
            type: "Region",
            name: "Brakna"
          },
          "MR-04": {
            type: "Region",
            name: "Gorgol"
          },
          "MR-03": {
            type: "Region",
            name: "Assaba"
          },
          "MR-02": {
            type: "Region",
            name: "Hodh el Gharbi"
          },
          "MR-01": {
            type: "Region",
            name: "Hodh ech Chargui"
          },
          "MR-11": {
            type: "Region",
            name: "Tiris Zemmour"
          },
          "MR-NKC": {
            type: "District",
            name: "Nouakchott"
          },
          "MR-12": {
            type: "Region",
            name: "Inchiri"
          },
          "MR-09": {
            type: "Region",
            name: "Tagant"
          },
          "MR-08": {
            type: "Region",
            name: "Dakhlet Nouâdhibou"
          },
          "MR-10": {
            type: "Region",
            name: "Guidimaka"
          }
        }
      },
      IM: {
        name: "Isle of Man",
        sub: {
          "IM-IM": {
            type: "Country",
            name: "Isle of Main"
          }
        }
      },
      UG: {
        name: "Uganda",
        sub: {
          "UG-328": {
            type: "District",
            name: "Napak"
          },
          "UG-329": {
            type: "District",
            name: "Nwoya"
          },
          "UG-108": {
            type: "District",
            name: "Mukono"
          },
          "UG-109": {
            type: "District",
            name: "Nakasongola"
          },
          "UG-101": {
            type: "District",
            name: "Kalangala"
          },
          "UG-102": {
            type: "City",
            name: "Kampala"
          },
          "UG-103": {
            type: "District",
            name: "Kiboga"
          },
          "UG-104": {
            type: "District",
            name: "Luwero"
          },
          "UG-105": {
            type: "District",
            name: "Masaka"
          },
          "UG-106": {
            type: "District",
            name: "Mpigi"
          },
          "UG-107": {
            type: "District",
            name: "Mubende"
          },
          "UG-229": {
            type: "District",
            name: "Luuka"
          },
          "UG-228": {
            type: "District",
            name: "Kween"
          },
          "UG-221": {
            type: "District",
            name: "Manafwa"
          },
          "UG-220": {
            type: "District",
            name: "Kaliro"
          },
          "UG-223": {
            type: "District",
            name: "Bududa"
          },
          "UG-222": {
            type: "District",
            name: "Namutumba"
          },
          "UG-225": {
            type: "District",
            name: "Bulambuli"
          },
          "UG-224": {
            type: "District",
            name: "Bukedea"
          },
          "UG-227": {
            type: "District",
            name: "Kibuku"
          },
          "UG-226": {
            type: "District",
            name: "Buyende"
          },
          "UG-117": {
            type: "District",
            name: "Buikwe"
          },
          "UG-116": {
            type: "District",
            name: "Lyantonde"
          },
          "UG-115": {
            type: "District",
            name: "Nakaseke"
          },
          "UG-114": {
            type: "District",
            name: "Mityana"
          },
          "UG-113": {
            type: "District",
            name: "Wakiso"
          },
          "UG-112": {
            type: "District",
            name: "Kayunga"
          },
          "UG-111": {
            type: "District",
            name: "Sembabule"
          },
          "UG-110": {
            type: "District",
            name: "Rakai"
          },
          "UG-119": {
            type: "District",
            name: "Butambala"
          },
          "UG-118": {
            type: "District",
            name: "Bukomansibi"
          },
          "UG-214": {
            type: "District",
            name: "Mayuge"
          },
          "UG-215": {
            type: "District",
            name: "Sironko"
          },
          "UG-216": {
            type: "District",
            name: "Amuria"
          },
          "UG-217": {
            type: "District",
            name: "Budaka"
          },
          "UG-210": {
            type: "District",
            name: "Pallisa"
          },
          "UG-211": {
            type: "District",
            name: "Soroti"
          },
          "UG-212": {
            type: "District",
            name: "Tororo"
          },
          "UG-213": {
            type: "District",
            name: "Kaberamaido"
          },
          "UG-218": {
            type: "District",
            name: "Bukwa"
          },
          "UG-219": {
            type: "District",
            name: "Butaleja"
          },
          "UG-421": {
            type: "District",
            name: "Kyegegwa"
          },
          "UG-203": {
            type: "District",
            name: "Iganga"
          },
          "UG-202": {
            type: "District",
            name: "Busia"
          },
          "UG-201": {
            type: "District",
            name: "Bugiri"
          },
          "UG-207": {
            type: "District",
            name: "Katakwi"
          },
          "UG-206": {
            type: "District",
            name: "Kapchorwa"
          },
          "UG-205": {
            type: "District",
            name: "Kamuli"
          },
          "UG-204": {
            type: "District",
            name: "Jinja"
          },
          "UG-209": {
            type: "District",
            name: "Mbale"
          },
          "UG-208": {
            type: "District",
            name: "Kumi"
          },
          "UG-416": {
            type: "District",
            name: "Ibanda"
          },
          "UG-418": {
            type: "District",
            name: "Kiruhura"
          },
          "UG-419": {
            type: "District",
            name: "Buliisa"
          },
          "UG-417": {
            type: "District",
            name: "Isingiro"
          },
          "UG-308": {
            type: "District",
            name: "Moroto"
          },
          "UG-309": {
            type: "District",
            name: "Moyo"
          },
          "UG-302": {
            type: "District",
            name: "Apac"
          },
          "UG-303": {
            type: "District",
            name: "Arua"
          },
          "UG-301": {
            type: "District",
            name: "Adjumani"
          },
          "UG-306": {
            type: "District",
            name: "Kotido"
          },
          "UG-307": {
            type: "District",
            name: "Lira"
          },
          "UG-304": {
            type: "District",
            name: "Gulu"
          },
          "UG-305": {
            type: "District",
            name: "Kitgum"
          },
          "UG-420": {
            type: "District",
            name: "Kiryandongo"
          },
          "UG-425": {
            type: "District",
            name: "Sheema"
          },
          "UG-424": {
            type: "District",
            name: "Rubirizi"
          },
          "UG-423": {
            type: "District",
            name: "Ntoroko"
          },
          "UG-422": {
            type: "District",
            name: "Mitooma"
          },
          "UG-319": {
            type: "District",
            name: "Amuru"
          },
          "UG-318": {
            type: "District",
            name: "Dokolo"
          },
          "UG-414": {
            type: "District",
            name: "Kanungu"
          },
          "UG-415": {
            type: "District",
            name: "Kyenjojo"
          },
          "UG-412": {
            type: "District",
            name: "Rukungiri"
          },
          "UG-413": {
            type: "District",
            name: "Kamwenge"
          },
          "UG-410": {
            type: "District",
            name: "Mbarara"
          },
          "UG-411": {
            type: "District",
            name: "Ntungamo"
          },
          "UG-311": {
            type: "District",
            name: "Nakapiripirit"
          },
          "UG-310": {
            type: "District",
            name: "Nebbi"
          },
          "UG-313": {
            type: "District",
            name: "Yumbe"
          },
          "UG-312": {
            type: "District",
            name: "Pader"
          },
          "UG-315": {
            type: "District",
            name: "Kaabong"
          },
          "UG-314": {
            type: "District",
            name: "Amolatar"
          },
          "UG-317": {
            type: "District",
            name: "Abim"
          },
          "UG-316": {
            type: "District",
            name: "Koboko"
          },
          "UG-324": {
            type: "District",
            name: "Amudat"
          },
          "UG-325": {
            type: "District",
            name: "Buhweju"
          },
          "UG-326": {
            type: "District",
            name: "Kole"
          },
          "UG-327": {
            type: "District",
            name: "Lamwo"
          },
          "UG-320": {
            type: "District",
            name: "Maracha"
          },
          "UG-321": {
            type: "District",
            name: "Oyam"
          },
          "UG-322": {
            type: "District",
            name: "Agago"
          },
          "UG-323": {
            type: "District",
            name: "Alebtong"
          },
          "UG-124": {
            type: "District",
            name: "Lwengo"
          },
          "UG-122": {
            type: "District",
            name: "Kalungu"
          },
          "UG-123": {
            type: "District",
            name: "Kyankwanzi"
          },
          "UG-120": {
            type: "District",
            name: "Buvuma"
          },
          "UG-121": {
            type: "District",
            name: "Gomba"
          },
          "UG-405": {
            type: "District",
            name: "Kabarole"
          },
          "UG-404": {
            type: "District",
            name: "Kabale"
          },
          "UG-407": {
            type: "District",
            name: "Kibaale"
          },
          "UG-406": {
            type: "District",
            name: "Kasese"
          },
          "UG-401": {
            type: "District",
            name: "Bundibugyo"
          },
          "UG-403": {
            type: "District",
            name: "Hoima"
          },
          "UG-402": {
            type: "District",
            name: "Bushenyi"
          },
          "UG-409": {
            type: "District",
            name: "Masindi"
          },
          "UG-408": {
            type: "District",
            name: "Kisoro"
          },
          "UG-N": {
            type: "Geographical region",
            name: "Northern"
          },
          "UG-C": {
            type: "Geographical region",
            name: "Central"
          },
          "UG-E": {
            type: "Geographical region",
            name: "Eastern"
          },
          "UG-W": {
            type: "Geographical region",
            name: "Western"
          },
          "UG-331": {
            type: "District",
            name: "Zombo"
          },
          "UG-330": {
            type: "District",
            name: "Otuke"
          },
          "UG-232": {
            type: "District",
            name: "Serere"
          },
          "UG-230": {
            type: "District",
            name: "Namayingo"
          },
          "UG-231": {
            type: "District",
            name: "Ngora"
          }
        }
      },
      TZ: {
        name: "Tanzania",
        sub: {
          "TZ-28": {
            type: "Region",
            name: "Katavi"
          },
          "TZ-29": {
            type: "Region",
            name: "Njombe"
          },
          "TZ-02": {
            type: "Region",
            name: "Dar es Salaam"
          },
          "TZ-03": {
            type: "Region",
            name: "Dodoma"
          },
          "TZ-01": {
            type: "Region",
            name: "Arusha"
          },
          "TZ-06": {
            type: "Region",
            name: "Kaskazini Pemba"
          },
          "TZ-07": {
            type: "Region",
            name: "Kaskazini Unguja"
          },
          "TZ-04": {
            type: "Region",
            name: "Iringa"
          },
          "TZ-05": {
            type: "Region",
            name: "Kagera"
          },
          "TZ-20": {
            type: "Region",
            name: "Rukwa"
          },
          "TZ-21": {
            type: "Region",
            name: "Ruvuma"
          },
          "TZ-08": {
            type: "Region",
            name: "Kigoma"
          },
          "TZ-09": {
            type: "Region",
            name: "Kilimanjaro"
          },
          "TZ-24": {
            type: "Region",
            name: "Tabora"
          },
          "TZ-25": {
            type: "Region",
            name: "Tanga"
          },
          "TZ-26": {
            type: "Region",
            name: "Manyara"
          },
          "TZ-27": {
            type: "Region",
            name: "Geita"
          },
          "TZ-22": {
            type: "Region",
            name: "Shinyanga"
          },
          "TZ-23": {
            type: "Region",
            name: "Singida"
          },
          "TZ-11": {
            type: "Region",
            name: "Kusini Unguja"
          },
          "TZ-10": {
            type: "Region",
            name: "Kusini Pemba"
          },
          "TZ-13": {
            type: "Region",
            name: "Mara"
          },
          "TZ-12": {
            type: "Region",
            name: "Lindi"
          },
          "TZ-15": {
            type: "Region",
            name: "Mjini Magharibi"
          },
          "TZ-14": {
            type: "Region",
            name: "Mbeya"
          },
          "TZ-17": {
            type: "Region",
            name: "Mtwara"
          },
          "TZ-16": {
            type: "Region",
            name: "Morogoro"
          },
          "TZ-19": {
            type: "Region",
            name: "Coast"
          },
          "TZ-18": {
            type: "Region",
            name: "Mwanza"
          },
          "TZ-30": {
            type: "Region",
            name: "Simiyu"
          }
        }
      },
      MY: {
        name: "Malaysia",
        sub: {
          "MY-08": {
            type: "State",
            name: "Perak"
          },
          "MY-09": {
            type: "State",
            name: "Perlis"
          },
          "MY-16": {
            type: "Federal territory",
            name: "Wilayah Persekutuan Putrajaya"
          },
          "MY-02": {
            type: "State",
            name: "Kedah"
          },
          "MY-03": {
            type: "State",
            name: "Kelantan"
          },
          "MY-13": {
            type: "State",
            name: "Sarawak"
          },
          "MY-01": {
            type: "State",
            name: "Johor"
          },
          "MY-06": {
            type: "State",
            name: "Pahang"
          },
          "MY-07": {
            type: "State",
            name: "Pulau Pinang"
          },
          "MY-04": {
            type: "State",
            name: "Melaka"
          },
          "MY-05": {
            type: "State",
            name: "Negeri Sembilan"
          },
          "MY-11": {
            type: "State",
            name: "Terengganu"
          },
          "MY-10": {
            type: "State",
            name: "Selangor"
          },
          "MY-14": {
            type: "Federal territory",
            name: "Wilayah Persekutuan Kuala Lumpur"
          },
          "MY-15": {
            type: "Federal territory",
            name: "Wilayah Persekutuan Labuan"
          },
          "MY-12": {
            type: "State",
            name: "Sabah"
          }
        }
      },
      MX: {
        name: "Mexico",
        sub: {
          "MX-JAL": {
            type: "State",
            name: "Jalisco"
          },
          "MX-MEX": {
            type: "State",
            name: "México"
          },
          "MX-DUR": {
            type: "State",
            name: "Durango"
          },
          "MX-TAB": {
            type: "State",
            name: "Tabasco"
          },
          "MX-GUA": {
            type: "State",
            name: "Guanajuato"
          },
          "MX-CMX": {
            type: "Federal District",
            name: "Ciudad de México"
          },
          "MX-SIN": {
            type: "State",
            name: "Sinaloa"
          },
          "MX-BCS": {
            type: "State",
            name: "Baja California Sur"
          },
          "MX-NAY": {
            type: "State",
            name: "Nayarit"
          },
          "MX-AGU": {
            type: "State",
            name: "Aguascalientes"
          },
          "MX-ROO": {
            type: "State",
            name: "Quintana Roo"
          },
          "MX-NLE": {
            type: "State",
            name: "Nuevo León"
          },
          "MX-BCN": {
            type: "State",
            name: "Baja California"
          },
          "MX-SON": {
            type: "State",
            name: "Sonora"
          },
          "MX-MOR": {
            type: "State",
            name: "Morelos"
          },
          "MX-MIC": {
            type: "State",
            name: "Michoacán de Ocampo"
          },
          "MX-CAM": {
            type: "State",
            name: "Campeche"
          },
          "MX-COA": {
            type: "State",
            name: "Coahuila de Zaragoza"
          },
          "MX-QUE": {
            type: "State",
            name: "Querétaro"
          },
          "MX-COL": {
            type: "State",
            name: "Colima"
          },
          "MX-TAM": {
            type: "State",
            name: "Tamaulipas"
          },
          "MX-YUC": {
            type: "State",
            name: "Yucatán"
          },
          "MX-PUE": {
            type: "State",
            name: "Puebla"
          },
          "MX-HID": {
            type: "State",
            name: "Hidalgo"
          },
          "MX-CHP": {
            type: "State",
            name: "Chiapas"
          },
          "MX-TLA": {
            type: "State",
            name: "Tlaxcala"
          },
          "MX-GRO": {
            type: "State",
            name: "Guerrero"
          },
          "MX-CHH": {
            type: "State",
            name: "Chihuahua"
          },
          "MX-VER": {
            type: "State",
            name: "Veracruz de Ignacio de la Llave"
          },
          "MX-ZAC": {
            type: "State",
            name: "Zacatecas"
          },
          "MX-SLP": {
            type: "State",
            name: "San Luis Potosí"
          },
          "MX-OAX": {
            type: "State",
            name: "Oaxaca"
          }
        }
      },
      IL: {
        name: "Israel",
        sub: {
          "IL-TA": {
            type: "District",
            name: "Tel Aviv"
          },
          "IL-Z": {
            type: "District",
            name: "Northern District"
          },
          "IL-M": {
            type: "District",
            name: "Central District"
          },
          "IL-HA": {
            type: "District",
            name: "Haifa"
          },
          "IL-D": {
            type: "District",
            name: "Southern District"
          },
          "IL-JM": {
            type: "District",
            name: "Jerusalem"
          }
        }
      },
      FR: {
        name: "France",
        sub: {
          "FR-28": {
            type: "Metropolitan department",
            name: "Eure-et-Loir"
          },
          "FR-29": {
            type: "Metropolitan department",
            name: "Finistère"
          },
          "FR-22": {
            type: "Metropolitan department",
            name: "Côtes-d'Armor"
          },
          "FR-23": {
            type: "Metropolitan department",
            name: "Creuse"
          },
          "FR-21": {
            type: "Metropolitan department",
            name: "Côte-d'Or"
          },
          "FR-26": {
            type: "Metropolitan department",
            name: "Drôme"
          },
          "FR-27": {
            type: "Metropolitan department",
            name: "Eure"
          },
          "FR-24": {
            type: "Metropolitan department",
            name: "Dordogne"
          },
          "FR-25": {
            type: "Metropolitan department",
            name: "Doubs"
          },
          "FR-TF": {
            type: "Overseas territorial collectivity",
            name: "Terres australes françaises"
          },
          "FR-95": {
            type: "Metropolitan department",
            name: "Val-d'Oise"
          },
          "FR-94": {
            type: "Metropolitan department",
            name: "Val-de-Marne"
          },
          "FR-93": {
            type: "Metropolitan department",
            name: "Seine-Saint-Denis"
          },
          "FR-92": {
            type: "Metropolitan department",
            name: "Hauts-de-Seine"
          },
          "FR-91": {
            type: "Metropolitan department",
            name: "Essonne"
          },
          "FR-90": {
            type: "Metropolitan department",
            name: "Territoire de Belfort"
          },
          "FR-17": {
            type: "Metropolitan department",
            name: "Charente-Maritime"
          },
          "FR-16": {
            type: "Metropolitan department",
            name: "Charente"
          },
          "FR-15": {
            type: "Metropolitan department",
            name: "Cantal"
          },
          "FR-14": {
            type: "Metropolitan department",
            name: "Calvados"
          },
          "FR-13": {
            type: "Metropolitan department",
            name: "Bouches-du-Rhône"
          },
          "FR-12": {
            type: "Metropolitan department",
            name: "Aveyron"
          },
          "FR-11": {
            type: "Metropolitan department",
            name: "Aude"
          },
          "FR-10": {
            type: "Metropolitan department",
            name: "Aube"
          },
          "FR-2B": {
            type: "Metropolitan department",
            name: "Haute-Corse"
          },
          "FR-2A": {
            type: "Metropolitan department",
            name: "Corse-du-Sud"
          },
          "FR-MF": {
            type: "Overseas territorial collectivity",
            name: "Saint-Martin"
          },
          "FR-19": {
            type: "Metropolitan department",
            name: "Corrèze"
          },
          "FR-18": {
            type: "Metropolitan department",
            name: "Cher"
          },
          "FR-88": {
            type: "Metropolitan department",
            name: "Vosges"
          },
          "FR-89": {
            type: "Metropolitan department",
            name: "Yonne"
          },
          "FR-A": {
            type: "Metropolitan region",
            name: "Alsace"
          },
          "FR-G": {
            type: "Metropolitan region",
            name: "Champagne-Ardenne"
          },
          "FR-F": {
            type: "Metropolitan region",
            name: "Centre"
          },
          "FR-BL": {
            type: "Overseas territorial collectivity",
            name: "Saint-Barthélemy"
          },
          "FR-D": {
            type: "Metropolitan region",
            name: "Bourgogne"
          },
          "FR-80": {
            type: "Metropolitan department",
            name: "Somme"
          },
          "FR-81": {
            type: "Metropolitan department",
            name: "Tarn"
          },
          "FR-82": {
            type: "Metropolitan department",
            name: "Tarn-et-Garonne"
          },
          "FR-83": {
            type: "Metropolitan department",
            name: "Var"
          },
          "FR-84": {
            type: "Metropolitan department",
            name: "Vaucluse"
          },
          "FR-85": {
            type: "Metropolitan department",
            name: "Vendée"
          },
          "FR-86": {
            type: "Metropolitan department",
            name: "Vienne"
          },
          "FR-87": {
            type: "Metropolitan department",
            name: "Haute-Vienne"
          },
          "FR-S": {
            type: "Metropolitan region",
            name: "Picardie"
          },
          "FR-01": {
            type: "Metropolitan department",
            name: "Ain"
          },
          "FR-02": {
            type: "Metropolitan department",
            name: "Aisne"
          },
          "FR-03": {
            type: "Metropolitan department",
            name: "Allier"
          },
          "FR-04": {
            type: "Metropolitan department",
            name: "Alpes-de-Haute-Provence"
          },
          "FR-05": {
            type: "Metropolitan department",
            name: "Hautes-Alpes"
          },
          "FR-06": {
            type: "Metropolitan department",
            name: "Alpes-Maritimes"
          },
          "FR-07": {
            type: "Metropolitan department",
            name: "Ardèche"
          },
          "FR-08": {
            type: "Metropolitan department",
            name: "Ardennes"
          },
          "FR-09": {
            type: "Metropolitan department",
            name: "Ariège"
          },
          "FR-RE": {
            type: "Overseas department",
            name: "La Réunion"
          },
          "FR-E": {
            type: "Metropolitan region",
            name: "Bretagne"
          },
          "FR-CP": {
            type: "Dependency",
            name: "Clipperton"
          },
          "FR-75": {
            type: "Metropolitan department",
            name: "Paris"
          },
          "FR-74": {
            type: "Metropolitan department",
            name: "Haute-Savoie"
          },
          "FR-77": {
            type: "Metropolitan department",
            name: "Seine-et-Marne"
          },
          "FR-76": {
            type: "Metropolitan department",
            name: "Seine-Maritime"
          },
          "FR-71": {
            type: "Metropolitan department",
            name: "Saône-et-Loire"
          },
          "FR-70": {
            type: "Metropolitan department",
            name: "Haute-Saône"
          },
          "FR-73": {
            type: "Metropolitan department",
            name: "Savoie"
          },
          "FR-72": {
            type: "Metropolitan department",
            name: "Sarthe"
          },
          "FR-79": {
            type: "Metropolitan department",
            name: "Deux-Sèvres"
          },
          "FR-78": {
            type: "Metropolitan department",
            name: "Yvelines"
          },
          "FR-C": {
            type: "Metropolitan region",
            name: "Auvergne"
          },
          "FR-B": {
            type: "Metropolitan region",
            name: "Aquitaine"
          },
          "FR-YT": {
            type: "Overseas department",
            name: "Mayotte"
          },
          "FR-66": {
            type: "Metropolitan department",
            name: "Pyrénées-Orientales"
          },
          "FR-67": {
            type: "Metropolitan department",
            name: "Bas-Rhin"
          },
          "FR-64": {
            type: "Metropolitan department",
            name: "Pyrénées-Atlantiques"
          },
          "FR-65": {
            type: "Metropolitan department",
            name: "Hautes-Pyrénées"
          },
          "FR-62": {
            type: "Metropolitan department",
            name: "Pas-de-Calais"
          },
          "FR-63": {
            type: "Metropolitan department",
            name: "Puy-de-Dôme"
          },
          "FR-60": {
            type: "Metropolitan department",
            name: "Oise"
          },
          "FR-61": {
            type: "Metropolitan department",
            name: "Orne"
          },
          "FR-68": {
            type: "Metropolitan department",
            name: "Haut-Rhin"
          },
          "FR-69": {
            type: "Metropolitan department",
            name: "Rhône"
          },
          "FR-K": {
            type: "Metropolitan region",
            name: "Languedoc-Roussillon"
          },
          "FR-J": {
            type: "Metropolitan region",
            name: "Île-de-France"
          },
          "FR-R": {
            type: "Metropolitan region",
            name: "Pays-de-la-Loire"
          },
          "FR-I": {
            type: "Metropolitan region",
            name: "Franche-Comté"
          },
          "FR-H": {
            type: "Metropolitan region",
            name: "Corse"
          },
          "FR-O": {
            type: "Metropolitan region",
            name: "Nord-Pas-de-Calais"
          },
          "FR-N": {
            type: "Metropolitan region",
            name: "Midi-Pyrénées"
          },
          "FR-M": {
            type: "Metropolitan region",
            name: "Lorraine"
          },
          "FR-L": {
            type: "Metropolitan region",
            name: "Limousin"
          },
          "FR-53": {
            type: "Metropolitan department",
            name: "Mayenne"
          },
          "FR-52": {
            type: "Metropolitan department",
            name: "Haute-Marne"
          },
          "FR-51": {
            type: "Metropolitan department",
            name: "Marne"
          },
          "FR-50": {
            type: "Metropolitan department",
            name: "Manche"
          },
          "FR-57": {
            type: "Metropolitan department",
            name: "Moselle"
          },
          "FR-56": {
            type: "Metropolitan department",
            name: "Morbihan"
          },
          "FR-55": {
            type: "Metropolitan department",
            name: "Meuse"
          },
          "FR-54": {
            type: "Metropolitan department",
            name: "Meurthe-et-Moselle"
          },
          "FR-59": {
            type: "Metropolitan department",
            name: "Nord"
          },
          "FR-58": {
            type: "Metropolitan department",
            name: "Nièvre"
          },
          "FR-Q": {
            type: "Metropolitan region",
            name: "Haute-Normandie"
          },
          "FR-P": {
            type: "Metropolitan region",
            name: "Basse-Normandie"
          },
          "FR-V": {
            type: "Metropolitan region",
            name: "Rhône-Alpes"
          },
          "FR-U": {
            type: "Metropolitan region",
            name: "Provence-Alpes-Côte-d'Azur"
          },
          "FR-T": {
            type: "Metropolitan region",
            name: "Poitou-Charentes"
          },
          "FR-NC": {
            type: "Overseas territorial collectivity",
            name: "Nouvelle-Calédonie"
          },
          "FR-48": {
            type: "Metropolitan department",
            name: "Lozère"
          },
          "FR-49": {
            type: "Metropolitan department",
            name: "Maine-et-Loire"
          },
          "FR-44": {
            type: "Metropolitan department",
            name: "Loire-Atlantique"
          },
          "FR-45": {
            type: "Metropolitan department",
            name: "Loiret"
          },
          "FR-46": {
            type: "Metropolitan department",
            name: "Lot"
          },
          "FR-47": {
            type: "Metropolitan department",
            name: "Lot-et-Garonne"
          },
          "FR-40": {
            type: "Metropolitan department",
            name: "Landes"
          },
          "FR-41": {
            type: "Metropolitan department",
            name: "Loir-et-Cher"
          },
          "FR-42": {
            type: "Metropolitan department",
            name: "Loire"
          },
          "FR-43": {
            type: "Metropolitan department",
            name: "Haute-Loire"
          },
          "FR-MQ": {
            type: "Overseas department",
            name: "Martinique"
          },
          "FR-GF": {
            type: "Overseas department",
            name: "Guyane"
          },
          "FR-PF": {
            type: "Overseas territorial collectivity",
            name: "Polynésie française"
          },
          "FR-PM": {
            type: "Overseas territorial collectivity",
            name: "Saint-Pierre-et-Miquelon"
          },
          "FR-GP": {
            type: "Overseas department",
            name: "Guadeloupe"
          },
          "FR-39": {
            type: "Metropolitan department",
            name: "Jura"
          },
          "FR-38": {
            type: "Metropolitan department",
            name: "Isère"
          },
          "FR-31": {
            type: "Metropolitan department",
            name: "Haute-Garonne"
          },
          "FR-30": {
            type: "Metropolitan department",
            name: "Gard"
          },
          "FR-33": {
            type: "Metropolitan department",
            name: "Gironde"
          },
          "FR-32": {
            type: "Metropolitan department",
            name: "Gers"
          },
          "FR-35": {
            type: "Metropolitan department",
            name: "Ille-et-Vilaine"
          },
          "FR-34": {
            type: "Metropolitan department",
            name: "Hérault"
          },
          "FR-37": {
            type: "Metropolitan department",
            name: "Indre-et-Loire"
          },
          "FR-36": {
            type: "Metropolitan department",
            name: "Indre"
          },
          "FR-WF": {
            type: "Overseas territorial collectivity",
            name: "Wallis-et-Futuna"
          }
        }
      },
      AW: {
        name: "Aruba",
        sub: {
          "AW-AW": {
            type: "Country",
            name: "Aruba"
          }
        }
      },
      SH: {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        sub: {
          "SH-HL": {
            type: "Geographical entity",
            name: "Saint Helena"
          },
          "SH-TA": {
            type: "Geographical entity",
            name: "Tristan da Cunha"
          },
          "SH-AC": {
            type: "Geographical entity",
            name: "Ascension"
          }
        }
      },
      SJ: {
        name: "Svalbard & Jan Mayen Islands",
        sub: {
          "SJ-SJ": {
            type: "Country",
            name: "Svalbard & Jan Mayen Islands"
          }
        }
      },
      FI: {
        name: "Finland",
        sub: {
          "FI-10": {
            type: "Region",
            name: "Lappi"
          },
          "FI-11": {
            type: "Region",
            name: "Birkaland"
          },
          "FI-12": {
            type: "Region",
            name: "Österbotten"
          },
          "FI-16": {
            type: "Region",
            name: "Päijänne-Tavastland"
          },
          "FI-17": {
            type: "Region",
            name: "Satakunda"
          },
          "FI-09": {
            type: "Region",
            name: "Kymenlaakso"
          },
          "FI-08": {
            type: "Region",
            name: "Keski-Suomi"
          },
          "FI-18": {
            type: "Region",
            name: "Nyland"
          },
          "FI-19": {
            type: "Region",
            name: "Egentliga Finland"
          },
          "FI-14": {
            type: "Region",
            name: "Norra Österbotten"
          },
          "FI-15": {
            type: "Region",
            name: "Norra Savolax"
          },
          "FI-01": {
            type: "Region",
            name: "Ahvenanmaan maakunta"
          },
          "FI-13": {
            type: "Region",
            name: "Norra Karelen"
          },
          "FI-03": {
            type: "Region",
            name: "Etelä-Pohjanmaa"
          },
          "FI-02": {
            type: "Region",
            name: "Etelä-Karjala"
          },
          "FI-05": {
            type: "Region",
            name: "Kainuu"
          },
          "FI-04": {
            type: "Region",
            name: "Etelä-Savo"
          },
          "FI-07": {
            type: "Region",
            name: "Keski-Pohjanmaa"
          },
          "FI-06": {
            type: "Region",
            name: "Egentliga Tavastland"
          }
        }
      },
      FJ: {
        name: "Fiji",
        sub: {
          "FJ-08": {
            type: "Province",
            name: "Nadroga and Navosa"
          },
          "FJ-09": {
            type: "Province",
            name: "Naitasiri"
          },
          "FJ-14": {
            type: "Province",
            name: "Tailevu"
          },
          "FJ-N": {
            type: "Division",
            name: "Northern"
          },
          "FJ-C": {
            type: "Division",
            name: "Central"
          },
          "FJ-01": {
            type: "Province",
            name: "Ba"
          },
          "FJ-02": {
            type: "Province",
            name: "Bua"
          },
          "FJ-03": {
            type: "Province",
            name: "Cakaudrove"
          },
          "FJ-04": {
            type: "Province",
            name: "Kadavu"
          },
          "FJ-05": {
            type: "Province",
            name: "Lau"
          },
          "FJ-06": {
            type: "Province",
            name: "Lomaiviti"
          },
          "FJ-07": {
            type: "Province",
            name: "Macuata"
          },
          "FJ-11": {
            type: "Province",
            name: "Ra"
          },
          "FJ-R": {
            type: "Dependency",
            name: "Rotuma"
          },
          "FJ-12": {
            type: "Province",
            name: "Rewa"
          },
          "FJ-13": {
            type: "Province",
            name: "Serua"
          },
          "FJ-10": {
            type: "Province",
            name: "Namosi"
          },
          "FJ-W": {
            type: "Division",
            name: "Western"
          },
          "FJ-E": {
            type: "Division",
            name: "Eastern"
          }
        }
      },
      FK: {
        name: "Falkland Islands",
        sub: {
          "FK-FK": {
            type: "Country",
            name: "Falkland Islands"
          }
        }
      },
      FM: {
        name: "Micronesia, Federated States Of",
        sub: {
          "FM-YAP": {
            type: "State",
            name: "Yap"
          },
          "FM-KSA": {
            type: "State",
            name: "Kosrae"
          },
          "FM-PNI": {
            type: "State",
            name: "Pohnpei"
          },
          "FM-TRK": {
            type: "State",
            name: "Chuuk"
          }
        }
      },
      FO: {
        name: "Faroe Islands",
        sub: {
          "FO-FO": {
            type: "Country",
            name: "Faroe Islands"
          }
        }
      },
      NI: {
        name: "Nicaragua",
        sub: {
          "NI-BO": {
            type: "Department",
            name: "Boaco"
          },
          "NI-CI": {
            type: "Department",
            name: "Chinandega"
          },
          "NI-LE": {
            type: "Department",
            name: "León"
          },
          "NI-RI": {
            type: "Department",
            name: "Rivas"
          },
          "NI-CO": {
            type: "Department",
            name: "Chontales"
          },
          "NI-MN": {
            type: "Department",
            name: "Managua"
          },
          "NI-CA": {
            type: "Department",
            name: "Carazo"
          },
          "NI-AN": {
            type: "Autonomous region",
            name: "Atlántico Norte"
          },
          "NI-ES": {
            type: "Department",
            name: "Estelí"
          },
          "NI-SJ": {
            type: "Department",
            name: "Río San Juan"
          },
          "NI-NS": {
            type: "Department",
            name: "Nueva Segovia"
          },
          "NI-AS": {
            type: "Autonomous region",
            name: "Atlántico Sur"
          },
          "NI-MT": {
            type: "Department",
            name: "Matagalpa"
          },
          "NI-MD": {
            type: "Department",
            name: "Madriz"
          },
          "NI-MS": {
            type: "Department",
            name: "Masaya"
          },
          "NI-GR": {
            type: "Department",
            name: "Granada"
          },
          "NI-JI": {
            type: "Department",
            name: "Jinotega"
          }
        }
      },
      NL: {
        name: "Netherlands",
        sub: {
          "NL-ZH": {
            type: "Province",
            name: "Zuid-Holland"
          },
          "NL-FR": {
            type: "Province",
            name: "Fryslân"
          },
          "NL-AW": {
            type: "Country",
            name: "Aruba"
          },
          "NL-CW": {
            type: "Country",
            name: "Curaçao"
          },
          "NL-UT": {
            type: "Province",
            name: "Utrecht"
          },
          "NL-OV": {
            type: "Province",
            name: "Overijssel"
          },
          "NL-GE": {
            type: "Province",
            name: "Gelderland"
          },
          "NL-BQ2": {
            type: "Special municipality",
            name: "Saba"
          },
          "NL-BQ3": {
            type: "Special municipality",
            name: "Sint Eustatius"
          },
          "NL-BQ1": {
            type: "Special municipality",
            name: "Bonaire"
          },
          "NL-NH": {
            type: "Province",
            name: "Noord-Holland"
          },
          "NL-ZE": {
            type: "Province",
            name: "Zeeland"
          },
          "NL-SX": {
            type: "Country",
            name: "Sint Maarten"
          },
          "NL-FL": {
            type: "Province",
            name: "Flevoland"
          },
          "NL-GR": {
            type: "Province",
            name: "Groningen"
          },
          "NL-DR": {
            type: "Province",
            name: "Drenthe"
          },
          "NL-NB": {
            type: "Province",
            name: "Noord-Brabant"
          },
          "NL-LI": {
            type: "Province",
            name: "Limburg"
          }
        }
      },
      NO: {
        name: "Norway",
        sub: {
          "NO-10": {
            type: "County",
            name: "Vest-Agder"
          },
          "NO-16": {
            type: "County",
            name: "Sør-Trøndelag"
          },
          "NO-19": {
            type: "County",
            name: "Romsa"
          },
          "NO-11": {
            type: "County",
            name: "Rogaland"
          },
          "NO-21": {
            type: "Arctic region",
            name: "Svalbard"
          },
          "NO-20": {
            type: "County",
            name: "Finnmark"
          },
          "NO-22": {
            type: "Arctic region",
            name: "Jan Mayen"
          },
          "NO-12": {
            type: "County",
            name: "Hordaland"
          },
          "NO-15": {
            type: "County",
            name: "Møre og Romsdal"
          },
          "NO-07": {
            type: "County",
            name: "Vestfold"
          },
          "NO-06": {
            type: "County",
            name: "Buskerud"
          },
          "NO-05": {
            type: "County",
            name: "Oppland"
          },
          "NO-04": {
            type: "County",
            name: "Hedmark"
          },
          "NO-03": {
            type: "County",
            name: "Oslo"
          },
          "NO-02": {
            type: "County",
            name: "Akershus"
          },
          "NO-01": {
            type: "County",
            name: "Østfold"
          },
          "NO-17": {
            type: "County",
            name: "Nord-Trøndelag"
          },
          "NO-18": {
            type: "County",
            name: "Nordland"
          },
          "NO-14": {
            type: "County",
            name: "Sogn og Fjordane"
          },
          "NO-09": {
            type: "County",
            name: "Aust-Agder"
          },
          "NO-08": {
            type: "County",
            name: "Telemark"
          }
        }
      },
      NA: {
        name: "Namibia",
        sub: {
          "NA-HA": {
            type: "Region",
            name: "Hardap"
          },
          "NA-KH": {
            type: "Region",
            name: "Khomas"
          },
          "NA-KA": {
            type: "Region",
            name: "Karas"
          },
          "NA-ON": {
            type: "Region",
            name: "Oshana"
          },
          "NA-OH": {
            type: "Region",
            name: "Omaheke"
          },
          "NA-KE": {
            type: "Region",
            name: "Kavango East"
          },
          "NA-OT": {
            type: "Region",
            name: "Oshikoto"
          },
          "NA-CA": {
            type: "Region",
            name: "Zambezi"
          },
          "NA-OW": {
            type: "Region",
            name: "Ohangwena"
          },
          "NA-ER": {
            type: "Region",
            name: "Erongo"
          },
          "NA-OS": {
            type: "Region",
            name: "Omusati"
          },
          "NA-OD": {
            type: "Region",
            name: "Otjozondjupa"
          },
          "NA-KU": {
            type: "Region",
            name: "Kunene"
          },
          "NA-KW": {
            type: "Region",
            name: "Kavango West"
          }
        }
      },
      VU: {
        name: "Vanuatu",
        sub: {
          "VU-PAM": {
            type: "Province",
            name: "Pénama"
          },
          "VU-TOB": {
            type: "Province",
            name: "Torba"
          },
          "VU-MAP": {
            type: "Province",
            name: "Malampa"
          },
          "VU-SAM": {
            type: "Province",
            name: "Sanma"
          },
          "VU-TAE": {
            type: "Province",
            name: "Taféa"
          },
          "VU-SEE": {
            type: "Province",
            name: "Shéfa"
          }
        }
      },
      NC: {
        name: "New Caledonia",
        sub: {
          "NC-NC": {
            type: "Country",
            name: "New Caledonia"
          }
        }
      },
      NE: {
        name: "Niger",
        sub: {
          "NE-4": {
            type: "Region",
            name: "Maradi"
          },
          "NE-5": {
            type: "Region",
            name: "Tahoua"
          },
          "NE-6": {
            type: "Region",
            name: "Tillabéri"
          },
          "NE-7": {
            type: "Region",
            name: "Zinder"
          },
          "NE-1": {
            type: "Region",
            name: "Agadez"
          },
          "NE-2": {
            type: "Region",
            name: "Diffa"
          },
          "NE-3": {
            type: "Region",
            name: "Dosso"
          },
          "NE-8": {
            type: "Urban community",
            name: "Niamey"
          }
        }
      },
      NF: {
        name: "Norfolk Island",
        sub: {
          "NF-NF": {
            type: "Country",
            name: "Norfolk Island"
          }
        }
      },
      NG: {
        name: "Nigeria",
        sub: {
          "NG-PL": {
            type: "State",
            name: "Plateau"
          },
          "NG-RI": {
            type: "State",
            name: "Rivers"
          },
          "NG-JI": {
            type: "State",
            name: "Jigawa"
          },
          "NG-LA": {
            type: "State",
            name: "Lagos"
          },
          "NG-NA": {
            type: "State",
            name: "Nasarawa"
          },
          "NG-FC": {
            type: "Capital territory",
            name: "Abuja Federal Capital Territory"
          },
          "NG-NI": {
            type: "State",
            name: "Niger"
          },
          "NG-OS": {
            type: "State",
            name: "Osun"
          },
          "NG-BY": {
            type: "State",
            name: "Bayelsa"
          },
          "NG-DE": {
            type: "State",
            name: "Delta"
          },
          "NG-BA": {
            type: "State",
            name: "Bauchi"
          },
          "NG-BE": {
            type: "State",
            name: "Benue"
          },
          "NG-BO": {
            type: "State",
            name: "Borno"
          },
          "NG-YO": {
            type: "State",
            name: "Yobe"
          },
          "NG-AN": {
            type: "State",
            name: "Anambra"
          },
          "NG-KW": {
            type: "State",
            name: "Kwara"
          },
          "NG-KT": {
            type: "State",
            name: "Katsina"
          },
          "NG-OY": {
            type: "State",
            name: "Oyo"
          },
          "NG-OG": {
            type: "State",
            name: "Ogun"
          },
          "NG-KN": {
            type: "State",
            name: "Kano"
          },
          "NG-KO": {
            type: "State",
            name: "Kogi"
          },
          "NG-ON": {
            type: "State",
            name: "Ondo"
          },
          "NG-EN": {
            type: "State",
            name: "Enugu"
          },
          "NG-KD": {
            type: "State",
            name: "Kaduna"
          },
          "NG-KE": {
            type: "State",
            name: "Kebbi"
          },
          "NG-CR": {
            type: "State",
            name: "Cross River"
          },
          "NG-GO": {
            type: "State",
            name: "Gombe"
          },
          "NG-ZA": {
            type: "State",
            name: "Zamfara"
          },
          "NG-SO": {
            type: "State",
            name: "Sokoto"
          },
          "NG-IM": {
            type: "State",
            name: "Imo"
          },
          "NG-ED": {
            type: "State",
            name: "Edo"
          },
          "NG-AK": {
            type: "State",
            name: "Akwa Ibom"
          },
          "NG-EB": {
            type: "State",
            name: "Ebonyi"
          },
          "NG-TA": {
            type: "State",
            name: "Taraba"
          },
          "NG-AB": {
            type: "State",
            name: "Abia"
          },
          "NG-AD": {
            type: "State",
            name: "Adamawa"
          },
          "NG-EK": {
            type: "State",
            name: "Ekiti"
          }
        }
      },
      NZ: {
        name: "New Zealand",
        sub: {
          "NZ-NSN": {
            type: "Unitary authority",
            name: "Nelson City"
          },
          "NZ-S": {
            type: "Island",
            name: "South Island"
          },
          "NZ-STL": {
            type: "Regional council",
            name: "Southland"
          },
          "NZ-BOP": {
            type: "Regional council",
            name: "Bay of Plenty"
          },
          "NZ-HKB": {
            type: "Regional council",
            name: "Hawke's Bay"
          },
          "NZ-AUK": {
            type: "Unitary authority",
            name: "Auckland"
          },
          "NZ-TKI": {
            type: "Regional council",
            name: "Taranaki"
          },
          "NZ-MBH": {
            type: "Unitary authority",
            name: "Marlborough District"
          },
          "NZ-CIT": {
            type: "Special island authority",
            name: "Chatham Islands Territory"
          },
          "NZ-WKO": {
            type: "Regional council",
            name: "Waikato"
          },
          "NZ-TAS": {
            type: "Unitary authority",
            name: "Tasman District"
          },
          "NZ-WGN": {
            type: "Regional council",
            name: "Wellington"
          },
          "NZ-WTC": {
            type: "Regional council",
            name: "West Coast"
          },
          "NZ-OTA": {
            type: "Regional council",
            name: "Otago"
          },
          "NZ-NTL": {
            type: "Regional council",
            name: "Northland"
          },
          "NZ-MWT": {
            type: "Regional council",
            name: "Manawatu-Wanganui"
          },
          "NZ-N": {
            type: "Island",
            name: "North Island"
          },
          "NZ-GIS": {
            type: "Unitary authority",
            name: "Gisborne District"
          },
          "NZ-CAN": {
            type: "Regional council",
            name: "Canterbury"
          }
        }
      },
      NP: {
        name: "Nepal",
        sub: {
          "NP-KA": {
            type: "Zone",
            name: "Karnali"
          },
          "NP-BA": {
            type: "Zone",
            name: "Bagmati"
          },
          "NP-MA": {
            type: "Zone",
            name: "Mahakali"
          },
          "NP-KO": {
            type: "Zone",
            name: "Kosi"
          },
          "NP-DH": {
            type: "Zone",
            name: "Dhawalagiri"
          },
          "NP-BH": {
            type: "Zone",
            name: "Bheri"
          },
          "NP-JA": {
            type: "Zone",
            name: "Janakpur"
          },
          "NP-GA": {
            type: "Zone",
            name: "Gandaki"
          },
          "NP-NA": {
            type: "Zone",
            name: "Narayani"
          },
          "NP-ME": {
            type: "Zone",
            name: "Mechi"
          },
          "NP-RA": {
            type: "Zone",
            name: "Rapti"
          },
          "NP-5": {
            type: "Development region",
            name: "Far Western"
          },
          "NP-4": {
            type: "Development region",
            name: "Eastern"
          },
          "NP-SE": {
            type: "Zone",
            name: "Seti"
          },
          "NP-LU": {
            type: "Zone",
            name: "Lumbini"
          },
          "NP-1": {
            type: "Development region",
            name: "Central"
          },
          "NP-SA": {
            type: "Zone",
            name: "Sagarmatha"
          },
          "NP-3": {
            type: "Development region",
            name: "Pashchimanchal"
          },
          "NP-2": {
            type: "Development region",
            name: "Madhya Pashchimanchal"
          }
        }
      },
      NR: {
        name: "Nauru",
        sub: {
          "NR-08": {
            type: "District",
            name: "Denigomodu"
          },
          "NR-09": {
            type: "District",
            name: "Ewa"
          },
          "NR-14": {
            type: "District",
            name: "Yaren"
          },
          "NR-01": {
            type: "District",
            name: "Aiwo"
          },
          "NR-02": {
            type: "District",
            name: "Anabar"
          },
          "NR-03": {
            type: "District",
            name: "Anetan"
          },
          "NR-04": {
            type: "District",
            name: "Anibare"
          },
          "NR-05": {
            type: "District",
            name: "Baiti"
          },
          "NR-06": {
            type: "District",
            name: "Boe"
          },
          "NR-07": {
            type: "District",
            name: "Buada"
          },
          "NR-11": {
            type: "District",
            name: "Meneng"
          },
          "NR-12": {
            type: "District",
            name: "Nibok"
          },
          "NR-13": {
            type: "District",
            name: "Uaboe"
          },
          "NR-10": {
            type: "District",
            name: "Ijuw"
          }
        }
      },
      NU: {
        name: "Niue",
        sub: {
          "NU-NU": {
            type: "Country",
            name: "Niue"
          }
        }
      },
      CK: {
        name: "Cook Islands",
        sub: {
          "CK-CK": {
            type: "Country",
            name: "Cook Islands"
          }
        }
      },
      CI: {
        name: "Côte D'Ivoire",
        sub: {
          "CI-11": {
            type: "Region",
            name: "Nzi-Comoé"
          },
          "CI-13": {
            type: "Region",
            name: "Sud-Comoé"
          },
          "CI-16": {
            type: "Region",
            name: "Agnébi"
          },
          "CI-14": {
            type: "Region",
            name: "Worodougou"
          },
          "CI-10": {
            type: "Region",
            name: "Denguélé"
          },
          "CI-15": {
            type: "Region",
            name: "Sud-Bandama"
          },
          "CI-17": {
            type: "Region",
            name: "Bafing"
          },
          "CI-01": {
            type: "Region",
            name: "Lagunes"
          },
          "CI-02": {
            type: "Region",
            name: "Haut-Sassandra"
          },
          "CI-03": {
            type: "Region",
            name: "Savanes"
          },
          "CI-04": {
            type: "Region",
            name: "Vallée du Bandama"
          },
          "CI-05": {
            type: "Region",
            name: "Moyen-Comoé"
          },
          "CI-06": {
            type: "Region",
            name: "18 Montagnes"
          },
          "CI-07": {
            type: "Region",
            name: "Lacs"
          },
          "CI-08": {
            type: "Region",
            name: "Zanzan"
          },
          "CI-09": {
            type: "Region",
            name: "Bas-Sassandra"
          },
          "CI-18": {
            type: "Region",
            name: "Fromager"
          },
          "CI-19": {
            type: "Region",
            name: "Moyen-Cavally"
          },
          "CI-12": {
            type: "Region",
            name: "Marahoué"
          }
        }
      },
      CH: {
        name: "Switzerland",
        sub: {
          "CH-SO": {
            type: "Canton",
            name: "Solothurn"
          },
          "CH-LU": {
            type: "Canton",
            name: "Luzern"
          },
          "CH-SH": {
            type: "Canton",
            name: "Schaffhausen"
          },
          "CH-SG": {
            type: "Canton",
            name: "Sankt Gallen"
          },
          "CH-UR": {
            type: "Canton",
            name: "Uri"
          },
          "CH-NE": {
            type: "Canton",
            name: "Neuchâtel"
          },
          "CH-BS": {
            type: "Canton",
            name: "Basel-Stadt"
          },
          "CH-JU": {
            type: "Canton",
            name: "Jura"
          },
          "CH-BL": {
            type: "Canton",
            name: "Basel-Landschaft"
          },
          "CH-SZ": {
            type: "Canton",
            name: "Schwyz"
          },
          "CH-BE": {
            type: "Canton",
            name: "Bern"
          },
          "CH-NW": {
            type: "Canton",
            name: "Nidwalden"
          },
          "CH-ZG": {
            type: "Canton",
            name: "Zug"
          },
          "CH-FR": {
            type: "Canton",
            name: "Freiburg"
          },
          "CH-ZH": {
            type: "Canton",
            name: "Zürich"
          },
          "CH-VS": {
            type: "Canton",
            name: "Valais"
          },
          "CH-VD": {
            type: "Canton",
            name: "Vaud"
          },
          "CH-TI": {
            type: "Canton",
            name: "Ticino"
          },
          "CH-TG": {
            type: "Canton",
            name: "Thurgau"
          },
          "CH-OW": {
            type: "Canton",
            name: "Obwalden"
          },
          "CH-AG": {
            type: "Canton",
            name: "Aargau"
          },
          "CH-GE": {
            type: "Canton",
            name: "Genève"
          },
          "CH-AI": {
            type: "Canton",
            name: "Appenzell Innerrhoden"
          },
          "CH-GL": {
            type: "Canton",
            name: "Glarus"
          },
          "CH-GR": {
            type: "Canton",
            name: "Graubünden"
          },
          "CH-AR": {
            type: "Canton",
            name: "Appenzell Ausserrhoden"
          }
        }
      },
      CO: {
        name: "Colombia",
        sub: {
          "CO-CUN": {
            type: "Department",
            name: "Cundinamarca"
          },
          "CO-BOY": {
            type: "Department",
            name: "Boyacá"
          },
          "CO-COR": {
            type: "Department",
            name: "Córdoba"
          },
          "CO-HUI": {
            type: "Department",
            name: "Huila"
          },
          "CO-VAU": {
            type: "Department",
            name: "Vaupés"
          },
          "CO-RIS": {
            type: "Department",
            name: "Risaralda"
          },
          "CO-NSA": {
            type: "Department",
            name: "Norte de Santander"
          },
          "CO-VID": {
            type: "Department",
            name: "Vichada"
          },
          "CO-BOL": {
            type: "Department",
            name: "Bolívar"
          },
          "CO-VAC": {
            type: "Department",
            name: "Valle del Cauca"
          },
          "CO-GUV": {
            type: "Department",
            name: "Guaviare"
          },
          "CO-CAU": {
            type: "Department",
            name: "Cauca"
          },
          "CO-CAS": {
            type: "Department",
            name: "Casanare"
          },
          "CO-CAQ": {
            type: "Department",
            name: "Caquetá"
          },
          "CO-CES": {
            type: "Department",
            name: "Cesar"
          },
          "CO-SAN": {
            type: "Department",
            name: "Santander"
          },
          "CO-ATL": {
            type: "Department",
            name: "Atlántico"
          },
          "CO-AMA": {
            type: "Department",
            name: "Amazonas"
          },
          "CO-MET": {
            type: "Department",
            name: "Meta"
          },
          "CO-MAG": {
            type: "Department",
            name: "Magdalena"
          },
          "CO-ARA": {
            type: "Department",
            name: "Arauca"
          },
          "CO-GUA": {
            type: "Department",
            name: "Guainía"
          },
          "CO-LAG": {
            type: "Department",
            name: "La Guajira"
          },
          "CO-CAL": {
            type: "Department",
            name: "Caldas"
          },
          "CO-QUI": {
            type: "Department",
            name: "Quindío"
          },
          "CO-SAP": {
            type: "Department",
            name: "San Andrés, Providencia y Santa Catalina"
          },
          "CO-TOL": {
            type: "Department",
            name: "Tolima"
          },
          "CO-SUC": {
            type: "Department",
            name: "Sucre"
          },
          "CO-PUT": {
            type: "Department",
            name: "Putumayo"
          },
          "CO-NAR": {
            type: "Department",
            name: "Nariño"
          },
          "CO-CHO": {
            type: "Department",
            name: "Chocó"
          },
          "CO-DC": {
            type: "Capital district",
            name: "Distrito Capital de Bogotá"
          },
          "CO-ANT": {
            type: "Department",
            name: "Antioquia"
          }
        }
      },
      CN: {
        name: "China",
        sub: {
          "CN-54": {
            type: "Autonomous region",
            name: "Xizang"
          },
          "CN-34": {
            type: "Province",
            name: "Anhui"
          },
          "CN-35": {
            type: "Province",
            name: "Fujian"
          },
          "CN-36": {
            type: "Province",
            name: "Jiangxi"
          },
          "CN-37": {
            type: "Province",
            name: "Shandong"
          },
          "CN-31": {
            type: "Municipality",
            name: "Shanghai"
          },
          "CN-32": {
            type: "Province",
            name: "Jiangsu"
          },
          "CN-33": {
            type: "Province",
            name: "Zhejiang"
          },
          "CN-71": {
            type: "Province",
            name: "Taiwan"
          },
          "CN-91": {
            type: "Special administrative region",
            name: "Hong Kong"
          },
          "CN-14": {
            type: "Province",
            name: "Shanxi"
          },
          "CN-15": {
            type: "Autonomous region",
            name: "Nei Mongol"
          },
          "CN-12": {
            type: "Municipality",
            name: "Tianjin"
          },
          "CN-13": {
            type: "Province",
            name: "Hebei"
          },
          "CN-11": {
            type: "Municipality",
            name: "Beijing"
          },
          "CN-52": {
            type: "Province",
            name: "Guizhou"
          },
          "CN-92": {
            type: "Special administrative region",
            name: "Aomen"
          },
          "CN-53": {
            type: "Province",
            name: "Yunnan"
          },
          "CN-50": {
            type: "Municipality",
            name: "Chongqing"
          },
          "CN-51": {
            type: "Province",
            name: "Sichuan"
          },
          "CN-41": {
            type: "Province",
            name: "Henan"
          },
          "CN-43": {
            type: "Province",
            name: "Hunan"
          },
          "CN-42": {
            type: "Province",
            name: "Hubei"
          },
          "CN-45": {
            type: "Autonomous region",
            name: "Guangxi"
          },
          "CN-44": {
            type: "Province",
            name: "Guangdong"
          },
          "CN-46": {
            type: "Province",
            name: "Hainan"
          },
          "CN-65": {
            type: "Autonomous region",
            name: "Xinjiang"
          },
          "CN-64": {
            type: "Autonomous region",
            name: "Ningxia"
          },
          "CN-63": {
            type: "Province",
            name: "Qinghai"
          },
          "CN-62": {
            type: "Province",
            name: "Gansu"
          },
          "CN-61": {
            type: "Province",
            name: "Shaanxi"
          },
          "CN-23": {
            type: "Province",
            name: "Heilongjiang"
          },
          "CN-22": {
            type: "Province",
            name: "Jilin"
          },
          "CN-21": {
            type: "Province",
            name: "Liaoning"
          }
        }
      },
      CM: {
        name: "Cameroon",
        sub: {
          "CM-CE": {
            type: "Region",
            name: "Centre"
          },
          "CM-SU": {
            type: "Region",
            name: "South"
          },
          "CM-SW": {
            type: "Region",
            name: "South-West"
          },
          "CM-ES": {
            type: "Region",
            name: "East"
          },
          "CM-AD": {
            type: "Region",
            name: "Adamaoua"
          },
          "CM-NO": {
            type: "Region",
            name: "Nord"
          },
          "CM-OU": {
            type: "Region",
            name: "Ouest"
          },
          "CM-NW": {
            type: "Region",
            name: "Nord-Ouest"
          },
          "CM-EN": {
            type: "Region",
            name: "Extrême-Nord"
          },
          "CM-LT": {
            type: "Region",
            name: "Littoral"
          }
        }
      },
      CL: {
        name: "Chile",
        sub: {
          "CL-AP": {
            type: "Region",
            name: "Arica y Parinacota"
          },
          "CL-BI": {
            type: "Region",
            name: "Biobío"
          },
          "CL-AR": {
            type: "Region",
            name: "Araucanía"
          },
          "CL-AT": {
            type: "Region",
            name: "Atacama"
          },
          "CL-CO": {
            type: "Region",
            name: "Coquimbo"
          },
          "CL-VS": {
            type: "Region",
            name: "Valparaíso"
          },
          "CL-ML": {
            type: "Region",
            name: "Maule"
          },
          "CL-LR": {
            type: "Region",
            name: "Los Ríos"
          },
          "CL-TA": {
            type: "Region",
            name: "Tarapacá"
          },
          "CL-LI": {
            type: "Region",
            name: "Libertador General Bernardo O'Higgins"
          },
          "CL-RM": {
            type: "Region",
            name: "Región Metropolitana de Santiago"
          },
          "CL-LL": {
            type: "Region",
            name: "Los Lagos"
          },
          "CL-AI": {
            type: "Region",
            name: "Aysén"
          },
          "CL-AN": {
            type: "Region",
            name: "Antofagasta"
          },
          "CL-MA": {
            type: "Region",
            name: "Magallanes"
          }
        }
      },
      CC: {
        name: "Cocos  Islands",
        sub: {
          "CC-CC": {
            type: "Country",
            name: "Cocos  Islands"
          }
        }
      },
      CA: {
        name: "Canada",
        sub: {
          "CA-NT": {
            type: "Territory",
            name: "Northwest Territories"
          },
          "CA-NU": {
            type: "Territory",
            name: "Nunavut"
          },
          "CA-NS": {
            type: "Province",
            name: "Nova Scotia"
          },
          "CA-MB": {
            type: "Province",
            name: "Manitoba"
          },
          "CA-SK": {
            type: "Province",
            name: "Saskatchewan"
          },
          "CA-QC": {
            type: "Province",
            name: "Quebec"
          },
          "CA-PE": {
            type: "Province",
            name: "Prince Edward Island"
          },
          "CA-BC": {
            type: "Province",
            name: "British Columbia"
          },
          "CA-YT": {
            type: "Territory",
            name: "Yukon"
          },
          "CA-NB": {
            type: "Province",
            name: "New Brunswick"
          },
          "CA-NL": {
            type: "Province",
            name: "Newfoundland and Labrador"
          },
          "CA-ON": {
            type: "Province",
            name: "Ontario"
          },
          "CA-AB": {
            type: "Province",
            name: "Alberta"
          }
        }
      },
      CG: {
        name: "Congo",
        sub: {
          "CG-2": {
            type: "Department",
            name: "Lékoumou"
          },
          "CG-5": {
            type: "Department",
            name: "Kouilou"
          },
          "CG-7": {
            type: "Department",
            name: "Likouala"
          },
          "CG-9": {
            type: "Department",
            name: "Niari"
          },
          "CG-8": {
            type: "Department",
            name: "Cuvette"
          },
          "CG-BZV": {
            type: "Department",
            name: "Brazzaville"
          },
          "CG-11": {
            type: "Department",
            name: "Bouenza"
          },
          "CG-13": {
            type: "Department",
            name: "Sangha"
          },
          "CG-12": {
            type: "Department",
            name: "Pool"
          },
          "CG-15": {
            type: "Department",
            name: "Cuvette-Ouest"
          },
          "CG-14": {
            type: "Department",
            name: "Plateaux"
          },
          "CG-16": {
            type: "Department",
            name: "Pointe-Noire"
          }
        }
      },
      CF: {
        name: "Central African Republic",
        sub: {
          "CF-MB": {
            type: "Prefecture",
            name: "Mbomou"
          },
          "CF-BB": {
            type: "Prefecture",
            name: "Bamïngï-Bangoran"
          },
          "CF-NM": {
            type: "Prefecture",
            name: "Nana-Mambéré"
          },
          "CF-BGF": {
            type: "Commune",
            name: "Bangî"
          },
          "CF-AC": {
            type: "Prefecture",
            name: "Ouham"
          },
          "CF-KB": {
            type: "Economic prefecture",
            name: "Gïrïbïngï"
          },
          "CF-BK": {
            type: "Prefecture",
            name: "Basse-Kotto"
          },
          "CF-LB": {
            type: "Prefecture",
            name: "Lobaye"
          },
          "CF-VK": {
            type: "Prefecture",
            name: "Vakaga"
          },
          "CF-HS": {
            type: "Prefecture",
            name: "Haute-Sangha / Mambéré-Kadéï"
          },
          "CF-HM": {
            type: "Prefecture",
            name: "Haut-Mbomou"
          },
          "CF-UK": {
            type: "Prefecture",
            name: "Ouaka"
          },
          "CF-MP": {
            type: "Prefecture",
            name: "Ömbëlä-Pökö"
          },
          "CF-KG": {
            type: "Prefecture",
            name: "Kemö-Gïrïbïngï"
          },
          "CF-HK": {
            type: "Prefecture",
            name: "Haute-Kotto"
          },
          "CF-OP": {
            type: "Prefecture",
            name: "Ouham-Pendé"
          },
          "CF-SE": {
            type: "Economic prefecture",
            name: "Sangä"
          }
        }
      },
      CD: {
        name: "Congo, The Democratic Republic Of The",
        sub: {
          "CD-OR": {
            type: "Province",
            name: "Orientale"
          },
          "CD-EQ": {
            type: "Province",
            name: "Équateur"
          },
          "CD-SK": {
            type: "Province",
            name: "Sud-Kivu"
          },
          "CD-KW": {
            type: "Province",
            name: "Kasai-Occidental"
          },
          "CD-BN": {
            type: "Province",
            name: "Bandundu"
          },
          "CD-BC": {
            type: "Province",
            name: "Bas-Congo"
          },
          "CD-KN": {
            type: "City",
            name: "Kinshasa"
          },
          "CD-KA": {
            type: "Province",
            name: "Katanga"
          },
          "CD-MA": {
            type: "Province",
            name: "Maniema"
          },
          "CD-NK": {
            type: "Province",
            name: "Nord-Kivu"
          },
          "CD-KE": {
            type: "Province",
            name: "Kasai-Oriental"
          }
        }
      },
      CZ: {
        name: "Czech Republic",
        sub: {
          "CZ-10C": {
            type: "District",
            name: "Praha 12"
          },
          "CZ-10B": {
            type: "District",
            name: "Praha 11"
          },
          "CZ-10A": {
            type: "District",
            name: "Praha 10"
          },
          "CZ-10F": {
            type: "District",
            name: "Praha 15"
          },
          "CZ-10E": {
            type: "District",
            name: "Praha 14"
          },
          "CZ-10D": {
            type: "District",
            name: "Praha 13"
          },
          "CZ-511": {
            type: "District",
            name: "Česká Lípa"
          },
          "CZ-512": {
            type: "District",
            name: "Jablonec nad Nisou"
          },
          "CZ-513": {
            type: "District",
            name: "Liberec"
          },
          "CZ-514": {
            type: "District",
            name: "Semily"
          },
          "CZ-JC": {
            type: "Region",
            name: "Jihočeský kraj"
          },
          "CZ-JM": {
            type: "Region",
            name: "Jihomoravský kraj"
          },
          "CZ-103": {
            type: "District",
            name: "Praha 3"
          },
          "CZ-102": {
            type: "District",
            name: "Praha 2"
          },
          "CZ-101": {
            type: "District",
            name: "Praha 1"
          },
          "CZ-107": {
            type: "District",
            name: "Praha 7"
          },
          "CZ-106": {
            type: "District",
            name: "Praha 6"
          },
          "CZ-105": {
            type: "District",
            name: "Praha 5"
          },
          "CZ-104": {
            type: "District",
            name: "Praha 4"
          },
          "CZ-613": {
            type: "District",
            name: "Pelhřimov"
          },
          "CZ-612": {
            type: "District",
            name: "Jihlava"
          },
          "CZ-611": {
            type: "District",
            name: "Havlíčkův Brod"
          },
          "CZ-108": {
            type: "District",
            name: "Praha 8"
          },
          "CZ-615": {
            type: "District",
            name: "Žd'ár nad Sázavou"
          },
          "CZ-614": {
            type: "District",
            name: "Třebíč"
          },
          "CZ-LI": {
            type: "Region",
            name: "Liberecký kraj"
          },
          "CZ-US": {
            type: "Region",
            name: "Ústecký kraj"
          },
          "CZ-714": {
            type: "District",
            name: "Přerov"
          },
          "CZ-715": {
            type: "District",
            name: "Šumperk"
          },
          "CZ-712": {
            type: "District",
            name: "Olomouc"
          },
          "CZ-713": {
            type: "District",
            name: "Prostĕjov"
          },
          "CZ-711": {
            type: "District",
            name: "Jeseník"
          },
          "CZ-ST": {
            type: "Region",
            name: "Středočeský kraj"
          },
          "CZ-109": {
            type: "District",
            name: "Praha 9"
          },
          "CZ-KA": {
            type: "Region",
            name: "Karlovarský kraj"
          },
          "CZ-ZL": {
            type: "Region",
            name: "Zlínský kraj"
          },
          "CZ-KR": {
            type: "Region",
            name: "Královéhradecký kraj"
          },
          "CZ-802": {
            type: "District",
            name: "Frýdek Místek"
          },
          "CZ-803": {
            type: "District",
            name: "Karviná"
          },
          "CZ-801": {
            type: "District",
            name: "Bruntál"
          },
          "CZ-806": {
            type: "District",
            name: "Ostrava město"
          },
          "CZ-804": {
            type: "District",
            name: "Nový Jičín"
          },
          "CZ-805": {
            type: "District",
            name: "Opava"
          },
          "CZ-411": {
            type: "District",
            name: "Cheb"
          },
          "CZ-413": {
            type: "District",
            name: "Sokolov"
          },
          "CZ-412": {
            type: "District",
            name: "Karlovy Vary"
          },
          "CZ-312": {
            type: "District",
            name: "Český Krumlov"
          },
          "CZ-313": {
            type: "District",
            name: "Jindřichův Hradec"
          },
          "CZ-311": {
            type: "District",
            name: "České Budějovice"
          },
          "CZ-316": {
            type: "District",
            name: "Strakonice"
          },
          "CZ-317": {
            type: "District",
            name: "Tábor"
          },
          "CZ-314": {
            type: "District",
            name: "Písek"
          },
          "CZ-315": {
            type: "District",
            name: "Prachatice"
          },
          "CZ-534": {
            type: "District",
            name: "Ústí nad Orlicí"
          },
          "CZ-724": {
            type: "District",
            name: "Zlín"
          },
          "CZ-532": {
            type: "District",
            name: "Pardubice"
          },
          "CZ-422": {
            type: "District",
            name: "Chomutov"
          },
          "CZ-423": {
            type: "District",
            name: "Litoměřice"
          },
          "CZ-424": {
            type: "District",
            name: "Louny"
          },
          "CZ-425": {
            type: "District",
            name: "Most"
          },
          "CZ-426": {
            type: "District",
            name: "Teplice"
          },
          "CZ-722": {
            type: "District",
            name: "Uherské Hradištĕ"
          },
          "CZ-PL": {
            type: "Region",
            name: "Plzeňský kraj"
          },
          "CZ-MO": {
            type: "Region",
            name: "Moravskoslezský kraj"
          },
          "CZ-VY": {
            type: "Region",
            name: "Vysočina"
          },
          "CZ-PA": {
            type: "Region",
            name: "Pardubický kraj"
          },
          "CZ-20A": {
            type: "District",
            name: "Praha-západ"
          },
          "CZ-20B": {
            type: "District",
            name: "Příbram"
          },
          "CZ-20C": {
            type: "District",
            name: "Rakovník"
          },
          "CZ-723": {
            type: "District",
            name: "Vsetín"
          },
          "CZ-533": {
            type: "District",
            name: "Svitavy"
          },
          "CZ-721": {
            type: "District",
            name: "Kromĕříž"
          },
          "CZ-531": {
            type: "District",
            name: "Chrudim"
          },
          "CZ-PR": {
            type: "Region",
            name: "Praha, hlavní mešto"
          },
          "CZ-622": {
            type: "District",
            name: "Brno-město"
          },
          "CZ-623": {
            type: "District",
            name: "Brno-venkov"
          },
          "CZ-621": {
            type: "District",
            name: "Blansko"
          },
          "CZ-626": {
            type: "District",
            name: "Vyškov"
          },
          "CZ-627": {
            type: "District",
            name: "Znojmo"
          },
          "CZ-624": {
            type: "District",
            name: "Břeclav"
          },
          "CZ-625": {
            type: "District",
            name: "Hodonín"
          },
          "CZ-421": {
            type: "District",
            name: "Děčín"
          },
          "CZ-525": {
            type: "District",
            name: "Trutnov"
          },
          "CZ-524": {
            type: "District",
            name: "Rychnov nad Kněžnou"
          },
          "CZ-521": {
            type: "District",
            name: "Hradec Králové"
          },
          "CZ-523": {
            type: "District",
            name: "Náchod"
          },
          "CZ-522": {
            type: "District",
            name: "Jičín"
          },
          "CZ-OL": {
            type: "Region",
            name: "Olomoucký kraj"
          },
          "CZ-427": {
            type: "District",
            name: "Ústí nad Labem"
          },
          "CZ-323": {
            type: "District",
            name: "Plzeň-město"
          },
          "CZ-322": {
            type: "District",
            name: "Klatovy"
          },
          "CZ-321": {
            type: "District",
            name: "Domažlice"
          },
          "CZ-327": {
            type: "District",
            name: "Tachov"
          },
          "CZ-326": {
            type: "District",
            name: "Rokycany"
          },
          "CZ-325": {
            type: "District",
            name: "Plzeň-sever"
          },
          "CZ-324": {
            type: "District",
            name: "Plzeň-jih"
          },
          "CZ-208": {
            type: "District",
            name: "Nymburk"
          },
          "CZ-209": {
            type: "District",
            name: "Praha-východ"
          },
          "CZ-201": {
            type: "District",
            name: "Benešov"
          },
          "CZ-202": {
            type: "District",
            name: "Beroun"
          },
          "CZ-203": {
            type: "District",
            name: "Kladno"
          },
          "CZ-204": {
            type: "District",
            name: "Kolín"
          },
          "CZ-205": {
            type: "District",
            name: "Kutná Hora"
          },
          "CZ-206": {
            type: "District",
            name: "Mělník"
          },
          "CZ-207": {
            type: "District",
            name: "Mladá Boleslav"
          }
        }
      },
      CY: {
        name: "Cyprus",
        sub: {
          "CY-01": {
            type: "District",
            name: "Lefkoşa"
          },
          "CY-02": {
            type: "District",
            name: "Lemesos"
          },
          "CY-03": {
            type: "District",
            name: "Larnaka"
          },
          "CY-04": {
            type: "District",
            name: "Ammochostos"
          },
          "CY-05": {
            type: "District",
            name: "Baf"
          },
          "CY-06": {
            type: "District",
            name: "Girne"
          }
        }
      },
      CX: {
        name: "Christmas Island",
        sub: {
          "CX-CX": {
            type: "Country",
            name: "Christmas Island"
          }
        }
      },
      CR: {
        name: "Costa Rica",
        sub: {
          "CR-P": {
            type: "Province",
            name: "Puntarenas"
          },
          "CR-A": {
            type: "Province",
            name: "Alajuela"
          },
          "CR-C": {
            type: "Province",
            name: "Cartago"
          },
          "CR-G": {
            type: "Province",
            name: "Guanacaste"
          },
          "CR-H": {
            type: "Province",
            name: "Heredia"
          },
          "CR-SJ": {
            type: "Province",
            name: "San José"
          },
          "CR-L": {
            type: "Province",
            name: "Limón"
          }
        }
      },
      CW: {
        name: "Curaçao",
        sub: {
          "CW-CW": {
            type: "Country",
            name: "Curaçao"
          }
        }
      },
      CV: {
        name: "Cape Verde",
        sub: {
          "CV-SL": {
            type: "Municipality",
            name: "Sal"
          },
          "CV-SM": {
            type: "Municipality",
            name: "São Miguel"
          },
          "CV-SO": {
            type: "Municipality",
            name: "São Lourenço dos Órgãos"
          },
          "CV-S": {
            type: "Geographical region",
            name: "Ilhas de Sotavento"
          },
          "CV-SD": {
            type: "Municipality",
            name: "São Domingos"
          },
          "CV-SF": {
            type: "Municipality",
            name: "São Filipe"
          },
          "CV-B": {
            type: "Geographical region",
            name: "Ilhas de Barlavento"
          },
          "CV-RG": {
            type: "Municipality",
            name: "Ribeira Grande"
          },
          "CV-SV": {
            type: "Municipality",
            name: "São Vicente"
          },
          "CV-SS": {
            type: "Municipality",
            name: "São Salvador do Mundo"
          },
          "CV-CR": {
            type: "Municipality",
            name: "Santa Cruz"
          },
          "CV-CF": {
            type: "Municipality",
            name: "Santa Catarina do Fogo"
          },
          "CV-CA": {
            type: "Municipality",
            name: "Santa Catarina"
          },
          "CV-RB": {
            type: "Municipality",
            name: "Ribeira Brava"
          },
          "CV-PA": {
            type: "Municipality",
            name: "Paul"
          },
          "CV-TA": {
            type: "Municipality",
            name: "Tarrafal"
          },
          "CV-PN": {
            type: "Municipality",
            name: "Porto Novo"
          },
          "CV-RS": {
            type: "Municipality",
            name: "Ribeira Grande de Santiago"
          },
          "CV-MA": {
            type: "Municipality",
            name: "Maio"
          },
          "CV-PR": {
            type: "Municipality",
            name: "Praia"
          },
          "CV-TS": {
            type: "Municipality",
            name: "Tarrafal de São Nicolau"
          },
          "CV-MO": {
            type: "Municipality",
            name: "Mosteiros"
          },
          "CV-BR": {
            type: "Municipality",
            name: "Brava"
          },
          "CV-BV": {
            type: "Municipality",
            name: "Boa Vista"
          }
        }
      },
      CU: {
        name: "Cuba",
        sub: {
          "CU-04": {
            type: "Province",
            name: "Matanzas"
          },
          "CU-05": {
            type: "Province",
            name: "Villa Clara"
          },
          "CU-06": {
            type: "Province",
            name: "Cienfuegos"
          },
          "CU-07": {
            type: "Province",
            name: "Sancti Spíritus"
          },
          "CU-01": {
            type: "Province",
            name: "Pinar del Río"
          },
          "CU-03": {
            type: "Province",
            name: "La Habana"
          },
          "CU-99": {
            type: "Special municipality",
            name: "Isla de la Juventud"
          },
          "CU-08": {
            type: "Province",
            name: "Ciego de Ávila"
          },
          "CU-09": {
            type: "Province",
            name: "Camagüey"
          },
          "CU-13": {
            type: "Province",
            name: "Santiago de Cuba"
          },
          "CU-12": {
            type: "Province",
            name: "Granma"
          },
          "CU-11": {
            type: "Province",
            name: "Holguín"
          },
          "CU-10": {
            type: "Province",
            name: "Las Tunas"
          },
          "CU-16": {
            type: "Province",
            name: "Mayabeque"
          },
          "CU-15": {
            type: "Province",
            name: "Artemisa"
          },
          "CU-14": {
            type: "Province",
            name: "Guantánamo"
          }
        }
      },
      SZ: {
        name: "Swaziland",
        sub: {
          "SZ-LU": {
            type: "District",
            name: "Lubombo"
          },
          "SZ-HH": {
            type: "District",
            name: "Hhohho"
          },
          "SZ-SH": {
            type: "District",
            name: "Shiselweni"
          },
          "SZ-MA": {
            type: "District",
            name: "Manzini"
          }
        }
      },
      SY: {
        name: "Syria",
        sub: {
          "SY-HI": {
            type: "Province",
            name: "Ḩimş"
          },
          "SY-HL": {
            type: "Province",
            name: "Ḩalab"
          },
          "SY-DI": {
            type: "Province",
            name: "Dimashq"
          },
          "SY-HA": {
            type: "Province",
            name: "Al Ḩasakah"
          },
          "SY-ID": {
            type: "Province",
            name: "Idlib"
          },
          "SY-SU": {
            type: "Province",
            name: "As Suwaydā'"
          },
          "SY-TA": {
            type: "Province",
            name: "Ţarţūs"
          },
          "SY-QU": {
            type: "Province",
            name: "Al Qunayţirah"
          },
          "SY-DY": {
            type: "Province",
            name: "Dayr az Zawr"
          },
          "SY-RA": {
            type: "Province",
            name: "Ar Raqqah"
          },
          "SY-LA": {
            type: "Province",
            name: "Al Lādhiqīyah"
          },
          "SY-RD": {
            type: "Province",
            name: "Rīf Dimashq"
          },
          "SY-DR": {
            type: "Province",
            name: "Dar'ā"
          },
          "SY-HM": {
            type: "Province",
            name: "Ḩamāh"
          }
        }
      },
      SX: {
        name: "St. Maarten",
        sub: {
          "SX-SX": {
            type: "Country",
            name: "St. Maarten"
          }
        }
      },
      KG: {
        name: "Kyrgyzstan",
        sub: {
          "KG-Y": {
            type: "Region",
            name: "Issyk-Kul'skaja oblast'"
          },
          "KG-GO": {
            type: "City",
            name: "Gorod Oš"
          },
          "KG-GB": {
            type: "City",
            name: "Bishkek"
          },
          "KG-T": {
            type: "Region",
            name: "Talas"
          },
          "KG-J": {
            type: "Region",
            name: "Džalal-Abadskaja oblast'"
          },
          "KG-O": {
            type: "Region",
            name: "Osh"
          },
          "KG-N": {
            type: "Region",
            name: "Naryn"
          },
          "KG-C": {
            type: "Region",
            name: "Chü"
          },
          "KG-B": {
            type: "Region",
            name: "Batken"
          }
        }
      },
      KE: {
        name: "Kenya",
        sub: {
          "KE-32": {
            type: "County",
            name: "Nandi"
          },
          "KE-38": {
            type: "County",
            name: "Siaya"
          },
          "KE-40": {
            type: "County",
            name: "Tana River"
          },
          "KE-41": {
            type: "County",
            name: "Tharaka-Nithi"
          },
          "KE-42": {
            type: "County",
            name: "Trans Nzoia"
          },
          "KE-43": {
            type: "County",
            name: "Turkana"
          },
          "KE-44": {
            type: "County",
            name: "Uasin Gishu"
          },
          "KE-28": {
            type: "County",
            name: "Mombasa"
          },
          "KE-46": {
            type: "County",
            name: "Wajir"
          },
          "KE-47": {
            type: "County",
            name: "West Pokot"
          },
          "KE-29": {
            type: "County",
            name: "Murang'a"
          },
          "KE-26": {
            type: "County",
            name: "Meru"
          },
          "KE-27": {
            type: "County",
            name: "Migori"
          },
          "KE-24": {
            type: "County",
            name: "Mandera"
          },
          "KE-25": {
            type: "County",
            name: "Marsabit"
          },
          "KE-08": {
            type: "County",
            name: "Homa Bay"
          },
          "KE-09": {
            type: "County",
            name: "Isiolo"
          },
          "KE-20": {
            type: "County",
            name: "Laikipia"
          },
          "KE-21": {
            type: "County",
            name: "Lamu"
          },
          "KE-04": {
            type: "County",
            name: "Busia"
          },
          "KE-05": {
            type: "County",
            name: "Elgeyo/Marakwet"
          },
          "KE-06": {
            type: "County",
            name: "Embu"
          },
          "KE-07": {
            type: "County",
            name: "Garissa"
          },
          "KE-01": {
            type: "County",
            name: "Baringo"
          },
          "KE-02": {
            type: "County",
            name: "Bomet"
          },
          "KE-03": {
            type: "County",
            name: "Bungoma"
          },
          "KE-31": {
            type: "County",
            name: "Nakuru"
          },
          "KE-35": {
            type: "County",
            name: "Nyandarua"
          },
          "KE-34": {
            type: "County",
            name: "Nyamira"
          },
          "KE-37": {
            type: "County",
            name: "Samburu"
          },
          "KE-30": {
            type: "County",
            name: "Nairobi City"
          },
          "KE-36": {
            type: "County",
            name: "Nyeri"
          },
          "KE-22": {
            type: "County",
            name: "Machakos"
          },
          "KE-23": {
            type: "County",
            name: "Makueni"
          },
          "KE-13": {
            type: "County",
            name: "Kiambu"
          },
          "KE-12": {
            type: "County",
            name: "Kericho"
          },
          "KE-11": {
            type: "County",
            name: "Kakamega"
          },
          "KE-10": {
            type: "County",
            name: "Kajiado"
          },
          "KE-17": {
            type: "County",
            name: "Kisumu"
          },
          "KE-16": {
            type: "County",
            name: "Kisii"
          },
          "KE-15": {
            type: "County",
            name: "Kirinyaga"
          },
          "KE-14": {
            type: "County",
            name: "Kilifi"
          },
          "KE-19": {
            type: "County",
            name: "Kwale"
          },
          "KE-18": {
            type: "County",
            name: "Kitui"
          },
          "KE-39": {
            type: "County",
            name: "Taita/Taveta"
          },
          "KE-33": {
            type: "County",
            name: "Narok"
          },
          "KE-45": {
            type: "County",
            name: "Vihiga"
          }
        }
      },
      SS: {
        name: "South Sudan",
        sub: {
          "SS-BW": {
            type: "State",
            name: "Western Bahr el Ghazal"
          },
          "SS-EW": {
            type: "State",
            name: "Western Equatoria"
          },
          "SS-LK": {
            type: "State",
            name: "Lakes"
          },
          "SS-EC": {
            type: "State",
            name: "Central Equatoria"
          },
          "SS-EE": {
            type: "State",
            name: "Eastern Equatoria"
          },
          "SS-NU": {
            type: "State",
            name: "Upper Nile"
          },
          "SS-UY": {
            type: "State",
            name: "Unity"
          },
          "SS-BN": {
            type: "State",
            name: "Northern Bahr el Ghazal"
          },
          "SS-JG": {
            type: "State",
            name: "Jonglei"
          },
          "SS-WR": {
            type: "State",
            name: "Warrap"
          }
        }
      },
      SR: {
        name: "Suriname",
        sub: {
          "SR-SA": {
            type: "District",
            name: "Saramacca"
          },
          "SR-CR": {
            type: "District",
            name: "Coronie"
          },
          "SR-BR": {
            type: "District",
            name: "Brokopondo"
          },
          "SR-SI": {
            type: "District",
            name: "Sipaliwini"
          },
          "SR-PR": {
            type: "District",
            name: "Para"
          },
          "SR-WA": {
            type: "District",
            name: "Wanica"
          },
          "SR-NI": {
            type: "District",
            name: "Nickerie"
          },
          "SR-PM": {
            type: "District",
            name: "Paramaribo"
          },
          "SR-CM": {
            type: "District",
            name: "Commewijne"
          },
          "SR-MA": {
            type: "District",
            name: "Marowijne"
          }
        }
      },
      KI: {
        name: "Kiribati",
        sub: {
          "KI-P": {
            type: "Group of islands",
            name: "Phoenix Islands"
          },
          "KI-G": {
            type: "Group of islands",
            name: "Gilbert Islands"
          },
          "KI-L": {
            type: "Group of islands",
            name: "Line Islands"
          }
        }
      },
      KH: {
        name: "Cambodia",
        sub: {
          "KH-24": {
            type: "Autonomous municipality",
            name: "Krŏng Pailĭn"
          },
          "KH-21": {
            type: "Province",
            name: "Taakaev"
          },
          "KH-20": {
            type: "Province",
            name: "Svaay Rieng"
          },
          "KH-23": {
            type: "Autonomous municipality",
            name: "Krong Kaeb"
          },
          "KH-22": {
            type: "Province",
            name: "Ŏtdâr Méanchey"
          },
          "KH-6": {
            type: "Province",
            name: "Kâmpóng Thum"
          },
          "KH-7": {
            type: "Province",
            name: "Kampot"
          },
          "KH-4": {
            type: "Province",
            name: "Kampong Chhnang"
          },
          "KH-5": {
            type: "Province",
            name: "Kâmpóng Spœ"
          },
          "KH-2": {
            type: "Province",
            name: "Baat Dambang"
          },
          "KH-3": {
            type: "Province",
            name: "Kampong Chaam"
          },
          "KH-1": {
            type: "Province",
            name: "Bântéay Méanchey"
          },
          "KH-8": {
            type: "Province",
            name: "Kandaal"
          },
          "KH-9": {
            type: "Province",
            name: "Kaoh Kong"
          },
          "KH-18": {
            type: "Autonomous municipality",
            name: "Krŏng Preăh Sihanouk"
          },
          "KH-19": {
            type: "Province",
            name: "Stoĕng Trêng"
          },
          "KH-14": {
            type: "Province",
            name: "Prey Veaeng"
          },
          "KH-15": {
            type: "Province",
            name: "Pousaat"
          },
          "KH-16": {
            type: "Province",
            name: "Rotanak Kiri"
          },
          "KH-17": {
            type: "Province",
            name: "Siem Reab"
          },
          "KH-10": {
            type: "Province",
            name: "Krâchéh"
          },
          "KH-11": {
            type: "Province",
            name: "Môndól Kiri"
          },
          "KH-12": {
            type: "Autonomous municipality",
            name: "Phnom Penh"
          },
          "KH-13": {
            type: "Province",
            name: "Preăh Vihéar"
          }
        }
      },
      SV: {
        name: "El Salvador",
        sub: {
          "SV-CH": {
            type: "Department",
            name: "Chalatenango"
          },
          "SV-LI": {
            type: "Department",
            name: "La Libertad"
          },
          "SV-US": {
            type: "Department",
            name: "Usulután"
          },
          "SV-SV": {
            type: "Department",
            name: "San Vicente"
          },
          "SV-CA": {
            type: "Department",
            name: "Cabañas"
          },
          "SV-AH": {
            type: "Department",
            name: "Ahuachapán"
          },
          "SV-MO": {
            type: "Department",
            name: "Morazán"
          },
          "SV-SM": {
            type: "Department",
            name: "San Miguel"
          },
          "SV-SO": {
            type: "Department",
            name: "Sonsonate"
          },
          "SV-PA": {
            type: "Department",
            name: "La Paz"
          },
          "SV-UN": {
            type: "Department",
            name: "La Unión"
          },
          "SV-CU": {
            type: "Department",
            name: "Cuscatlán"
          },
          "SV-SS": {
            type: "Department",
            name: "San Salvador"
          },
          "SV-SA": {
            type: "Department",
            name: "Santa Ana"
          }
        }
      },
      KM: {
        name: "Comoros",
        sub: {
          "KM-G": {
            type: "Island",
            name: "Andjazîdja"
          },
          "KM-M": {
            type: "Island",
            name: "Mohéli"
          },
          "KM-A": {
            type: "Island",
            name: "Andjouân"
          }
        }
      },
      ST: {
        name: "Sao Tome and Principe",
        sub: {
          "ST-S": {
            type: "Province",
            name: "São Tomé"
          },
          "ST-P": {
            type: "Province",
            name: "Príncipe"
          }
        }
      },
      SK: {
        name: "Slovakia",
        sub: {
          "SK-PV": {
            type: "Region",
            name: "Prešovský kraj"
          },
          "SK-KI": {
            type: "Region",
            name: "Košický kraj"
          },
          "SK-TC": {
            type: "Region",
            name: "Trenčiansky kraj"
          },
          "SK-TA": {
            type: "Region",
            name: "Trnavský kraj"
          },
          "SK-ZI": {
            type: "Region",
            name: "Žilinský kraj"
          },
          "SK-BL": {
            type: "Region",
            name: "Bratislavský kraj"
          },
          "SK-NI": {
            type: "Region",
            name: "Nitriansky kraj"
          },
          "SK-BC": {
            type: "Region",
            name: "Banskobystrický kraj"
          }
        }
      },
      KR: {
        name: "Korea, Republic of",
        sub: {
          "KR-31": {
            type: "Metropolitan city",
            name: "Ulsan Gwang'yeogsi"
          },
          "KR-49": {
            type: "Special self-governing province",
            name: "Cheju-do"
          },
          "KR-48": {
            type: "Province",
            name: "Gyeongsangnamdo"
          },
          "KR-45": {
            type: "Province",
            name: "Chǒllabuk-do"
          },
          "KR-44": {
            type: "Province",
            name: "Ch'ungch'ǒngnam-do"
          },
          "KR-47": {
            type: "Province",
            name: "Gyeongsangbugdo"
          },
          "KR-46": {
            type: "Province",
            name: "Chǒllanam-do"
          },
          "KR-41": {
            type: "Province",
            name: "Gyeonggido"
          },
          "KR-43": {
            type: "Province",
            name: "Ch'ungch'ǒngbuk-do"
          },
          "KR-42": {
            type: "Province",
            name: "Gang'weondo"
          },
          "KR-30": {
            type: "Metropolitan city",
            name: "Daejeon Gwang'yeogsi"
          },
          "KR-11": {
            type: "Special city",
            name: "Seoul-T'ǔkpyǒlshi"
          },
          "KR-50": {
            type: "Special self-governing city",
            name: "Sejong"
          },
          "KR-29": {
            type: "Metropolitan city",
            name: "Gwangju Gwang'yeogsi"
          },
          "KR-28": {
            type: "Metropolitan city",
            name: "Inch'ǒn-Kwangyǒkshi"
          },
          "KR-27": {
            type: "Metropolitan city",
            name: "Daegu Gwang'yeogsi"
          },
          "KR-26": {
            type: "Metropolitan city",
            name: "Busan Gwang'yeogsi"
          }
        }
      },
      SI: {
        name: "Slovenia",
        sub: {
          "SI-056": {
            type: "Commune",
            name: "Kuzma"
          },
          "SI-057": {
            type: "Commune",
            name: "Laško"
          },
          "SI-054": {
            type: "Commune",
            name: "Krško"
          },
          "SI-055": {
            type: "Commune",
            name: "Kungota"
          },
          "SI-052": {
            type: "Commune",
            name: "Kranj"
          },
          "SI-053": {
            type: "Commune",
            name: "Kranjska Gora"
          },
          "SI-050": {
            type: "Commune",
            name: "Koper"
          },
          "SI-051": {
            type: "Commune",
            name: "Kozje"
          },
          "SI-117": {
            type: "Commune",
            name: "Šenčur"
          },
          "SI-116": {
            type: "Commune",
            name: "Sveti Jurij"
          },
          "SI-115": {
            type: "Commune",
            name: "Starše"
          },
          "SI-114": {
            type: "Commune",
            name: "Slovenske Konjice"
          },
          "SI-113": {
            type: "Commune",
            name: "Slovenska Bistrica"
          },
          "SI-112": {
            type: "Commune",
            name: "Slovenj Gradec"
          },
          "SI-058": {
            type: "Commune",
            name: "Lenart"
          },
          "SI-059": {
            type: "Commune",
            name: "Lendava"
          },
          "SI-166": {
            type: "Commune",
            name: "Križevci"
          },
          "SI-167": {
            type: "Commune",
            name: "Lovrenc na Pohorju"
          },
          "SI-128": {
            type: "Commune",
            name: "Tolmin"
          },
          "SI-129": {
            type: "Commune",
            name: "Trbovlje"
          },
          "SI-210": {
            type: "Commune",
            name: "Sveti Jurij v Slovenskih Goricah"
          },
          "SI-211": {
            type: "Commune",
            name: "Šentrupert"
          },
          "SI-212": {
            type: "Commune",
            name: "Mirna"
          },
          "SI-045": {
            type: "Commune",
            name: "Kidričevo"
          },
          "SI-044": {
            type: "Commune",
            name: "Kanal"
          },
          "SI-047": {
            type: "Commune",
            name: "Kobilje"
          },
          "SI-046": {
            type: "Commune",
            name: "Kobarid"
          },
          "SI-041": {
            type: "Commune",
            name: "Jesenice"
          },
          "SI-040": {
            type: "Commune",
            name: "Izola"
          },
          "SI-043": {
            type: "Commune",
            name: "Kamnik"
          },
          "SI-042": {
            type: "Commune",
            name: "Juršinci"
          },
          "SI-162": {
            type: "Commune",
            name: "Horjul"
          },
          "SI-163": {
            type: "Commune",
            name: "Jezersko"
          },
          "SI-160": {
            type: "Commune",
            name: "Hoče-Slivnica"
          },
          "SI-161": {
            type: "Commune",
            name: "Hodoš"
          },
          "SI-049": {
            type: "Commune",
            name: "Komen"
          },
          "SI-048": {
            type: "Commune",
            name: "Kočevje"
          },
          "SI-164": {
            type: "Commune",
            name: "Komenda"
          },
          "SI-165": {
            type: "Commune",
            name: "Kostel"
          },
          "SI-197": {
            type: "Commune",
            name: "Kosanjevica na Krki"
          },
          "SI-196": {
            type: "Commune",
            name: "Cirkulane"
          },
          "SI-195": {
            type: "Commune",
            name: "Apače"
          },
          "SI-194": {
            type: "Commune",
            name: "Šmartno pri Litiji"
          },
          "SI-193": {
            type: "Commune",
            name: "Žužemberk"
          },
          "SI-192": {
            type: "Commune",
            name: "Žirovnica"
          },
          "SI-191": {
            type: "Commune",
            name: "Žetale"
          },
          "SI-190": {
            type: "Commune",
            name: "Žalec"
          },
          "SI-199": {
            type: "Commune",
            name: "Mokronog-Trebelno"
          },
          "SI-198": {
            type: "Commune",
            name: "Makole"
          },
          "SI-188": {
            type: "Commune",
            name: "Veržej"
          },
          "SI-209": {
            type: "Commune",
            name: "Rečica ob Savinji"
          },
          "SI-208": {
            type: "Commune",
            name: "Log-Dragomer"
          },
          "SI-119": {
            type: "Commune",
            name: "Šentjernej"
          },
          "SI-189": {
            type: "Commune",
            name: "Vransko"
          },
          "SI-203": {
            type: "Commune",
            name: "Straža"
          },
          "SI-202": {
            type: "Commune",
            name: "Središče ob Dravi"
          },
          "SI-201": {
            type: "Commune",
            name: "Renče-Vogrsko"
          },
          "SI-118": {
            type: "Commune",
            name: "Šentilj"
          },
          "SI-207": {
            type: "Commune",
            name: "Gorje"
          },
          "SI-206": {
            type: "Commune",
            name: "Šmarješke Toplice"
          },
          "SI-205": {
            type: "Commune",
            name: "Sveti Tomaž"
          },
          "SI-204": {
            type: "Commune",
            name: "Sveta Trojica v Slovenskih Goricah"
          },
          "SI-070": {
            type: "Commune",
            name: "Maribor"
          },
          "SI-071": {
            type: "Commune",
            name: "Medvode"
          },
          "SI-072": {
            type: "Commune",
            name: "Mengeš"
          },
          "SI-073": {
            type: "Commune",
            name: "Metlika"
          },
          "SI-074": {
            type: "Commune",
            name: "Mežica"
          },
          "SI-075": {
            type: "Commune",
            name: "Miren-Kostanjevica"
          },
          "SI-076": {
            type: "Commune",
            name: "Mislinja"
          },
          "SI-077": {
            type: "Commune",
            name: "Moravče"
          },
          "SI-078": {
            type: "Commune",
            name: "Moravske Toplice"
          },
          "SI-079": {
            type: "Commune",
            name: "Mozirje"
          },
          "SI-173": {
            type: "Commune",
            name: "Polzela"
          },
          "SI-172": {
            type: "Commune",
            name: "Podlehnik"
          },
          "SI-175": {
            type: "Commune",
            name: "Prevalje"
          },
          "SI-174": {
            type: "Commune",
            name: "Prebold"
          },
          "SI-177": {
            type: "Commune",
            name: "Ribnica na Pohorju"
          },
          "SI-176": {
            type: "Commune",
            name: "Razkrižje"
          },
          "SI-180": {
            type: "Commune",
            name: "Solčava"
          },
          "SI-111": {
            type: "Commune",
            name: "Sežana"
          },
          "SI-110": {
            type: "Commune",
            name: "Sevnica"
          },
          "SI-182": {
            type: "Commune",
            name: "Sveti Andraž v Slovenskih Goricah"
          },
          "SI-144": {
            type: "Commune",
            name: "Zreče"
          },
          "SI-146": {
            type: "Commune",
            name: "Železniki"
          },
          "SI-147": {
            type: "Commune",
            name: "Žiri"
          },
          "SI-140": {
            type: "Commune",
            name: "Vrhnika"
          },
          "SI-141": {
            type: "Commune",
            name: "Vuzenica"
          },
          "SI-142": {
            type: "Commune",
            name: "Zagorje ob Savi"
          },
          "SI-143": {
            type: "Commune",
            name: "Zavrč"
          },
          "SI-148": {
            type: "Commune",
            name: "Benedikt"
          },
          "SI-149": {
            type: "Commune",
            name: "Bistrica ob Sotli"
          },
          "SI-069": {
            type: "Commune",
            name: "Majšperk"
          },
          "SI-068": {
            type: "Commune",
            name: "Lukovica"
          },
          "SI-067": {
            type: "Commune",
            name: "Luče"
          },
          "SI-066": {
            type: "Commune",
            name: "Loški Potok"
          },
          "SI-065": {
            type: "Commune",
            name: "Loška Dolina"
          },
          "SI-064": {
            type: "Commune",
            name: "Logatec"
          },
          "SI-063": {
            type: "Commune",
            name: "Ljutomer"
          },
          "SI-062": {
            type: "Commune",
            name: "Ljubno"
          },
          "SI-061": {
            type: "Commune",
            name: "Ljubljana"
          },
          "SI-060": {
            type: "Commune",
            name: "Litija"
          },
          "SI-153": {
            type: "Commune",
            name: "Cerkvenjak"
          },
          "SI-152": {
            type: "Commune",
            name: "Cankova"
          },
          "SI-151": {
            type: "Commune",
            name: "Braslovče"
          },
          "SI-150": {
            type: "Commune",
            name: "Bloke"
          },
          "SI-157": {
            type: "Commune",
            name: "Dolenjske Toplice"
          },
          "SI-156": {
            type: "Commune",
            name: "Dobrovnik"
          },
          "SI-155": {
            type: "Commune",
            name: "Dobrna"
          },
          "SI-154": {
            type: "Commune",
            name: "Dobje"
          },
          "SI-159": {
            type: "Commune",
            name: "Hajdina"
          },
          "SI-158": {
            type: "Commune",
            name: "Grad"
          },
          "SI-018": {
            type: "Commune",
            name: "Destrnik"
          },
          "SI-019": {
            type: "Commune",
            name: "Divača"
          },
          "SI-012": {
            type: "Commune",
            name: "Cerklje na Gorenjskem"
          },
          "SI-013": {
            type: "Commune",
            name: "Cerknica"
          },
          "SI-010": {
            type: "Commune",
            name: "Tišina"
          },
          "SI-011": {
            type: "Commune",
            name: "Celje"
          },
          "SI-016": {
            type: "Commune",
            name: "Črna na Koroškem"
          },
          "SI-017": {
            type: "Commune",
            name: "Črnomelj"
          },
          "SI-014": {
            type: "Commune",
            name: "Cerkno"
          },
          "SI-015": {
            type: "Commune",
            name: "Črenšovci"
          },
          "SI-200": {
            type: "Commune",
            name: "Poljčane"
          },
          "SI-009": {
            type: "Commune",
            name: "Brežice"
          },
          "SI-008": {
            type: "Commune",
            name: "Brezovica"
          },
          "SI-124": {
            type: "Commune",
            name: "Šmarje pri Jelšah"
          },
          "SI-125": {
            type: "Commune",
            name: "Šmartno ob Paki"
          },
          "SI-122": {
            type: "Commune",
            name: "Škofja Loka"
          },
          "SI-123": {
            type: "Commune",
            name: "Škofljica"
          },
          "SI-120": {
            type: "Commune",
            name: "Šentjur"
          },
          "SI-121": {
            type: "Commune",
            name: "Škocjan"
          },
          "SI-001": {
            type: "Commune",
            name: "Ajdovščina"
          },
          "SI-003": {
            type: "Commune",
            name: "Bled"
          },
          "SI-002": {
            type: "Commune",
            name: "Beltinci"
          },
          "SI-005": {
            type: "Commune",
            name: "Borovnica"
          },
          "SI-004": {
            type: "Commune",
            name: "Bohinj"
          },
          "SI-007": {
            type: "Commune",
            name: "Brda"
          },
          "SI-006": {
            type: "Commune",
            name: "Bovec"
          },
          "SI-179": {
            type: "Commune",
            name: "Sodražica"
          },
          "SI-178": {
            type: "Commune",
            name: "Selnica ob Dravi"
          },
          "SI-092": {
            type: "Commune",
            name: "Podčetrtek"
          },
          "SI-093": {
            type: "Commune",
            name: "Podvelka"
          },
          "SI-090": {
            type: "Commune",
            name: "Piran"
          },
          "SI-091": {
            type: "Commune",
            name: "Pivka"
          },
          "SI-096": {
            type: "Commune",
            name: "Ptuj"
          },
          "SI-097": {
            type: "Commune",
            name: "Puconci"
          },
          "SI-094": {
            type: "Commune",
            name: "Postojna"
          },
          "SI-095": {
            type: "Commune",
            name: "Preddvor"
          },
          "SI-098": {
            type: "Commune",
            name: "Rače-Fram"
          },
          "SI-099": {
            type: "Commune",
            name: "Radeče"
          },
          "SI-034": {
            type: "Commune",
            name: "Hrastnik"
          },
          "SI-035": {
            type: "Commune",
            name: "Hrpelje-Kozina"
          },
          "SI-036": {
            type: "Commune",
            name: "Idrija"
          },
          "SI-037": {
            type: "Commune",
            name: "Ig"
          },
          "SI-030": {
            type: "Commune",
            name: "Gornji Grad"
          },
          "SI-031": {
            type: "Commune",
            name: "Gornji Petrovci"
          },
          "SI-032": {
            type: "Commune",
            name: "Grosuplje"
          },
          "SI-033": {
            type: "Commune",
            name: "Šalovci"
          },
          "SI-038": {
            type: "Commune",
            name: "Ilirska Bistrica"
          },
          "SI-039": {
            type: "Commune",
            name: "Ivančna Gorica"
          },
          "SI-135": {
            type: "Commune",
            name: "Videm"
          },
          "SI-134": {
            type: "Commune",
            name: "Velike Lašče"
          },
          "SI-137": {
            type: "Commune",
            name: "Vitanje"
          },
          "SI-136": {
            type: "Commune",
            name: "Vipava"
          },
          "SI-131": {
            type: "Commune",
            name: "Tržič"
          },
          "SI-130": {
            type: "Commune",
            name: "Trebnje"
          },
          "SI-133": {
            type: "Commune",
            name: "Velenje"
          },
          "SI-132": {
            type: "Commune",
            name: "Turnišče"
          },
          "SI-139": {
            type: "Commune",
            name: "Vojnik"
          },
          "SI-138": {
            type: "Commune",
            name: "Vodice"
          },
          "SI-171": {
            type: "Commune",
            name: "Oplotnica"
          },
          "SI-081": {
            type: "Commune",
            name: "Muta"
          },
          "SI-080": {
            type: "Commune",
            name: "Murska Sobota"
          },
          "SI-083": {
            type: "Commune",
            name: "Nazarje"
          },
          "SI-082": {
            type: "Commune",
            name: "Naklo"
          },
          "SI-085": {
            type: "Commune",
            name: "Novo Mesto"
          },
          "SI-084": {
            type: "Commune",
            name: "Nova Gorica"
          },
          "SI-087": {
            type: "Commune",
            name: "Ormož"
          },
          "SI-086": {
            type: "Commune",
            name: "Odranci"
          },
          "SI-089": {
            type: "Commune",
            name: "Pesnica"
          },
          "SI-088": {
            type: "Commune",
            name: "Osilnica"
          },
          "SI-023": {
            type: "Commune",
            name: "Domžale"
          },
          "SI-022": {
            type: "Commune",
            name: "Dol pri Ljubljani"
          },
          "SI-021": {
            type: "Commune",
            name: "Dobrova-Polhov Gradec"
          },
          "SI-020": {
            type: "Commune",
            name: "Dobrepolje"
          },
          "SI-027": {
            type: "Commune",
            name: "Gorenja vas-Poljane"
          },
          "SI-026": {
            type: "Commune",
            name: "Duplek"
          },
          "SI-025": {
            type: "Commune",
            name: "Dravograd"
          },
          "SI-024": {
            type: "Commune",
            name: "Dornava"
          },
          "SI-170": {
            type: "Commune",
            name: "Mirna Peč"
          },
          "SI-181": {
            type: "Commune",
            name: "Sveta Ana"
          },
          "SI-029": {
            type: "Commune",
            name: "Gornja Radgona"
          },
          "SI-028": {
            type: "Commune",
            name: "Gorišnica"
          },
          "SI-184": {
            type: "Commune",
            name: "Tabor"
          },
          "SI-185": {
            type: "Commune",
            name: "Trnovska Vas"
          },
          "SI-186": {
            type: "Commune",
            name: "Trzin"
          },
          "SI-187": {
            type: "Commune",
            name: "Velika Polana"
          },
          "SI-100": {
            type: "Commune",
            name: "Radenci"
          },
          "SI-101": {
            type: "Commune",
            name: "Radlje ob Dravi"
          },
          "SI-102": {
            type: "Commune",
            name: "Radovljica"
          },
          "SI-103": {
            type: "Commune",
            name: "Ravne na Koroškem"
          },
          "SI-104": {
            type: "Commune",
            name: "Ribnica"
          },
          "SI-105": {
            type: "Commune",
            name: "Rogašovci"
          },
          "SI-106": {
            type: "Commune",
            name: "Rogaška Slatina"
          },
          "SI-107": {
            type: "Commune",
            name: "Rogatec"
          },
          "SI-108": {
            type: "Commune",
            name: "Ruše"
          },
          "SI-109": {
            type: "Commune",
            name: "Semič"
          },
          "SI-126": {
            type: "Commune",
            name: "Šoštanj"
          },
          "SI-127": {
            type: "Commune",
            name: "Štore"
          },
          "SI-168": {
            type: "Commune",
            name: "Markovci"
          },
          "SI-169": {
            type: "Commune",
            name: "Miklavž na Dravskem Polju"
          },
          "SI-183": {
            type: "Commune",
            name: "Šempeter-Vrtojba"
          }
        }
      },
      KP: {
        name: "Korea, Democratic People's Republic Of",
        sub: {
          "KP-09": {
            type: "Province",
            name: "Hamgyǒng-bukto"
          },
          "KP-08": {
            type: "Province",
            name: "Hamgyǒng-namdo"
          },
          "KP-03": {
            type: "Province",
            name: "P'yǒngan-bukto"
          },
          "KP-02": {
            type: "Province",
            name: "P'yǒngan-namdo"
          },
          "KP-01": {
            type: "Capital city",
            name: "P'yǒngyang"
          },
          "KP-07": {
            type: "Province",
            name: "Kangweonto"
          },
          "KP-06": {
            type: "Province",
            name: "Hwanghae-bukto"
          },
          "KP-05": {
            type: "Province",
            name: "Hwanghae-namdo"
          },
          "KP-04": {
            type: "Province",
            name: "Chagang-do"
          },
          "KP-10": {
            type: "Province",
            name: "Ryangkangto"
          },
          "KP-13": {
            type: "Special city",
            name: "Nasǒn"
          }
        }
      },
      KW: {
        name: "Kuwait",
        sub: {
          "KW-KU": {
            type: "Governorate",
            name: "Al Kuwayt"
          },
          "KW-FA": {
            type: "Governorate",
            name: "Al Farwānīyah"
          },
          "KW-AH": {
            type: "Governorate",
            name: "Al Aḩmadī"
          },
          "KW-HA": {
            type: "Governorate",
            name: "Ḩawallī"
          },
          "KW-MU": {
            type: "Governorate",
            name: "Mubārak al Kabīr"
          },
          "KW-JA": {
            type: "Governorate",
            name: "Al Jahrā’"
          }
        }
      },
      SN: {
        name: "Senegal",
        sub: {
          "SN-SE": {
            type: "Region",
            name: "Sédhiou"
          },
          "SN-TC": {
            type: "Region",
            name: "Tambacounda"
          },
          "SN-ZG": {
            type: "Region",
            name: "Ziguinchor"
          },
          "SN-SL": {
            type: "Region",
            name: "Saint-Louis"
          },
          "SN-TH": {
            type: "Region",
            name: "Thiès"
          },
          "SN-MT": {
            type: "Region",
            name: "Matam"
          },
          "SN-KL": {
            type: "Region",
            name: "Kaolack"
          },
          "SN-DB": {
            type: "Region",
            name: "Diourbel"
          },
          "SN-FK": {
            type: "Region",
            name: "Fatick"
          },
          "SN-KD": {
            type: "Region",
            name: "Kolda"
          },
          "SN-KE": {
            type: "Region",
            name: "Kédougou"
          },
          "SN-DK": {
            type: "Region",
            name: "Dakar"
          },
          "SN-KA": {
            type: "Region",
            name: "Kaffrine"
          },
          "SN-LG": {
            type: "Region",
            name: "Louga"
          }
        }
      },
      SM: {
        name: "San Marino",
        sub: {
          "SM-08": {
            type: "Municipality",
            name: "Montegiardino"
          },
          "SM-09": {
            type: "Municipality",
            name: "Serravalle"
          },
          "SM-04": {
            type: "Municipality",
            name: "Faetano"
          },
          "SM-05": {
            type: "Municipality",
            name: "Fiorentino"
          },
          "SM-06": {
            type: "Municipality",
            name: "Borgo Maggiore"
          },
          "SM-07": {
            type: "Municipality",
            name: "San Marino"
          },
          "SM-01": {
            type: "Municipality",
            name: "Acquaviva"
          },
          "SM-02": {
            type: "Municipality",
            name: "Chiesanuova"
          },
          "SM-03": {
            type: "Municipality",
            name: "Domagnano"
          }
        }
      },
      SL: {
        name: "Sierra Leone",
        sub: {
          "SL-S": {
            type: "Province",
            name: "Southern"
          },
          "SL-N": {
            type: "Province",
            name: "Northern"
          },
          "SL-W": {
            type: "Area",
            name: "Western Area"
          },
          "SL-E": {
            type: "Province",
            name: "Eastern"
          }
        }
      },
      SC: {
        name: "Seychelles",
        sub: {
          "SC-19": {
            type: "District",
            name: "Plaisance"
          },
          "SC-18": {
            type: "District",
            name: "Mon Fleri"
          },
          "SC-15": {
            type: "District",
            name: "Ladig"
          },
          "SC-14": {
            type: "District",
            name: "Grand'Anse Praslin"
          },
          "SC-17": {
            type: "District",
            name: "Mon Bikston"
          },
          "SC-16": {
            type: "District",
            name: "English River"
          },
          "SC-11": {
            type: "District",
            name: "Cascade"
          },
          "SC-10": {
            type: "District",
            name: "Bel Ombre"
          },
          "SC-13": {
            type: "District",
            name: "Grand'Anse Mahé"
          },
          "SC-12": {
            type: "District",
            name: "Glacis"
          },
          "SC-24": {
            type: "District",
            name: "Lemamel"
          },
          "SC-25": {
            type: "District",
            name: "Roche Caïman"
          },
          "SC-20": {
            type: "District",
            name: "Pointe La Rue"
          },
          "SC-21": {
            type: "District",
            name: "Porglo"
          },
          "SC-22": {
            type: "District",
            name: "Saint Louis"
          },
          "SC-23": {
            type: "District",
            name: "Takamaka"
          },
          "SC-08": {
            type: "District",
            name: "Beau Vallon"
          },
          "SC-09": {
            type: "District",
            name: "Bel Air"
          },
          "SC-06": {
            type: "District",
            name: "Baie Lazare"
          },
          "SC-07": {
            type: "District",
            name: "Baie Sainte-Anne"
          },
          "SC-04": {
            type: "District",
            name: "Au Cap"
          },
          "SC-05": {
            type: "District",
            name: "Anse Royale"
          },
          "SC-02": {
            type: "District",
            name: "Ans Bwalo"
          },
          "SC-03": {
            type: "District",
            name: "Anse Etoile"
          },
          "SC-01": {
            type: "District",
            name: "Anse aux Pins"
          }
        }
      },
      KZ: {
        name: "Kazakhstan",
        sub: {
          "KZ-KAR": {
            type: "Region",
            name: "Karagandinskaja oblast'"
          },
          "KZ-SEV": {
            type: "Region",
            name: "Severo-Kazahstanskaja oblast'"
          },
          "KZ-MAN": {
            type: "Region",
            name: "Mangghystaū oblysy"
          },
          "KZ-VOS": {
            type: "Region",
            name: "Shyghys Qazaqstan oblysy"
          },
          "KZ-KZY": {
            type: "Region",
            name: "Kyzylordinskaja oblast'"
          },
          "KZ-PAV": {
            type: "Region",
            name: "Pavlodar oblysy"
          },
          "KZ-KUS": {
            type: "Region",
            name: "Kostanajskaja oblast'"
          },
          "KZ-AKT": {
            type: "Region",
            name: "Aktjubinskaja oblast'"
          },
          "KZ-ALM": {
            type: "Region",
            name: "Almatinskaja oblast'"
          },
          "KZ-ALA": {
            type: "City",
            name: "Almaty"
          },
          "KZ-AST": {
            type: "City",
            name: "Astana"
          },
          "KZ-AKM": {
            type: "Region",
            name: "Akmolinskaja oblast'"
          },
          "KZ-ZHA": {
            type: "Region",
            name: "Žambylskaja oblast'"
          },
          "KZ-ZAP": {
            type: "Region",
            name: "Batys Qazaqstan oblysy"
          },
          "KZ-YUZ": {
            type: "Region",
            name: "Južno-Kazahstanskaja oblast'"
          },
          "KZ-ATY": {
            type: "Region",
            name: "Atyraū oblysy"
          }
        }
      },
      KY: {
        name: "Cayman Islands",
        sub: {
          "KY-KY": {
            type: "Country",
            name: "Cayman Islands"
          }
        }
      },
      SG: {
        name: "Singapore",
        sub: {
          "SG-02": {
            type: "District",
            name: "North East"
          },
          "SG-03": {
            type: "District",
            name: "North West"
          },
          "SG-01": {
            type: "District",
            name: "Central Singapore"
          },
          "SG-04": {
            type: "District",
            name: "South East"
          },
          "SG-05": {
            type: "District",
            name: "South West"
          }
        }
      },
      SE: {
        name: "Sweden",
        sub: {
          "SE-O": {
            type: "County",
            name: "Västra Götalands län"
          },
          "SE-N": {
            type: "County",
            name: "Hallands län"
          },
          "SE-M": {
            type: "County",
            name: "Skåne län"
          },
          "SE-K": {
            type: "County",
            name: "Blekinge län"
          },
          "SE-I": {
            type: "County",
            name: "Gotlands län"
          },
          "SE-H": {
            type: "County",
            name: "Kalmar län"
          },
          "SE-G": {
            type: "County",
            name: "Kronobergs län"
          },
          "SE-F": {
            type: "County",
            name: "Jönköpings län"
          },
          "SE-E": {
            type: "County",
            name: "Östergötlands län"
          },
          "SE-D": {
            type: "County",
            name: "Södermanlands län"
          },
          "SE-C": {
            type: "County",
            name: "Uppsala län"
          },
          "SE-W": {
            type: "County",
            name: "Dalarnas län"
          },
          "SE-Z": {
            type: "County",
            name: "Jämtlands län"
          },
          "SE-Y": {
            type: "County",
            name: "Västernorrlands län"
          },
          "SE-X": {
            type: "County",
            name: "Gävleborgs län"
          },
          "SE-AC": {
            type: "County",
            name: "Västerbottens län"
          },
          "SE-AB": {
            type: "County",
            name: "Stockholms län"
          },
          "SE-BD": {
            type: "County",
            name: "Norrbottens län"
          },
          "SE-T": {
            type: "County",
            name: "Örebro län"
          },
          "SE-S": {
            type: "County",
            name: "Värmlands län"
          },
          "SE-U": {
            type: "County",
            name: "Västmanlands län"
          }
        }
      },
      SD: {
        name: "Sudan",
        sub: {
          "SD-KH": {
            type: "State",
            name: "Al Kharţūm"
          },
          "SD-GZ": {
            type: "State",
            name: "Al Jazīrah"
          },
          "SD-NB": {
            type: "State",
            name: "An Nīl al Azraq"
          },
          "SD-DS": {
            type: "State",
            name: "Janūb Dārfūr"
          },
          "SD-NR": {
            type: "State",
            name: "An Nīl"
          },
          "SD-NO": {
            type: "State",
            name: "Ash Shamālīyah"
          },
          "SD-KA": {
            type: "State",
            name: "Kassala"
          },
          "SD-DW": {
            type: "State",
            name: "Gharb Dārfūr"
          },
          "SD-RS": {
            type: "State",
            name: "Al Baḩr al Aḩmar"
          },
          "SD-NW": {
            type: "State",
            name: "An Nīl al Abyaḑ"
          },
          "SD-DN": {
            type: "State",
            name: "North Darfur"
          },
          "SD-KS": {
            type: "State",
            name: "Janūb Kurdufān"
          },
          "SD-DC": {
            type: "State",
            name: "Central Darfur"
          },
          "SD-KN": {
            type: "State",
            name: "North Kordofan"
          },
          "SD-GD": {
            type: "State",
            name: "Al Qaḑārif"
          },
          "SD-SI": {
            type: "State",
            name: "Sennar"
          },
          "SD-DE": {
            type: "State",
            name: "East Darfur"
          }
        }
      },
      DO: {
        name: "Dominican Republic",
        sub: {
          "DO-33": {
            type: "Region",
            name: "Cibao Nordeste"
          },
          "DO-40": {
            type: "Region",
            name: "Ozama"
          },
          "DO-12": {
            type: "Province",
            name: "La Romana"
          },
          "DO-13": {
            type: "Province",
            name: "La Vega"
          },
          "DO-10": {
            type: "Province",
            name: "Independencia"
          },
          "DO-11": {
            type: "Province",
            name: "La Altagracia"
          },
          "DO-16": {
            type: "Province",
            name: "Pedernales"
          },
          "DO-17": {
            type: "Province",
            name: "Peravia"
          },
          "DO-14": {
            type: "Province",
            name: "María Trinidad Sánchez"
          },
          "DO-15": {
            type: "Province",
            name: "Monte Cristi"
          },
          "DO-30": {
            type: "Province",
            name: "Hato Mayor"
          },
          "DO-31": {
            type: "Province",
            name: "San José de Ocoa"
          },
          "DO-18": {
            type: "Province",
            name: "Puerto Plata"
          },
          "DO-19": {
            type: "Province",
            name: "Hermanas Mirabal"
          },
          "DO-34": {
            type: "Region",
            name: "Cibao Noroeste"
          },
          "DO-35": {
            type: "Region",
            name: "Cibao Norte"
          },
          "DO-36": {
            type: "Region",
            name: "Cibao Sur"
          },
          "DO-37": {
            type: "Region",
            name: "El Valle"
          },
          "DO-38": {
            type: "Region",
            name: "Enriquillo"
          },
          "DO-39": {
            type: "Region",
            name: "Higuamo"
          },
          "DO-29": {
            type: "Province",
            name: "Monte Plata"
          },
          "DO-28": {
            type: "Province",
            name: "Monseñor Nouel"
          },
          "DO-01": {
            type: "District",
            name: "Distrito Nacional"
          },
          "DO-03": {
            type: "Province",
            name: "Baoruco"
          },
          "DO-02": {
            type: "Province",
            name: "Azua"
          },
          "DO-05": {
            type: "Province",
            name: "Dajabón"
          },
          "DO-04": {
            type: "Province",
            name: "Barahona"
          },
          "DO-07": {
            type: "Province",
            name: "La Estrelleta"
          },
          "DO-06": {
            type: "Province",
            name: "Duarte"
          },
          "DO-09": {
            type: "Province",
            name: "Espaillat"
          },
          "DO-08": {
            type: "Province",
            name: "El Seibo"
          },
          "DO-25": {
            type: "Province",
            name: "Santiago"
          },
          "DO-24": {
            type: "Province",
            name: "Sánchez Ramírez"
          },
          "DO-23": {
            type: "Province",
            name: "San Pedro de Macorís"
          },
          "DO-22": {
            type: "Province",
            name: "San Juan"
          },
          "DO-21": {
            type: "Province",
            name: "San Cristóbal"
          },
          "DO-20": {
            type: "Province",
            name: "Samaná"
          },
          "DO-27": {
            type: "Province",
            name: "Valverde"
          },
          "DO-26": {
            type: "Province",
            name: "Santiago Rodríguez"
          },
          "DO-41": {
            type: "Region",
            name: "Valdesia"
          },
          "DO-32": {
            type: "Province",
            name: "Santo Domingo"
          },
          "DO-42": {
            type: "Region",
            name: "Yuma"
          }
        }
      },
      DM: {
        name: "Dominica",
        sub: {
          "DM-10": {
            type: "Parish",
            name: "Saint Paul"
          },
          "DM-11": {
            type: "Parish",
            name: "Saint Peter"
          },
          "DM-09": {
            type: "Parish",
            name: "Saint Patrick"
          },
          "DM-08": {
            type: "Parish",
            name: "Saint Mark"
          },
          "DM-03": {
            type: "Parish",
            name: "Saint David"
          },
          "DM-02": {
            type: "Parish",
            name: "Saint Andrew"
          },
          "DM-07": {
            type: "Parish",
            name: "Saint Luke"
          },
          "DM-06": {
            type: "Parish",
            name: "Saint Joseph"
          },
          "DM-05": {
            type: "Parish",
            name: "Saint John"
          },
          "DM-04": {
            type: "Parish",
            name: "Saint George"
          }
        }
      },
      DJ: {
        name: "Djibouti",
        sub: {
          "DJ-AS": {
            type: "Region",
            name: "Ali Sabieh"
          },
          "DJ-AR": {
            type: "Region",
            name: "Arta"
          },
          "DJ-DJ": {
            type: "City",
            name: "Djibouti"
          },
          "DJ-DI": {
            type: "Region",
            name: "Dikhīl"
          },
          "DJ-OB": {
            type: "Region",
            name: "Obock"
          },
          "DJ-TA": {
            type: "Region",
            name: "Tadjourah"
          }
        }
      },
      DK: {
        name: "Denmark",
        sub: {
          "DK-85": {
            type: "Region",
            name: "Sjælland"
          },
          "DK-84": {
            type: "Region",
            name: "Hovedstaden"
          },
          "DK-81": {
            type: "Region",
            name: "Nordjylland"
          },
          "DK-83": {
            type: "Region",
            name: "Syddanmark"
          },
          "DK-82": {
            type: "Region",
            name: "Midtjylland"
          }
        }
      },
      VG: {
        name: "Virgin Islands",
        sub: {
          "VG-VG": {
            type: "Country",
            name: "Virgin Islands"
          }
        }
      },
      DE: {
        name: "Germany",
        sub: {
          "DE-BE": {
            type: "Länder",
            name: "Berlin"
          },
          "DE-ST": {
            type: "Länder",
            name: "Sachsen-Anhalt"
          },
          "DE-RP": {
            type: "Länder",
            name: "Rheinland-Pfalz"
          },
          "DE-BB": {
            type: "Länder",
            name: "Brandenburg"
          },
          "DE-NI": {
            type: "Länder",
            name: "Niedersachsen"
          },
          "DE-SH": {
            type: "Länder",
            name: "Schleswig-Holstein"
          },
          "DE-TH": {
            type: "Länder",
            name: "Thüringen"
          },
          "DE-BW": {
            type: "Länder",
            name: "Baden-Württemberg"
          },
          "DE-HH": {
            type: "Länder",
            name: "Hamburg"
          },
          "DE-MV": {
            type: "Länder",
            name: "Mecklenburg-Vorpommern"
          },
          "DE-NW": {
            type: "Länder",
            name: "Nordrhein-Westfalen"
          },
          "DE-SN": {
            type: "Länder",
            name: "Sachsen"
          },
          "DE-HB": {
            type: "Länder",
            name: "Bremen"
          },
          "DE-SL": {
            type: "Länder",
            name: "Saarland"
          },
          "DE-BY": {
            type: "Länder",
            name: "Bayern"
          },
          "DE-HE": {
            type: "Länder",
            name: "Hessen"
          }
        }
      },
      YE: {
        name: "Yemen",
        sub: {
          "YE-BA": {
            type: "Governorate",
            name: "Al Bayḑā'"
          },
          "YE-SD": {
            type: "Governorate",
            name: "Şāʻdah"
          },
          "YE-SA": {
            type: "Municipality",
            name: "Amānat al ‘Āşimah"
          },
          "YE-HU": {
            type: "Governorate",
            name: "Al Ḩudaydah"
          },
          "YE-SN": {
            type: "Governorate",
            name: "Şanʻā'"
          },
          "YE-SH": {
            type: "Governorate",
            name: "Shabwah"
          },
          "YE-TA": {
            type: "Governorate",
            name: "Tāʻizz"
          },
          "YE-MA": {
            type: "Governorate",
            name: "Ma'rib"
          },
          "YE-LA": {
            type: "Governorate",
            name: "Laḩij"
          },
          "YE-RA": {
            type: "Governorate",
            name: "Raymah"
          },
          "YE-IB": {
            type: "Governorate",
            name: "Ibb"
          },
          "YE-AM": {
            type: "Governorate",
            name: "'Amrān"
          },
          "YE-HD": {
            type: "Governorate",
            name: "Ḩaḑramawt"
          },
          "YE-DA": {
            type: "Governorate",
            name: "Aḑ Ḑāli'"
          },
          "YE-HJ": {
            type: "Governorate",
            name: "Ḩajjah"
          },
          "YE-JA": {
            type: "Governorate",
            name: "Al Jawf"
          },
          "YE-MW": {
            type: "Governorate",
            name: "Al Maḩwīt"
          },
          "YE-AB": {
            type: "Governorate",
            name: "Abyān"
          },
          "YE-AD": {
            type: "Governorate",
            name: "'Adan"
          },
          "YE-DH": {
            type: "Governorate",
            name: "Dhamār"
          },
          "YE-MR": {
            type: "Governorate",
            name: "Al Mahrah"
          }
        }
      },
      DZ: {
        name: "Algeria",
        sub: {
          "DZ-11": {
            type: "Province",
            name: "Tamanrasset"
          },
          "DZ-10": {
            type: "Province",
            name: "Bouira"
          },
          "DZ-13": {
            type: "Province",
            name: "Tlemcen"
          },
          "DZ-12": {
            type: "Province",
            name: "Tébessa"
          },
          "DZ-15": {
            type: "Province",
            name: "Tizi Ouzou"
          },
          "DZ-14": {
            type: "Province",
            name: "Tiaret"
          },
          "DZ-17": {
            type: "Province",
            name: "Djelfa"
          },
          "DZ-16": {
            type: "Province",
            name: "Alger"
          },
          "DZ-19": {
            type: "Province",
            name: "Sétif"
          },
          "DZ-18": {
            type: "Province",
            name: "Jijel"
          },
          "DZ-35": {
            type: "Province",
            name: "Boumerdès"
          },
          "DZ-34": {
            type: "Province",
            name: "Bordj Bou Arréridj"
          },
          "DZ-33": {
            type: "Province",
            name: "Illizi"
          },
          "DZ-32": {
            type: "Province",
            name: "El Bayadh"
          },
          "DZ-31": {
            type: "Province",
            name: "Oran"
          },
          "DZ-30": {
            type: "Province",
            name: "Ouargla"
          },
          "DZ-28": {
            type: "Province",
            name: "Msila"
          },
          "DZ-39": {
            type: "Province",
            name: "El Oued"
          },
          "DZ-38": {
            type: "Province",
            name: "Tissemsilt"
          },
          "DZ-37": {
            type: "Province",
            name: "Tindouf"
          },
          "DZ-36": {
            type: "Province",
            name: "El Tarf"
          },
          "DZ-22": {
            type: "Province",
            name: "Sidi Bel Abbès"
          },
          "DZ-29": {
            type: "Province",
            name: "Mascara"
          },
          "DZ-02": {
            type: "Province",
            name: "Chlef"
          },
          "DZ-03": {
            type: "Province",
            name: "Laghouat"
          },
          "DZ-01": {
            type: "Province",
            name: "Adrar"
          },
          "DZ-06": {
            type: "Province",
            name: "Béjaïa"
          },
          "DZ-07": {
            type: "Province",
            name: "Biskra"
          },
          "DZ-04": {
            type: "Province",
            name: "Oum el Bouaghi"
          },
          "DZ-05": {
            type: "Province",
            name: "Batna"
          },
          "DZ-20": {
            type: "Province",
            name: "Saïda"
          },
          "DZ-21": {
            type: "Province",
            name: "Skikda"
          },
          "DZ-08": {
            type: "Province",
            name: "Béchar"
          },
          "DZ-09": {
            type: "Province",
            name: "Blida"
          },
          "DZ-24": {
            type: "Province",
            name: "Guelma"
          },
          "DZ-25": {
            type: "Province",
            name: "Constantine"
          },
          "DZ-26": {
            type: "Province",
            name: "Médéa"
          },
          "DZ-27": {
            type: "Province",
            name: "Mostaganem"
          },
          "DZ-48": {
            type: "Province",
            name: "Relizane"
          },
          "DZ-46": {
            type: "Province",
            name: "Aïn Témouchent"
          },
          "DZ-47": {
            type: "Province",
            name: "Ghardaïa"
          },
          "DZ-44": {
            type: "Province",
            name: "Aïn Defla"
          },
          "DZ-45": {
            type: "Province",
            name: "Naama"
          },
          "DZ-42": {
            type: "Province",
            name: "Tipaza"
          },
          "DZ-43": {
            type: "Province",
            name: "Mila"
          },
          "DZ-40": {
            type: "Province",
            name: "Khenchela"
          },
          "DZ-41": {
            type: "Province",
            name: "Souk Ahras"
          },
          "DZ-23": {
            type: "Province",
            name: "Annaba"
          }
        }
      },
      US: {
        name: "United States",
        sub: {
          "US-NY": {
            type: "State",
            name: "New York"
          },
          "US-PA": {
            type: "State",
            name: "Pennsylvania"
          },
          "US-TN": {
            type: "State",
            name: "Tennessee"
          },
          "US-VI": {
            type: "Outlying area",
            name: "Virgin Islands, U.S."
          },
          "US-NV": {
            type: "State",
            name: "Nevada"
          },
          "US-NJ": {
            type: "State",
            name: "New Jersey"
          },
          "US-NH": {
            type: "State",
            name: "New Hampshire"
          },
          "US-VA": {
            type: "State",
            name: "Virginia"
          },
          "US-HI": {
            type: "State",
            name: "Hawaii"
          },
          "US-PR": {
            type: "Outlying area",
            name: "Puerto Rico"
          },
          "US-NM": {
            type: "State",
            name: "New Mexico"
          },
          "US-NC": {
            type: "State",
            name: "North Carolina"
          },
          "US-ND": {
            type: "State",
            name: "North Dakota"
          },
          "US-NE": {
            type: "State",
            name: "Nebraska"
          },
          "US-LA": {
            type: "State",
            name: "Louisiana"
          },
          "US-UT": {
            type: "State",
            name: "Utah"
          },
          "US-SD": {
            type: "State",
            name: "South Dakota"
          },
          "US-DC": {
            type: "District",
            name: "District of Columbia"
          },
          "US-DE": {
            type: "State",
            name: "Delaware"
          },
          "US-FL": {
            type: "State",
            name: "Florida"
          },
          "US-WA": {
            type: "State",
            name: "Washington"
          },
          "US-UM": {
            type: "Outlying area",
            name: "United States Minor Outlying Islands"
          },
          "US-KS": {
            type: "State",
            name: "Kansas"
          },
          "US-WI": {
            type: "State",
            name: "Wisconsin"
          },
          "US-OR": {
            type: "State",
            name: "Oregon"
          },
          "US-KY": {
            type: "State",
            name: "Kentucky"
          },
          "US-ME": {
            type: "State",
            name: "Maine"
          },
          "US-OH": {
            type: "State",
            name: "Ohio"
          },
          "US-IA": {
            type: "State",
            name: "Iowa"
          },
          "US-MD": {
            type: "State",
            name: "Maryland"
          },
          "US-ID": {
            type: "State",
            name: "Idaho"
          },
          "US-WY": {
            type: "State",
            name: "Wyoming"
          },
          "US-VT": {
            type: "State",
            name: "Vermont"
          },
          "US-RI": {
            type: "State",
            name: "Rhode Island"
          },
          "US-IN": {
            type: "State",
            name: "Indiana"
          },
          "US-IL": {
            type: "State",
            name: "Illinois"
          },
          "US-AK": {
            type: "State",
            name: "Alaska"
          },
          "US-TX": {
            type: "State",
            name: "Texas"
          },
          "US-CO": {
            type: "State",
            name: "Colorado"
          },
          "US-AR": {
            type: "State",
            name: "Arkansas"
          },
          "US-GU": {
            type: "Outlying area",
            name: "Guam"
          },
          "US-MA": {
            type: "State",
            name: "Massachusetts"
          },
          "US-AL": {
            type: "State",
            name: "Alabama"
          },
          "US-MO": {
            type: "State",
            name: "Missouri"
          },
          "US-MN": {
            type: "State",
            name: "Minnesota"
          },
          "US-WV": {
            type: "State",
            name: "West Virginia"
          },
          "US-CA": {
            type: "State",
            name: "California"
          },
          "US-OK": {
            type: "State",
            name: "Oklahoma"
          },
          "US-MI": {
            type: "State",
            name: "Michigan"
          },
          "US-GA": {
            type: "State",
            name: "Georgia"
          },
          "US-AZ": {
            type: "State",
            name: "Arizona"
          },
          "US-MT": {
            type: "State",
            name: "Montana"
          },
          "US-MS": {
            type: "State",
            name: "Mississippi"
          },
          "US-SC": {
            type: "State",
            name: "South Carolina"
          },
          "US-MP": {
            type: "Outlying area",
            name: "Northern Mariana Islands"
          },
          "US-AS": {
            type: "Outlying area",
            name: "American Samoa"
          },
          "US-CT": {
            type: "State",
            name: "Connecticut"
          }
        }
      },
      UY: {
        name: "Uruguay",
        sub: {
          "UY-SO": {
            type: "Department",
            name: "Soriano"
          },
          "UY-FD": {
            type: "Department",
            name: "Florida"
          },
          "UY-AR": {
            type: "Department",
            name: "Artigas"
          },
          "UY-SJ": {
            type: "Department",
            name: "San José"
          },
          "UY-RN": {
            type: "Department",
            name: "Río Negro"
          },
          "UY-FS": {
            type: "Department",
            name: "Flores"
          },
          "UY-PA": {
            type: "Department",
            name: "Paysandú"
          },
          "UY-TT": {
            type: "Department",
            name: "Treinta y Tres"
          },
          "UY-RO": {
            type: "Department",
            name: "Rocha"
          },
          "UY-SA": {
            type: "Department",
            name: "Salto"
          },
          "UY-CO": {
            type: "Department",
            name: "Colonia"
          },
          "UY-CL": {
            type: "Department",
            name: "Cerro Largo"
          },
          "UY-MA": {
            type: "Department",
            name: "Maldonado"
          },
          "UY-RV": {
            type: "Department",
            name: "Rivera"
          },
          "UY-LA": {
            type: "Department",
            name: "Lavalleja"
          },
          "UY-MO": {
            type: "Department",
            name: "Montevideo"
          },
          "UY-DU": {
            type: "Department",
            name: "Durazno"
          },
          "UY-CA": {
            type: "Department",
            name: "Canelones"
          },
          "UY-TA": {
            type: "Department",
            name: "Tacuarembó"
          }
        }
      },
      YT: {
        name: "Mayotte",
        sub: {
          "YT-YT": {
            type: "Country",
            name: "Mayotte"
          }
        }
      },
      UM: {
        name: "United States Minor Outlying Islands",
        sub: {
          "UM-76": {
            type: "Islands, groups of islands",
            name: "Navassa Island"
          },
          "UM-71": {
            type: "Islands, groups of islands",
            name: "Midway Islands"
          },
          "UM-67": {
            type: "Islands, groups of islands",
            name: "Johnston Atoll"
          },
          "UM-86": {
            type: "Islands, groups of islands",
            name: "Jarvis Island"
          },
          "UM-84": {
            type: "Islands, groups of islands",
            name: "Howland Island"
          },
          "UM-89": {
            type: "Islands, groups of islands",
            name: "Kingman Reef"
          },
          "UM-79": {
            type: "Islands, groups of islands",
            name: "Wake Island"
          },
          "UM-81": {
            type: "Islands, groups of islands",
            name: "Baker Island"
          },
          "UM-95": {
            type: "Islands, groups of islands",
            name: "Palmyra Atoll"
          }
        }
      },
      LB: {
        name: "Lebanon",
        sub: {
          "LB-JA": {
            type: "Governorate",
            name: "Al Janūb"
          },
          "LB-AS": {
            type: "Governorate",
            name: "Ash Shimāl"
          },
          "LB-JL": {
            type: "Governorate",
            name: "Jabal Lubnān"
          },
          "LB-BH": {
            type: "Governorate",
            name: "Baalbek-Hermel"
          },
          "LB-BI": {
            type: "Governorate",
            name: "Al Biqā‘"
          },
          "LB-BA": {
            type: "Governorate",
            name: "Bayrūt"
          },
          "LB-AK": {
            type: "Governorate",
            name: "Aakkâr"
          },
          "LB-NA": {
            type: "Governorate",
            name: "An Nabaţīyah"
          }
        }
      },
      LC: {
        name: "Saint Lucia",
        sub: {
          "LC-11": {
            type: "District",
            name: "Vieux Fort"
          },
          "LC-12": {
            type: "District",
            name: "Canaries"
          },
          "LC-10": {
            type: "District",
            name: "Soufrière"
          },
          "LC-08": {
            type: "District",
            name: "Micoud"
          },
          "LC-05": {
            type: "District",
            name: "Dennery"
          },
          "LC-07": {
            type: "District",
            name: "Laborie"
          },
          "LC-06": {
            type: "District",
            name: "Gros Islet"
          },
          "LC-01": {
            type: "District",
            name: "Anse la Raye"
          },
          "LC-03": {
            type: "District",
            name: "Choiseul"
          },
          "LC-02": {
            type: "District",
            name: "Castries"
          }
        }
      },
      LA: {
        name: "Laos",
        sub: {
          "LA-HO": {
            type: "Province",
            name: "Houaphan"
          },
          "LA-VT": {
            type: "Prefecture",
            name: "Vientiane"
          },
          "LA-CH": {
            type: "Province",
            name: "Champasak"
          },
          "LA-OU": {
            type: "Province",
            name: "Oudomsai"
          },
          "LA-AT": {
            type: "Province",
            name: "Attapu"
          },
          "LA-BL": {
            type: "Province",
            name: "Bolikhamxai"
          },
          "LA-SL": {
            type: "Province",
            name: "Salavan"
          },
          "LA-KH": {
            type: "Province",
            name: "Khammouan"
          },
          "LA-LP": {
            type: "Province",
            name: "Louangphabang"
          },
          "LA-SV": {
            type: "Province",
            name: "Savannakhét"
          },
          "LA-BK": {
            type: "Province",
            name: "Bokèo"
          },
          "LA-PH": {
            type: "Province",
            name: "Phôngsali"
          },
          "LA-XN": {
            type: "Special zone",
            name: "Xaisômboun"
          },
          "LA-LM": {
            type: "Province",
            name: "Louang Namtha"
          },
          "LA-XI": {
            type: "Province",
            name: "Xiangkhouang"
          },
          "LA-XE": {
            type: "Province",
            name: "Sékong"
          },
          "LA-VI": {
            type: "Province",
            name: "Vientiane"
          },
          "LA-XA": {
            type: "Province",
            name: "Sayaboury"
          }
        }
      },
      TV: {
        name: "Tuvalu",
        sub: {
          "TV-NKL": {
            type: "Island council",
            name: "Nukulaelae"
          },
          "TV-NKF": {
            type: "Island council",
            name: "Nukufetau"
          },
          "TV-VAI": {
            type: "Island council",
            name: "Vaitupu"
          },
          "TV-FUN": {
            type: "Town council",
            name: "Funafuti"
          },
          "TV-NIT": {
            type: "Island council",
            name: "Niutao"
          },
          "TV-NMG": {
            type: "Island council",
            name: "Nanumanga"
          },
          "TV-NUI": {
            type: "Island council",
            name: "Nui"
          },
          "TV-NMA": {
            type: "Island council",
            name: "Nanumea"
          }
        }
      },
      TW: {
        name: "Taiwan",
        sub: {
          "TW-ILA": {
            type: "District",
            name: "Ilan"
          },
          "TW-YUN": {
            type: "District",
            name: "Yunlin"
          },
          "TW-NAN": {
            type: "District",
            name: "Nantou"
          },
          "TW-TNQ": {
            type: "District",
            name: "Tainan"
          },
          "TW-TNN": {
            type: "Municipality",
            name: "Tainan"
          },
          "TW-KEE": {
            type: "Municipality",
            name: "Keelung"
          },
          "TW-CHA": {
            type: "District",
            name: "Changhua"
          },
          "TW-TPE": {
            type: "Special municipality",
            name: "Taipei"
          },
          "TW-HUA": {
            type: "District",
            name: "Hualien"
          },
          "TW-TXG": {
            type: "Municipality",
            name: "Taichung"
          },
          "TW-TAO": {
            type: "District",
            name: "Taoyuan"
          },
          "TW-PIF": {
            type: "District",
            name: "Pingtung"
          },
          "TW-KHH": {
            type: "Special municipality",
            name: "Kaohsiung"
          },
          "TW-PEN": {
            type: "District",
            name: "Penghu"
          },
          "TW-CYI": {
            type: "Municipality",
            name: "Chiayi"
          },
          "TW-TXQ": {
            type: "District",
            name: "Taichung"
          },
          "TW-MIA": {
            type: "District",
            name: "Miaoli"
          },
          "TW-HSQ": {
            type: "District",
            name: "Hsinchu"
          },
          "TW-CYQ": {
            type: "District",
            name: "Chiayi"
          },
          "TW-TPQ": {
            type: "District",
            name: "Taipei"
          },
          "TW-KHQ": {
            type: "District",
            name: "Kaohsiung"
          },
          "TW-HSZ": {
            type: "Municipality",
            name: "Hsinchu"
          },
          "TW-TTT": {
            type: "District",
            name: "Taitung"
          }
        }
      },
      TT: {
        name: "Trinidad and Tobago",
        sub: {
          "TT-SJL": {
            type: "Region",
            name: "San Juan-Laventille"
          },
          "TT-ETO": {
            type: "Region",
            name: "Eastern Tobago"
          },
          "TT-SGE": {
            type: "Region",
            name: "Sangre Grande"
          },
          "TT-PTF": {
            type: "Municipality",
            name: "Point Fortin"
          },
          "TT-RCM": {
            type: "Region",
            name: "Rio Claro-Mayaro"
          },
          "TT-WTO": {
            type: "Region",
            name: "Western Tobago"
          },
          "TT-PRT": {
            type: "Region",
            name: "Princes Town"
          },
          "TT-POS": {
            type: "Municipality",
            name: "Port of Spain"
          },
          "TT-CTT": {
            type: "Region",
            name: "Couva-Tabaquite-Talparo"
          },
          "TT-CHA": {
            type: "Municipality",
            name: "Chaguanas"
          },
          "TT-ARI": {
            type: "Municipality",
            name: "Arima"
          },
          "TT-SIP": {
            type: "Region",
            name: "Siparia"
          },
          "TT-PED": {
            type: "Region",
            name: "Penal-Debe"
          },
          "TT-SFO": {
            type: "Municipality",
            name: "San Fernando"
          },
          "TT-DMN": {
            type: "Region",
            name: "Diego Martin"
          },
          "TT-TUP": {
            type: "Region",
            name: "Tunapuna-Piarco"
          }
        }
      },
      TR: {
        name: "Turkey",
        sub: {
          "TR-61": {
            type: "Province",
            name: "Trabzon"
          },
          "TR-73": {
            type: "Province",
            name: "Şırnak"
          },
          "TR-62": {
            type: "Province",
            name: "Tunceli"
          },
          "TR-77": {
            type: "Province",
            name: "Yalova"
          },
          "TR-54": {
            type: "Province",
            name: "Sakarya"
          },
          "TR-60": {
            type: "Province",
            name: "Tokat"
          },
          "TR-37": {
            type: "Province",
            name: "Kastamonu"
          },
          "TR-36": {
            type: "Province",
            name: "Kars"
          },
          "TR-35": {
            type: "Province",
            name: "İzmir"
          },
          "TR-34": {
            type: "Province",
            name: "İstanbul"
          },
          "TR-33": {
            type: "Province",
            name: "Mersin"
          },
          "TR-32": {
            type: "Province",
            name: "Isparta"
          },
          "TR-31": {
            type: "Province",
            name: "Hatay"
          },
          "TR-30": {
            type: "Province",
            name: "Hakkâri"
          },
          "TR-68": {
            type: "Province",
            name: "Aksaray"
          },
          "TR-55": {
            type: "Province",
            name: "Samsun"
          },
          "TR-63": {
            type: "Province",
            name: "Şanlıurfa"
          },
          "TR-44": {
            type: "Province",
            name: "Malatya"
          },
          "TR-39": {
            type: "Province",
            name: "Kırklareli"
          },
          "TR-38": {
            type: "Province",
            name: "Kayseri"
          },
          "TR-19": {
            type: "Province",
            name: "Çorum"
          },
          "TR-18": {
            type: "Province",
            name: "Çankırı"
          },
          "TR-57": {
            type: "Province",
            name: "Sinop"
          },
          "TR-45": {
            type: "Province",
            name: "Manisa"
          },
          "TR-51": {
            type: "Province",
            name: "Niğde"
          },
          "TR-50": {
            type: "Province",
            name: "Nevşehir"
          },
          "TR-53": {
            type: "Province",
            name: "Rize"
          },
          "TR-52": {
            type: "Province",
            name: "Ordu"
          },
          "TR-11": {
            type: "Province",
            name: "Bilecik"
          },
          "TR-10": {
            type: "Province",
            name: "Balıkesir"
          },
          "TR-13": {
            type: "Province",
            name: "Bitlis"
          },
          "TR-12": {
            type: "Province",
            name: "Bingöl"
          },
          "TR-15": {
            type: "Province",
            name: "Burdur"
          },
          "TR-14": {
            type: "Province",
            name: "Bolu"
          },
          "TR-17": {
            type: "Province",
            name: "Çanakkale"
          },
          "TR-16": {
            type: "Province",
            name: "Bursa"
          },
          "TR-48": {
            type: "Province",
            name: "Muğla"
          },
          "TR-75": {
            type: "Province",
            name: "Ardahan"
          },
          "TR-76": {
            type: "Province",
            name: "Iğdır"
          },
          "TR-69": {
            type: "Province",
            name: "Bayburt"
          },
          "TR-78": {
            type: "Province",
            name: "Karabük"
          },
          "TR-74": {
            type: "Province",
            name: "Bartın"
          },
          "TR-72": {
            type: "Province",
            name: "Batman"
          },
          "TR-49": {
            type: "Province",
            name: "Muş"
          },
          "TR-64": {
            type: "Province",
            name: "Uşak"
          },
          "TR-80": {
            type: "Province",
            name: "Osmaniye"
          },
          "TR-81": {
            type: "Province",
            name: "Düzce"
          },
          "TR-67": {
            type: "Province",
            name: "Zonguldak"
          },
          "TR-56": {
            type: "Province",
            name: "Siirt"
          },
          "TR-65": {
            type: "Province",
            name: "Van"
          },
          "TR-70": {
            type: "Province",
            name: "Karaman"
          },
          "TR-71": {
            type: "Province",
            name: "Kırıkkale"
          },
          "TR-79": {
            type: "Province",
            name: "Kilis"
          },
          "TR-20": {
            type: "Province",
            name: "Denizli"
          },
          "TR-21": {
            type: "Province",
            name: "Diyarbakır"
          },
          "TR-22": {
            type: "Province",
            name: "Edirne"
          },
          "TR-23": {
            type: "Province",
            name: "Elazığ"
          },
          "TR-24": {
            type: "Province",
            name: "Erzincan"
          },
          "TR-25": {
            type: "Province",
            name: "Erzurum"
          },
          "TR-26": {
            type: "Province",
            name: "Eskişehir"
          },
          "TR-27": {
            type: "Province",
            name: "Gaziantep"
          },
          "TR-28": {
            type: "Province",
            name: "Giresun"
          },
          "TR-29": {
            type: "Province",
            name: "Gümüşhane"
          },
          "TR-59": {
            type: "Province",
            name: "Tekirdağ"
          },
          "TR-58": {
            type: "Province",
            name: "Sivas"
          },
          "TR-46": {
            type: "Province",
            name: "Kahramanmaraş"
          },
          "TR-47": {
            type: "Province",
            name: "Mardin"
          },
          "TR-08": {
            type: "Province",
            name: "Artvin"
          },
          "TR-09": {
            type: "Province",
            name: "Aydın"
          },
          "TR-42": {
            type: "Province",
            name: "Konya"
          },
          "TR-43": {
            type: "Province",
            name: "Kütahya"
          },
          "TR-40": {
            type: "Province",
            name: "Kırşehir"
          },
          "TR-41": {
            type: "Province",
            name: "Kocaeli"
          },
          "TR-02": {
            type: "Province",
            name: "Adıyaman"
          },
          "TR-03": {
            type: "Province",
            name: "Afyonkarahisar"
          },
          "TR-66": {
            type: "Province",
            name: "Yozgat"
          },
          "TR-01": {
            type: "Province",
            name: "Adana"
          },
          "TR-06": {
            type: "Province",
            name: "Ankara"
          },
          "TR-07": {
            type: "Province",
            name: "Antalya"
          },
          "TR-04": {
            type: "Province",
            name: "Ağrı"
          },
          "TR-05": {
            type: "Province",
            name: "Amasya"
          }
        }
      },
      LK: {
        name: "Sri Lanka",
        sub: {
          "LK-23": {
            type: "District",
            name: "Nuvara Ĕliya"
          },
          "LK-22": {
            type: "District",
            name: "Matale"
          },
          "LK-21": {
            type: "District",
            name: "Kandy"
          },
          "LK-41": {
            type: "District",
            name: "Jaffna"
          },
          "LK-43": {
            type: "District",
            name: "Mannar"
          },
          "LK-42": {
            type: "District",
            name: "Kilinochchi"
          },
          "LK-45": {
            type: "District",
            name: "Mulativ"
          },
          "LK-44": {
            type: "District",
            name: "Vavuniya"
          },
          "LK-4": {
            type: "Province",
            name: "Northern Province"
          },
          "LK-5": {
            type: "Province",
            name: "Eastern Province"
          },
          "LK-6": {
            type: "Province",
            name: "North Western Province"
          },
          "LK-7": {
            type: "Province",
            name: "North Central Province"
          },
          "LK-1": {
            type: "Province",
            name: "Basnāhira paḷāta"
          },
          "LK-2": {
            type: "Province",
            name: "Central Province"
          },
          "LK-3": {
            type: "Province",
            name: "Dakuṇu paḷāta"
          },
          "LK-8": {
            type: "Province",
            name: "Ūvā mākāṇam"
          },
          "LK-9": {
            type: "Province",
            name: "Chappirakamuva mākāṇam"
          },
          "LK-71": {
            type: "District",
            name: "Anuradhapura"
          },
          "LK-72": {
            type: "District",
            name: "Pŏlaṉṉaṛuvai"
          },
          "LK-92": {
            type: "District",
            name: "Kægalla"
          },
          "LK-91": {
            type: "District",
            name: "Irattiṉapuri"
          },
          "LK-12": {
            type: "District",
            name: "Gampaha"
          },
          "LK-13": {
            type: "District",
            name: "Kalutara"
          },
          "LK-11": {
            type: "District",
            name: "Colombo"
          },
          "LK-52": {
            type: "District",
            name: "Ampara"
          },
          "LK-53": {
            type: "District",
            name: "Tirukŏṇamalai"
          },
          "LK-51": {
            type: "District",
            name: "Batticaloa"
          },
          "LK-31": {
            type: "District",
            name: "Gālla"
          },
          "LK-32": {
            type: "District",
            name: "Matara"
          },
          "LK-33": {
            type: "District",
            name: "Ampāntōṭṭai"
          },
          "LK-62": {
            type: "District",
            name: "Puttalam"
          },
          "LK-61": {
            type: "District",
            name: "Kuruṇægala"
          },
          "LK-81": {
            type: "District",
            name: "Badulla"
          },
          "LK-82": {
            type: "District",
            name: "Monaragala"
          }
        }
      },
      LI: {
        name: "Liechtenstein",
        sub: {
          "LI-11": {
            type: "Commune",
            name: "Vaduz"
          },
          "LI-10": {
            type: "Commune",
            name: "Triesenberg"
          },
          "LI-09": {
            type: "Commune",
            name: "Triesen"
          },
          "LI-08": {
            type: "Commune",
            name: "Schellenberg"
          },
          "LI-07": {
            type: "Commune",
            name: "Schaan"
          },
          "LI-06": {
            type: "Commune",
            name: "Ruggell"
          },
          "LI-05": {
            type: "Commune",
            name: "Planken"
          },
          "LI-04": {
            type: "Commune",
            name: "Mauren"
          },
          "LI-03": {
            type: "Commune",
            name: "Gamprin"
          },
          "LI-02": {
            type: "Commune",
            name: "Eschen"
          },
          "LI-01": {
            type: "Commune",
            name: "Balzers"
          }
        }
      },
      LV: {
        name: "Latvia",
        sub: {
          "LV-DGV": {
            type: "Republican city",
            name: "Daugavpils"
          },
          "LV-078": {
            type: "Municipality",
            name: "Riebiņu novads"
          },
          "LV-079": {
            type: "Municipality",
            name: "Rojas novads"
          },
          "LV-072": {
            type: "Municipality",
            name: "Pļaviņu novads"
          },
          "LV-073": {
            type: "Municipality",
            name: "Preiļu novads"
          },
          "LV-070": {
            type: "Municipality",
            name: "Pārgaujas novads"
          },
          "LV-071": {
            type: "Municipality",
            name: "Pāvilostas novads"
          },
          "LV-076": {
            type: "Municipality",
            name: "Raunas novads"
          },
          "LV-077": {
            type: "Municipality",
            name: "Rēzeknes novads"
          },
          "LV-074": {
            type: "Municipality",
            name: "Priekules novads"
          },
          "LV-075": {
            type: "Municipality",
            name: "Priekuļu novads"
          },
          "LV-106": {
            type: "Municipality",
            name: "Ventspils novads"
          },
          "LV-107": {
            type: "Municipality",
            name: "Viesītes novads"
          },
          "LV-JEL": {
            type: "Republican city",
            name: "Jelgava"
          },
          "LV-069": {
            type: "Municipality",
            name: "Ozolnieku novads"
          },
          "LV-068": {
            type: "Municipality",
            name: "Olaines novads"
          },
          "LV-104": {
            type: "Municipality",
            name: "Vecpiebalgas novads"
          },
          "LV-105": {
            type: "Municipality",
            name: "Vecumnieku novads"
          },
          "LV-102": {
            type: "Municipality",
            name: "Varakļānu novads"
          },
          "LV-103": {
            type: "Municipality",
            name: "Vārkavas novads"
          },
          "LV-100": {
            type: "Municipality",
            name: "Vaiņodes novads"
          },
          "LV-101": {
            type: "Municipality",
            name: "Valkas novads"
          },
          "LV-061": {
            type: "Municipality",
            name: "Mālpils novads"
          },
          "LV-060": {
            type: "Municipality",
            name: "Mazsalacas novads"
          },
          "LV-063": {
            type: "Municipality",
            name: "Mērsraga novads"
          },
          "LV-062": {
            type: "Municipality",
            name: "Mārupes novads"
          },
          "LV-065": {
            type: "Municipality",
            name: "Neretas novads"
          },
          "LV-064": {
            type: "Municipality",
            name: "Naukšēnu novads"
          },
          "LV-067": {
            type: "Municipality",
            name: "Ogres novads"
          },
          "LV-066": {
            type: "Municipality",
            name: "Nīcas novads"
          },
          "LV-LPX": {
            type: "Republican city",
            name: "Liepāja"
          },
          "LV-VEN": {
            type: "Republican city",
            name: "Ventspils"
          },
          "LV-054": {
            type: "Municipality",
            name: "Limbažu novads"
          },
          "LV-055": {
            type: "Municipality",
            name: "Līgatnes novads"
          },
          "LV-056": {
            type: "Municipality",
            name: "Līvānu novads"
          },
          "LV-057": {
            type: "Municipality",
            name: "Lubānas novads"
          },
          "LV-050": {
            type: "Municipality",
            name: "Kuldīgas novads"
          },
          "LV-051": {
            type: "Municipality",
            name: "Ķeguma novads"
          },
          "LV-052": {
            type: "Municipality",
            name: "Ķekavas novads"
          },
          "LV-053": {
            type: "Municipality",
            name: "Lielvārdes novads"
          },
          "LV-REZ": {
            type: "Republican city",
            name: "Rēzekne"
          },
          "LV-109": {
            type: "Municipality",
            name: "Viļānu novads"
          },
          "LV-058": {
            type: "Municipality",
            name: "Ludzas novads"
          },
          "LV-059": {
            type: "Municipality",
            name: "Madonas novads"
          },
          "LV-110": {
            type: "Municipality",
            name: "Zilupes novads"
          },
          "LV-VMR": {
            type: "Republican city",
            name: "Valmiera"
          },
          "LV-043": {
            type: "Municipality",
            name: "Kandavas novads"
          },
          "LV-042": {
            type: "Municipality",
            name: "Jēkabpils novads"
          },
          "LV-041": {
            type: "Municipality",
            name: "Jelgavas novads"
          },
          "LV-040": {
            type: "Municipality",
            name: "Jaunpils novads"
          },
          "LV-047": {
            type: "Municipality",
            name: "Krāslavas novads"
          },
          "LV-046": {
            type: "Municipality",
            name: "Kokneses novads"
          },
          "LV-045": {
            type: "Municipality",
            name: "Kocēnu novads"
          },
          "LV-044": {
            type: "Municipality",
            name: "Kārsavas novads"
          },
          "LV-049": {
            type: "Municipality",
            name: "Krustpils novads"
          },
          "LV-048": {
            type: "Municipality",
            name: "Krimuldas novads"
          },
          "LV-036": {
            type: "Municipality",
            name: "Ilūkstes novads"
          },
          "LV-037": {
            type: "Municipality",
            name: "Inčukalna novads"
          },
          "LV-034": {
            type: "Municipality",
            name: "Iecavas novads"
          },
          "LV-035": {
            type: "Municipality",
            name: "Ikšķiles novads"
          },
          "LV-032": {
            type: "Municipality",
            name: "Grobiņas novads"
          },
          "LV-033": {
            type: "Municipality",
            name: "Gulbenes novads"
          },
          "LV-030": {
            type: "Municipality",
            name: "Ērgļu novads"
          },
          "LV-031": {
            type: "Municipality",
            name: "Garkalnes novads"
          },
          "LV-038": {
            type: "Municipality",
            name: "Jaunjelgavas novads"
          },
          "LV-039": {
            type: "Municipality",
            name: "Jaunpiebalgas novads"
          },
          "LV-025": {
            type: "Municipality",
            name: "Daugavpils novads"
          },
          "LV-024": {
            type: "Municipality",
            name: "Dagdas novads"
          },
          "LV-027": {
            type: "Municipality",
            name: "Dundagas novads"
          },
          "LV-026": {
            type: "Municipality",
            name: "Dobeles novads"
          },
          "LV-021": {
            type: "Municipality",
            name: "Cesvaines novads"
          },
          "LV-020": {
            type: "Municipality",
            name: "Carnikavas novads"
          },
          "LV-023": {
            type: "Municipality",
            name: "Ciblas novads"
          },
          "LV-022": {
            type: "Municipality",
            name: "Cēsu novads"
          },
          "LV-029": {
            type: "Municipality",
            name: "Engures novads"
          },
          "LV-028": {
            type: "Municipality",
            name: "Durbes novads"
          },
          "LV-108": {
            type: "Municipality",
            name: "Viļakas novads"
          },
          "LV-JKB": {
            type: "Republican city",
            name: "Jēkabpils"
          },
          "LV-098": {
            type: "Municipality",
            name: "Tērvetes novads"
          },
          "LV-099": {
            type: "Municipality",
            name: "Tukuma novads"
          },
          "LV-090": {
            type: "Municipality",
            name: "Sējas novads"
          },
          "LV-091": {
            type: "Municipality",
            name: "Siguldas novads"
          },
          "LV-092": {
            type: "Municipality",
            name: "Skrīveru novads"
          },
          "LV-093": {
            type: "Municipality",
            name: "Skrundas novads"
          },
          "LV-094": {
            type: "Municipality",
            name: "Smiltenes novads"
          },
          "LV-095": {
            type: "Municipality",
            name: "Stopiņu novads"
          },
          "LV-096": {
            type: "Municipality",
            name: "Strenču novads"
          },
          "LV-097": {
            type: "Municipality",
            name: "Talsu novads"
          },
          "LV-010": {
            type: "Municipality",
            name: "Auces novads"
          },
          "LV-011": {
            type: "Municipality",
            name: "Ādažu novads"
          },
          "LV-012": {
            type: "Municipality",
            name: "Babītes novads"
          },
          "LV-013": {
            type: "Municipality",
            name: "Baldones novads"
          },
          "LV-014": {
            type: "Municipality",
            name: "Baltinavas novads"
          },
          "LV-015": {
            type: "Municipality",
            name: "Balvu novads"
          },
          "LV-016": {
            type: "Municipality",
            name: "Bauskas novads"
          },
          "LV-017": {
            type: "Municipality",
            name: "Beverīnas novads"
          },
          "LV-018": {
            type: "Municipality",
            name: "Brocēnu novads"
          },
          "LV-019": {
            type: "Municipality",
            name: "Burtnieku novads"
          },
          "LV-JUR": {
            type: "Republican city",
            name: "Jūrmala"
          },
          "LV-087": {
            type: "Municipality",
            name: "Salaspils novads"
          },
          "LV-086": {
            type: "Municipality",
            name: "Salacgrīvas novads"
          },
          "LV-085": {
            type: "Municipality",
            name: "Salas novads"
          },
          "LV-084": {
            type: "Municipality",
            name: "Rūjienas novads"
          },
          "LV-083": {
            type: "Municipality",
            name: "Rundāles novads"
          },
          "LV-082": {
            type: "Municipality",
            name: "Rugāju novads"
          },
          "LV-081": {
            type: "Municipality",
            name: "Rucavas novads"
          },
          "LV-080": {
            type: "Municipality",
            name: "Ropažu novads"
          },
          "LV-089": {
            type: "Municipality",
            name: "Saulkrastu novads"
          },
          "LV-088": {
            type: "Municipality",
            name: "Saldus novads"
          },
          "LV-009": {
            type: "Municipality",
            name: "Apes novads"
          },
          "LV-008": {
            type: "Municipality",
            name: "Amatas novads"
          },
          "LV-007": {
            type: "Municipality",
            name: "Alūksnes novads"
          },
          "LV-006": {
            type: "Municipality",
            name: "Alsungas novads"
          },
          "LV-005": {
            type: "Municipality",
            name: "Alojas novads"
          },
          "LV-004": {
            type: "Municipality",
            name: "Aknīstes novads"
          },
          "LV-003": {
            type: "Municipality",
            name: "Aizputes novads"
          },
          "LV-002": {
            type: "Municipality",
            name: "Aizkraukles novads"
          },
          "LV-001": {
            type: "Municipality",
            name: "Aglonas novads"
          },
          "LV-RIX": {
            type: "Republican city",
            name: "Rīga"
          }
        }
      },
      TO: {
        name: "Tonga",
        sub: {
          "TO-01": {
            type: "Division",
            name: "'Eua"
          },
          "TO-03": {
            type: "Division",
            name: "Niuas"
          },
          "TO-02": {
            type: "Division",
            name: "Ha'apai"
          },
          "TO-05": {
            type: "Division",
            name: "Vava'u"
          },
          "TO-04": {
            type: "Division",
            name: "Tongatapu"
          }
        }
      },
      LT: {
        name: "Lithuania",
        sub: {
          "LT-MR": {
            type: "County",
            name: "Marijampolės apskritis"
          },
          "LT-TA": {
            type: "County",
            name: "Tauragės apskritis"
          },
          "LT-56": {
            type: "District municipality",
            name: "Vilkaviškis"
          },
          "LT-41": {
            type: "District municipality",
            name: "Šakiai"
          },
          "LT-KL": {
            type: "County",
            name: "Klaipėdos apskritis"
          },
          "LT-52": {
            type: "District municipality",
            name: "Trakai"
          },
          "LT-53": {
            type: "District municipality",
            name: "Ukmergė"
          },
          "LT-01": {
            type: "District municipality",
            name: "Akmenė"
          },
          "LT-02": {
            type: "City municipality",
            name: "Alytaus miestas"
          },
          "LT-03": {
            type: "District municipality",
            name: "Alytus"
          },
          "LT-04": {
            type: "District municipality",
            name: "Anykščiai"
          },
          "LT-05": {
            type: "Municipality",
            name: "Birštono"
          },
          "LT-06": {
            type: "District municipality",
            name: "Biržai"
          },
          "LT-07": {
            type: "Municipality",
            name: "Druskininkai"
          },
          "LT-08": {
            type: "Municipality",
            name: "Elektrénai"
          },
          "LT-09": {
            type: "District municipality",
            name: "Ignalina"
          },
          "LT-KU": {
            type: "County",
            name: "Kauno apskritis"
          },
          "LT-28": {
            type: "Municipality",
            name: "Neringa"
          },
          "LT-29": {
            type: "Municipality",
            name: "Pagégiai"
          },
          "LT-48": {
            type: "District municipality",
            name: "Skuodas"
          },
          "LT-49": {
            type: "District municipality",
            name: "Švenčionys"
          },
          "LT-60": {
            type: "District municipality",
            name: "Zarasai"
          },
          "LT-UT": {
            type: "County",
            name: "Utenos apskritis"
          },
          "LT-22": {
            type: "District municipality",
            name: "Kretinga"
          },
          "LT-23": {
            type: "District municipality",
            name: "Kupiškis"
          },
          "LT-20": {
            type: "City municipality",
            name: "Klaipėdos miestas"
          },
          "LT-21": {
            type: "District municipality",
            name: "Klaipėda"
          },
          "LT-26": {
            type: "District municipality",
            name: "Mažeikiai"
          },
          "LT-27": {
            type: "District municipality",
            name: "Molėtai"
          },
          "LT-24": {
            type: "District municipality",
            name: "Lazdijai"
          },
          "LT-25": {
            type: "District municipality",
            name: "Marijampolė"
          },
          "LT-58": {
            type: "District municipality",
            name: "Vilnius"
          },
          "LT-PN": {
            type: "County",
            name: "Panevėžio apskritis"
          },
          "LT-54": {
            type: "District municipality",
            name: "Utena"
          },
          "LT-43": {
            type: "City municipality",
            name: "Šiaulių miestas"
          },
          "LT-59": {
            type: "Municipality",
            name: "Visaginas"
          },
          "LT-45": {
            type: "District municipality",
            name: "Šilalė"
          },
          "LT-47": {
            type: "District municipality",
            name: "Širvintos"
          },
          "LT-46": {
            type: "District municipality",
            name: "Šilutė"
          },
          "LT-17": {
            type: "Municipality",
            name: "Kazlų Rūdos"
          },
          "LT-16": {
            type: "District municipality",
            name: "Kaunas"
          },
          "LT-15": {
            type: "City municipality",
            name: "Kauno miestas"
          },
          "LT-14": {
            type: "Municipality",
            name: "Kalvarijos"
          },
          "LT-13": {
            type: "District municipality",
            name: "Kaišiadorys"
          },
          "LT-12": {
            type: "District municipality",
            name: "Jurbarkas"
          },
          "LT-11": {
            type: "District municipality",
            name: "Joniškis"
          },
          "LT-10": {
            type: "District municipality",
            name: "Jonava"
          },
          "LT-TE": {
            type: "County",
            name: "Telšių apskritis"
          },
          "LT-40": {
            type: "District municipality",
            name: "Rokiškis"
          },
          "LT-VL": {
            type: "County",
            name: "Vilniaus apskritis"
          },
          "LT-AL": {
            type: "County",
            name: "Alytaus apskritis"
          },
          "LT-SA": {
            type: "County",
            name: "Šiaulių apskritis"
          },
          "LT-19": {
            type: "District municipality",
            name: "Kelmė"
          },
          "LT-18": {
            type: "District municipality",
            name: "Kėdainiai"
          },
          "LT-39": {
            type: "Municipality",
            name: "Rietavo"
          },
          "LT-38": {
            type: "District municipality",
            name: "Raseiniai"
          },
          "LT-51": {
            type: "District municipality",
            name: "Telšiai"
          },
          "LT-50": {
            type: "District municipality",
            name: "Tauragė"
          },
          "LT-57": {
            type: "City municipality",
            name: "Vilniaus miestas"
          },
          "LT-42": {
            type: "District municipality",
            name: "Šalčininkai"
          },
          "LT-55": {
            type: "District municipality",
            name: "Varėna"
          },
          "LT-44": {
            type: "District municipality",
            name: "Šiauliai"
          },
          "LT-31": {
            type: "City municipality",
            name: "Palangos miestas"
          },
          "LT-30": {
            type: "District municipality",
            name: "Pakruojis"
          },
          "LT-33": {
            type: "District municipality",
            name: "Panevėžys"
          },
          "LT-32": {
            type: "City municipality",
            name: "Panevėžio miestas"
          },
          "LT-35": {
            type: "District municipality",
            name: "Plungė"
          },
          "LT-34": {
            type: "District municipality",
            name: "Pasvalys"
          },
          "LT-37": {
            type: "District municipality",
            name: "Radviliškis"
          },
          "LT-36": {
            type: "District municipality",
            name: "Prienai"
          }
        }
      },
      LU: {
        name: "Luxembourg",
        sub: {
          "LU-G": {
            type: "District",
            name: "Grevenmacher"
          },
          "LU-D": {
            type: "District",
            name: "Diekirch"
          },
          "LU-L": {
            type: "District",
            name: "Lëtzebuerg"
          }
        }
      },
      LR: {
        name: "Liberia",
        sub: {
          "LR-CM": {
            type: "County",
            name: "Grand Cape Mount"
          },
          "LR-BM": {
            type: "County",
            name: "Bomi"
          },
          "LR-MY": {
            type: "County",
            name: "Maryland"
          },
          "LR-LO": {
            type: "County",
            name: "Lofa"
          },
          "LR-MO": {
            type: "County",
            name: "Montserrado"
          },
          "LR-GP": {
            type: "County",
            name: "Gbarpolu"
          },
          "LR-MG": {
            type: "County",
            name: "Margibi"
          },
          "LR-BG": {
            type: "County",
            name: "Bong"
          },
          "LR-GK": {
            type: "County",
            name: "Grand Kru"
          },
          "LR-RI": {
            type: "County",
            name: "Rivercess"
          },
          "LR-SI": {
            type: "County",
            name: "Sinoe"
          },
          "LR-GB": {
            type: "County",
            name: "Grand Bassa"
          },
          "LR-RG": {
            type: "County",
            name: "River Gee"
          },
          "LR-GG": {
            type: "County",
            name: "Grand Gedeh"
          },
          "LR-NI": {
            type: "County",
            name: "Nimba"
          }
        }
      },
      LS: {
        name: "Lesotho",
        sub: {
          "LS-H": {
            type: "District",
            name: "Qacha's Nek"
          },
          "LS-J": {
            type: "District",
            name: "Mokhotlong"
          },
          "LS-K": {
            type: "District",
            name: "Thaba-Tseka"
          },
          "LS-D": {
            type: "District",
            name: "Berea"
          },
          "LS-E": {
            type: "District",
            name: "Mafeteng"
          },
          "LS-F": {
            type: "District",
            name: "Mohale's Hoek"
          },
          "LS-G": {
            type: "District",
            name: "Quthing"
          },
          "LS-A": {
            type: "District",
            name: "Maseru"
          },
          "LS-B": {
            type: "District",
            name: "Butha-Buthe"
          },
          "LS-C": {
            type: "District",
            name: "Leribe"
          }
        }
      },
      TH: {
        name: "Thailand",
        sub: {
          "TH-57": {
            type: "Province",
            name: "Chiang Rai"
          },
          "TH-56": {
            type: "Province",
            name: "Phayao"
          },
          "TH-55": {
            type: "Province",
            name: "Nan"
          },
          "TH-54": {
            type: "Province",
            name: "Phrae"
          },
          "TH-53": {
            type: "Province",
            name: "Uttaradit"
          },
          "TH-33": {
            type: "Province",
            name: "Si Sa Ket"
          },
          "TH-51": {
            type: "Province",
            name: "Lamphun"
          },
          "TH-50": {
            type: "Province",
            name: "Chiang Mai"
          },
          "TH-90": {
            type: "Province",
            name: "Songkhla"
          },
          "TH-92": {
            type: "Province",
            name: "Trang"
          },
          "TH-91": {
            type: "Province",
            name: "Satun"
          },
          "TH-32": {
            type: "Province",
            name: "Surin"
          },
          "TH-96": {
            type: "Province",
            name: "Narathiwat"
          },
          "TH-95": {
            type: "Province",
            name: "Yala"
          },
          "TH-58": {
            type: "Province",
            name: "Mae Hong Son"
          },
          "TH-13": {
            type: "Province",
            name: "Pathum Thani"
          },
          "TH-12": {
            type: "Province",
            name: "Nonthaburi"
          },
          "TH-11": {
            type: "Province",
            name: "Samut Prakan"
          },
          "TH-10": {
            type: "Metropolitan administration",
            name: "Krung Thep Maha Nakhon"
          },
          "TH-31": {
            type: "Province",
            name: "Buri Ram"
          },
          "TH-30": {
            type: "Province",
            name: "Nakhon Ratchasima"
          },
          "TH-15": {
            type: "Province",
            name: "Ang Thong"
          },
          "TH-14": {
            type: "Province",
            name: "Phra Nakhon Si Ayutthaya"
          },
          "TH-71": {
            type: "Province",
            name: "Kanchanaburi"
          },
          "TH-70": {
            type: "Province",
            name: "Ratchaburi"
          },
          "TH-19": {
            type: "Province",
            name: "Saraburi"
          },
          "TH-18": {
            type: "Province",
            name: "Chai Nat"
          },
          "TH-39": {
            type: "Province",
            name: "Nong Bua Lam Phu"
          },
          "TH-38": {
            type: "Province",
            name: "Bueng Kan"
          },
          "TH-77": {
            type: "Province",
            name: "Prachuap Khiri Khan"
          },
          "TH-52": {
            type: "Province",
            name: "Lampang"
          },
          "TH-72": {
            type: "Province",
            name: "Suphan Buri"
          },
          "TH-75": {
            type: "Province",
            name: "Samut Songkhram"
          },
          "TH-93": {
            type: "Province",
            name: "Phatthalung"
          },
          "TH-74": {
            type: "Province",
            name: "Samut Sakhon"
          },
          "TH-84": {
            type: "Province",
            name: "Surat Thani"
          },
          "TH-85": {
            type: "Province",
            name: "Ranong"
          },
          "TH-86": {
            type: "Province",
            name: "Chumphon"
          },
          "TH-S": {
            type: "Special administrative city",
            name: "Phatthaya"
          },
          "TH-80": {
            type: "Province",
            name: "Nakhon Si Thammarat"
          },
          "TH-81": {
            type: "Province",
            name: "Krabi"
          },
          "TH-82": {
            type: "Province",
            name: "Phangnga"
          },
          "TH-83": {
            type: "Province",
            name: "Phuket"
          },
          "TH-76": {
            type: "Province",
            name: "Phetchaburi"
          },
          "TH-40": {
            type: "Province",
            name: "Khon Kaen"
          },
          "TH-41": {
            type: "Province",
            name: "Udon Thani"
          },
          "TH-42": {
            type: "Province",
            name: "Loei"
          },
          "TH-43": {
            type: "Province",
            name: "Nong Khai"
          },
          "TH-44": {
            type: "Province",
            name: "Maha Sarakham"
          },
          "TH-45": {
            type: "Province",
            name: "Roi Et"
          },
          "TH-46": {
            type: "Province",
            name: "Kalasin"
          },
          "TH-47": {
            type: "Province",
            name: "Sakon Nakhon"
          },
          "TH-48": {
            type: "Province",
            name: "Nakhon Phanom"
          },
          "TH-49": {
            type: "Province",
            name: "Mukdahan"
          },
          "TH-26": {
            type: "Province",
            name: "Nakhon Nayok"
          },
          "TH-27": {
            type: "Province",
            name: "Sa Kaeo"
          },
          "TH-24": {
            type: "Province",
            name: "Chachoengsao"
          },
          "TH-25": {
            type: "Province",
            name: "Prachin Buri"
          },
          "TH-22": {
            type: "Province",
            name: "Chanthaburi"
          },
          "TH-23": {
            type: "Province",
            name: "Trat"
          },
          "TH-20": {
            type: "Province",
            name: "Chon Buri"
          },
          "TH-21": {
            type: "Province",
            name: "Rayong"
          },
          "TH-62": {
            type: "Province",
            name: "Kamphaeng Phet"
          },
          "TH-63": {
            type: "Province",
            name: "Tak"
          },
          "TH-60": {
            type: "Province",
            name: "Nakhon Sawan"
          },
          "TH-61": {
            type: "Province",
            name: "Uthai Thani"
          },
          "TH-66": {
            type: "Province",
            name: "Phichit"
          },
          "TH-67": {
            type: "Province",
            name: "Phetchabun"
          },
          "TH-64": {
            type: "Province",
            name: "Sukhothai"
          },
          "TH-65": {
            type: "Province",
            name: "Phitsanulok"
          },
          "TH-35": {
            type: "Province",
            name: "Yasothon"
          },
          "TH-34": {
            type: "Province",
            name: "Ubon Ratchathani"
          },
          "TH-37": {
            type: "Province",
            name: "Amnat Charoen"
          },
          "TH-73": {
            type: "Province",
            name: "Nakhon Pathom"
          },
          "TH-36": {
            type: "Province",
            name: "Chaiyaphum"
          },
          "TH-17": {
            type: "Province",
            name: "Sing Buri"
          },
          "TH-94": {
            type: "Province",
            name: "Pattani"
          },
          "TH-16": {
            type: "Province",
            name: "Lop Buri"
          }
        }
      },
      TF: {
        name: "French Southern Territories",
        sub: {
          "TF-TF": {
            type: "Country",
            name: "French Southern Territories"
          }
        }
      },
      TG: {
        name: "Togo",
        sub: {
          "TG-P": {
            type: "Region",
            name: "Plateaux"
          },
          "TG-S": {
            type: "Region",
            name: "Savannes"
          },
          "TG-C": {
            type: "Region",
            name: "Centre"
          },
          "TG-M": {
            type: "Region",
            name: "Maritime"
          },
          "TG-K": {
            type: "Region",
            name: "Kara"
          }
        }
      },
      TD: {
        name: "Chad",
        sub: {
          "TD-ND": {
            type: "Region",
            name: "Madīnat Injamīnā"
          },
          "TD-LO": {
            type: "Region",
            name: "Logone-Occidental"
          },
          "TD-WF": {
            type: "Region",
            name: "Wadi Fira"
          },
          "TD-LC": {
            type: "Region",
            name: "Al Buḩayrah"
          },
          "TD-SA": {
            type: "Region",
            name: "Salamat"
          },
          "TD-SI": {
            type: "Region",
            name: "Sila"
          },
          "TD-HL": {
            type: "Region",
            name: "Hadjer Lamis"
          },
          "TD-LR": {
            type: "Region",
            name: "Logone-Oriental"
          },
          "TD-BA": {
            type: "Region",
            name: "Al Baţḩah"
          },
          "TD-BG": {
            type: "Region",
            name: "Baḩr al Ghazāl"
          },
          "TD-BO": {
            type: "Region",
            name: "Borkou"
          },
          "TD-MC": {
            type: "Region",
            name: "Moyen-Chari"
          },
          "TD-OD": {
            type: "Region",
            name: "Ouaddaï"
          },
          "TD-MA": {
            type: "Region",
            name: "Mandoul"
          },
          "TD-ME": {
            type: "Region",
            name: "Mayo-Kebbi-Est"
          },
          "TD-KA": {
            type: "Region",
            name: "Kanem"
          },
          "TD-MO": {
            type: "Region",
            name: "Mayo-Kebbi-Ouest"
          },
          "TD-GR": {
            type: "Region",
            name: "Guéra"
          },
          "TD-EO": {
            type: "Region",
            name: "Ennedi-Ouest"
          },
          "TD-TA": {
            type: "Region",
            name: "Tandjilé"
          },
          "TD-TI": {
            type: "Region",
            name: "Tibastī"
          },
          "TD-EE": {
            type: "Region",
            name: "Ennedi-Est"
          },
          "TD-CB": {
            type: "Region",
            name: "Chari-Baguirmi"
          }
        }
      },
      TC: {
        name: "Turks & Caicos Islands",
        sub: {
          "TC-TC": {
            type: "Country",
            name: "Turks & Caicos Islands"
          }
        }
      },
      LY: {
        name: "Libya",
        sub: {
          "LY-JI": {
            type: "Popularate",
            name: "Al Jifārah"
          },
          "LY-SR": {
            type: "Popularate",
            name: "Surt"
          },
          "LY-JA": {
            type: "Popularate",
            name: "Al Jabal al Akhḑar"
          },
          "LY-DR": {
            type: "Popularate",
            name: "Darnah"
          },
          "LY-WD": {
            type: "Popularate",
            name: "Wādī al Ḩayāt"
          },
          "LY-JG": {
            type: "Popularate",
            name: "Al Jabal al Gharbī"
          },
          "LY-WA": {
            type: "Popularate",
            name: "Al Wāḩāt"
          },
          "LY-SB": {
            type: "Popularate",
            name: "Sabhā"
          },
          "LY-NQ": {
            type: "Popularate",
            name: "An Nuqāţ al Khams"
          },
          "LY-JU": {
            type: "Popularate",
            name: "Al Jufrah"
          },
          "LY-WS": {
            type: "Popularate",
            name: "Wādī ash Shāţi’"
          },
          "LY-BA": {
            type: "Popularate",
            name: "Banghāzī"
          },
          "LY-BU": {
            type: "Popularate",
            name: "Al Buţnān"
          },
          "LY-NL": {
            type: "Popularate",
            name: "Nālūt"
          },
          "LY-ZA": {
            type: "Popularate",
            name: "Az Zāwiyah"
          },
          "LY-MI": {
            type: "Popularate",
            name: "Mişrātah"
          },
          "LY-MJ": {
            type: "Popularate",
            name: "Al Marj"
          },
          "LY-TB": {
            type: "Popularate",
            name: "Ţarābulus"
          },
          "LY-GT": {
            type: "Popularate",
            name: "Ghāt"
          },
          "LY-KF": {
            type: "Popularate",
            name: "Al Kufrah"
          },
          "LY-MB": {
            type: "Popularate",
            name: "Al Marqab"
          },
          "LY-MQ": {
            type: "Popularate",
            name: "Murzuq"
          }
        }
      },
      VA: {
        name: "Vatican City",
        sub: {
          "VA-VA": {
            type: "Country",
            name: "Vatican City"
          }
        }
      },
      VC: {
        name: "Saint Vincent And The Grenadines",
        sub: {
          "VC-03": {
            type: "Parish",
            name: "Saint David"
          },
          "VC-02": {
            type: "Parish",
            name: "Saint Andrew"
          },
          "VC-01": {
            type: "Parish",
            name: "Charlotte"
          },
          "VC-06": {
            type: "Parish",
            name: "Grenadines"
          },
          "VC-05": {
            type: "Parish",
            name: "Saint Patrick"
          },
          "VC-04": {
            type: "Parish",
            name: "Saint George"
          }
        }
      },
      AE: {
        name: "United Arab Emirates",
        sub: {
          "AE-FU": {
            type: "Emirate",
            name: "Al Fujayrah"
          },
          "AE-AJ": {
            type: "Emirate",
            name: "'Ajmān"
          },
          "AE-SH": {
            type: "Emirate",
            name: "Ash Shāriqah"
          },
          "AE-AZ": {
            type: "Emirate",
            name: "Abū Z̧aby"
          },
          "AE-UQ": {
            type: "Emirate",
            name: "Umm al Qaywayn"
          },
          "AE-RK": {
            type: "Emirate",
            name: "Ra's al Khaymah"
          },
          "AE-DU": {
            type: "Emirate",
            name: "Dubayy"
          }
        }
      },
      AD: {
        name: "Andorra",
        sub: {
          "AD-08": {
            type: "Parish",
            name: "Escaldes-Engordany"
          },
          "AD-05": {
            type: "Parish",
            name: "Ordino"
          },
          "AD-04": {
            type: "Parish",
            name: "La Massana"
          },
          "AD-07": {
            type: "Parish",
            name: "Andorra la Vella"
          },
          "AD-06": {
            type: "Parish",
            name: "Sant Julià de Lòria"
          },
          "AD-03": {
            type: "Parish",
            name: "Encamp"
          },
          "AD-02": {
            type: "Parish",
            name: "Canillo"
          }
        }
      },
      AG: {
        name: "Antigua and Barbuda",
        sub: {
          "AG-11": {
            type: "Dependency",
            name: "Redonda"
          },
          "AG-10": {
            type: "Dependency",
            name: "Barbuda"
          },
          "AG-08": {
            type: "Parish",
            name: "Saint Philip"
          },
          "AG-04": {
            type: "Parish",
            name: "Saint John"
          },
          "AG-05": {
            type: "Parish",
            name: "Saint Mary"
          },
          "AG-06": {
            type: "Parish",
            name: "Saint Paul"
          },
          "AG-07": {
            type: "Parish",
            name: "Saint Peter"
          },
          "AG-03": {
            type: "Parish",
            name: "Saint George"
          }
        }
      },
      AF: {
        name: "Afghanistan",
        sub: {
          "AF-TAK": {
            type: "Province",
            name: "Takhār"
          },
          "AF-BGL": {
            type: "Province",
            name: "Baghlān"
          },
          "AF-BAM": {
            type: "Province",
            name: "Bāmyān"
          },
          "AF-BAL": {
            type: "Province",
            name: "Balkh"
          },
          "AF-SAR": {
            type: "Province",
            name: "Sar-e Pul"
          },
          "AF-KNR": {
            type: "Province",
            name: "Kunar"
          },
          "AF-SAM": {
            type: "Province",
            name: "Samangān"
          },
          "AF-LOG": {
            type: "Province",
            name: "Lōgar"
          },
          "AF-NUR": {
            type: "Province",
            name: "Nūristān"
          },
          "AF-KDZ": {
            type: "Province",
            name: "Kunduz"
          },
          "AF-NIM": {
            type: "Province",
            name: "Nīmrōz"
          },
          "AF-PAN": {
            type: "Province",
            name: "Panjshayr"
          },
          "AF-NAN": {
            type: "Province",
            name: "Nangarhār"
          },
          "AF-KHO": {
            type: "Province",
            name: "Khōst"
          },
          "AF-PIA": {
            type: "Province",
            name: "Paktiyā"
          },
          "AF-BDS": {
            type: "Province",
            name: "Badakhshān"
          },
          "AF-FYB": {
            type: "Province",
            name: "Fāryāb"
          },
          "AF-URU": {
            type: "Province",
            name: "Uruzgān"
          },
          "AF-BDG": {
            type: "Province",
            name: "Bādghīs"
          },
          "AF-LAG": {
            type: "Province",
            name: "Laghmān"
          },
          "AF-PAR": {
            type: "Province",
            name: "Parwān"
          },
          "AF-KAP": {
            type: "Province",
            name: "Kāpīsā"
          },
          "AF-GHA": {
            type: "Province",
            name: "Ghaznī"
          },
          "AF-GHO": {
            type: "Province",
            name: "Ghōr"
          },
          "AF-FRA": {
            type: "Province",
            name: "Farāh"
          },
          "AF-KAB": {
            type: "Province",
            name: "Kābul"
          },
          "AF-KAN": {
            type: "Province",
            name: "Kandahār"
          },
          "AF-ZAB": {
            type: "Province",
            name: "Zābul"
          },
          "AF-WAR": {
            type: "Province",
            name: "Wardak"
          },
          "AF-HEL": {
            type: "Province",
            name: "Helmand"
          },
          "AF-PKA": {
            type: "Province",
            name: "Paktīkā"
          },
          "AF-DAY": {
            type: "Province",
            name: "Dāykundī"
          },
          "AF-JOW": {
            type: "Province",
            name: "Jowzjān"
          },
          "AF-HER": {
            type: "Province",
            name: "Herāt"
          }
        }
      },
      AI: {
        name: "Anguilla",
        sub: {
          "AI-AI": {
            type: "Country",
            name: "Anguilla"
          }
        }
      },
      VI: {
        name: "Virgin Islands",
        sub: {
          "VI-VI": {
            type: "Country",
            name: "Virgin Islands"
          }
        }
      },
      IS: {
        name: "Iceland",
        sub: {
          "IS-8": {
            type: "Region",
            name: "Suðurland"
          },
          "IS-3": {
            type: "Region",
            name: "Vesturland"
          },
          "IS-2": {
            type: "Region",
            name: "Suðurnes"
          },
          "IS-1": {
            type: "Region",
            name: "Höfuðborgarsvæði utan Reykjavíkur"
          },
          "IS-0": {
            type: "Region",
            name: "Reykjavík"
          },
          "IS-7": {
            type: "Region",
            name: "Austurland"
          },
          "IS-6": {
            type: "Region",
            name: "Norðurland eystra"
          },
          "IS-5": {
            type: "Region",
            name: "Norðurland vestra"
          },
          "IS-4": {
            type: "Region",
            name: "Vestfirðir"
          }
        }
      },
      IR: {
        name: "Iran",
        sub: {
          "IR-03": {
            type: "Province",
            name: "Ardabīl"
          },
          "IR-02": {
            type: "Province",
            name: "Āz̄arbāyjān-e Gharbī"
          },
          "IR-01": {
            type: "Province",
            name: "Āz̄arbāyjān-e Sharqī"
          },
          "IR-26": {
            type: "Province",
            name: "Qom"
          },
          "IR-07": {
            type: "Province",
            name: "Tehrān"
          },
          "IR-06": {
            type: "Province",
            name: "Būshehr"
          },
          "IR-05": {
            type: "Province",
            name: "Īlām"
          },
          "IR-04": {
            type: "Province",
            name: "Eşfahān"
          },
          "IR-08": {
            type: "Province",
            name: "Chahār Maḩāll va Bakhtīārī"
          },
          "IR-29": {
            type: "Province",
            name: "Khorāsān-e Janūbī"
          },
          "IR-27": {
            type: "Province",
            name: "Golestān"
          },
          "IR-21": {
            type: "Province",
            name: "Māzandarān"
          },
          "IR-20": {
            type: "Province",
            name: "Lorestān"
          },
          "IR-23": {
            type: "Province",
            name: "Hormozgān"
          },
          "IR-22": {
            type: "Province",
            name: "Markazī"
          },
          "IR-18": {
            type: "Province",
            name: "Kohgīlūyeh va Būyer Aḩmad"
          },
          "IR-19": {
            type: "Province",
            name: "Gīlān"
          },
          "IR-30": {
            type: "Province",
            name: "Khorāsān-e Razavī"
          },
          "IR-31": {
            type: "Province",
            name: "Khorāsān-e Shemālī"
          },
          "IR-14": {
            type: "Province",
            name: "Fārs"
          },
          "IR-15": {
            type: "Province",
            name: "Kermān"
          },
          "IR-16": {
            type: "Province",
            name: "Kordestān"
          },
          "IR-17": {
            type: "Province",
            name: "Kermānshāh"
          },
          "IR-10": {
            type: "Province",
            name: "Khūzestān"
          },
          "IR-11": {
            type: "Province",
            name: "Zanjān"
          },
          "IR-12": {
            type: "Province",
            name: "Semnān"
          },
          "IR-13": {
            type: "Province",
            name: "Sīstān va Balūchestān"
          },
          "IR-24": {
            type: "Province",
            name: "Hamadān"
          },
          "IR-25": {
            type: "Province",
            name: "Yazd"
          },
          "IR-28": {
            type: "Province",
            name: "Qazvīn"
          },
          "IR-32": {
            type: "Province",
            name: "Alborz"
          }
        }
      },
      AM: {
        name: "Armenia",
        sub: {
          "AM-GR": {
            type: "Region",
            name: "Geġark'unik'"
          },
          "AM-SU": {
            type: "Region",
            name: "Syunik'"
          },
          "AM-AR": {
            type: "Region",
            name: "Ararat"
          },
          "AM-ER": {
            type: "City",
            name: "Erevan"
          },
          "AM-VD": {
            type: "Region",
            name: "Vayoć Jor"
          },
          "AM-KT": {
            type: "Region",
            name: "Kotayk'"
          },
          "AM-LO": {
            type: "Region",
            name: "Loṙi"
          },
          "AM-AV": {
            type: "Region",
            name: "Armavir"
          },
          "AM-TV": {
            type: "Region",
            name: "Tavuš"
          },
          "AM-AG": {
            type: "Region",
            name: "Aragac̣otn"
          },
          "AM-SH": {
            type: "Region",
            name: "Širak"
          }
        }
      },
      AL: {
        name: "Albania",
        sub: {
          "AL-DI": {
            type: "District",
            name: "Dibër"
          },
          "AL-KO": {
            type: "District",
            name: "Korçë"
          },
          "AL-FR": {
            type: "District",
            name: "Fier"
          },
          "AL-BR": {
            type: "District",
            name: "Berat"
          },
          "AL-BU": {
            type: "District",
            name: "Bulqizë"
          },
          "AL-MR": {
            type: "District",
            name: "Mirditë"
          },
          "AL-DR": {
            type: "District",
            name: "Durrës"
          },
          "AL-HA": {
            type: "District",
            name: "Has"
          },
          "AL-DV": {
            type: "District",
            name: "Devoll"
          },
          "AL-05": {
            type: "County",
            name: "Gjirokastër"
          },
          "AL-04": {
            type: "County",
            name: "Fier"
          },
          "AL-07": {
            type: "County",
            name: "Kukës"
          },
          "AL-06": {
            type: "County",
            name: "Korçë"
          },
          "AL-01": {
            type: "County",
            name: "Berat"
          },
          "AL-03": {
            type: "County",
            name: "Elbasan"
          },
          "AL-02": {
            type: "County",
            name: "Durrës"
          },
          "AL-TP": {
            type: "District",
            name: "Tropojë"
          },
          "AL-TR": {
            type: "District",
            name: "Tiranë"
          },
          "AL-09": {
            type: "County",
            name: "Dibër"
          },
          "AL-08": {
            type: "County",
            name: "Lezhë"
          },
          "AL-PG": {
            type: "District",
            name: "Pogradec"
          },
          "AL-LU": {
            type: "District",
            name: "Lushnjë"
          },
          "AL-PR": {
            type: "District",
            name: "Përmet"
          },
          "AL-TE": {
            type: "District",
            name: "Tepelenë"
          },
          "AL-PU": {
            type: "District",
            name: "Pukë"
          },
          "AL-GR": {
            type: "District",
            name: "Gramsh"
          },
          "AL-MT": {
            type: "District",
            name: "Mat"
          },
          "AL-EL": {
            type: "District",
            name: "Elbasan"
          },
          "AL-VL": {
            type: "District",
            name: "Vlorë"
          },
          "AL-KB": {
            type: "District",
            name: "Kurbin"
          },
          "AL-LB": {
            type: "District",
            name: "Librazhd"
          },
          "AL-ER": {
            type: "District",
            name: "Kolonjë"
          },
          "AL-LE": {
            type: "District",
            name: "Lezhë"
          },
          "AL-GJ": {
            type: "District",
            name: "Gjirokastër"
          },
          "AL-12": {
            type: "County",
            name: "Vlorë"
          },
          "AL-KA": {
            type: "District",
            name: "Kavajë"
          },
          "AL-10": {
            type: "County",
            name: "Shkodër"
          },
          "AL-11": {
            type: "County",
            name: "Tiranë"
          },
          "AL-MK": {
            type: "District",
            name: "Mallakastër"
          },
          "AL-KC": {
            type: "District",
            name: "Kuçovë"
          },
          "AL-SR": {
            type: "District",
            name: "Sarandë"
          },
          "AL-MM": {
            type: "District",
            name: "Malësi e Madhe"
          },
          "AL-DL": {
            type: "District",
            name: "Delvinë"
          },
          "AL-KU": {
            type: "District",
            name: "Kukës"
          },
          "AL-SH": {
            type: "District",
            name: "Shkodër"
          },
          "AL-PQ": {
            type: "District",
            name: "Peqin"
          },
          "AL-KR": {
            type: "District",
            name: "Krujë"
          },
          "AL-SK": {
            type: "District",
            name: "Skrapar"
          }
        }
      },
      AO: {
        name: "Angola",
        sub: {
          "AO-CUS": {
            type: "Province",
            name: "Kwanza Sul"
          },
          "AO-HUA": {
            type: "Province",
            name: "Huambo"
          },
          "AO-BIE": {
            type: "Province",
            name: "Bié"
          },
          "AO-NAM": {
            type: "Province",
            name: "Namibe"
          },
          "AO-LNO": {
            type: "Province",
            name: "Lunda Norte"
          },
          "AO-UIG": {
            type: "Province",
            name: "Uíge"
          },
          "AO-HUI": {
            type: "Province",
            name: "Huíla"
          },
          "AO-BGO": {
            type: "Province",
            name: "Bengo"
          },
          "AO-LUA": {
            type: "Province",
            name: "Luanda"
          },
          "AO-LSU": {
            type: "Province",
            name: "Lunda Sul"
          },
          "AO-CCU": {
            type: "Province",
            name: "Kuando Kubango"
          },
          "AO-MAL": {
            type: "Province",
            name: "Malange"
          },
          "AO-BGU": {
            type: "Province",
            name: "Benguela"
          },
          "AO-MOX": {
            type: "Province",
            name: "Moxico"
          },
          "AO-CNN": {
            type: "Province",
            name: "Cunene"
          },
          "AO-CNO": {
            type: "Province",
            name: "Kwanza Norte"
          },
          "AO-ZAI": {
            type: "Province",
            name: "Zaire"
          },
          "AO-CAB": {
            type: "Province",
            name: "Cabinda"
          }
        }
      },
      AQ: {
        name: "Antarctica",
        sub: {
          "AQ-AQ": {
            type: "Country",
            name: "Antarctica"
          }
        }
      },
      AS: {
        name: "American Samoa",
        sub: {
          "AS-AS": {
            type: "Country",
            name: "American Samoa"
          }
        }
      },
      AR: {
        name: "Argentina",
        sub: {
          "AR-Z": {
            type: "Province",
            name: "Santa Cruz"
          },
          "AR-X": {
            type: "Province",
            name: "Córdoba"
          },
          "AR-Y": {
            type: "Province",
            name: "Jujuy"
          },
          "AR-V": {
            type: "Province",
            name: "Tierra del Fuego"
          },
          "AR-W": {
            type: "Province",
            name: "Corrientes"
          },
          "AR-T": {
            type: "Province",
            name: "Tucumán"
          },
          "AR-U": {
            type: "Province",
            name: "Chubut"
          },
          "AR-R": {
            type: "Province",
            name: "Río Negro"
          },
          "AR-S": {
            type: "Province",
            name: "Santa Fe"
          },
          "AR-P": {
            type: "Province",
            name: "Formosa"
          },
          "AR-Q": {
            type: "Province",
            name: "Neuquén"
          },
          "AR-N": {
            type: "Province",
            name: "Misiones"
          },
          "AR-L": {
            type: "Province",
            name: "La Pampa"
          },
          "AR-M": {
            type: "Province",
            name: "Mendoza"
          },
          "AR-J": {
            type: "Province",
            name: "San Juan"
          },
          "AR-K": {
            type: "Province",
            name: "Catamarca"
          },
          "AR-H": {
            type: "Province",
            name: "Chaco"
          },
          "AR-F": {
            type: "Province",
            name: "La Rioja"
          },
          "AR-G": {
            type: "Province",
            name: "Santiago del Estero"
          },
          "AR-D": {
            type: "Province",
            name: "San Luis"
          },
          "AR-E": {
            type: "Province",
            name: "Entre Ríos"
          },
          "AR-B": {
            type: "Province",
            name: "Buenos Aires"
          },
          "AR-C": {
            type: "City",
            name: "Ciudad Autónoma de Buenos Aires"
          },
          "AR-A": {
            type: "Province",
            name: "Salta"
          }
        }
      },
      AU: {
        name: "Australia",
        sub: {
          "AU-ACT": {
            type: "Territory",
            name: "Australian Capital Territory"
          },
          "AU-QLD": {
            type: "State",
            name: "Queensland"
          },
          "AU-TAS": {
            type: "State",
            name: "Tasmania"
          },
          "AU-VIC": {
            type: "State",
            name: "Victoria"
          },
          "AU-NT": {
            type: "Territory",
            name: "Northern Territory"
          },
          "AU-WA": {
            type: "State",
            name: "Western Australia"
          },
          "AU-SA": {
            type: "State",
            name: "South Australia"
          },
          "AU-NSW": {
            type: "State",
            name: "New South Wales"
          }
        }
      },
      AT: {
        name: "Austria",
        sub: {
          "AT-8": {
            type: "Federal länder",
            name: "Vorarlberg"
          },
          "AT-9": {
            type: "Federal länder",
            name: "Wien"
          },
          "AT-4": {
            type: "Federal länder",
            name: "Oberösterreich"
          },
          "AT-5": {
            type: "Federal länder",
            name: "Salzburg"
          },
          "AT-6": {
            type: "Federal länder",
            name: "Steiermark"
          },
          "AT-7": {
            type: "Federal länder",
            name: "Tirol"
          },
          "AT-1": {
            type: "Federal länder",
            name: "Burgenland"
          },
          "AT-2": {
            type: "Federal länder",
            name: "Kärnten"
          },
          "AT-3": {
            type: "Federal länder",
            name: "Niederösterreich"
          }
        }
      },
      IO: {
        name: "British Indian Ocean Territory",
        sub: {
          "IO-IO": {
            type: "Country",
            name: "British Indian Ocean Territory"
          }
        }
      },
      IN: {
        name: "India",
        sub: {
          "IN-BR": {
            type: "State",
            name: "Bihar"
          },
          "IN-MZ": {
            type: "State",
            name: "Mizoram"
          },
          "IN-DD": {
            type: "Union territory",
            name: "Daman and Diu"
          },
          "IN-LD": {
            type: "Union territory",
            name: "Lakshadweep"
          },
          "IN-DN": {
            type: "Union territory",
            name: "Dadra and Nagar Haveli"
          },
          "IN-DL": {
            type: "Union territory",
            name: "Delhi"
          },
          "IN-NL": {
            type: "State",
            name: "Nagaland"
          },
          "IN-WB": {
            type: "State",
            name: "West Bengal"
          },
          "IN-HR": {
            type: "State",
            name: "Haryana"
          },
          "IN-HP": {
            type: "State",
            name: "Himachal Pradesh"
          },
          "IN-AS": {
            type: "State",
            name: "Assam"
          },
          "IN-UT": {
            type: "State",
            name: "Uttarakhand"
          },
          "IN-JH": {
            type: "State",
            name: "Jharkhand"
          },
          "IN-JK": {
            type: "State",
            name: "Jammu and Kashmir"
          },
          "IN-UP": {
            type: "State",
            name: "Uttar Pradesh"
          },
          "IN-SK": {
            type: "State",
            name: "Sikkim"
          },
          "IN-CT": {
            type: "State",
            name: "Chhattisgarh"
          },
          "IN-PY": {
            type: "Union territory",
            name: "Puducherry"
          },
          "IN-CH": {
            type: "Union territory",
            name: "Chandigarh"
          },
          "IN-GA": {
            type: "State",
            name: "Goa"
          },
          "IN-TG": {
            type: "State",
            name: "Telangana"
          },
          "IN-GJ": {
            type: "State",
            name: "Gujarat"
          },
          "IN-RJ": {
            type: "State",
            name: "Rajasthan"
          },
          "IN-MP": {
            type: "State",
            name: "Madhya Pradesh"
          },
          "IN-OR": {
            type: "State",
            name: "Odisha"
          },
          "IN-TN": {
            type: "State",
            name: "Tamil Nadu"
          },
          "IN-AN": {
            type: "Union territory",
            name: "Andaman and Nicobar Islands"
          },
          "IN-AP": {
            type: "State",
            name: "Andhra Pradesh"
          },
          "IN-TR": {
            type: "State",
            name: "Tripura"
          },
          "IN-AR": {
            type: "State",
            name: "Arunachal Pradesh"
          },
          "IN-KA": {
            type: "State",
            name: "Karnataka"
          },
          "IN-PB": {
            type: "State",
            name: "Punjab"
          },
          "IN-ML": {
            type: "State",
            name: "Meghalaya"
          },
          "IN-MN": {
            type: "State",
            name: "Manipur"
          },
          "IN-MH": {
            type: "State",
            name: "Maharashtra"
          },
          "IN-KL": {
            type: "State",
            name: "Kerala"
          }
        }
      },
      AX: {
        name: "Åland Islands",
        sub: {
          "AX-AX": {
            type: "Country",
            name: "Åland Islands"
          }
        }
      },
      AZ: {
        name: "Azerbaijan",
        sub: {
          "AZ-SBN": {
            type: "Rayon",
            name: "Şabran"
          },
          "AZ-OGU": {
            type: "Rayon",
            name: "Oğuz"
          },
          "AZ-ABS": {
            type: "Rayon",
            name: "Abşeron"
          },
          "AZ-QBA": {
            type: "Rayon",
            name: "Quba"
          },
          "AZ-FUZ": {
            type: "Rayon",
            name: "Füzuli"
          },
          "AZ-HAC": {
            type: "Rayon",
            name: "Hacıqabul"
          },
          "AZ-SKR": {
            type: "Rayon",
            name: "Şәmkir"
          },
          "AZ-AST": {
            type: "Rayon",
            name: "Astara"
          },
          "AZ-ZAQ": {
            type: "Rayon",
            name: "Zaqatala"
          },
          "AZ-QBI": {
            type: "Rayon",
            name: "Qubadlı"
          },
          "AZ-XCI": {
            type: "Rayon",
            name: "Xocalı"
          },
          "AZ-GYG": {
            type: "Rayon",
            name: "Göygöl"
          },
          "AZ-BA": {
            type: "Municipality",
            name: "Bakı"
          },
          "AZ-CUL": {
            type: "Rayon",
            name: "Culfa"
          },
          "AZ-YEV": {
            type: "Rayon",
            name: "Yevlax"
          },
          "AZ-BEY": {
            type: "Rayon",
            name: "Beyləqan"
          },
          "AZ-MAS": {
            type: "Rayon",
            name: "Masallı"
          },
          "AZ-SAB": {
            type: "Rayon",
            name: "Sabirabad"
          },
          "AZ-GOR": {
            type: "Rayon",
            name: "Goranboy"
          },
          "AZ-QAX": {
            type: "Rayon",
            name: "Qax"
          },
          "AZ-BAR": {
            type: "Rayon",
            name: "Bərdə"
          },
          "AZ-SAH": {
            type: "Rayon",
            name: "Şahbuz"
          },
          "AZ-LER": {
            type: "Rayon",
            name: "Lerik"
          },
          "AZ-SAK": {
            type: "Rayon",
            name: "Şәki"
          },
          "AZ-SAL": {
            type: "Rayon",
            name: "Salyan"
          },
          "AZ-ORD": {
            type: "Rayon",
            name: "Ordubad"
          },
          "AZ-KUR": {
            type: "Rayon",
            name: "Kürdəmir"
          },
          "AZ-GOY": {
            type: "Rayon",
            name: "Göyçay"
          },
          "AZ-NA": {
            type: "Municipality",
            name: "Naftalan"
          },
          "AZ-CAB": {
            type: "Rayon",
            name: "Cəbrayıl"
          },
          "AZ-XVD": {
            type: "Rayon",
            name: "Xocavәnd"
          },
          "AZ-LAC": {
            type: "Rayon",
            name: "Laçın"
          },
          "AZ-QOB": {
            type: "Rayon",
            name: "Qobustan"
          },
          "AZ-BAB": {
            type: "Rayon",
            name: "Babək"
          },
          "AZ-YE": {
            type: "Municipality",
            name: "Yevlax"
          },
          "AZ-BAL": {
            type: "Rayon",
            name: "Balakən"
          },
          "AZ-IMI": {
            type: "Rayon",
            name: "İmişli"
          },
          "AZ-CAL": {
            type: "Rayon",
            name: "Cəlilabad"
          },
          "AZ-LA": {
            type: "Municipality",
            name: "Lәnkәran"
          },
          "AZ-BIL": {
            type: "Rayon",
            name: "Biləsuvar"
          },
          "AZ-SMI": {
            type: "Rayon",
            name: "Şamaxı"
          },
          "AZ-SIY": {
            type: "Rayon",
            name: "Siyәzәn"
          },
          "AZ-AGU": {
            type: "Rayon",
            name: "Ağsu"
          },
          "AZ-SUS": {
            type: "Rayon",
            name: "Şuşa"
          },
          "AZ-TAR": {
            type: "Rayon",
            name: "Tәrtәr"
          },
          "AZ-AGS": {
            type: "Rayon",
            name: "Ağdaş"
          },
          "AZ-AGM": {
            type: "Rayon",
            name: "Ağdam"
          },
          "AZ-YAR": {
            type: "Rayon",
            name: "Yardımlı"
          },
          "AZ-SM": {
            type: "Municipality",
            name: "Sumqayıt"
          },
          "AZ-SA": {
            type: "Municipality",
            name: "Şәki"
          },
          "AZ-LAN": {
            type: "Rayon",
            name: "Lənkəran"
          },
          "AZ-GAD": {
            type: "Rayon",
            name: "Gədəbəy"
          },
          "AZ-QAZ": {
            type: "Rayon",
            name: "Qazax"
          },
          "AZ-GA": {
            type: "Municipality",
            name: "Gәncә"
          },
          "AZ-AGA": {
            type: "Rayon",
            name: "Ağstafa"
          },
          "AZ-SAT": {
            type: "Rayon",
            name: "Saatlı"
          },
          "AZ-AGC": {
            type: "Rayon",
            name: "Ağcabәdi"
          },
          "AZ-QUS": {
            type: "Rayon",
            name: "Qusar"
          },
          "AZ-ZAN": {
            type: "Rayon",
            name: "Zәngilan"
          },
          "AZ-SAR": {
            type: "Rayon",
            name: "Şәrur"
          },
          "AZ-UCA": {
            type: "Rayon",
            name: "Ucar"
          },
          "AZ-TOV": {
            type: "Rayon",
            name: "Tovuz"
          },
          "AZ-QAB": {
            type: "Rayon",
            name: "Qәbәlә"
          },
          "AZ-NV": {
            type: "Municipality",
            name: "Naxçıvan"
          },
          "AZ-ISM": {
            type: "Rayon",
            name: "İsmayıllı"
          },
          "AZ-SR": {
            type: "Municipality",
            name: "Şirvan"
          },
          "AZ-MI": {
            type: "Municipality",
            name: "Mingәçevir"
          },
          "AZ-NEF": {
            type: "Rayon",
            name: "Neftçala"
          },
          "AZ-KAL": {
            type: "Rayon",
            name: "Kəlbəcər"
          },
          "AZ-KAN": {
            type: "Rayon",
            name: "Kǝngǝrli"
          },
          "AZ-NX": {
            type: "Autonomous republic",
            name: "Naxçıvan"
          },
          "AZ-XAC": {
            type: "Rayon",
            name: "Xaçmaz"
          },
          "AZ-SMX": {
            type: "Rayon",
            name: "Samux"
          },
          "AZ-XA": {
            type: "Municipality",
            name: "Xankәndi"
          },
          "AZ-XIZ": {
            type: "Rayon",
            name: "Xızı"
          },
          "AZ-DAS": {
            type: "Rayon",
            name: "Daşkəsən"
          },
          "AZ-SAD": {
            type: "Rayon",
            name: "Sәdәrәk"
          },
          "AZ-ZAR": {
            type: "Rayon",
            name: "Zәrdab"
          }
        }
      },
      IE: {
        name: "Ireland",
        sub: {
          "IE-WD": {
            type: "County",
            name: "Port Láirge"
          },
          "IE-TA": {
            type: "County",
            name: "Tiobraid Árann"
          },
          "IE-WW": {
            type: "County",
            name: "Cill Mhantáin"
          },
          "IE-CW": {
            type: "County",
            name: "Carlow"
          },
          "IE-C": {
            type: "Province",
            name: "Connacht"
          },
          "IE-D": {
            type: "County",
            name: "Baile Átha Cliath"
          },
          "IE-G": {
            type: "County",
            name: "Gaillimh"
          },
          "IE-M": {
            type: "Province",
            name: "An Mhumhain"
          },
          "IE-L": {
            type: "Province",
            name: "Laighin"
          },
          "IE-RN": {
            type: "County",
            name: "Ros Comáin"
          },
          "IE-MO": {
            type: "County",
            name: "Maigh Eo"
          },
          "IE-MN": {
            type: "County",
            name: "Monaghan"
          },
          "IE-U": {
            type: "Province",
            name: "Ulaidh"
          },
          "IE-MH": {
            type: "County",
            name: "An Mhí"
          },
          "IE-CO": {
            type: "County",
            name: "Corcaigh"
          },
          "IE-CN": {
            type: "County",
            name: "An Cabhán"
          },
          "IE-KK": {
            type: "County",
            name: "Cill Chainnigh"
          },
          "IE-KE": {
            type: "County",
            name: "Cill Dara"
          },
          "IE-WX": {
            type: "County",
            name: "Loch Garman"
          },
          "IE-OY": {
            type: "County",
            name: "Offaly"
          },
          "IE-KY": {
            type: "County",
            name: "Ciarraí"
          },
          "IE-CE": {
            type: "County",
            name: "An Clár"
          },
          "IE-WH": {
            type: "County",
            name: "An Iarmhí"
          },
          "IE-SO": {
            type: "County",
            name: "Sligeach"
          },
          "IE-DL": {
            type: "County",
            name: "Donegal"
          },
          "IE-LS": {
            type: "County",
            name: "Laois"
          },
          "IE-LM": {
            type: "County",
            name: "Leitrim"
          },
          "IE-LK": {
            type: "County",
            name: "Limerick"
          },
          "IE-LH": {
            type: "County",
            name: "Louth"
          },
          "IE-LD": {
            type: "County",
            name: "An Longfort"
          }
        }
      },
      ID: {
        name: "Indonesia",
        sub: {
          "ID-JK": {
            type: "Special district",
            name: "Jakarta Raya"
          },
          "ID-SL": {
            type: "Geographical unit",
            name: "Sulawesi"
          },
          "ID-JI": {
            type: "Province",
            name: "Jawa Timur"
          },
          "ID-SM": {
            type: "Geographical unit",
            name: "Sumatera"
          },
          "ID-JB": {
            type: "Province",
            name: "Jawa Barat"
          },
          "ID-JA": {
            type: "Province",
            name: "Jambi"
          },
          "ID-NT": {
            type: "Province",
            name: "Nusa Tenggara Timur"
          },
          "ID-SA": {
            type: "Province",
            name: "Sulawesi Utara"
          },
          "ID-SB": {
            type: "Province",
            name: "Sumatera Barat"
          },
          "ID-LA": {
            type: "Province",
            name: "Lampung"
          },
          "ID-YO": {
            type: "Special region",
            name: "Yogyakarta"
          },
          "ID-ST": {
            type: "Province",
            name: "Sulawesi Tengah"
          },
          "ID-SU": {
            type: "Province",
            name: "Sumatera Utara"
          },
          "ID-JW": {
            type: "Geographical unit",
            name: "Jawa"
          },
          "ID-NB": {
            type: "Province",
            name: "Nusa Tenggara Barat"
          },
          "ID-SR": {
            type: "Province",
            name: "Sulawesi Barat"
          },
          "ID-JT": {
            type: "Province",
            name: "Jawa Tengah"
          },
          "ID-MU": {
            type: "Province",
            name: "Maluku Utara"
          },
          "ID-AC": {
            type: "Autonomous province",
            name: "Aceh"
          },
          "ID-SS": {
            type: "Province",
            name: "Sumatera Selatan"
          },
          "ID-NU": {
            type: "Geographical unit",
            name: "Nusa Tenggara"
          },
          "ID-KT": {
            type: "Province",
            name: "Kalimantan Tengah"
          },
          "ID-KU": {
            type: "Province",
            name: "Kalimantan Utara"
          },
          "ID-SG": {
            type: "Province",
            name: "Sulawesi Tenggara"
          },
          "ID-PA": {
            type: "Province",
            name: "Papua"
          },
          "ID-KR": {
            type: "Province",
            name: "Kepulauan Riau"
          },
          "ID-KS": {
            type: "Province",
            name: "Kalimantan Selatan"
          },
          "ID-RI": {
            type: "Province",
            name: "Riau"
          },
          "ID-PB": {
            type: "Province",
            name: "Papua Barat"
          },
          "ID-GO": {
            type: "Province",
            name: "Gorontalo"
          },
          "ID-MA": {
            type: "Province",
            name: "Maluku"
          },
          "ID-KA": {
            type: "Geographical unit",
            name: "Kalimantan"
          },
          "ID-KB": {
            type: "Province",
            name: "Kalimantan Barat"
          },
          "ID-KI": {
            type: "Province",
            name: "Kalimantan Timur"
          },
          "ID-ML": {
            type: "Geographical unit",
            name: "Maluku"
          },
          "ID-SN": {
            type: "Province",
            name: "Sulawesi Selatan"
          },
          "ID-BT": {
            type: "Province",
            name: "Banten"
          },
          "ID-BB": {
            type: "Province",
            name: "Bangka Belitung"
          },
          "ID-BA": {
            type: "Province",
            name: "Bali"
          },
          "ID-BE": {
            type: "Province",
            name: "Bengkulu"
          },
          "ID-PP": {
            type: "Geographical unit",
            name: "Papua"
          }
        }
      },
      UA: {
        name: "Ukraine",
        sub: {
          "UA-61": {
            type: "Region",
            name: "Ternopilska oblast"
          },
          "UA-21": {
            type: "Region",
            name: "Zakarpatska oblast"
          },
          "UA-23": {
            type: "Region",
            name: "Zaporizka oblast"
          },
          "UA-26": {
            type: "Region",
            name: "Ivano-Frankivska oblast"
          },
          "UA-05": {
            type: "Region",
            name: "Vinnytska oblast"
          },
          "UA-46": {
            type: "Region",
            name: "Lvivska oblast"
          },
          "UA-09": {
            type: "Region",
            name: "Luhanska oblast"
          },
          "UA-68": {
            type: "Region",
            name: "Khmelnytska oblast"
          },
          "UA-43": {
            type: "Republic",
            name: "Avtonomna Respublika Krym"
          },
          "UA-40": {
            type: "City",
            name: "Sevastopol"
          },
          "UA-65": {
            type: "Region",
            name: "Khersonska oblast"
          },
          "UA-07": {
            type: "Region",
            name: "Volynska oblast"
          },
          "UA-48": {
            type: "Region",
            name: "Mykolaivska oblast"
          },
          "UA-63": {
            type: "Region",
            name: "Kharkivska oblast"
          },
          "UA-35": {
            type: "Region",
            name: "Kirovohradska oblast"
          },
          "UA-32": {
            type: "Region",
            name: "Kyivska oblast"
          },
          "UA-30": {
            type: "City",
            name: "Kyiv"
          },
          "UA-77": {
            type: "Region",
            name: "Chernivetska oblast"
          },
          "UA-18": {
            type: "Region",
            name: "Zhytomyrska oblast"
          },
          "UA-71": {
            type: "Region",
            name: "Cherkaska oblast"
          },
          "UA-56": {
            type: "Region",
            name: "Rivnenska oblast"
          },
          "UA-51": {
            type: "Region",
            name: "Odeska oblast"
          },
          "UA-53": {
            type: "Region",
            name: "Poltavska oblast"
          },
          "UA-74": {
            type: "Region",
            name: "Chernihivska oblast"
          },
          "UA-12": {
            type: "Region",
            name: "Dnipropetrovska oblast"
          },
          "UA-59": {
            type: "Region",
            name: "Sumska oblast"
          },
          "UA-14": {
            type: "Region",
            name: "Donetska oblast"
          }
        }
      },
      QA: {
        name: "Qatar",
        sub: {
          "QA-MS": {
            type: "Municipality",
            name: "Ash Shamāl"
          },
          "QA-KH": {
            type: "Municipality",
            name: "Al Khawr wa adh Dhakhīrah"
          },
          "QA-ZA": {
            type: "Municipality",
            name: "Az̧ Z̧a‘āyin"
          },
          "QA-RA": {
            type: "Municipality",
            name: "Ar Rayyān"
          },
          "QA-WA": {
            type: "Municipality",
            name: "Al Wakrah"
          },
          "QA-US": {
            type: "Municipality",
            name: "Umm Şalāl"
          },
          "QA-DA": {
            type: "Municipality",
            name: "Ad Dawḩah"
          }
        }
      },
      MZ: {
        name: "Mozambique",
        sub: {
          "MZ-MPM": {
            type: "City",
            name: "Maputo"
          },
          "MZ-I": {
            type: "Province",
            name: "Inhambane"
          },
          "MZ-N": {
            type: "Province",
            name: "Nampula"
          },
          "MZ-L": {
            type: "Province",
            name: "Maputo"
          },
          "MZ-B": {
            type: "Province",
            name: "Manica"
          },
          "MZ-A": {
            type: "Province",
            name: "Niassa"
          },
          "MZ-G": {
            type: "Province",
            name: "Gaza"
          },
          "MZ-S": {
            type: "Province",
            name: "Sofala"
          },
          "MZ-P": {
            type: "Province",
            name: "Cabo Delgado"
          },
          "MZ-Q": {
            type: "Province",
            name: "Zambézia"
          },
          "MZ-T": {
            type: "Province",
            name: "Tete"
          }
        }
      }
    },
    codes = {
      AGO: "AO",
      DZA: "DZ",
      EGY: "EG",
      BGD: "BD",
      NER: "NE",
      LIE: "LI",
      NAM: "NA",
      BGR: "BG",
      BOL: "BO",
      GHA: "GH",
      CCK: "CC",
      PAK: "PK",
      CPV: "CV",
      JOR: "JO",
      LBR: "LR",
      LBY: "LY",
      MYS: "MY",
      DOM: "DO",
      PRI: "PR",
      SXM: "SX",
      PRK: "KP",
      PSE: "PS",
      TZA: "TZ",
      BWA: "BW",
      KHM: "KH",
      UMI: "UM",
      NIC: "NI",
      TTO: "TT",
      ETH: "ET",
      PRY: "PY",
      HKG: "HK",
      SAU: "SA",
      LBN: "LB",
      SVN: "SI",
      BFA: "BF",
      CHE: "CH",
      MRT: "MR",
      HRV: "HR",
      CHL: "CL",
      CHN: "CN",
      KNA: "KN",
      SLE: "SL",
      JAM: "JM",
      SMR: "SM",
      GIB: "GI",
      DJI: "DJ",
      GIN: "GN",
      FIN: "FI",
      URY: "UY",
      THA: "TH",
      STP: "ST",
      SYC: "SC",
      NPL: "NP",
      CXR: "CX",
      LAO: "LA",
      YEM: "YE",
      BVT: "BV",
      ZAF: "ZA",
      KIR: "KI",
      PHL: "PH",
      ROU: "RO",
      VIR: "VI",
      SYR: "SY",
      MAC: "MO",
      MAF: "MF",
      MLT: "MT",
      KAZ: "KZ",
      TCA: "TC",
      PYF: "PF",
      NIU: "NU",
      DMA: "DM",
      BEN: "BJ",
      GUF: "GF",
      BEL: "BE",
      MSR: "MS",
      TGO: "TG",
      DEU: "DE",
      GUM: "GU",
      LKA: "LK",
      SSD: "SS",
      FLK: "FK",
      GBR: "GB",
      BES: "BQ",
      GUY: "GY",
      CRI: "CR",
      CMR: "CM",
      MAR: "MA",
      MNP: "MP",
      LSO: "LS",
      HUN: "HU",
      TKM: "TM",
      SUR: "SR",
      NLD: "NL",
      BMU: "BM",
      HMD: "HM",
      TCD: "TD",
      GEO: "GE",
      MNE: "ME",
      MNG: "MN",
      MHL: "MH",
      MTQ: "MQ",
      BLZ: "BZ",
      NFK: "NF",
      MMR: "MM",
      AFG: "AF",
      BDI: "BI",
      VGB: "VG",
      BLR: "BY",
      BLM: "BL",
      GRD: "GD",
      TKL: "TK",
      GRC: "GR",
      RUS: "RU",
      GRL: "GL",
      SHN: "SH",
      AND: "AD",
      MOZ: "MZ",
      TJK: "TJ",
      HTI: "HT",
      MEX: "MX",
      ZWE: "ZW",
      LCA: "LC",
      IND: "IN",
      LVA: "LV",
      BTN: "BT",
      VCT: "VC",
      VNM: "VN",
      NOR: "NO",
      CZE: "CZ",
      ATF: "TF",
      ATG: "AG",
      FJI: "FJ",
      IOT: "IO",
      HND: "HN",
      MUS: "MU",
      ATA: "AQ",
      LUX: "LU",
      ISR: "IL",
      FSM: "FM",
      PER: "PE",
      REU: "RE",
      IDN: "ID",
      VUT: "VU",
      MKD: "MK",
      COD: "CD",
      COG: "CG",
      ISL: "IS",
      GLP: "GP",
      COK: "CK",
      COM: "KM",
      COL: "CO",
      NGA: "NG",
      TLS: "TL",
      TWN: "TW",
      PRT: "PT",
      MDA: "MD",
      GGY: "GG",
      MDG: "MG",
      ECU: "EC",
      SEN: "SN",
      NZL: "NZ",
      MDV: "MV",
      ASM: "AS",
      SPM: "PM",
      CUW: "CW",
      FRA: "FR",
      LTU: "LT",
      RWA: "RW",
      ZMB: "ZM",
      GMB: "GM",
      WLF: "WF",
      JEY: "JE",
      FRO: "FO",
      GTM: "GT",
      DNK: "DK",
      IMN: "IM",
      AUS: "AU",
      AUT: "AT",
      SJM: "SJ",
      VEN: "VE",
      PLW: "PW",
      KEN: "KE",
      MYT: "YT",
      WSM: "WS",
      TUR: "TR",
      ALB: "AL",
      OMN: "OM",
      TUV: "TV",
      ALA: "AX",
      BRN: "BN",
      TUN: "TN",
      PCN: "PN",
      BRB: "BB",
      BRA: "BR",
      CIV: "CI",
      SRB: "RS",
      GNQ: "GQ",
      USA: "US",
      QAT: "QA",
      SWE: "SE",
      AZE: "AZ",
      GNB: "GW",
      SWZ: "SZ",
      TON: "TO",
      CAN: "CA",
      UKR: "UA",
      KOR: "KR",
      AIA: "AI",
      CAF: "CF",
      SVK: "SK",
      CYP: "CY",
      BIH: "BA",
      SGP: "SG",
      SGS: "GS",
      SOM: "SO",
      UZB: "UZ",
      ERI: "ER",
      POL: "PL",
      KWT: "KW",
      GAB: "GA",
      CYM: "KY",
      VAT: "VA",
      EST: "EE",
      MWI: "MW",
      ESP: "ES",
      IRQ: "IQ",
      SLV: "SV",
      MLI: "ML",
      IRL: "IE",
      IRN: "IR",
      ABW: "AW",
      PNG: "PG",
      PAN: "PA",
      SDN: "SD",
      SLB: "SB",
      ESH: "EH",
      MCO: "MC",
      ITA: "IT",
      JPN: "JP",
      KGZ: "KG",
      UGA: "UG",
      NCL: "NC",
      ARE: "AE",
      ARG: "AR",
      BHS: "BS",
      BHR: "BH",
      ARM: "AM",
      NRU: "NR",
      CUB: "CU"
    },
    functions = {
      subdivision: function (e, a) {
        if ("undefined" == typeof a) {
          a = e.trim().toUpperCase();
          var n = a.split("-");
          if (2 !== n.length) return {};
          e = n[0], a = n[1];
        }
        3 === e.length && (e = codes[e]);
        var t = e + "-" + a;
        if (!(e in data)) return null;
        var i = data[e].sub,
          m = i[t];
        if ("undefined" == typeof m) {
          for (var o in i) if (i.hasOwnProperty(o) && i[o].name.toUpperCase() === a.toUpperCase()) {
            m = i[o], t = o;
            break;
          }
          if ("undefined" == typeof m) return null;
        }
        return m.countryName = data[e].name, m.countryCode = e, m.code = t, m.regionCode = 2 === t.split("-").length ? t.split("-")[1] : "", m;
      },
      country: function (e) {
        if (e = e.trim().toUpperCase(), 3 === e.length && (e = codes[e]), e in data) {
          var a = data[e];
          return a.code = e, a;
        }
        for (var n in data) if (data.hasOwnProperty(n) && data[n].name.toUpperCase() === e.toUpperCase()) {
          a = data[n], a.code = n;
          break;
        }
        return "undefined" == typeof a ? null : a;
      },
      data: data,
      codes: codes
    };
  "function" == typeof define && define.amd ? define(function () {
    return functions;
  }) : "object" == typeof module && null !== module ? module.exports = functions : window.iso3166 = functions;
})();