<div class="header-container" [ngClass]="{ 'header-small': small }">
  <div class="header-content">
    <div class="left-content header-area">
      <h1 *ngIf="heading.header || heading.icon" class="header">
        <img
          *ngIf="heading.icon"
          class="header-icon"
          [alt]="heading.header"
          src="{{ heading.icon }}" />
        <ng-container *ngIf="heading.header">{{
          heading.header | translate
        }}</ng-container>
      </h1>
      <h2 *ngIf="heading.subHeading">
        {{ heading.subHeading | translate }}
      </h2>
      <span *ngIf="heading.description">
        {{ heading.description | translate }}
      </span>
    </div>
    <ng-content></ng-content>
  </div>
</div>
