import { Pipe, PipeTransform } from '@angular/core';
import { SearchFormConfig } from '@app/shared/search-card';

export const transformRadiusOptions = (
  maxRadius: number | undefined
): number[] => {
  if (!maxRadius) {
    return [];
  }

  const options = SearchFormConfig.INSTANCE.RADIUS_OPTION_VALUES.filter(
    radius => radius <= maxRadius
  );

  if (!options.includes(maxRadius)) {
    options.push(maxRadius);
  }

  return options;
};

@Pipe({
  name: 'radiusOptions',
  standalone: true,
})
export class RadiusOptionsPipe implements PipeTransform {
  public transform(maxRadius: number | undefined): number[] {
    return transformRadiusOptions(maxRadius);
  }
}
