<ng-container *ngIf="input">
  <form [formGroup]="maxCapacityFormGroup">
    <mat-form-field color="accent">
      <mat-label>{{
        'SEARCH_CARD.TOTAL_LOCATION_CAPACITY_IN_KW' | translate
      }}</mat-label>
      <input
        type="number"
        matInput
        [title]="'SEARCH_CARD.TOTAL_LOCATION_CAPACITY_IN_KW' | translate"
        [placeholder]="'SEARCH_CARD.TOTAL_LOCATION_CAPACITY_IN_KW' | translate"
        [formControlName]="FORM_NAME.MAX_CAPACITY_IN_KW"
        [min]="minMaxCapacityInKw"
        [max]="maxMaxCapacityInKw"
        [attr.data-testid]="FORM_NAME.MAX_CAPACITY_IN_KW" />
      <mat-error
        *ngIf="
          maxCapacityFormGroup?.invalid &&
          (maxCapacityFormGroup?.dirty || maxCapacityFormGroup?.touched)
        ">
        <span
          *ngIf="
            maxCapacityFormGroup.get(FORM_NAME.MAX_CAPACITY_IN_KW)?.errors?.[
              'required'
            ]
          ">
          {{ 'COMMON.REQUIRED_FIELD' | translate }}
        </span>
        <span
          *ngIf="
            maxCapacityFormGroup?.get(FORM_NAME.MAX_CAPACITY_IN_KW)?.errors?.[
              'min'
            ]
          ">
          {{
            'SEARCH_CARD.MIN_LENGTH_ERROR'
              | translate: { minLength: minMaxCapacityInKw }
          }}
        </span>
        <span
          *ngIf="
            maxCapacityFormGroup?.get(FORM_NAME.MAX_CAPACITY_IN_KW)?.errors?.[
              'max'
            ]
          ">
          {{
            'SEARCH_CARD.MAX_LENGTH_ERROR'
              | translate: { maxLength: maxMaxCapacityInKw }
          }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>
</ng-container>
