import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-icon-label',
  standalone: true,
  templateUrl: './icon-label.component.html',
  styleUrls: ['./icon-label.component.scss'],
  imports: [MatIconModule, MatMenuModule, NgIf, MatTooltipModule],
})
export class IconLabelComponent {
  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger | null = null;

  @Input()
  public icon = '';

  @Input()
  public label?: string;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public menu: any = null;

  @Input()
  public responsiveLabel = true;

  @Input()
  public reverseLabel = false;

  public openMenu(): void {
    this.trigger?.openMenu();
  }
}
