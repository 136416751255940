import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { APP_ROUTES } from '@app/shared/app-routes.enum';
import { MapSmallComponent } from '@app/shared/project-card/components/map-small/map-small.component';
import { ProjectCardFooterComponent } from '@app/shared/project-card/components/project-card-footer/project-card-footer.component';
import { ProjectCardHeaderComponent } from '@app/shared/project-card/components/project-card-header/project-card-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { LngLat, LngLatLike } from 'maplibre-gl';
import { DialogData, ProjectStatisticDto } from '@app/shared';

@Component({
  selector: 'app-project-card',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    ProjectCardFooterComponent,
    MapSmallComponent,
    TranslateModule,
    ProjectCardHeaderComponent,
  ],
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() public project?: ProjectStatisticDto;
  @Output() public deleteProject = new EventEmitter<DialogData>();
  @Output() public updateProject = new EventEmitter<DialogData>();

  public coordinates: LngLatLike = [6.993161644021407, 51.424822916147576];
  public readonly APP_ROUTES = APP_ROUTES;

  public ngOnInit(): void {
    if (this.project?.lastSavedLocation) {
      this.coordinates = new LngLat(
        this.project?.lastSavedLocation.longitude,
        this.project?.lastSavedLocation.latitude
      );
    }
  }

  public onProjectDeletion($event: DialogData): void {
    this.deleteProject.emit($event);
  }

  public onUpdateDeletion($event: DialogData): void {
    this.updateProject.emit($event);
  }
}
