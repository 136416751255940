import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import {
  CopyValueBoxComponent,
  DIALOG_EVENT,
  DialogLoadingIndicatorComponent,
  LinkButtonComponent,
  SharingDetailsDto,
} from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ProjectSharingService } from '@app/core';
import { DialogHeaderComponent } from '@app/shared/dialogs/dialog-header/dialog-header.component';
import { CommonModule } from '@angular/common';
import {
  SharingDialogData,
  ShareUrlFactory,
} from '@app/core/components/navbar';

@Component({
  selector: 'app-share-project-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    TranslateModule,
    MatProgressBarModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    DialogLoadingIndicatorComponent,
    CopyValueBoxComponent,
    LinkButtonComponent,
    MatIconModule,
  ],
  templateUrl: './sharing-project-dialog.component.html',
  styleUrls: ['./sharing-project-dialog.component.scss'],
})
export class SharingProjectDialogComponent implements OnInit {
  readonly #projectSharingService = inject(ProjectSharingService);
  readonly #destroyRef = inject(DestroyRef);

  public isLoading = false;
  public isSuccessfulSave = false;
  public shareProjectUrl?: string;
  public isSaveButtonDisabled = false;
  sharingDetails?: SharingDetailsDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SharingDialogData,
    public dialogRef: MatDialogRef<SharingProjectDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.sharingDetails = this.data.sharingDetails;
    if (this.sharingDetails) {
      this.shareProjectUrl = ShareUrlFactory.create(
        window.location.href,
        this.sharingDetails.id
      );
    }
  }

  public closeDialog(): void {
    if (this.isSuccessfulSave) {
      this.dialogRef.close({
        ...this.data,
        event: DIALOG_EVENT.CREATE,
        sharingDetails: this.sharingDetails,
      });
    } else {
      this.dialogRef.close({ ...this.data, event: null });
    }
  }

  public executeProjectSharing(): void {
    this.isLoading = true;
    this.#projectSharingService
      .createSharedProject(this.data.projectId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: (sharingDetails: SharingDetailsDto) => {
          this.sharingDetails = sharingDetails;
          this.isSuccessfulSave = true;
          this.isLoading = false;
          this.shareProjectUrl = ShareUrlFactory.create(
            window.location.href,
            sharingDetails.id
          );
          this.isSaveButtonDisabled = true;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  public deleteSharingDetails(): void {
    this.isLoading = true;
    this.#projectSharingService
      .deleteSharingDetails(this.data.projectId)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.dialogRef.close({ ...this.data, event: DIALOG_EVENT.DELETE });
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
}
