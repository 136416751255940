import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {
  APP_ROUTES,
  DIALOG_EVENT,
  DialogConfigFactory,
  LinkButtonComponent,
  SharingDetailsDto,
  SharingProjectDialogComponent,
} from '@app/shared';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { isNil } from 'lodash-es';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProjectSharingService } from '@app/core';
import { SpotsSkeletonListComponent } from '@app/map/components/sidebar/components/spots-found/components';
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { SharingDialogData } from '../sharing-dialog-data';
import { ShareProjectBtnSkeletonComponent } from './share-project-btn-skeleton/share-project-btn-skeleton.component';

export type OptionIdentifier = 'share';

export interface MatOptionValue {
  identifier: OptionIdentifier;
  icon: string;
  value: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-share-project-btn',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    FormsModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatChipsModule,
    ReactiveFormsModule,
    LinkButtonComponent,
    MatDialogModule,
    MatProgressBarModule,
    SpotsSkeletonListComponent,
    ShareProjectBtnSkeletonComponent,
  ],
  templateUrl: './share-project-btn.component.html',
  styleUrls: ['./share-project-btn.component.scss'],
})
export class ShareProjectBtnComponent implements OnInit {
  @Input() public projectId?: string;

  readonly #dialog = inject(MatDialog);
  readonly #projectSharingService = inject(ProjectSharingService);
  readonly #destroyRef = inject(DestroyRef);

  protected readonly APP_ROUTES = APP_ROUTES;

  sharingDetails?: SharingDetailsDto | null;
  public options: MatOptionValue[] = [
    {
      identifier: 'share',
      icon: 'share',
      value: 'COMMON.SHARE',
      isSelected: false,
    },
  ];

  public ngOnInit(): void {
    if (this.projectId) {
      this.#projectSharingService
        .loadSharingDetails(this.projectId)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe({
          next: sharingDetails => {
            if (sharingDetails) {
              this.sharingDetails = sharingDetails;
              this.selectOptionByIdentifier('share');
            } else {
              this.unselectOptionByIdentifier('share');
            }
          },
          error: () => {
            this.sharingDetails = null;
            // no error handling because of possible 404
          },
        });
    }
  }

  public onChange($event: MatChipListboxChange): void {
    const config = DialogConfigFactory.INSTANCE.buildDialogConfig('medium');
    if (this.isDisableChipEvent($event)) {
      this.selectOptionByIdentifier('share'); // prevent directly disabling of chip
      this.initSharingDialog($event, config);
    } else {
      this.unselectOptionByIdentifier($event.value.identifier);
      this.initSharingDialog($event, config);
    }
  }

  private selectOptionByIdentifier(identifier: OptionIdentifier): void {
    const optionIndex = this.options.findIndex(
      (element: MatOptionValue) => element.identifier === identifier
    );
    this.options[optionIndex] = {
      ...this.options[optionIndex],
      isSelected: true,
    };
  }

  private initSharingDialog(
    $event: MatChipListboxChange,
    config: MatDialogConfig
  ): void {
    const dialogRef = this.#dialog.open(SharingProjectDialogComponent, {
      ...config,
      data: {
        projectId: this.projectId,
        sharingDetails: this.sharingDetails,
      },
    });

    dialogRef.afterClosed().subscribe((dialogData: SharingDialogData) => {
      if (dialogData.event === DIALOG_EVENT.DELETE) {
        this.sharingDetails = null;
        this.unselectOptionByIdentifier('share');
      } else if (!isNil(dialogData.event) && $event.value?.identifier) {
        this.sharingDetails = dialogData.sharingDetails;
        this.selectOptionByIdentifier($event.value.identifier);
      }
    });
  }

  private isDisableChipEvent($event: MatChipListboxChange) {
    return isNil($event.value);
  }

  private unselectOptionByIdentifier(identifier: OptionIdentifier): void {
    const optionIndex = this.options.findIndex(
      (element: MatOptionValue) => element.identifier === identifier
    );
    this.options[optionIndex] = {
      ...this.options[optionIndex],
      isSelected: false,
    };
  }
}
