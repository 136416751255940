<ng-container *ngIf="input">
  <form [formGroup]="amountOfChargingStationsFormGroup">
    <mat-form-field color="accent">
      <mat-label>{{
        'SEARCH_CARD.AMOUNT_OF_CHARGING_STATIONS_TO_BUILD' | translate
      }}</mat-label>
      <input
        type="number"
        matInput
        [title]="'SEARCH_CARD.AMOUNT_OF_CHARGING_STATIONS_TO_BUILD' | translate"
        [placeholder]="
          'SEARCH_CARD.AMOUNT_OF_CHARGING_STATIONS_TO_BUILD' | translate
        "
        [formControlName]="FORM_NAME.AMOUNT_OF_CHARGING_STATIONS"
        [min]="minAmountOfChargingStations"
        [max]="maxAmountOfChargingStations"
        [attr.data-testid]="FORM_NAME.AMOUNT_OF_CHARGING_STATIONS" />
      <mat-error
        *ngIf="
          amountOfChargingStationsFormGroup?.invalid &&
          (amountOfChargingStationsFormGroup?.dirty ||
            amountOfChargingStationsFormGroup?.touched)
        ">
        <span
          *ngIf="
            amountOfChargingStationsFormGroup.get(
              FORM_NAME.AMOUNT_OF_CHARGING_STATIONS
            )?.errors?.['required']
          ">
          {{ 'COMMON.REQUIRED_FIELD' | translate }}
        </span>
        <span
          *ngIf="
            amountOfChargingStationsFormGroup?.get(
              FORM_NAME.AMOUNT_OF_CHARGING_STATIONS
            )?.errors?.['min']
          ">
          {{
            'SEARCH_CARD.MIN_LENGTH_ERROR'
              | translate: { minLength: minAmountOfChargingStations }
          }}
        </span>
        <span
          *ngIf="
            amountOfChargingStationsFormGroup?.get(
              FORM_NAME.AMOUNT_OF_CHARGING_STATIONS
            )?.errors?.['max']
          ">
          {{
            'SEARCH_CARD.MAX_LENGTH_ERROR'
              | translate: { maxLength: maxAmountOfChargingStations }
          }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>
</ng-container>
