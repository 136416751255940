import { Validators } from '@angular/forms';
import { LIST_FORM_META_TYPE } from '@app/shared/list-form/list-form-meta-type.enum';

export abstract class ListFormMetaBase {
  private readonly _labelTranslationIdentifier: string;
  private readonly _formControlName: string;
  private readonly _type: LIST_FORM_META_TYPE;
  private readonly _validators: Validators[];
  private readonly _isDisplayed: boolean = true;

  protected constructor(
    labelTranslationIdentifier: string,
    formControlName: string,
    type: LIST_FORM_META_TYPE,
    validators: Validators[] = [],
    isDisplayed: boolean = true
  ) {
    this._labelTranslationIdentifier = labelTranslationIdentifier;
    this._formControlName = formControlName;
    this._type = type;
    this._validators = validators;
    this._isDisplayed = isDisplayed;
  }

  public get labelTranslationIdentifier(): string {
    return this._labelTranslationIdentifier;
  }

  public get formControlName(): string {
    return this._formControlName;
  }

  public get type(): LIST_FORM_META_TYPE {
    return this._type;
  }

  public get validators(): Validators[] {
    return this._validators;
  }

  public get isDisplayed(): boolean {
    return this._isDisplayed;
  }
}
