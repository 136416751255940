import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  readonly isLoading: Signal<boolean> = computed(
    () => this.#loadingCounter() !== 0
  );
  readonly #loadingCounter: WritableSignal<number> = signal(0);

  public increaseCounter() {
    this.#loadingCounter.update(loadingCounter => loadingCounter + 1);
  }

  public decreaseCounter() {
    this.#loadingCounter.update(loadingCounter => loadingCounter - 1);
  }
}
