import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardHeaderComponent } from '@app/shared/card/components/card-header/card-header.component';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule, CardHeaderComponent, TranslateModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {}
