<mat-list role="list" data-testid="skeleton-spot-list">
  <mat-divider />
  <ng-container *ngFor="let i of [].constructor(100)">
    <div class="skeleton-spot">
      <div class="skeleton left"></div>
      <div class="skeleton text"></div>
      <div class="skeleton circle"></div>
      <div class="skeleton score-text"></div>
    </div>
    <mat-divider />
  </ng-container>
</mat-list>
