import { Validators } from '@angular/forms';
import { ListFormMetaBase } from '@app/shared/list-form/interfaces/list-form-meta-base';
import { LIST_FORM_META_TYPE } from '@app/shared/list-form/list-form-meta-type.enum';

export class ListFormMetaNumber extends ListFormMetaBase {
  public constructor(
    labelTranslationIdentifier: string,
    formControlName: string,
    validators: Validators[] = [],
    isDisplayed: boolean = true
  ) {
    super(
      labelTranslationIdentifier,
      formControlName,
      LIST_FORM_META_TYPE.NUMBER,
      validators,
      isDisplayed
    );
  }
}
