<ng-container *ngIf="input">
  <form [formGroup]="cityOrAreaFormGroup">
    <mat-form-field color="accent">
      <mat-label>{{
        'SEARCH_CARD.AREA_OR_CITY_OR_ADDRESS' | translate
      }}</mat-label>
      <input
        type="text"
        matInput
        [title]="'SEARCH_CARD.AREA_OR_CITY_OR_ADDRESS' | translate"
        [placeholder]="'SEARCH_CARD.AREA_OR_CITY_OR_ADDRESS' | translate"
        (input)="loadGeocodingResults()"
        [formControlName]="FORM_NAME.CITY_OR_AREA"
        [matAutocomplete]="auto"
        [attr.data-testid]="FORM_NAME.CITY_OR_AREA" />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayName"
        [autoSelectActiveOption]="true">
        <mat-option
          class="autocomplete-option"
          *ngFor="let option of options"
          [value]="option"
          [disabled]="option.isRestricted">
          <span>
            {{ option.displayName | json }}
          </span>
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="
          cityOrAreaFormGroup?.invalid &&
          (cityOrAreaFormGroup?.dirty || cityOrAreaFormGroup?.touched)
        ">
        <span
          *ngIf="
            cityOrAreaFormGroup.get(FORM_NAME.CITY_OR_AREA)?.errors?.[
              'required'
            ]
          ">
          {{ 'COMMON.REQUIRED_FIELD' | translate }}
        </span>
        <span
          *ngIf="
            cityOrAreaFormGroup.get(FORM_NAME.CITY_OR_AREA)?.errors?.[
              'noOptionSelected'
            ] &&
            !cityOrAreaFormGroup.get(FORM_NAME.CITY_OR_AREA)?.errors?.[
              'required'
            ]
          ">
          {{ 'SEARCH_CARD.CHOOSE_AN_OPTION' | translate }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>
</ng-container>
