<app-dialog-header (closeButtonClicked)="closeDialog()">
  {{ 'PROJECT_DELETE_DIALOG.DELETE_PROJECT' | translate }}
</app-dialog-header>

<mat-dialog-content>
  {{ 'PROJECT_DELETE_DIALOG.DIALOG_CONTENT' | translate }}
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    (click)="closeDialog()"
    tabindex="-1"
    [disabled]="isLoading"
    mat-stroked-button
    color="primary"
    class="small">
    {{ 'COMMON.NO' | translate }}
  </button>
  <button
    id="delete-button"
    data-testid="delete-project-btn"
    (click)="deleteProject()"
    [disabled]="isLoading"
    tabindex="-1"
    mat-flat-button
    color="primary"
    class="small">
    {{ 'COMMON.YES' | translate }}
  </button>
</mat-dialog-actions>
