export enum TOOLTIP {
  GRID_SCORE,
  USAGE_SCORE,
  TOTAL_CHARGING_CAPACITY_IN_KW,
  CHARGING_STATIONS_TO_BUILD,
  CHARGING_STATIONS_CONFIGURATION,
  GRID_SCORE_LOCAL,
  GRID_SCORE_COUNTRY,
  TOTAL_COST_INFO,
  CABLING_COST,
  SUBSTATION_COST,
  GRID_INVESTMENT_COST,
  USAGE_SCORE_LOCAL,
  USAGE_SCORE_COUNTRY,
  USAGE_PER_DAY_ESTIMATION,
  USAGE_PER_YEAR_ESTIMATION,
  CONSUMPTION_PER_DAY_ESTIMATION,
  CONSUMPTION_PER_YEAR_ESTIMATION,
  NEW_PROJECT_NAME,
  SPOTS_FOUND,
  SEARCH_TITLE,
  SEARCH_CRITERIA,
  LOCATION_CRITERIA,
}

type ValuesType<T> = T[keyof T];

export class TooltipProvider {
  public static readonly INSTANCE = new TooltipProvider();

  readonly #_DELAY_IN_MS = 500;

  readonly #tooltipsByIdentifier: Map<ValuesType<typeof TOOLTIP>, string> =
    new Map([
      [TOOLTIP.GRID_SCORE, 'MAP.LOCATION_INFORMATION.GRID_SCORE_TOOLTIP'],
      [TOOLTIP.USAGE_SCORE, 'MAP.LOCATION_INFORMATION.USAGE_SCORE_TOOLTIP'],
      [
        TOOLTIP.TOTAL_CHARGING_CAPACITY_IN_KW,
        'MAP.LOCATION_INFORMATION.TOTAL_CHARGING_CAPACITY_IN_KW_TOOLTIP',
      ],
      [
        TOOLTIP.CHARGING_STATIONS_TO_BUILD,
        'MAP.LOCATION_INFORMATION.CHARGING_STATIONS_TO_BUILD_TOOLTIP',
      ],
      [
        TOOLTIP.CHARGING_STATIONS_CONFIGURATION,
        'MAP.LOCATION_INFORMATION.CHARGING_STATIONS_CONFIGURATION_TOOLTIP',
      ],
      [
        TOOLTIP.GRID_SCORE_LOCAL,
        'MAP.LOCATION_INFORMATION.SCORES.GRID.EXPLANATIONS.LOCAL',
      ],
      [
        TOOLTIP.GRID_SCORE_COUNTRY,
        'MAP.LOCATION_INFORMATION.SCORES.GRID.EXPLANATIONS.COUNTRY',
      ],
      [
        TOOLTIP.TOTAL_COST_INFO,
        'MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.TOTAL_COST_INFO',
      ],
      [
        TOOLTIP.CABLING_COST,
        'MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.CABLING_COST_INFO',
      ],
      [
        TOOLTIP.SUBSTATION_COST,
        'MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.SUBSTATION_COST_INFO',
      ],
      [
        TOOLTIP.GRID_INVESTMENT_COST,
        'MAP.LOCATION_INFORMATION.SCORES.GRID.ATTRIBUTES.GRID_INVESTMENT_COST_INFO',
      ],
      [
        TOOLTIP.USAGE_SCORE_LOCAL,
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.EXPLANATIONS.LOCAL',
      ],
      [
        TOOLTIP.USAGE_SCORE_COUNTRY,
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.EXPLANATIONS.COUNTRY',
      ],
      [
        TOOLTIP.USAGE_PER_DAY_ESTIMATION,
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_DAY_ESTIMATION_TOOLTIP',
      ],
      [
        TOOLTIP.USAGE_PER_YEAR_ESTIMATION,
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.USAGE_PER_YEAR_ESTIMATION_TOOLTIP',
      ],
      [
        TOOLTIP.CONSUMPTION_PER_DAY_ESTIMATION,
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CONSUMPTION_PER_DAY_ESTIMATION_TOOLTIP',
      ],
      [
        TOOLTIP.CONSUMPTION_PER_YEAR_ESTIMATION,
        'MAP.LOCATION_INFORMATION.SCORES.USAGE.ATTRIBUTES.CONSUMPTION_PER_YEAR_ESTIMATION_TOOLTIP',
      ],
      [TOOLTIP.NEW_PROJECT_NAME, 'IMPORT_CARD.NEW_PROJECT_NAME_TOOLTIP'],
      [TOOLTIP.SPOTS_FOUND, 'SIDEBAR.SPOTS_FOUND_TOOLTIP'],
      [
        TOOLTIP.SEARCH_TITLE,
        'SEARCH_CARD.GET_RECOMMENDATIONS_HEADLINE_TOOLTIP',
      ],
      [TOOLTIP.SEARCH_CRITERIA, 'SEARCH_CARD.SEARCH_CRITERIA_TOOLTIP'],
      [TOOLTIP.LOCATION_CRITERIA, 'SEARCH_CARD.LOCATION_CRITERIA_TOOLTIP'],
    ]);

  public get DELAY_IN_MS(): number {
    return this.#_DELAY_IN_MS;
  }

  public receiveTranslationIdentifier(
    tooltipIdentifier: TOOLTIP
  ): string | undefined {
    return this.#tooltipsByIdentifier.get(tooltipIdentifier);
  }
}
