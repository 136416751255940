<app-dialog-header (closeButtonClicked)="closeDialog()">
  {{ 'SHARING_PROJECT_DIALOG.SHARE_PROJECT' | translate }}
</app-dialog-header>

<app-dialog-loading-indicator [isLoading]="isLoading" />

<mat-dialog-content>
  <ng-container *ngIf="sharingDetails; else notYetShared">
    <p>{{ 'SHARING_PROJECT_DIALOG.STATEMENT_SHARING' | translate }}</p>

    <h5 class="content-headline">
      {{ 'SHARING_PROJECT_DIALOG.URL_TO_SHARE' | translate }}
    </h5>
    <app-copy-value-box *ngIf="shareProjectUrl" [value]="shareProjectUrl" />
  </ng-container>
  <ng-template #notYetShared>
    <p>
      {{ 'SHARING_PROJECT_DIALOG.QUESTION_SHARING' | translate }}
    </p>
  </ng-template>
</mat-dialog-content>

<div class="sharing-dialog-actions">
  <div class="sharing-dialog-actions_start">
    <button
      *ngIf="sharingDetails"
      (click)="deleteSharingDetails()"
      data-testid="stop-sharing-btn"
      color="primary"
      mat-icon-button
      class="sharing-dialog-actions-button">
      <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
      <span class="sharing-dialog-actions-button-text">
        {{ 'SHARING_PROJECT_DIALOG.STOP_SHARING' | translate }}
      </span>
    </button>
  </div>
  <div class="sharing-dialog-actions_end">
    <mat-dialog-actions>
      <button
        (click)="closeDialog()"
        [disabled]="isLoading"
        data-testid="cancel-btn"
        tabindex="-1"
        mat-stroked-button
        color="primary"
        class="small">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <button
        *ngIf="!sharingDetails"
        (click)="executeProjectSharing()"
        [disabled]="isSaveButtonDisabled"
        data-testid="start-sharing-btn"
        tabindex="-1"
        mat-flat-button
        color="primary"
        class="small">
        {{ 'SHARING_PROJECT_DIALOG.SHARE_PROJECT' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
