import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/core';
import { DateExpiringPipe } from '@app/shared';

@Component({
  selector: 'app-subscription-plan-expiring-banner',
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule, DateExpiringPipe],
  templateUrl: './subscription-plan-expiring-banner.component.html',
  styleUrls: ['./subscription-plan-expiring-banner.component.scss'],
})
export class SubscriptionPlanExpiringBannerComponent {
  translateService = inject(TranslateService);
  #userService = inject(UserService);
  user = this.#userService.user;

  onContactSupportClicked(): void {
    const lang = this.translateService.currentLang;

    const url =
      lang === 'en'
        ? 'https://one.eon.com/en/customer-support.html'
        : 'https://one.eon.com/de/kundenservice.html';

    window.open(url, '_blank');
  }
}
