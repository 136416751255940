/* eslint-disable @typescript-eslint/no-loss-of-precision */
import { AllowedRegion } from './dto';

export const ALLOWED_REGIONS_FREE_USER = [
  {
    region: 'DE-BB',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [11.265521, 53.14360283],
          [11.50261778, 53.14640062],
          [11.51526212, 53.21074649],
          [11.52988631, 53.22566184],
          [11.60804641, 53.25950017],
          [11.63317921, 53.26344116],
          [11.77448077, 53.25380254],
          [11.78419018, 53.27362073],
          [11.98786913, 53.31577987],
          [12.00994521, 53.3754606],
          [12.05920517, 53.39053399],
          [12.16722165, 53.36287612],
          [12.21319489, 53.37715323],
          [12.25288739, 53.37455697],
          [12.45815882, 53.2716352],
          [12.65365208, 53.27671239],
          [12.67147766, 53.27440491],
          [12.77399146, 53.24327117],
          [12.79343703, 53.22647355],
          [12.79605879, 53.21237173],
          [12.96209717, 53.22167106],
          [12.99670077, 53.19210421],
          [13.12729731, 53.2667578],
          [13.17356484, 53.26845617],
          [13.22026368, 53.24766751],
          [13.26821525, 53.29262497],
          [13.31563346, 53.29995612],
          [13.38588556, 53.27719151],
          [13.40405608, 53.30694589],
          [13.42456087, 53.31953964],
          [13.49221578, 53.33556788],
          [13.51510519, 53.40357154],
          [13.54271957, 53.42028588],
          [13.60361346, 53.42815427],
          [13.68256698, 53.49381556],
          [13.71012222, 53.50272496],
          [13.76996491, 53.50437309],
          [13.75571218, 53.55482706],
          [13.82258297, 53.56870086],
          [13.93852092, 53.44419183],
          [14.22238692, 53.45564452],
          [14.26074038, 53.4347418],
          [14.26326378, 53.36312306],
          [14.25598189, 53.34972332],
          [14.1700468, 53.28194959],
          [14.2516415, 53.28078393],
          [14.3787887, 53.34631034],
          [14.43532001, 53.33846982],
          [14.48362601, 53.27067067],
          [14.4796539, 53.24963216],
          [14.41186515, 53.19366534],
          [14.38425759, 53.0522047],
          [14.37025855, 53.03749458],
          [14.17783353, 52.94980548],
          [14.16092885, 52.84891472],
          [14.37007711, 52.7699528],
          [14.37670348, 52.76673148],
          [14.66467508, 52.58860097],
          [14.67492054, 52.57249205],
          [14.66983264, 52.49065671],
          [14.66434287, 52.47983433],
          [14.57280343, 52.39109815],
          [14.60683713, 52.30296921],
          [14.73440617, 52.25485607],
          [14.75096468, 52.23240346],
          [14.72026617, 52.12277596],
          [14.78521281, 52.08055097],
          [14.79399823, 52.06013566],
          [14.72908783, 51.89662867],
          [14.72230151, 51.88808893],
          [14.63463915, 51.82003823],
          [14.77726596, 51.68805698],
          [14.78305861, 51.67841559],
          [14.79756551, 51.60593459],
          [14.76753187, 51.5811357],
          [14.72430384, 51.57687795],
          [14.70781728, 51.54419807],
          [14.65852836, 51.5305778],
          [14.56869839, 51.55624892],
          [14.34892154, 51.4850558],
          [14.32151419, 51.48356479],
          [14.15619367, 51.51604531],
          [14.12167647, 51.46855167],
          [14.0936929, 51.4566303],
          [14.09432924, 51.43492423],
          [14.03233824, 51.36202298],
          [13.97516105, 51.35668957],
          [13.95088526, 51.37187866],
          [13.77396736, 51.33762752],
          [13.76063949, 51.33668372],
          [13.53984969, 51.34703017],
          [13.51763481, 51.35323098],
          [13.39954628, 51.42407531],
          [13.2918715, 51.36737678],
          [13.25738557, 51.36322752],
          [13.20502787, 51.37406786],
          [13.18829842, 51.38133093],
          [13.14599192, 51.41426992],
          [13.13894378, 51.4331094],
          [13.16927508, 51.51871864],
          [13.02271492, 51.63374838],
          [13.02830875, 51.66493358],
          [13.14834077, 51.72502302],
          [13.11861469, 51.84975974],
          [13.04023503, 51.84870413],
          [13.010834, 51.85747179],
          [12.94276772, 51.91280315],
          [12.8524591, 51.91295664],
          [12.81705652, 51.93169508],
          [12.81192182, 51.95194678],
          [12.67296971, 51.98786169],
          [12.61828224, 51.96356624],
          [12.57626633, 51.96355666],
          [12.47689381, 52.00762533],
          [12.44478019, 51.99832026],
          [12.40225105, 52.00389967],
          [12.18881645, 52.15620213],
          [12.18869555, 52.1848785],
          [12.23573322, 52.21870421],
          [12.23460685, 52.21921018],
          [12.23449205, 52.21915863],
          [12.23365645, 52.21985704],
          [12.22710477, 52.22959493],
          [12.22706527, 52.22975574],
          [12.22831415, 52.2304023],
          [12.22558144, 52.23150931],
          [12.21037007, 52.25475042],
          [12.28907564, 52.46560736],
          [12.12786184, 52.50822828],
          [12.10684619, 52.53228614],
          [12.13669269, 52.62969096],
          [12.16929905, 52.64744245],
          [12.19092744, 52.64843831],
          [12.16262415, 52.71333836],
          [12.16429244, 52.72741347],
          [12.21982675, 52.80648784],
          [12.2065242, 52.83663108],
          [12.12724953, 52.83221409],
          [12.08802427, 52.85426547],
          [12.08847151, 52.86758538],
          [11.98722332, 52.85487458],
          [11.9668312, 52.85590804],
          [11.8204904, 52.89041991],
          [11.79824409, 52.91458197],
          [11.80492311, 52.93616558],
          [11.68173175, 52.95843327],
          [11.66047991, 52.96883465],
          [11.61284367, 53.02162897],
          [11.44691356, 53.05555499],
          [11.35311149, 53.03448912],
          [11.31058769, 53.04279088],
          [11.23627819, 53.11013263],
          [11.265521, 53.14360283],
        ],
        [
          [13.13139927, 52.42196471],
          [13.14114762, 52.41458107],
          [13.2302951, 52.44089438],
          [13.25992461, 52.4413587],
          [13.40562062, 52.40385455],
          [13.43246937, 52.43207605],
          [13.47685536, 52.44143074],
          [13.65598396, 52.39787965],
          [13.66418368, 52.39108804],
          [13.7157258, 52.43625894],
          [13.68980978, 52.44661608],
          [13.60977554, 52.44877907],
          [13.5788281, 52.47993664],
          [13.61145889, 52.52233963],
          [13.48773629, 52.57415815],
          [13.47294173, 52.58768476],
          [13.45551996, 52.6384386],
          [13.37846205, 52.60519618],
          [13.33468164, 52.60620135],
          [13.29349636, 52.62698218],
          [13.24899037, 52.57707942],
          [13.21751113, 52.56564214],
          [13.16148277, 52.56553216],
          [13.15623153, 52.53368156],
          [13.17754408, 52.53043782],
          [13.20034386, 52.4987268],
          [13.13139927, 52.42196471],
        ],
      ],
    },
  },
  {
    region: 'DE-BE',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [13.05682384, 52.43015168],
          [13.114965, 52.49488218],
          [13.10865852, 52.49584282],
          [13.08162651, 52.51924115],
          [13.09320851, 52.58948246],
          [13.12884587, 52.60913017],
          [13.19595578, 52.60926177],
          [13.25117253, 52.67112012],
          [13.30573406, 52.67749954],
          [13.35936043, 52.65047062],
          [13.45867315, 52.69326465],
          [13.51466397, 52.6799539],
          [13.5348727, 52.62113136],
          [13.54134783, 52.61891219],
          [13.54133273, 52.61870779],
          [13.53713641, 52.61453734],
          [13.54035746, 52.60515297],
          [13.67729441, 52.54783682],
          [13.6894515, 52.52056379],
          [13.66061668, 52.48309418],
          [13.77335826, 52.45830959],
          [13.79065352, 52.42519447],
          [13.67722542, 52.32570134],
          [13.61191075, 52.33652378],
          [13.60860783, 52.36218302],
          [13.47905974, 52.39370951],
          [13.45189233, 52.36512809],
          [13.40683909, 52.35592443],
          [13.24696477, 52.39712128],
          [13.1467385, 52.36750742],
          [13.10309524, 52.37349493],
          [13.06035774, 52.40588632],
          [13.05682384, 52.43015168],
        ],
      ],
    },
  },
  {
    region: 'DE-BW',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [7.47704825, 47.70406006],
          [7.58499612, 47.97327163],
          [7.54197121, 48.11488113],
          [7.54553463, 48.13101231],
          [7.70963609, 48.33437053],
          [7.76702081, 48.59292557],
          [7.77426738, 48.60410608],
          [8.25354085, 49.0076661],
          [8.35457172, 49.24024714],
          [8.36512862, 49.25128707],
          [8.43949376, 49.29485197],
          [8.42175952, 49.30990784],
          [8.41624577, 49.3317318],
          [8.46291056, 49.42086121],
          [8.4297789, 49.42893053],
          [8.40661484, 49.4485539],
          [8.38673254, 49.58147492],
          [8.44143862, 49.60351949],
          [8.5508139, 49.55952829],
          [8.55826647, 49.61235004],
          [8.58423778, 49.63262293],
          [8.66834438, 49.64808322],
          [8.68601354, 49.64840438],
          [8.70052204, 49.64629926],
          [8.72776912, 49.62855936],
          [8.75783808, 49.53934326],
          [8.90508707, 49.52630055],
          [8.91573471, 49.49904389],
          [8.91573539, 49.50535833],
          [8.94807118, 49.52856781],
          [9.06710783, 49.53634018],
          [9.04281471, 49.56337305],
          [9.07175605, 49.59836554],
          [9.2187323, 49.60394354],
          [9.27275098, 49.66628502],
          [9.30917172, 49.67792473],
          [9.37489733, 49.67184427],
          [9.37914013, 49.6944511],
          [9.3369672, 49.70929701],
          [9.28860888, 49.7159287],
          [9.26319067, 49.74802712],
          [9.28346977, 49.77770589],
          [9.31029436, 49.79120719],
          [9.49542686, 49.81161206],
          [9.52806799, 49.80434915],
          [9.54287639, 49.79383082],
          [9.55375662, 49.80291764],
          [9.64186132, 49.8140534],
          [9.68468624, 49.79194098],
          [9.68766342, 49.72299703],
          [9.78481937, 49.75204773],
          [9.83338151, 49.73941559],
          [9.90630231, 49.61849911],
          [9.9035865, 49.60490867],
          [9.94720689, 49.59120911],
          [9.97890775, 49.50576301],
          [9.9958103, 49.50687846],
          [10.02886093, 49.55471294],
          [10.08542152, 49.56265299],
          [10.14504512, 49.5285179],
          [10.15594024, 49.50489315],
          [10.13442874, 49.45359334],
          [10.18948115, 49.41015118],
          [10.17681574, 49.37404311],
          [10.151327, 49.36637929],
          [10.19074298, 49.28840294],
          [10.17819906, 49.262325],
          [10.14842227, 49.24772245],
          [10.15747915, 49.21327419],
          [10.26697371, 49.16927768],
          [10.27955693, 49.13783566],
          [10.25896654, 49.11360669],
          [10.28655558, 49.09223749],
          [10.28637782, 49.06000979],
          [10.3566619, 49.05150591],
          [10.37899623, 49.04237805],
          [10.48033043, 48.95276053],
          [10.48713603, 48.93581855],
          [10.45351868, 48.75592562],
          [10.52259027, 48.70396706],
          [10.51125102, 48.66698168],
          [10.42615012, 48.63972094],
          [10.4004236, 48.64949516],
          [10.39083106, 48.63988513],
          [10.33784874, 48.63470727],
          [10.31917254, 48.64497828],
          [10.34755565, 48.52667622],
          [10.33244042, 48.50333838],
          [10.19273402, 48.43958372],
          [10.17302202, 48.43538562],
          [10.05361229, 48.43392749],
          [10.00928568, 48.37619694],
          [10.0950176, 48.29536458],
          [10.10032191, 48.28786651],
          [10.17537801, 48.10403318],
          [10.17603635, 48.09335428],
          [10.11508135, 47.87341744],
          [10.16738161, 47.82355315],
          [10.15419717, 47.78811956],
          [10.13478542, 47.78153212],
          [10.13966684, 47.77923074],
          [10.15483245, 47.76150897],
          [10.16538645, 47.67809026],
          [10.15558099, 47.65933419],
          [10.10318789, 47.62233515],
          [10.04746537, 47.62544625],
          [10.01980977, 47.6528823],
          [9.98147062, 47.63282885],
          [9.94852549, 47.62888456],
          [9.85268399, 47.64946383],
          [9.77693066, 47.58751954],
          [9.74391059, 47.57896347],
          [9.66814601, 47.5865752],
          [9.53670969, 47.51775438],
          [9.49407752, 47.51704785],
          [9.24174786, 47.63620852],
          [9.02839179, 47.66142359],
          [8.91122772, 47.62589321],
          [8.86661722, 47.63450675],
          [8.82958884, 47.67177711],
          [8.78326187, 47.65276645],
          [8.72489426, 47.66768768],
          [8.72426911, 47.66688304],
          [8.67634631, 47.66104468],
          [8.64668801, 47.66641374],
          [8.63532901, 47.67291094],
          [8.63563568, 47.70995345],
          [8.67831438, 47.73379494],
          [8.69169921, 47.73173422],
          [8.69150956, 47.75065163],
          [8.67212752, 47.76115809],
          [8.66500784, 47.74921129],
          [8.60428177, 47.74254321],
          [8.56864001, 47.77071471],
          [8.45656549, 47.67805955],
          [8.47973848, 47.66585237],
          [8.59407649, 47.69475257],
          [8.64184261, 47.66704088],
          [8.61771381, 47.59106661],
          [8.55906132, 47.57781348],
          [8.51609502, 47.6028944],
          [8.51396171, 47.60176996],
          [8.51768722, 47.5996867],
          [8.50191606, 47.55727602],
          [8.38994906, 47.54189495],
          [8.3676845, 47.54365278],
          [8.21064402, 47.59271515],
          [8.11294122, 47.5390233],
          [8.09531928, 47.53381678],
          [7.94933438, 47.51976865],
          [7.92723601, 47.52242392],
          [7.82411461, 47.55988847],
          [7.71331467, 47.51203767],
          [7.67315347, 47.51269141],
          [7.61315737, 47.54144706],
          [7.61135187, 47.55684608],
          [7.57304639, 47.56663202],
          [7.47991049, 47.68655919],
          [7.47704825, 47.70406006],
        ],
        [
          [8.91573284, 49.48162949],
          [8.85749502, 49.45389287],
          [8.84780813, 49.42532451],
          [8.91573118, 49.4662164],
          [8.91573284, 49.48162949],
        ],
      ],
    },
  },
  {
    region: 'DE-BY',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [8.99440405, 50.00209186],
          [8.94904634, 50.03678958],
          [8.94376638, 50.06105948],
          [8.98557598, 50.12096131],
          [9.01015994, 50.13397309],
          [9.11030514, 50.14903763],
          [9.15023002, 50.13744484],
          [9.15456078, 50.13223335],
          [9.19327959, 50.16370639],
          [9.22823048, 50.17206586],
          [9.38314918, 50.15313641],
          [9.40863646, 50.14071162],
          [9.43310247, 50.10894128],
          [9.4754555, 50.11357712],
          [9.46545667, 50.24199788],
          [9.50447651, 50.26602937],
          [9.61434674, 50.25988435],
          [9.62703273, 50.2844657],
          [9.7127723, 50.32545719],
          [9.72441434, 50.42663662],
          [9.77447784, 50.44597802],
          [9.86033005, 50.42239902],
          [9.93413517, 50.44181767],
          [10.07950948, 50.57692377],
          [10.12509701, 50.58448962],
          [10.34664931, 50.51484615],
          [10.36403377, 50.50292198],
          [10.41608778, 50.42087956],
          [10.44015415, 50.42514399],
          [10.48127859, 50.4141064],
          [10.51619012, 50.37357621],
          [10.61123121, 50.35592727],
          [10.63704806, 50.33536506],
          [10.64637022, 50.24164941],
          [10.68991458, 50.23348031],
          [10.69702253, 50.25628822],
          [10.7324542, 50.27476768],
          [10.75235907, 50.27469967],
          [10.69111308, 50.30747662],
          [10.67826, 50.32547823],
          [10.6793482, 50.3640991],
          [10.7020713, 50.38500046],
          [10.8172686, 50.41401262],
          [10.83076491, 50.41561374],
          [10.93753171, 50.4150597],
          [10.96678729, 50.40519131],
          [11.00145424, 50.37324977],
          [11.10712729, 50.38922805],
          [11.1504052, 50.37215337],
          [11.17535087, 50.30411158],
          [11.2163508, 50.29748232],
          [11.21139602, 50.47749356],
          [11.22996606, 50.4979125],
          [11.35750326, 50.54264714],
          [11.39140418, 50.54292364],
          [11.45743298, 50.52115509],
          [11.47583954, 50.49556298],
          [11.45910104, 50.45093977],
          [11.53266874, 50.39888408],
          [11.92820092, 50.44616778],
          [11.96887385, 50.43099188],
          [12.00179133, 50.36881133],
          [12.14048401, 50.32854052],
          [12.15890736, 50.29904255],
          [12.13404562, 50.25980857],
          [12.21932174, 50.21710086],
          [12.23308956, 50.19958756],
          [12.23630966, 50.11963177],
          [12.57085732, 49.93816957],
          [12.58148492, 49.91266006],
          [12.50646052, 49.77825224],
          [12.49313395, 49.76704482],
          [12.45924846, 49.75185803],
          [12.54538494, 49.70408368],
          [12.55596206, 49.69401084],
          [12.68698752, 49.44720173],
          [12.80145536, 49.36870787],
          [12.94586229, 49.36719818],
          [12.96632642, 49.36276969],
          [13.05015292, 49.3233175],
          [13.06004692, 49.31624692],
          [13.20905052, 49.15160238],
          [13.415779, 49.07088106],
          [13.43229743, 49.05619414],
          [13.45779856, 48.98702549],
          [13.49965469, 48.9687918],
          [13.56286061, 48.99143835],
          [13.61003307, 48.9837652],
          [13.68895667, 48.90552595],
          [13.73314419, 48.9094274],
          [13.76894603, 48.89794175],
          [13.87057013, 48.78357171],
          [13.87142031, 48.76067616],
          [13.8434025, 48.72520757],
          [13.85395308, 48.72160416],
          [13.87320852, 48.6983345],
          [13.86157342, 48.6163591],
          [13.85635316, 48.6060754],
          [13.75764616, 48.50049188],
          [13.70957134, 48.49223223],
          [13.51787755, 48.5631257],
          [13.48312759, 48.54936052],
          [13.44433911, 48.36936834],
          [13.43105593, 48.35401905],
          [13.28232513, 48.27580242],
          [13.27215092, 48.2720459],
          [12.89113592, 48.18115332],
          [12.79447106, 48.11657755],
          [12.79523595, 48.08312051],
          [13.03244769, 47.86417845],
          [13.03523177, 47.83888452],
          [12.95703874, 47.73799055],
          [13.09133272, 47.71014459],
          [13.11574733, 47.68155515],
          [13.03866583, 47.4578941],
          [12.98383262, 47.44313579],
          [12.77448122, 47.53677979],
          [12.76060778, 47.56540375],
          [12.78554601, 47.61095109],
          [12.75841903, 47.64960539],
          [12.61824417, 47.64953173],
          [12.51911387, 47.60495191],
          [12.46791962, 47.61314494],
          [12.42070585, 47.66919189],
          [12.2597709, 47.65544521],
          [12.21932365, 47.67990639],
          [12.21981928, 47.69763128],
          [12.20445498, 47.69082809],
          [12.23841269, 47.61364845],
          [12.20510676, 47.58254499],
          [11.65802343, 47.570775],
          [11.60406576, 47.50301369],
          [11.57102533, 47.49024998],
          [11.45797417, 47.49320987],
          [11.43185361, 47.47278189],
          [11.44778341, 47.46093459],
          [11.41777903, 47.42039459],
          [11.35336274, 47.42455525],
          [11.29675141, 47.38141135],
          [11.24653889, 47.37907213],
          [11.20356177, 47.40393889],
          [11.13369312, 47.37475119],
          [11.11330192, 47.37114781],
          [10.97037432, 47.3752516],
          [10.9373661, 47.39282862],
          [10.90976938, 47.45768554],
          [10.8683641, 47.45899235],
          [10.83520695, 47.48921706],
          [10.83979327, 47.50145122],
          [10.78805704, 47.49071611],
          [10.75562255, 47.49466919],
          [10.67909442, 47.53436218],
          [10.48805064, 47.53195941],
          [10.47261406, 47.37871004],
          [10.45907934, 47.36128944],
          [10.25466304, 47.25146546],
          [10.22503795, 47.24670201],
          [10.16476675, 47.25520294],
          [10.13897724, 47.28856264],
          [10.17358497, 47.3440964],
          [10.10994045, 47.33142531],
          [10.06392998, 47.3534949],
          [10.05700059, 47.44179466],
          [9.98807897, 47.45938025],
          [9.96653568, 47.47471519],
          [9.94681354, 47.5164265],
          [9.88374944, 47.5050861],
          [9.8456797, 47.51404945],
          [9.80071324, 47.55484098],
          [9.76411085, 47.51993633],
          [9.73385364, 47.5096983],
          [9.54963604, 47.51291969],
          [9.5247747, 47.55406375],
          [9.632461, 47.62892981],
          [9.66354581, 47.63601997],
          [9.73440131, 47.6289082],
          [9.81269844, 47.69288065],
          [9.85133521, 47.70054027],
          [9.9523337, 47.67887467],
          [10.00598598, 47.70691082],
          [10.05774573, 47.70139621],
          [10.08177437, 47.67758129],
          [10.08819017, 47.68215754],
          [10.03174521, 47.77584593],
          [10.03845458, 47.80114943],
          [10.06484268, 47.82065084],
          [10.07762893, 47.82533678],
          [10.04760959, 47.8539628],
          [10.04166325, 47.87223578],
          [10.10387957, 48.09671895],
          [10.03263944, 48.27124669],
          [9.9380621, 48.36045381],
          [9.93541568, 48.38504796],
          [9.99937768, 48.46831602],
          [10.03073008, 48.4813154],
          [10.16089178, 48.48290334],
          [10.27302658, 48.53403844],
          [10.23321625, 48.69986733],
          [10.29169076, 48.7218083],
          [10.35295076, 48.68816179],
          [10.37619784, 48.71142581],
          [10.41395019, 48.72152204],
          [10.41862501, 48.72084027],
          [10.38208012, 48.7575079],
          [10.37684791, 48.77507987],
          [10.41877694, 48.91561446],
          [10.32841454, 49.00700895],
          [10.243813, 49.01725412],
          [10.21440462, 49.0405079],
          [10.21458028, 49.07236823],
          [10.1981938, 49.07414144],
          [10.17264687, 49.10879387],
          [10.20003383, 49.14102098],
          [10.10599479, 49.17883117],
          [10.08940024, 49.19486363],
          [10.07388034, 49.25390157],
          [10.08771044, 49.27661559],
          [10.11441992, 49.28970646],
          [10.07304432, 49.37155413],
          [10.09210916, 49.4001093],
          [10.10439674, 49.40380148],
          [10.06714175, 49.43320761],
          [10.06020166, 49.45420797],
          [10.08072118, 49.50314272],
          [10.07446614, 49.50672677],
          [10.05288757, 49.47547056],
          [10.02373382, 49.46179391],
          [9.95495062, 49.45725035],
          [9.91638281, 49.47501813],
          [9.8875795, 49.55270922],
          [9.82682396, 49.53452673],
          [9.78273135, 49.56942136],
          [9.83220387, 49.61344392],
          [9.78032161, 49.69951431],
          [9.66835323, 49.66599677],
          [9.61734633, 49.68645916],
          [9.61407213, 49.76324877],
          [9.59802458, 49.76122288],
          [9.59919065, 49.74532252],
          [9.54729714, 49.72342879],
          [9.42408371, 49.76302847],
          [9.38464786, 49.74778908],
          [9.38806902, 49.74327691],
          [9.43573033, 49.73077348],
          [9.44203433, 49.64658123],
          [9.40105858, 49.6224173],
          [9.31828212, 49.63008284],
          [9.22158449, 49.55852818],
          [9.18291169, 49.55183516],
          [9.05483909, 49.58028641],
          [9.03298061, 49.61074092],
          [9.11195058, 49.74252622],
          [9.06266492, 49.82177451],
          [9.04366387, 49.81974754],
          [9.00205621, 49.84025642],
          [8.97841671, 49.98903129],
          [8.99440405, 50.00209186],
        ],
      ],
    },
  },
  {
    region: 'DE-HB',
    geoBoundary: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [8.49187251, 53.24706838],
            [8.83953961, 53.1849136],
            [8.85980013, 53.17562567],
            [8.87755362, 53.15845781],
            [8.93079058, 53.17195261],
            [8.98000794, 53.15636879],
            [9.01543884, 53.05087025],
            [9.00452032, 53.03068798],
            [8.94013062, 52.99511065],
            [8.90022142, 52.99155791],
            [8.7751546, 53.02783488],
            [8.75229242, 53.01702049],
            [8.69790976, 53.02438237],
            [8.59172778, 53.15255354],
            [8.46063022, 53.20898639],
            [8.49187251, 53.24706838],
          ],
        ],
        [
          [
            [8.48222684, 53.62182998],
            [8.63349347, 53.62378583],
            [8.64140528, 53.63026301],
            [8.65705088, 53.63055686],
            [8.69395493, 53.60720714],
            [8.69294593, 53.60100174],
            [8.68850446, 53.599132],
            [8.66147412, 53.4903971],
            [8.63515823, 53.4728565],
            [8.50190363, 53.45176814],
            [8.46278071, 53.48430997],
            [8.51596169, 53.53172074],
            [8.45295581, 53.58882866],
            [8.48222684, 53.62182998],
          ],
        ],
      ],
    },
  },
  {
    region: 'DE-HE',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [7.74367172, 50.07212756],
          [7.82539782, 50.14234625],
          [7.87046093, 50.14910377],
          [7.88561113, 50.14417412],
          [7.87418478, 50.20085748],
          [7.88959564, 50.22280817],
          [7.96982994, 50.25775494],
          [7.99968162, 50.25703023],
          [7.99991633, 50.27145202],
          [8.03185152, 50.29403169],
          [8.07066808, 50.29680618],
          [8.03092211, 50.35964301],
          [7.9456378, 50.39200665],
          [7.92856326, 50.41543429],
          [7.95733566, 50.52589275],
          [7.96589846, 50.53731033],
          [8.01456086, 50.57217913],
          [8.04429736, 50.5797873],
          [8.09538563, 50.57720551],
          [8.07482378, 50.66803018],
          [8.07893913, 50.68227864],
          [8.12631674, 50.73658692],
          [8.09338565, 50.77809458],
          [8.0997222, 50.8041081],
          [8.2446483, 50.89832997],
          [8.28357305, 50.90363295],
          [8.35080808, 50.88696147],
          [8.42713207, 50.93054422],
          [8.50122662, 51.02442813],
          [8.49126839, 51.09449612],
          [8.52298289, 51.1189189],
          [8.66291875, 51.12894549],
          [8.66160066, 51.1442541],
          [8.72779332, 51.21069367],
          [8.70152166, 51.24748085],
          [8.60002631, 51.22808057],
          [8.56073738, 51.23614241],
          [8.52763178, 51.26390724],
          [8.52635967, 51.28965798],
          [8.57493457, 51.33606614],
          [8.57989399, 51.33983373],
          [8.66518459, 51.3918541],
          [8.68729179, 51.39815819],
          [8.89483428, 51.40901397],
          [8.86677889, 51.48402825],
          [8.8883298, 51.50985189],
          [9.0070467, 51.53965062],
          [9.03773402, 51.53853211],
          [9.10966232, 51.51405396],
          [9.12832123, 51.49351754],
          [9.12658337, 51.46703852],
          [9.14726191, 51.46593671],
          [9.28033959, 51.52722094],
          [9.32978665, 51.58727672],
          [9.31130325, 51.5989048],
          [9.32211087, 51.63492356],
          [9.47651977, 51.6780805],
          [9.52541793, 51.66471701],
          [9.53279468, 51.65078474],
          [9.62747637, 51.65671998],
          [9.66140464, 51.64650692],
          [9.72114394, 51.58821111],
          [9.6837453, 51.55432638],
          [9.68090456, 51.55468149],
          [9.67156686, 51.53386018],
          [9.62770905, 51.51166403],
          [9.67845978, 51.42605102],
          [9.65793298, 51.39743779],
          [9.6086411, 51.38530954],
          [9.60002592, 51.36032974],
          [9.68601361, 51.33410103],
          [9.6697339, 51.35439098],
          [9.68177104, 51.38304275],
          [9.79195364, 51.42938082],
          [9.83291725, 51.42597788],
          [9.84387522, 51.43640826],
          [9.89864442, 51.44304566],
          [9.94081432, 51.42520079],
          [9.97210174, 51.32145372],
          [10.07312853, 51.29686054],
          [10.09559297, 51.27938263],
          [10.10517188, 51.24094535],
          [10.24883232, 51.20781035],
          [10.27068076, 51.17990198],
          [10.23341546, 51.10582279],
          [10.20557381, 51.10448318],
          [10.18368856, 51.06697909],
          [10.23548326, 51.04368998],
          [10.23611335, 51.00721651],
          [10.16813465, 50.97518001],
          [10.13718747, 50.97145578],
          [10.08510519, 50.98035549],
          [10.09736697, 50.94854519],
          [10.06180385, 50.92056154],
          [10.04983983, 50.92069689],
          [10.08322103, 50.90277872],
          [10.09316463, 50.87690463],
          [10.05808873, 50.82300866],
          [10.03262357, 50.80947167],
          [9.98140236, 50.80230146],
          [9.92980267, 50.6777367],
          [9.93659253, 50.68553937],
          [9.98973853, 50.69798131],
          [10.01765092, 50.69717913],
          [10.08535612, 50.67673978],
          [10.10530934, 50.65230782],
          [10.05381904, 50.46261861],
          [10.04060485, 50.44851539],
          [9.90534925, 50.38081656],
          [9.87178219, 50.37709994],
          [9.79270567, 50.39392067],
          [9.78324676, 50.31167417],
          [9.76894317, 50.29498016],
          [9.69018703, 50.25730458],
          [9.69798354, 50.24065906],
          [9.66042989, 50.21116247],
          [9.53928599, 50.21794491],
          [9.54881943, 50.09544736],
          [9.51897697, 50.07157091],
          [9.41878747, 50.06059411],
          [9.38052224, 50.07311936],
          [9.35211525, 50.11003286],
          [9.23620464, 50.1242094],
          [9.17693311, 50.07598162],
          [9.11700048, 50.07941678],
          [9.10243891, 50.09695674],
          [9.02756961, 50.075948],
          [9.01719168, 50.05272196],
          [9.07828075, 50.0083545],
          [9.05386815, 49.9700027],
          [9.05351009, 49.96998662],
          [9.0685887, 49.87042058],
          [9.08379552, 49.87082062],
          [9.11860544, 49.85630874],
          [9.18392404, 49.75132749],
          [9.18346362, 49.7333145],
          [9.10378865, 49.61625502],
          [9.15966218, 49.52540462],
          [9.12988302, 49.49354649],
          [8.98759834, 49.48424789],
          [8.98759531, 49.45601027],
          [8.97165163, 49.43659837],
          [8.83489093, 49.3770271],
          [8.77916589, 49.39890651],
          [8.79104059, 49.47244155],
          [8.80524278, 49.48805051],
          [8.72323613, 49.49532043],
          [8.69299078, 49.51345912],
          [8.66433125, 49.59854205],
          [8.66396382, 49.5988837],
          [8.63767574, 49.59404712],
          [8.65427248, 49.55268481],
          [8.64212618, 49.52870056],
          [8.58960478, 49.50105642],
          [8.54327663, 49.50164334],
          [8.36214653, 49.60475353],
          [8.35097967, 49.61662538],
          [8.32337127, 49.68836347],
          [8.33453002, 49.71144681],
          [8.40590369, 49.75195288],
          [8.38328664, 49.76482636],
          [8.23844067, 49.99665043],
          [8.18110308, 50.01078057],
          [7.89396644, 49.95017359],
          [7.85500532, 49.95745631],
          [7.74471182, 50.04367631],
          [7.74367172, 50.07212756],
        ],
      ],
    },
  },
  {
    region: 'DE-HH',
    geoBoundary: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [9.69600417, 53.56435066],
            [9.73715647, 53.63810156],
            [9.80106751, 53.64330878],
            [9.82567322, 53.62165406],
            [9.87762022, 53.66484593],
            [9.91012643, 53.67368844],
            [9.95829003, 53.67109835],
            [9.96472247, 53.68659771],
            [10.00134973, 53.70276958],
            [10.03961743, 53.70167546],
            [10.04664498, 53.72429012],
            [10.06945816, 53.74036804],
            [10.15522326, 53.7592312],
            [10.20236109, 53.73360539],
            [10.18034213, 53.68688869],
            [10.18392608, 53.6848483],
            [10.19693954, 53.68311046],
            [10.19749334, 53.68273379],
            [10.1961089, 53.67791172],
            [10.24685376, 53.64900661],
            [10.25503608, 53.625336],
            [10.19433637, 53.54029734],
            [10.34343009, 53.45032805],
            [10.33280811, 53.41583743],
            [10.19105751, 53.3771124],
            [10.15194033, 53.38072759],
            [10.05532834, 53.4330035],
            [10.01088307, 53.40096947],
            [9.98364706, 53.39319917],
            [9.91124126, 53.3926516],
            [9.88229149, 53.41180806],
            [9.87824727, 53.41056958],
            [9.83117893, 53.41843915],
            [9.69946753, 53.54649815],
            [9.69600417, 53.56435066],
          ],
        ],
        [
          [
            [8.10395069, 54.04615013],
            [8.39382039, 54.04874881],
            [8.42091921, 54.04186979],
            [8.57221691, 53.94433643],
            [8.58087786, 53.9343012],
            [8.61054706, 53.84836728],
            [8.54445765, 53.83329761],
            [8.51201622, 53.86545507],
            [8.42423691, 53.87242622],
            [8.40863857, 53.87597958],
            [8.08410457, 54.00766785],
            [8.10395069, 54.04615013],
          ],
        ],
      ],
    },
  },
  {
    region: 'DE-MV',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [10.55907117, 53.3730849],
          [10.59745547, 53.46037435],
          [10.6157621, 53.47396792],
          [10.78767015, 53.52663017],
          [10.78658788, 53.57411391],
          [10.82412988, 53.59571194],
          [10.88554043, 53.59407453],
          [10.91446006, 53.64677738],
          [10.89320534, 53.68415365],
          [10.74254215, 53.72832167],
          [10.72262306, 53.74865625],
          [10.73558276, 53.87456588],
          [10.74940627, 53.89000785],
          [10.86109917, 53.94110829],
          [10.86359222, 53.9413629],
          [10.8679533, 53.95989425],
          [10.87665673, 53.97101599],
          [11.09691903, 54.11691946],
          [11.10284931, 54.12005614],
          [11.64572391, 54.34790895],
          [11.666069, 54.35183763],
          [11.94413971, 54.35460763],
          [12.13865373, 54.40163521],
          [12.28967615, 54.58681901],
          [12.30208579, 54.59517852],
          [12.72875149, 54.76122292],
          [12.73353886, 54.76278906],
          [13.18699016, 54.88549542],
          [13.20108785, 54.88744534],
          [13.54443275, 54.89393061],
          [13.56808529, 54.8894012],
          [13.99220269, 54.69340577],
          [14.00444896, 54.6825508],
          [14.11301106, 54.44651438],
          [14.11420724, 54.44048646],
          [14.10616477, 54.28854838],
          [14.18814096, 54.25619439],
          [14.20342482, 54.24238377],
          [14.27761372, 53.99133334],
          [14.2750761, 53.97918916],
          [14.22441763, 53.91170337],
          [14.31678384, 53.78043842],
          [14.31948231, 53.77338871],
          [14.33827989, 53.5569896],
          [14.44671644, 53.33553792],
          [14.42258477, 53.30897806],
          [14.34043971, 53.29419065],
          [14.29707788, 53.24877914],
          [14.26514337, 53.23794363],
          [14.09829717, 53.23997525],
          [14.07038531, 53.27444597],
          [14.19228618, 53.37056949],
          [14.19675296, 53.40663278],
          [13.91948517, 53.40050991],
          [13.88617818, 53.41214533],
          [13.8280508, 53.47968851],
          [13.81377498, 53.46277077],
          [13.73085635, 53.46048486],
          [13.65398412, 53.39650555],
          [13.63238163, 53.38803765],
          [13.58093302, 53.38138355],
          [13.55883221, 53.31569037],
          [13.53685233, 53.29996034],
          [13.46603867, 53.2831696],
          [13.43911772, 53.23905438],
          [13.38823964, 53.22752093],
          [13.31080661, 53.25264093],
          [13.26008545, 53.20504089],
          [13.20833956, 53.19934873],
          [13.15374303, 53.2236796],
          [13.00388189, 53.1464019],
          [12.94550081, 53.15771345],
          [12.93776274, 53.1770701],
          [12.76759913, 53.16752979],
          [12.73776656, 53.1744197],
          [12.6505885, 53.23137727],
          [12.44666371, 53.22830076],
          [12.42265601, 53.23332877],
          [12.22507467, 53.33248806],
          [12.18529211, 53.32012081],
          [12.15452933, 53.31943625],
          [12.07075755, 53.34090964],
          [12.05370929, 53.29479115],
          [12.03038062, 53.27912502],
          [11.85542548, 53.24288281],
          [11.8568883, 53.24177171],
          [11.82459229, 53.20708488],
          [11.63888099, 53.2197662],
          [11.58478924, 53.19632904],
          [11.57032628, 53.12269569],
          [11.53667275, 53.10368481],
          [11.29243525, 53.09537675],
          [11.279843, 53.09629497],
          [11.1728152, 53.11604964],
          [11.14769433, 53.13920711],
          [11.15397906, 53.17086426],
          [11.05519147, 53.2060672],
          [11.04103159, 53.21541843],
          [10.96185596, 53.31629837],
          [10.92694151, 53.32379064],
          [10.85920252, 53.28886027],
          [10.81313144, 53.28842029],
          [10.68926605, 53.34825964],
          [10.59513434, 53.34621834],
          [10.55907117, 53.3730849],
        ],
      ],
    },
  },
  {
    region: 'DE-NI',
    geoBoundary: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [6.32704414, 53.74262465],
            [6.70799034, 53.88141966],
            [6.71854891, 53.88400083],
            [7.85750351, 54.04329933],
            [7.8652298, 54.04386865],
            [8.1039145, 54.0461498],
            [8.12413853, 54.0427225],
            [8.52298463, 53.88958992],
            [8.5124331, 53.92014148],
            [8.36781879, 54.01342534],
            [8.39389165, 54.04874941],
            [8.49844095, 54.0495649],
            [8.51239296, 54.0480273],
            [8.78182766, 53.98398863],
            [8.80147005, 53.97259893],
            [8.83521155, 53.92543582],
            [9.32606143, 53.87174771],
            [9.35055983, 53.86157248],
            [9.57600388, 53.63248368],
            [9.78951883, 53.5736834],
            [9.80997984, 53.55137579],
            [9.80120172, 53.51397865],
            [9.86242972, 53.46499474],
            [9.87035192, 53.47107424],
            [9.9286404, 53.4698303],
            [9.9528246, 53.44823463],
            [9.95425657, 53.43654264],
            [9.96668872, 53.43632654],
            [10.02392413, 53.47754032],
            [10.07576607, 53.47974739],
            [10.18590469, 53.42021275],
            [10.36014754, 53.44687317],
            [10.38623041, 53.44495284],
            [10.57997894, 53.38225739],
            [10.69680908, 53.39123919],
            [10.72399796, 53.38664812],
            [10.83618381, 53.33250352],
            [10.90651682, 53.36509351],
            [10.95612868, 53.3619006],
            [11.34738031, 53.08143402],
            [11.43783477, 53.09888629],
            [11.46424437, 53.09783301],
            [11.61320167, 53.05532146],
            [11.61557171, 53.01703205],
            [11.54660697, 52.99338647],
            [11.54092558, 52.93968674],
            [11.52226137, 52.92204589],
            [11.33104887, 52.85916998],
            [11.30760714, 52.85685465],
            [11.01876433, 52.88762883],
            [10.97034414, 52.84117238],
            [10.94354833, 52.83113617],
            [10.8059257, 52.82272271],
            [10.83106258, 52.72249646],
            [10.93304174, 52.63670384],
            [10.96330103, 52.64423689],
            [11.00999805, 52.61551238],
            [10.97761257, 52.56932519],
            [11.03959009, 52.50797098],
            [11.0312321, 52.47934324],
            [10.98761994, 52.45838017],
            [11.09938897, 52.38994842],
            [11.09224489, 52.35559325],
            [11.03186579, 52.33374047],
            [11.11765641, 52.23933581],
            [11.10590881, 52.21021649],
            [11.05546423, 52.19000907],
            [11.07923184, 52.14037382],
            [11.06057129, 52.1143496],
            [10.98494194, 52.09157813],
            [10.9991333, 52.06256082],
            [10.96647729, 52.0341276],
            [10.69559922, 52.02597488],
            [10.62339262, 52.00051423],
            [10.67422135, 51.97436712],
            [10.68534543, 51.95050598],
            [10.61240577, 51.81252962],
            [10.7342311, 51.65037338],
            [10.71896463, 51.62142211],
            [10.67932479, 51.60788892],
            [10.6953376, 51.56591993],
            [10.65495248, 51.53867903],
            [10.39825495, 51.56304136],
            [10.37675457, 51.51359376],
            [10.35477434, 51.49851213],
            [10.22681787, 51.46838873],
            [10.18391845, 51.41930743],
            [10.1488869, 51.40772291],
            [10.05789519, 51.41324146],
            [9.95144287, 51.35801851],
            [9.89418171, 51.36856947],
            [9.8918223, 51.37327485],
            [9.84314211, 51.35455199],
            [9.79433734, 51.3794769],
            [9.74747618, 51.35768481],
            [9.75063911, 51.35374373],
            [9.80333541, 51.32481305],
            [9.76499321, 51.28422085],
            [9.71990527, 51.27498872],
            [9.55396947, 51.31942932],
            [9.53233979, 51.33798179],
            [9.52553859, 51.38348116],
            [9.54219109, 51.40454593],
            [9.60094448, 51.42758202],
            [9.55139132, 51.51117387],
            [9.56242534, 51.53626251],
            [9.57994499, 51.54512559],
            [9.57819956, 51.54633402],
            [9.57287475, 51.55117093],
            [9.57169255, 51.55262255],
            [9.5889007, 51.58305211],
            [9.5901577, 51.58340655],
            [9.6022606, 51.58061486],
            [9.62527958, 51.59726043],
            [9.60567885, 51.61554164],
            [9.37154569, 51.62762068],
            [9.33963595, 51.65518758],
            [9.41244052, 51.83778384],
            [9.32629739, 51.83284544],
            [9.28782577, 51.85954998],
            [9.30036964, 51.89609826],
            [9.25696786, 51.91036429],
            [9.23793011, 51.92989873],
            [9.2378859, 51.95417826],
            [9.17209528, 51.95853801],
            [9.14015599, 51.97851758],
            [9.12081502, 52.10761033],
            [9.01618937, 52.11046744],
            [8.98351249, 52.12588156],
            [8.95161079, 52.18802471],
            [8.9965907, 52.21566229],
            [9.02410268, 52.21040025],
            [9.03286987, 52.22451686],
            [8.94357349, 52.26047556],
            [8.93491266, 52.29230997],
            [9.08626736, 52.41163295],
            [9.07353625, 52.45891109],
            [8.87442679, 52.37167769],
            [8.85112756, 52.36760364],
            [8.70321846, 52.37348756],
            [8.66962942, 52.39493021],
            [8.66636014, 52.4935873],
            [8.6411121, 52.50793468],
            [8.52873933, 52.49488207],
            [8.44634641, 52.4310266],
            [8.41090476, 52.42310095],
            [8.34392688, 52.43144558],
            [8.34587396, 52.42029335],
            [8.45979726, 52.38052125],
            [8.4778845, 52.36149354],
            [8.47802623, 52.22606015],
            [8.53628562, 52.20386945],
            [8.54358381, 52.17012923],
            [8.43133401, 52.09630269],
            [8.39655621, 52.08991699],
            [8.28205457, 52.10706033],
            [8.22132912, 52.05886079],
            [8.19440643, 52.0503109],
            [8.04868984, 52.04659237],
            [8.00660058, 52.02035922],
            [7.95854668, 52.0186425],
            [7.86249348, 52.06602327],
            [7.87088747, 52.1035615],
            [7.97425189, 52.13060901],
            [7.97875452, 52.15904505],
            [7.88414385, 52.18717347],
            [7.86464919, 52.21150624],
            [7.89706569, 52.30623641],
            [7.92472096, 52.32321388],
            [7.93448648, 52.32449025],
            [7.91341544, 52.34614547],
            [7.7046056, 52.37972342],
            [7.67963547, 52.3940544],
            [7.65421779, 52.44100016],
            [7.64061608, 52.44408238],
            [7.61723193, 52.37219791],
            [7.60153934, 52.35806019],
            [7.31736844, 52.24535568],
            [7.30345627, 52.24208591],
            [7.07143217, 52.21949785],
            [7.03332841, 52.23179963],
            [6.99399041, 52.28254492],
            [6.99245487, 52.29908011],
            [7.03316205, 52.37179953],
            [6.98317197, 52.43017197],
            [6.96759783, 52.42026415],
            [6.93297117, 52.41416381],
            [6.74404807, 52.44286275],
            [6.72047056, 52.45447285],
            [6.64861596, 52.54369899],
            [6.67522134, 52.57488734],
            [6.71171392, 52.57842376],
            [6.67818732, 52.61738987],
            [6.68761749, 52.64453657],
            [6.73018259, 52.66514604],
            [6.75351448, 52.6698989],
            [7.02304266, 52.66573001],
            [7.05147794, 52.85188458],
            [7.05511099, 52.85958105],
            [7.18108609, 53.01162129],
            [7.15729424, 53.29643621],
            [6.92147291, 53.31763846],
            [6.89190988, 53.33377612],
            [6.85188015, 53.43168238],
            [6.66981457, 53.47693408],
            [6.65715313, 53.48222769],
            [6.53195512, 53.56352193],
            [6.40344341, 53.58365745],
            [6.37847446, 53.59762101],
            [6.31177716, 53.71789798],
            [6.32704414, 53.74262465],
          ],
          [
            [8.65504867, 53.1729761],
            [8.74258007, 53.06739571],
            [8.74835248, 53.07012328],
            [8.78616848, 53.07260029],
            [8.90947282, 53.03687189],
            [8.94098657, 53.05427105],
            [8.91825615, 53.12197723],
            [8.87721262, 53.11156155],
            [8.83267887, 53.12006214],
            [8.80590808, 53.14597038],
            [8.65504867, 53.1729761],
          ],
          [
            [8.54858696, 53.58003308],
            [8.58909229, 53.54332066],
            [8.58892999, 53.51976197],
            [8.57532598, 53.50763564],
            [8.59383663, 53.51056183],
            [8.61130863, 53.58084487],
            [8.54858696, 53.58003308],
          ],
        ],
        [
          [
            [7.37273109, 54.00681238],
            [7.37279539, 54.13570636],
            [7.4054295, 54.15665776],
            [7.4451666, 54.15880277],
            [7.48013644, 54.14778845],
            [7.58155244, 54.03680168],
            [7.55831194, 54.00631],
            [7.41709454, 53.98627342],
            [7.37273109, 54.00681238],
          ],
        ],
      ],
    },
  },
  {
    region: 'DE-NW',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [5.84935512, 51.07101295],
          [5.89624762, 51.08678567],
          [5.94029942, 51.08171431],
          [5.96081206, 51.06692229],
          [6.12007773, 51.15743982],
          [6.09660546, 51.15099194],
          [6.04638443, 51.16974216],
          [6.03684763, 51.24066707],
          [6.04481435, 51.25677577],
          [6.18969801, 51.3677501],
          [6.176828, 51.50489706],
          [6.06351468, 51.59179013],
          [6.05730325, 51.61284531],
          [6.07620228, 51.64837327],
          [5.93248116, 51.72085224],
          [5.91926562, 51.7392062],
          [5.92708872, 51.83800021],
          [5.94797116, 51.85708318],
          [6.04847846, 51.8856076],
          [6.07649564, 51.88612973],
          [6.07774751, 51.8858296],
          [6.08994802, 51.91302036],
          [6.14081462, 51.92573904],
          [6.17063822, 51.92494389],
          [6.35601123, 51.86661048],
          [6.355025, 51.87070247],
          [6.4038569, 51.89457461],
          [6.47102592, 51.87806954],
          [6.66822484, 51.93748687],
          [6.70317861, 51.93631242],
          [6.72521194, 51.92773397],
          [6.78095023, 51.96832862],
          [6.66606279, 52.02227794],
          [6.65658535, 52.05074579],
          [6.72930475, 52.12965025],
          [6.75953551, 52.14082883],
          [6.83740704, 52.14220848],
          [6.96064956, 52.2402738],
          [6.98221724, 52.24843863],
          [7.28409574, 52.28457153],
          [7.54862422, 52.3894021],
          [7.56854784, 52.47795598],
          [7.61622085, 52.49559116],
          [7.69499755, 52.47847758],
          [7.71704423, 52.46472515],
          [7.7417622, 52.41909699],
          [7.9459365, 52.38629401],
          [7.96767209, 52.37629277],
          [8.02184339, 52.3206362],
          [7.99847164, 52.28792172],
          [7.96261156, 52.28323068],
          [7.9407399, 52.21930454],
          [8.03261265, 52.19201011],
          [8.05270207, 52.17005335],
          [8.04371807, 52.11331153],
          [8.02204053, 52.09514201],
          [7.95428345, 52.07739966],
          [7.97822282, 52.06559864],
          [8.00748572, 52.08382062],
          [8.03156927, 52.09037061],
          [8.17473293, 52.09402041],
          [8.23950128, 52.14538659],
          [8.27642229, 52.15331023],
          [8.39314321, 52.13585268],
          [8.46122125, 52.18060238],
          [8.42313538, 52.19511794],
          [8.4061738, 52.21380571],
          [8.40603262, 52.34874721],
          [8.29424224, 52.38780309],
          [8.27635691, 52.40451279],
          [8.26726561, 52.45657913],
          [8.31018944, 52.48034352],
          [8.40355838, 52.46872336],
          [8.48055079, 52.52834514],
          [8.50206447, 52.53630693],
          [8.64556157, 52.55295839],
          [8.67681594, 52.54746815],
          [8.72649834, 52.51925671],
          [8.73790408, 52.50371231],
          [8.74081372, 52.41593927],
          [8.85030872, 52.41158775],
          [8.91530896, 52.42124199],
          [9.02392086, 52.51313179],
          [9.06441639, 52.52110373],
          [9.14533719, 52.50473106],
          [9.16901026, 52.47904178],
          [9.12056836, 52.35124227],
          [9.11140032, 52.34093419],
          [9.01849338, 52.28214705],
          [9.09619691, 52.24907181],
          [9.10921571, 52.22310435],
          [9.07920061, 52.17528964],
          [9.03953848, 52.16352483],
          [9.04450424, 52.15385211],
          [9.15531633, 52.15082899],
          [9.18950121, 52.1308217],
          [9.20901936, 52.00061244],
          [9.27762402, 51.99607046],
          [9.30971489, 51.97409245],
          [9.30977111, 51.94323069],
          [9.35802074, 51.92738251],
          [9.37627813, 51.90322696],
          [9.3706884, 51.88694675],
          [9.460734, 51.88493885],
          [9.49236279, 51.85388232],
          [9.41120744, 51.73902601],
          [9.41061871, 51.66931832],
          [9.45022122, 51.666008],
          [9.45976287, 51.62346568],
          [9.39868044, 51.60695627],
          [9.39924567, 51.6065927],
          [9.40221041, 51.5777498],
          [9.24510551, 51.44651711],
          [9.22338194, 51.43794173],
          [9.10136194, 51.42262787],
          [9.05834801, 51.44407015],
          [9.05697573, 51.48099998],
          [9.01902999, 51.49392443],
          [8.93446437, 51.46990944],
          [8.97341223, 51.39582461],
          [8.94214063, 51.36648478],
          [8.70950616, 51.35482974],
          [8.64795673, 51.32139446],
          [8.60144941, 51.278755],
          [8.60896343, 51.27315124],
          [8.7091019, 51.29590629],
          [8.7541739, 51.28395938],
          [8.8012105, 51.21812733],
          [8.79888514, 51.19701154],
          [8.7358059, 51.13368035],
          [8.74789277, 51.12386709],
          [8.72601402, 51.08788581],
          [8.553535, 51.0677937],
          [8.57245906, 51.02631563],
          [8.57018117, 51.01013049],
          [8.49198527, 50.90969775],
          [8.48408771, 50.90302024],
          [8.38277031, 50.84403925],
          [8.34525935, 50.83959971],
          [8.27954102, 50.85591174],
          [8.16907211, 50.78404366],
          [8.19903895, 50.74627857],
          [8.19882929, 50.72561614],
          [8.15773809, 50.67538022],
          [8.11876552, 50.6635274],
          [8.03186652, 50.67457605],
          [8.004347, 50.69072657],
          [7.94760511, 50.8181831],
          [7.81238071, 50.86192033],
          [7.79416958, 50.88823722],
          [7.80587205, 50.91449114],
          [7.7970189, 50.91675701],
          [7.77589063, 50.9076823],
          [7.79762901, 50.84990039],
          [7.77528852, 50.82341243],
          [7.69414093, 50.80417857],
          [7.69141743, 50.76700243],
          [7.66520975, 50.74616411],
          [7.40264893, 50.69963325],
          [7.3686411, 50.63109969],
          [7.34103232, 50.61553919],
          [7.21925922, 50.60094424],
          [7.20092719, 50.60854171],
          [7.17719877, 50.58717614],
          [7.15781264, 50.57840442],
          [6.9578082, 50.54166038],
          [6.9013458, 50.44794349],
          [6.84489096, 50.43849991],
          [6.79613726, 50.46417691],
          [6.78827396, 50.46162808],
          [6.83438297, 50.36764245],
          [6.81308159, 50.33942647],
          [6.71164789, 50.31430867],
          [6.67517306, 50.3183322],
          [6.59868024, 50.36047722],
          [6.46867899, 50.31532764],
          [6.44003374, 50.32145473],
          [6.37611611, 50.31015461],
          [6.31352641, 50.3670856],
          [6.30704177, 50.3812481],
          [6.31294226, 50.46737178],
          [6.22397487, 50.47154782],
          [6.19357538, 50.48536698],
          [6.18237794, 50.50203687],
          [6.16198858, 50.50878777],
          [6.15689038, 50.51385859],
          [6.1600348, 50.52683222],
          [6.14268882, 50.53763528],
          [6.13865882, 50.5524703],
          [6.15293836, 50.57481506],
          [6.21390972, 50.60295683],
          [6.18544893, 50.6011128],
          [6.14944987, 50.61387268],
          [6.13433867, 50.63370086],
          [6.14177648, 50.64466174],
          [6.1381391, 50.64750258],
          [6.14771504, 50.66284545],
          [6.09933457, 50.69865105],
          [6.04236335, 50.69570266],
          [6.00746408, 50.708],
          [5.94288918, 50.78767165],
          [5.95288485, 50.8160012],
          [6.04108259, 50.85906196],
          [6.05331213, 50.90469488],
          [6.00819119, 50.9129479],
          [5.98247595, 50.93712768],
          [5.9860281, 50.9578943],
          [5.90077318, 50.95235363],
          [5.86225826, 50.96929621],
          [5.83148796, 51.04554386],
          [5.84935512, 51.07101295],
        ],
      ],
    },
  },
  {
    region: 'DE-RP',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [6.0772402, 50.06489391],
          [6.1405869, 50.24045997],
          [6.16021532, 50.25605046],
          [6.24925805, 50.28300658],
          [6.27237087, 50.32742543],
          [6.30517738, 50.34311278],
          [6.37235657, 50.34465568],
          [6.35180853, 50.37498858],
          [6.39571239, 50.40590218],
          [6.45991309, 50.39284839],
          [6.48394186, 50.37284044],
          [6.58616811, 50.40830436],
          [6.62682294, 50.40552377],
          [6.70578967, 50.36205636],
          [6.75576538, 50.37442096],
          [6.71062593, 50.46641908],
          [6.72861702, 50.49361786],
          [6.78455362, 50.51173405],
          [6.82376044, 50.50897909],
          [6.85214479, 50.49404582],
          [6.8971004, 50.56860592],
          [6.92067066, 50.58236044],
          [7.12572983, 50.62000021],
          [7.17354843, 50.66301715],
          [7.23792639, 50.6550228],
          [7.23982877, 50.64980505],
          [7.30656372, 50.65779573],
          [7.3400708, 50.72509978],
          [7.36837457, 50.74068664],
          [7.57767195, 50.7629778],
          [7.63617348, 50.79025854],
          [7.62496193, 50.81358012],
          [7.64672208, 50.84142934],
          [7.72039141, 50.85887708],
          [7.70025499, 50.91239302],
          [7.72897012, 50.93992834],
          [7.84009062, 50.95228622],
          [7.88134388, 50.92488235],
          [7.87050468, 50.8953517],
          [7.98151366, 50.86482292],
          [8.00308575, 50.84432434],
          [8.00440008, 50.78065979],
          [8.06198474, 50.71717733],
          [8.13288715, 50.70816844],
          [8.16116726, 50.68993747],
          [8.18767176, 50.59778519],
          [8.18606663, 50.58585315],
          [8.15247523, 50.52753264],
          [8.10423364, 50.51447958],
          [8.05093884, 50.52942025],
          [8.02653965, 50.5119245],
          [8.00347425, 50.42336978],
          [8.0780798, 50.39507844],
          [8.09302438, 50.38396861],
          [8.15503218, 50.28595063],
          [8.12570888, 50.25452671],
          [8.07144683, 50.25064473],
          [8.07087677, 50.2155946],
          [8.01252612, 50.19786198],
          [7.98836128, 50.21021112],
          [7.94828352, 50.19274068],
          [7.96558862, 50.1068535],
          [7.931248, 50.08086488],
          [7.9218105, 50.08064627],
          [7.88931875, 50.092245],
          [7.88128194, 50.10126406],
          [7.86918907, 50.10293239],
          [7.81810577, 50.05901779],
          [7.89619354, 49.99800953],
          [8.23490885, 50.05085304],
          [8.27093653, 50.04324501],
          [8.36532223, 49.97080896],
          [8.37238886, 49.96217083],
          [8.44569701, 49.79255558],
          [8.48866367, 49.78528023],
          [8.50517103, 49.74655886],
          [8.39442421, 49.67371606],
          [8.47279931, 49.46832899],
          [8.51994964, 49.45750781],
          [8.54202942, 49.42800274],
          [8.49064284, 49.32892464],
          [8.51664077, 49.30808077],
          [8.51301241, 49.27600423],
          [8.42086276, 49.22098229],
          [8.31851866, 48.98821907],
          [8.30010117, 48.97366374],
          [8.21552227, 48.94580306],
          [8.18275972, 48.94598358],
          [7.92645042, 49.03392452],
          [7.67756526, 49.02144706],
          [7.6576402, 49.02424824],
          [7.5115713, 49.07638473],
          [7.49447989, 49.08999175],
          [7.46819643, 49.14468598],
          [7.3527026, 49.13946631],
          [7.31871399, 49.15162053],
          [7.25718289, 49.22501407],
          [7.26478339, 49.25379919],
          [7.35290848, 49.30505241],
          [7.36338196, 49.35685108],
          [7.23028401, 49.41563245],
          [7.22395982, 49.45075356],
          [7.26654907, 49.4809736],
          [7.24340872, 49.54947459],
          [7.22999159, 49.54495359],
          [7.19465371, 49.54576619],
          [7.02506236, 49.61316148],
          [6.62023988, 49.49540928],
          [6.5972793, 49.49399147],
          [6.371118, 49.52867387],
          [6.3457537, 49.55958778],
          [6.48121467, 49.79216406],
          [6.31374548, 49.81580882],
          [6.29072638, 49.82640706],
          [6.08155128, 50.04770707],
          [6.0772402, 50.06489391],
        ],
      ],
    },
  },
  {
    region: 'DE-SH',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [7.48697533, 54.20237093],
          [7.55069723, 54.31735531],
          [7.56338705, 54.32769425],
          [7.72935845, 54.39988856],
          [7.74936647, 54.40402632],
          [7.98180977, 54.40963476],
          [8.00022376, 54.40718734],
          [8.08833545, 54.37979367],
          [7.93152303, 54.59340751],
          [7.92898606, 54.59870457],
          [7.89690286, 54.73207777],
          [7.89660687, 54.73599485],
          [7.91391227, 54.93113275],
          [7.91558234, 54.93638465],
          [8.01016524, 55.10543951],
          [8.05079401, 55.11938493],
          [8.47879401, 55.07496441],
          [8.50674303, 55.06073607],
          [8.52982375, 55.01748425],
          [8.56514526, 55.01292173],
          [8.59317684, 54.99251824],
          [8.59181877, 54.93873213],
          [9.14802811, 54.89349071],
          [9.16570033, 54.88904522],
          [9.2996956, 54.82615613],
          [9.5767286, 54.90535754],
          [9.6278659, 54.89113952],
          [9.63580258, 54.86961062],
          [9.74324517, 54.84484324],
          [9.88699226, 54.86204847],
          [9.91395907, 54.85911422],
          [10.18903087, 54.75554806],
          [10.19914984, 54.74979894],
          [10.36326259, 54.60940643],
          [10.60167942, 54.5412045],
          [10.74223815, 54.53568107],
          [10.79630563, 54.56304818],
          [10.81427226, 54.56792473],
          [11.13566016, 54.59763273],
          [11.15693722, 54.59582364],
          [11.32665892, 54.54834459],
          [11.33669482, 54.54416801],
          [11.69803912, 54.32990036],
          [11.69221407, 54.29774198],
          [11.14756965, 54.086899],
          [10.93764761, 53.94775587],
          [10.93533537, 53.93792872],
          [10.98114954, 53.92772411],
          [10.95723945, 53.88781236],
          [10.89009899, 53.90078455],
          [10.80632956, 53.86242818],
          [10.79580223, 53.76014937],
          [10.93844825, 53.71836447],
          [10.95655259, 53.70609756],
          [10.98636329, 53.65369362],
          [10.98648002, 53.64030084],
          [10.94541412, 53.56543164],
          [10.90963302, 53.55071472],
          [10.85896234, 53.55206716],
          [10.85983002, 53.513983],
          [10.8403528, 53.49469398],
          [10.6615884, 53.43988873],
          [10.61221861, 53.35293366],
          [10.56367463, 53.34018777],
          [10.31321963, 53.40613634],
          [10.30253946, 53.41045675],
          [10.13720806, 53.50721852],
          [10.12666615, 53.5201957],
          [10.11673545, 53.57565356],
          [10.12357759, 53.59059557],
          [10.17428424, 53.63126618],
          [10.11505954, 53.6650138],
          [10.10867093, 53.68021403],
          [10.00854153, 53.63173957],
          [9.98256057, 53.62702065],
          [9.92416499, 53.63016425],
          [9.85409588, 53.57184623],
          [9.79503849, 53.57231055],
          [9.77442502, 53.59047379],
          [9.73599004, 53.5488369],
          [9.68548548, 53.54097655],
          [9.53163388, 53.59787692],
          [9.52013883, 53.60469434],
          [9.26803501, 53.84390941],
          [8.80417318, 53.88578062],
          [8.77649315, 53.89849531],
          [8.74103187, 53.94809286],
          [8.49219107, 54.00729128],
          [7.87224658, 54.00170868],
          [7.74002714, 53.97422323],
          [7.70218107, 53.97947974],
          [7.54757067, 54.07368153],
          [7.53887913, 54.08254189],
          [7.48660163, 54.19014237],
          [7.48697533, 54.20237093],
        ],
      ],
    },
  },
  {
    region: 'DE-SL',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [6.35881363, 49.55361969],
          [6.5978572, 49.54052705],
          [6.92758565, 49.65638345],
          [6.94318333, 49.65917409],
          [7.03034003, 49.66157454],
          [7.05075212, 49.65812421],
          [7.21496659, 49.5924107],
          [7.25190061, 49.6048428],
          [7.30355997, 49.58916329],
          [7.34074087, 49.47914154],
          [7.33214038, 49.45836707],
          [7.3050385, 49.43912968],
          [7.42207679, 49.38746937],
          [7.43752777, 49.36505285],
          [7.42241317, 49.2902956],
          [7.41070011, 49.27586154],
          [7.33346663, 49.23091913],
          [7.39456419, 49.15410117],
          [7.3717506, 49.12051119],
          [7.207391, 49.09244481],
          [7.17928658, 49.0952558],
          [7.11026672, 49.12347547],
          [7.08563141, 49.09907978],
          [7.020231, 49.1081112],
          [7.0028308, 49.1750992],
          [6.92078142, 49.19898408],
          [6.87209586, 49.19310517],
          [6.87009087, 49.15055785],
          [6.82719119, 49.12822222],
          [6.73157269, 49.14061859],
          [6.70791328, 49.1514002],
          [6.53512968, 49.33560499],
          [6.54315054, 49.366015],
          [6.55601221, 49.37280189],
          [6.5274435, 49.40554372],
          [6.36327724, 49.4422838],
          [6.33965803, 49.46004174],
          [6.32048143, 49.52606198],
          [6.35881363, 49.55361969],
        ],
      ],
    },
  },
  {
    region: 'DE-SN',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [11.84524124, 50.5545676],
          [11.97640214, 50.64947127],
          [12.03862939, 50.63897804],
          [12.05787913, 50.57879469],
          [12.0871611, 50.5847799],
          [12.07934517, 50.60102314],
          [12.11964037, 50.6410667],
          [12.17381017, 50.64582957],
          [12.18765116, 50.63802097],
          [12.26541995, 50.66918023],
          [12.19825643, 50.72444392],
          [12.19984361, 50.75340221],
          [12.24420893, 50.78504669],
          [12.22132649, 50.80533316],
          [12.23738797, 50.83962439],
          [12.60787781, 50.93190958],
          [12.58235613, 50.97480435],
          [12.52694545, 50.97577798],
          [12.49582159, 50.98825803],
          [12.43601191, 51.06334449],
          [12.24947518, 51.07470536],
          [12.22505777, 51.08293625],
          [12.16770897, 51.12723909],
          [12.16015524, 51.13773106],
          [12.11234994, 51.31621369],
          [12.13572123, 51.34107665],
          [12.1584189, 51.34613119],
          [12.15836578, 51.35595282],
          [12.15008543, 51.3605678],
          [12.14982243, 51.36295468],
          [12.1582983, 51.36842462],
          [12.15825277, 51.37683863],
          [12.14339638, 51.38566102],
          [12.14326838, 51.38773226],
          [12.15812157, 51.40107644],
          [12.1580496, 51.41436716],
          [12.11894791, 51.45060544],
          [12.11745248, 51.47408543],
          [12.21094878, 51.57738794],
          [12.23534196, 51.58834286],
          [12.59604198, 51.63228222],
          [12.63741157, 51.64704548],
          [12.65274639, 51.66977448],
          [12.69479078, 51.68275975],
          [12.76356827, 51.67185942],
          [12.82082058, 51.70208861],
          [12.86603884, 51.70278041],
          [12.94017274, 51.66739554],
          [12.97182534, 51.68553665],
          [13.01680786, 51.68743255],
          [13.17546086, 51.6185932],
          [13.18782059, 51.6092046],
          [13.24099469, 51.53318449],
          [13.24313002, 51.51945384],
          [13.21401299, 51.43727626],
          [13.26329911, 51.41337682],
          [13.37847873, 51.47159563],
          [13.42601429, 51.47034644],
          [13.55811944, 51.39116613],
          [13.75920807, 51.38175207],
          [13.95034394, 51.41871905],
          [13.98641885, 51.41314357],
          [13.99165922, 51.40986811],
          [14.02151265, 51.44495345],
          [14.00314583, 51.46474416],
          [14.03232458, 51.4982922],
          [14.06520562, 51.49939153],
          [14.10386473, 51.5525449],
          [14.14741641, 51.56463903],
          [14.32894384, 51.52901115],
          [14.55112036, 51.60091156],
          [14.58258732, 51.6014316],
          [14.65343069, 51.5814173],
          [14.66682109, 51.60474048],
          [14.73503697, 51.60374971],
          [14.76580201, 51.54101361],
          [14.99171473, 51.46161715],
          [15.00927597, 51.44653461],
          [15.07307857, 51.24842634],
          [15.07281687, 51.23880606],
          [14.99962127, 51.04491664],
          [14.99722366, 51.04057786],
          [14.82720246, 50.81068777],
          [14.78313679, 50.7987682],
          [14.60741889, 50.83626387],
          [14.58420497, 50.86362015],
          [14.59990229, 50.90064018],
          [14.57272213, 50.89653808],
          [14.53012452, 50.92545375],
          [14.55813276, 50.98080187],
          [14.49979061, 51.01693362],
          [14.42164283, 50.99772499],
          [14.3915189, 50.99888255],
          [14.32007827, 51.02311075],
          [14.30353891, 50.9971769],
          [14.41517767, 50.95585757],
          [14.43249206, 50.93293284],
          [14.42352486, 50.89578922],
          [14.39968941, 50.87776474],
          [13.92739517, 50.77514301],
          [13.88787278, 50.71785253],
          [13.85740968, 50.70425541],
          [13.57500896, 50.69193658],
          [13.49708873, 50.59169358],
          [13.44200884, 50.5841741],
          [13.38495969, 50.61401233],
          [13.35609298, 50.57197114],
          [13.31505665, 50.55885399],
          [13.27081672, 50.56535382],
          [13.22888603, 50.49512995],
          [13.19305112, 50.48042529],
          [13.05306152, 50.48599097],
          [12.98017761, 50.3939383],
          [12.93790992, 50.38228294],
          [12.92719962, 50.38430118],
          [12.91083014, 50.3908365],
          [12.88910622, 50.40602485],
          [12.82292965, 50.43191765],
          [12.73094777, 50.37997329],
          [12.70707781, 50.37420918],
          [12.5300487, 50.37433547],
          [12.36731549, 50.23490299],
          [12.36805162, 50.17149812],
          [12.32496239, 50.14876837],
          [12.28220479, 50.1543374],
          [12.25672944, 50.16725975],
          [12.15944108, 50.3022703],
          [11.96498434, 50.32966922],
          [11.93739012, 50.35622152],
          [11.94458892, 50.38111885],
          [11.86401383, 50.42522757],
          [11.86346518, 50.45964106],
          [11.91062142, 50.48649533],
          [11.84809964, 50.52260014],
          [11.84524124, 50.5545676],
        ],
      ],
    },
  },
  {
    region: 'DE-ST',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [10.54287595, 52.02310018],
          [10.66736245, 52.06696396],
          [10.68346891, 52.06986005],
          [10.91708773, 52.07688455],
          [10.9073441, 52.09680316],
          [10.92594011, 52.12297097],
          [11.00174392, 52.14577889],
          [10.97899126, 52.19328993],
          [10.99380889, 52.21792836],
          [11.03859163, 52.2358569],
          [10.95206809, 52.33107468],
          [10.96515931, 52.36062557],
          [11.01587397, 52.3789701],
          [10.90823613, 52.44489078],
          [10.9114375, 52.47752858],
          [10.96163091, 52.50164369],
          [10.90666441, 52.55606953],
          [10.90422155, 52.57606269],
          [10.91605106, 52.59293663],
          [10.89130853, 52.59723428],
          [10.76768673, 52.70130651],
          [10.7613122, 52.71076944],
          [10.7292083, 52.83876833],
          [10.76111717, 52.86361251],
          [10.91886076, 52.87324684],
          [10.97080526, 52.92304589],
          [11.00746426, 52.93284114],
          [11.30770693, 52.9008853],
          [11.4706058, 52.95440513],
          [11.47697647, 53.00877528],
          [11.49702423, 53.02666947],
          [11.61373233, 53.06100971],
          [11.66030687, 53.05275639],
          [11.71567385, 52.99747911],
          [11.85649075, 52.97204754],
          [11.88142896, 52.94715599],
          [11.87402463, 52.92436516],
          [11.98304943, 52.89865143],
          [12.11799558, 52.91557479],
          [12.16123603, 52.89391694],
          [12.1606897, 52.87765533],
          [12.23012757, 52.88152022],
          [12.26814679, 52.86551234],
          [12.29244919, 52.81046453],
          [12.29080066, 52.79637125],
          [12.23524952, 52.71725451],
          [12.27144275, 52.63425293],
          [12.23942295, 52.60692522],
          [12.20228648, 52.60521367],
          [12.18614569, 52.55253524],
          [12.20961687, 52.53923463],
          [12.21106365, 52.54003939],
          [12.26597955, 52.53530303],
          [12.28885999, 52.51250901],
          [12.32214948, 52.51740547],
          [12.36574857, 52.49162641],
          [12.28561083, 52.25995462],
          [12.30636212, 52.251553],
          [12.31049596, 52.25224159],
          [12.31278396, 52.25078019],
          [12.31230493, 52.24914663],
          [12.31696306, 52.24726036],
          [12.32435467, 52.21453311],
          [12.2594154, 52.16836038],
          [12.30411645, 52.11884171],
          [12.54538076, 52.00969528],
          [12.65628125, 52.0338989],
          [12.68219358, 52.03344912],
          [12.85822638, 51.98799144],
          [12.87982658, 51.97100018],
          [12.88332394, 51.95721315],
          [12.9607374, 51.95708171],
          [12.9892562, 51.94832829],
          [13.05443596, 51.89538669],
          [13.12529871, 51.90421889],
          [13.1676347, 51.88687353],
          [13.2221034, 51.7200997],
          [13.20887068, 51.69808191],
          [13.04573238, 51.61977915],
          [12.99209834, 51.62685515],
          [12.98654117, 51.63326832],
          [12.96667616, 51.62187076],
          [12.92036696, 51.62057673],
          [12.83812022, 51.65987283],
          [12.2213699, 51.514036],
          [12.18923549, 51.46270294],
          [12.22277359, 51.43461097],
          [12.22987761, 51.42131299],
          [12.23036961, 51.33039714],
          [12.20650347, 51.30916839],
          [12.19312057, 51.30618582],
          [12.23884943, 51.22131869],
          [12.23496281, 51.20189337],
          [12.21538241, 51.18294],
          [12.32696378, 51.03887556],
          [12.32608233, 51.01804337],
          [12.24589623, 50.92687855],
          [12.18262057, 50.92735748],
          [12.16190951, 50.95231666],
          [12.01694638, 50.9455396],
          [11.98760974, 50.95294983],
          [11.87460773, 51.03177474],
          [11.76556021, 51.0205325],
          [11.73238859, 51.02818437],
          [11.65255683, 51.08711864],
          [11.48764085, 51.07998292],
          [11.44947873, 51.09989659],
          [11.43442766, 51.18125157],
          [11.43362124, 51.18168073],
          [11.43097508, 51.17734977],
          [11.42676268, 51.17836123],
          [11.42899594, 51.18414216],
          [11.42454431, 51.18651103],
          [11.41994514, 51.18608127],
          [11.40340708, 51.18244823],
          [11.3652433, 51.1883429],
          [11.3389189, 51.20603259],
          [11.33892858, 51.23673598],
          [11.42787317, 51.29636847],
          [11.39441419, 51.31510727],
          [11.39248226, 51.32411642],
          [11.36391809, 51.34230907],
          [11.36035426, 51.36487545],
          [10.96039371, 51.41030207],
          [10.93112697, 51.42980671],
          [10.91317154, 51.52778522],
          [10.8570577, 51.56354058],
          [10.86042352, 51.59349024],
          [10.68553083, 51.62058975],
          [10.66225408, 51.63205599],
          [10.54677688, 51.77164693],
          [10.54393122, 51.78727677],
          [10.61077757, 51.94932332],
          [10.53770235, 51.98693059],
          [10.54287595, 52.02310018],
        ],
      ],
    },
  },
  {
    region: 'DE-TH',
    geoBoundary: {
      type: 'Polygon',
      coordinates: [
        [
          [9.8422189, 50.64690854],
          [9.9174664, 50.82726518],
          [9.94443713, 50.84362784],
          [9.99875135, 50.85122426],
          [10.01678295, 50.87892179],
          [9.92679807, 50.92723233],
          [9.95073555, 50.96710572],
          [10.01699882, 50.96635681],
          [10.00184431, 51.00733517],
          [10.03865204, 51.03503413],
          [10.12796126, 51.03219998],
          [10.11236117, 51.04459553],
          [10.10680072, 51.06638472],
          [10.13096656, 51.10779576],
          [10.09847475, 51.12112388],
          [10.10343369, 51.16056908],
          [10.15212729, 51.17425398],
          [10.18836328, 51.166079],
          [10.19356167, 51.17640645],
          [9.95439267, 51.2650734],
          [9.93831788, 51.27787768],
          [9.89409568, 51.36874352],
          [9.91208381, 51.39522675],
          [10.272579, 51.51036792],
          [10.33908736, 51.5976075],
          [10.37750054, 51.61005825],
          [10.61268381, 51.58555535],
          [10.60366014, 51.61691981],
          [10.6265714, 51.64176446],
          [10.7505019, 51.67019104],
          [10.77671266, 51.6698779],
          [10.94987336, 51.62537652],
          [10.9581005, 51.58710284],
          [10.9265668, 51.57195394],
          [11.01622542, 51.43863839],
          [11.3983112, 51.40612792],
          [11.42451469, 51.39518652],
          [11.50681129, 51.30680998],
          [11.50201952, 51.28018264],
          [11.43071666, 51.23236727],
          [11.45458101, 51.22959544],
          [11.49174191, 51.20983551],
          [11.50417957, 51.19526818],
          [11.51691107, 51.12648133],
          [11.66569855, 51.13291277],
          [11.69552711, 51.12503453],
          [11.7739558, 51.06718414],
          [11.88233559, 51.0783463],
          [11.91482536, 51.07119151],
          [12.02917655, 50.99149248],
          [12.18014762, 50.99854341],
          [12.21464903, 50.98646845],
          [12.21644624, 50.98430473],
          [12.24432477, 51.01387109],
          [12.23189031, 51.01873678],
          [12.21711487, 51.04567384],
          [12.24865097, 51.09995656],
          [12.28485973, 51.11472845],
          [12.46169803, 51.10712625],
          [12.48786401, 51.09825808],
          [12.68136561, 50.93789935],
          [12.66023508, 50.90204884],
          [12.5289351, 50.88439724],
          [12.4702838, 50.82970985],
          [12.44755424, 50.82017465],
          [12.31209481, 50.80311068],
          [12.31933221, 50.796695],
          [12.31696039, 50.76843319],
          [12.27332008, 50.73729495],
          [12.34641177, 50.67716679],
          [12.33713477, 50.64401667],
          [12.20317517, 50.59030206],
          [12.17239432, 50.59247049],
          [12.17063773, 50.55391517],
          [12.07225293, 50.53096344],
          [12.02570282, 50.54533269],
          [11.99784808, 50.59967441],
          [11.92190473, 50.54237361],
          [11.98881827, 50.50275168],
          [11.99328987, 50.47248929],
          [11.89500267, 50.3872356],
          [11.85477282, 50.37904147],
          [11.77932365, 50.39492908],
          [11.52881745, 50.35186159],
          [11.49276156, 50.3586573],
          [11.39306396, 50.42924832],
          [11.38392781, 50.44620569],
          [11.38938066, 50.49439648],
          [11.35549276, 50.49753655],
          [11.28358859, 50.46602308],
          [11.28902978, 50.26825552],
          [11.24428513, 50.24558146],
          [11.13652603, 50.26302202],
          [11.1103561, 50.28005338],
          [11.08864148, 50.33931763],
          [10.99415911, 50.32501666],
          [10.95632051, 50.33428368],
          [10.91830097, 50.36934188],
          [10.83717234, 50.36976327],
          [10.75077088, 50.34798463],
          [10.75042228, 50.33561182],
          [10.87492262, 50.26899881],
          [10.8516568, 50.22840764],
          [10.76249976, 50.22871257],
          [10.75488728, 50.20046912],
          [10.70955244, 50.18226921],
          [10.60218124, 50.20202486],
          [10.57624604, 50.22266663],
          [10.56692733, 50.31638497],
          [10.48181389, 50.33220384],
          [10.46036101, 50.34318507],
          [10.43198688, 50.37614948],
          [10.40441945, 50.3712596],
          [10.36147373, 50.38475478],
          [10.30413423, 50.47518864],
          [10.22090915, 50.48920808],
          [10.19654811, 50.50313097],
          [10.1779378, 50.53403373],
          [10.12178003, 50.53936934],
          [10.06799445, 50.50121639],
          [10.005331, 50.51734781],
          [10.01324047, 50.64777778],
          [9.99289073, 50.64619761],
          [9.9962273, 50.63279759],
          [9.95291362, 50.60699023],
          [9.86913992, 50.61884474],
          [9.8422189, 50.64690854],
        ],
      ],
    },
  },
] as AllowedRegion[];
