import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import {
  LngLatLike,
  RasterLayerSpecification,
  RasterSourceSpecification,
  StyleSpecification,
} from 'maplibre-gl';

@Component({
  selector: 'app-map-small',
  standalone: true,
  imports: [CommonModule, NgxMapLibreGLModule],
  templateUrl: './map-small.component.html',
  styleUrls: ['./map-small.component.scss'],
})
export class MapSmallComponent implements OnInit {
  @Input()
  public center: LngLatLike = [0, 0];

  public style: StyleSpecification | undefined;

  public ngOnInit(): void {
    const source = {
      type: 'raster',
      tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
      minzoom: 0,
      maxzoom: 18,
      tileSize: 256,
    } as RasterSourceSpecification;

    const layer = {
      id: 'some-raster-layer-id',
      type: 'raster',
      source: 'raster',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'raster-opacity': 1.0,
      },
    } as RasterLayerSpecification;

    this.style = {
      version: 8,
      sources: {
        raster: source,
      },
      layers: [layer],
    };
  }
}
