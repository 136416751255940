import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateExpiring',
  standalone: true,
})
export class DateExpiringPipe implements PipeTransform {
  public transform(date: Date | string | undefined): boolean {
    if (!date) {
      return false;
    }

    const expiryDate = new Date(date);
    const currentDate = new Date();
    const timeDiff = expiryDate.getTime() - currentDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff <= 7;
  }
}
