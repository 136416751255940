import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { DialogBase } from '@app/shared';

@Pipe({
  name: 'projectFromError',
  standalone: true,
})
export class ProjectFromErrorPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  public transform(formGroup: FormGroup): Observable<string> {
    if (formGroup.invalid) {
      if (formGroup.get('name')?.hasError('required')) {
        return this.translate.get('PROJECT_FORM_ERRORS.REQUIRED');
      }
      if (formGroup.get('name')?.hasError('maxlength')) {
        return this.translate.get('PROJECT_FORM_ERRORS.MAX_LENGTH', {
          maxLength: DialogBase.MAX_LENGTH_FOR_PROJECT_NAME,
          currentLength: formGroup.get('name')?.value.length,
        });
      }
    }
    return of('');
  }
}
