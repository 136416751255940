export enum API_ERROR_TYPE {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  NOT_FOUND = 'NOT_FOUND',

  // Import
  IMPORT_NO_VALID_ENTRIES = 'IMPORT_NO_VALID_ENTRIES',
  IMPORT_MAXIMUM_EXCEEDED = 'IMPORT_MAXIMUM_EXCEEDED',

  NO_SUBSCRIPTION_PLAN = 'NO_SUBSCRIPTION_PLAN',
  SUBSCRIPTION_PLAN_EXPIRED = 'SUBSCRIPTION_PLAN_EXPIRED',

  // Restrictions
  OUTSIDE_AREA_RESTRICTION = 'OUTSIDE_AREA_RESTRICTION',
}

export interface ApiErrorDetail {
  type: string;
  message: string;
}

export interface ApiErrorResponse {
  status: string;
  code: number;
  timestamp: string;
  errors: ApiErrorDetail[];
}

export interface ApiError {
  type: API_ERROR_TYPE;
  message: string;
  field?: string;
}
