export enum FEATURES {
  LAYERS = 'layers',
  CLICKABLE_LOCATIONS = 'clickableLocations',
  SEARCH = 'search',
}

export enum SPOT_INFORMATION_FEATURE {
  NETWORK_OPERATOR = 'networkOperator',
  CRUD_OPERATIONS = 'crudOperations',
}

export interface MapFeatureConfig {
  [FEATURES.LAYERS]: boolean;
  [FEATURES.CLICKABLE_LOCATIONS]: boolean;
  [FEATURES.SEARCH]: boolean;
  SPOT_INFORMATION_FEATURE: {
    [SPOT_INFORMATION_FEATURE.NETWORK_OPERATOR]: boolean;
    [SPOT_INFORMATION_FEATURE.CRUD_OPERATIONS]: boolean;
  };
}
