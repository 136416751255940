import { isNil } from 'lodash';
import { computed, inject, Injectable } from '@angular/core';
import * as turf from '@turf/turf';
import { booleanPointInPolygon, MultiPolygon, Units } from '@turf/turf';
import { LngLatLike } from 'maplibre-gl';
import { UserService } from './user.service';
import { Feature, Polygon, Properties } from '@turf/helpers';

interface RadiusRestriction {
  /*eslint-disable @typescript-eslint/no-explicit-any */
  radiusRestrictionLayer: any;
  circleRestriction: any;

  /*eslint-enable @typescript-eslint/no-explicit-any */
  maxRadius: number;
  locationMaxKW?: number;
  chargingStations?: number;
  mercatorCoordinates: number[];
}

@Injectable({ providedIn: 'root' })
export class RestrictionService {
  readonly #userService = inject(UserService);

  public isRestricted = computed(() => {
    const plan = this.#userService.subscriptionPlan();

    return !!plan?.details?.info?.areas;
  });

  public allowedRegions = computed(() => {
    const allowedRegions = this.#userService.allowedRegions();
    if (!allowedRegions || allowedRegions.length === 0) {
      return undefined;
    }

    const bbox = [-180, -90, 180, 90];
    let boundary: Feature<Polygon | MultiPolygon> | null = turf.bboxPolygon(
      bbox as turf.BBox
    );

    if (!isNil(boundary)) {
      allowedRegions.forEach(region => {
        boundary = turf.difference(boundary!, region.geoBoundary);
      });
    }

    return boundary as GeoJSON.Feature;
  });

  public restrictedAreas = computed(() => {
    const plan = this.#userService.subscriptionPlan();

    return plan?.details?.info?.areas;
  });

  public radiusRestrictions = computed(() => {
    const plan = this.#userService.subscriptionPlan();
    const areas = plan?.details?.info?.areas;

    if (!plan || !plan?.details?.info?.areas) {
      return undefined;
    }

    const bbox = [-180, -90, 180, 90];
    const world = turf.bboxPolygon(bbox as turf.BBox);

    const areaDetails = areas?.map(area => {
      const center = [area.longitude, area.latitude];
      const radius = area.radius / 1000;
      const options = {
        steps: 150,
        units: 'kilometers' as Units,
      };
      const circleJson = turf.circle(center, radius, options);
      const result = turf.difference(world, circleJson);

      return {
        radiusRestrictionLayer: result,
        circleRestriction: circleJson,
        maxRadius: area.radius,
        locationMaxKW: area.locationMaxKW,
        chargingStations: area.chargingStations,
        mercatorCoordinates: turf.toMercator(
          turf.point(center).geometry.coordinates
        ),
      } as RadiusRestriction;
    });
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    let radiusRestrictionLayer = world as any;

    areaDetails?.forEach(element => {
      radiusRestrictionLayer = turf.difference(
        radiusRestrictionLayer,
        element.circleRestriction
      );
    });

    return {
      radiusRestrictionLayer: radiusRestrictionLayer,
      areaDetails,
    };
  });

  public checkIfOutsideRadiusRestriction(coordinates: LngLatLike): boolean {
    const user = this.#userService.user();

    if (!user) {
      return false;
    }

    const areas = user.subscriptionPlan?.details?.info?.areas;

    if (!areas) {
      return false;
    }

    if (areas.length === 0) {
      return true;
    }

    const insideAnyArea = areas.some(area => {
      const center = [area.longitude, area.latitude];
      const radius = area.radius;

      const areaCenterPoint = turf.point(center);
      const temporaryPoint = turf.point(coordinates as turf.Position);

      const distance = turf.distance(areaCenterPoint, temporaryPoint, {
        units: 'meters',
      });

      return distance <= radius;
    });

    return !insideAnyArea;
  }

  public checkIfInsideAllowedRegions(coordinates: LngLatLike): boolean {
    const allowedRegions = this.allowedRegions();
    if (allowedRegions) {
      return !booleanPointInPolygon(
        coordinates as turf.Coord,
        this.allowedRegions() as Feature<Polygon | MultiPolygon, Properties>
      );
    }
    return false;
  }
}
