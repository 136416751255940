<div
  class="search-card"
  [ngClass]="{
    'search-card--small': size === 'small',
    'search-card--normal': size === 'normal'
  }">
  <div class="search-card__header">
    <h2 *ngIf="headline && headlineTooltip" class="card-headline">
      <span
        [matTooltip]="headlineTooltip"
        [matTooltipShowDelay]="headlineTooltipShowDelay"
        [matTooltipPosition]="headlineTooltipPosition"
        >{{ headline }}
      </span>
    </h2>
  </div>
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="search-card__form">
      <div class="grid-element">
        <div class="grid-element--content">
          <h3 class="search-card__sub-headline">
            <span
              [matTooltip]="TT.SEARCH_CRITERIA | tooltip | translate"
              [matTooltipShowDelay]="TT.SEARCH_CRITERIA | tooltipShowDelay"
              >{{ 'SEARCH_CARD.SEARCH_CRITERIA' | translate }}</span
            >
          </h3>
        </div>

        <app-country-selection
          [selectOptions]="COUNTRY_SELECTION_OPTIONS()"
          [input]="searchForm.get(FORM_NAME.COUNTRY_CODE)" />
        <app-restricted-area-form
          *ngIf="radiusRestrictions()"
          [input]="searchForm.get(FORM_NAME.CITY_OR_AREA)" />

        <app-city-or-area-form
          *ngIf="!radiusRestrictions()"
          [input]="searchForm.get(FORM_NAME.CITY_OR_AREA)"
          [options]="cityAreaOptions"
          (searchGeolocation)="geolocationSearch$.next($event)">
        </app-city-or-area-form>

        <app-radius-form
          [radiusOptions]="maxRadius | radiusOptions"
          [input]="searchForm.get(FORM_NAME.RADIUS_IN_METER)">
        </app-radius-form>
      </div>

      <div class="grid-element">
        <div class="grid-element--content">
          <h3 class="search-card__sub-headline">
            <span
              [matTooltip]="TT.LOCATION_CRITERIA | tooltip | translate"
              [matTooltipShowDelay]="TT.LOCATION_CRITERIA | tooltipShowDelay"
              >{{ 'SEARCH_CARD.LOCATION_CRITERIA' | translate }}
            </span>
          </h3>
        </div>

        <app-max-capacity-form
          [minMaxCapacityInKw]="searchFormConfig.MAX_CAPACITY_IN_KW.MIN"
          [maxMaxCapacityInKw]="searchFormConfig.MAX_CAPACITY_IN_KW.MAX"
          [input]="
            searchForm.get(FORM_NAME.MAX_CAPACITY_IN_KW)
          "></app-max-capacity-form>

        <app-amount-of-charging-stations-form
          [maxAmountOfChargingStations]="
            searchFormConfig.AMOUNT_OF_CHARGING_STATIONS.MAX
          "
          [minAmountOfChargingStations]="
            searchFormConfig.AMOUNT_OF_CHARGING_STATIONS.MIN
          "
          [input]="
            searchForm.get(FORM_NAME.AMOUNT_OF_CHARGING_STATIONS)
          "></app-amount-of-charging-stations-form>
      </div>
    </div>
    <div class="search-card__footer">
      <button
        class="search-button"
        [ngClass]="{
          small: size === 'small'
        }"
        color="primary"
        mat-flat-button
        [disabled]="searchForm.invalid"
        data-testid="submit-search-form">
        <mat-icon class="footer-icon" fontSet="material-symbols-outlined"
          >arrow_forward
        </mat-icon>
        {{ buttonText | translate }}
      </button>
    </div>
  </form>
</div>
