import { Component, inject, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/core/services';
import { DisclaimerDialogComponent, Link, LinkComponent } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [LinkComponent, TranslateModule],
})
export class FooterComponent {
  public footerLinks = input<Link[]>([]);
  readonly dialog = inject(MatDialog);
  readonly userService = inject(UserService);

  public onClick = () => {
    this.dialog.open(DisclaimerDialogComponent);
  };
}
