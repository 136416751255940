import { Pipe, PipeTransform } from '@angular/core';
import { LayerWrapperOrGroup } from '@app/map';
import { LayerGroupDiscriminator } from '@app/shared';

@Pipe({
  name: 'isLayerGroup',
  standalone: true,
})
export class IsLayerGroupPipe implements PipeTransform {
  public transform(item: LayerWrapperOrGroup): item is LayerWrapperOrGroup {
    return LayerGroupDiscriminator.isGroup(item);
  }
}
