import { Component, HostBinding, input } from '@angular/core';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss',
})
export class LinkComponent {
  public url = input<string>();
  public label = input<string>();
  public decorated = input<boolean>(false);
  public fontSize = input<string>('15px');
  public onClick = input<() => void>();

  @HostBinding('style.--font-size') get size() {
    return this.fontSize;
  }
}
