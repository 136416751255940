import { Pipe, PipeTransform } from '@angular/core';
import { kWhFormatter } from '@app/shared';

@Pipe({
  name: 'kWhFormat',
  standalone: true,
})
export class KWhFormatPipe implements PipeTransform {
  public transform(value: number): string {
    return kWhFormatter(value);
  }
}
