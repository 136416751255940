import { HttpErrorResponse } from '@angular/common/http';

/* eslint-disable no-prototype-builtins */

export class ApiErrorDiscriminator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isApiError(error: any): boolean {
    if (error instanceof HttpErrorResponse) {
      if (error.error && typeof error.error === 'object') {
        const errorObj = error.error;

        if (
          errorObj.hasOwnProperty('status') &&
          errorObj.hasOwnProperty('code') &&
          errorObj.hasOwnProperty('timestamp') &&
          Array.isArray(errorObj.errors)
        ) {
          return true;
        }
      }
    }
    return false;
  }
}
