import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-dialog-loading-indicator',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatProgressBarModule,
  ],
  templateUrl: './dialog-loading-indicator.component.html',
  styleUrls: ['./dialog-loading-indicator.component.scss'],
})
export class DialogLoadingIndicatorComponent {
  @Input({ required: true }) public isLoading = false;
}
