import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-share-project-btn-skeleton',
  standalone: true,
  imports: [CommonModule, MatChipsModule, MatIconModule, TranslateModule],
  templateUrl: './share-project-btn-skeleton.component.html',
  styleUrls: ['./share-project-btn-skeleton.component.scss'],
})
export class ShareProjectBtnSkeletonComponent {}
