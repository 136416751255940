import { Pipe, PipeTransform } from '@angular/core';
import { TOOLTIP, TooltipProvider } from '@app/shared/util';

@Pipe({
  name: 'tooltipShowDelay',
  standalone: true,
})
export class TooltipShowDelayPipe implements PipeTransform {
  readonly #tooltipProvider = TooltipProvider.INSTANCE;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public transform(tooltipIdentifier: TOOLTIP): number {
    return this.#tooltipProvider.DELAY_IN_MS;
  }
}
