import { ListFormMetaBase } from '@app/shared/list-form/interfaces/list-form-meta-base';
import { LIST_FORM_META_TYPE } from '@app/shared/list-form/list-form-meta-type.enum';
import { Validators } from '@angular/forms';

export class ListFormIdentifier extends ListFormMetaBase {
  public constructor(
    labelTranslationIdentifier: string,
    validators: Validators[] = [],
    isDisplayed: boolean = true
  ) {
    super(
      labelTranslationIdentifier,
      LIST_FORM_META_TYPE.IDENTIFIER,
      LIST_FORM_META_TYPE.IDENTIFIER,
      validators,
      isDisplayed
    );
  }
}
