<form [formGroup]="formGroup">
  <mat-chip-listbox formControlName="rankBy">
    <mat-chip-option
      data-testid="sort-option"
      (selectionChange)="onChipSelectionChange($event)"
      [matTooltip]="
        'COMMON.SCORE_SORTING.' + option.toUpperCase() + '_TOOLTIP' | translate
      "
      [value]="option"
      [selectable]="formGroup.get('rankBy')?.value !== option"
      color="accent"
      *ngFor="let option of options">
      <mat-icon matChipAvatar fontSet="material-symbols-outlined">
        speed
      </mat-icon>
      {{ 'COMMON.SCORE_SORTING.' + option.toUpperCase() | translate }}
    </mat-chip-option>
  </mat-chip-listbox>
</form>
