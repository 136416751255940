import { Pipe, PipeTransform } from '@angular/core';
import { ListFormMetaBase } from '@app/shared/list-form/interfaces/list-form-meta-base';
import { ListFormMetaSelect } from '@app/shared/list-form/interfaces/list-form-meta-select';

@Pipe({
  name: 'toListFormMetaSelect',
  standalone: true,
})
export class ToListFormMetaSelectPipe implements PipeTransform {
  public transform(value?: ListFormMetaBase): ListFormMetaSelect {
    return value as ListFormMetaSelect;
  }
}
