import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchFormValue } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  #lastSubmittedSearch?: SearchFormValue;
  #lastSubmittedSearch$ = new BehaviorSubject<SearchFormValue | null>(null);

  public set lastSubmittedSearch(value: SearchFormValue) {
    this.#lastSubmittedSearch = value;
    this.#lastSubmittedSearch$.next(this.#lastSubmittedSearch);
  }

  public get lastSubmittedSearch$(): Observable<SearchFormValue | null> {
    return this.#lastSubmittedSearch$;
  }
}
