import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { EnvironmentService } from '@app/core';
import {
  ALLOWED_REGIONS_FREE_USER,
  API_ERROR_TYPE,
  AllowedRegion,
  ApiError,
  ApiErrorDiscriminator,
  ErrorDetails,
  SubscriptionPlanName,
  User,
} from '@app/shared';
import { AuthService } from '@auth0/auth0-angular';
import { isNil } from 'lodash-es';
import { EMPTY, Observable, ReplaySubject, catchError, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public isFreeUser = signal<boolean>(false);
  public user = signal<User | null>(null);
  public user$ = new ReplaySubject<User | null>();
  public subscriptionPlan = computed(() => {
    return this.user()?.subscriptionPlan;
  });
  private _allowedRegions: WritableSignal<AllowedRegion[] | undefined> =
    signal(undefined);
  public allowedRegions: Signal<AllowedRegion[]> = computed(() => {
    return (
      this._allowedRegions() ||
      this.subscriptionPlan()?.details?.allowedRegions ||
      []
    );
  });
  readonly #httpClient = inject(HttpClient);
  readonly #environment = inject(EnvironmentService);
  readonly #router = inject(Router);
  readonly #authService = inject(AuthService);

  public initFreeUser() {
    this.isFreeUser.set(true);
    this._allowedRegions.set(ALLOWED_REGIONS_FREE_USER);
  }

  public isDemo(): boolean {
    return this.subscriptionPlan()?.name === SubscriptionPlanName.DEMO;
  }

  public validateUser(): void {
    this.#httpClient
      .get<User>(
        `${this.#environment.apiUrl}/users/self?expand=REGION_BOUNDARIES`,
        {
          observe: 'response',
        }
      )
      .pipe(catchError(error => this.checkIfUserExists(error)))
      .subscribe(response => {
        const user = response.body as User;

        if (user?.subscriptionPlan?.expired) {
          this.redirectToPlanExpiredPage();
        }
        if (
          !this.#isUserWithoutAreaRestrictions(user) &&
          !this.#isUserWithoutAllowedRegions(user)
        ) {
          this.#redirectWithErrorMessage(
            'COMMON.NO_REGION_RESTRICTION_ERROR',
            'COMMON.NO_REGION_RESTRICTION_ERROR_DETAILS'
          );
        }
        this.user.set(user);
        this.user$.next(user);
      });
  }

  #isUserWithoutAllowedRegions(user: User): boolean {
    return (
      isNil(user?.subscriptionPlan?.details?.allowedRegions) ||
      user?.subscriptionPlan?.details?.allowedRegions.length === 0
    );
  }

  #isUserWithoutAreaRestrictions(user: User): boolean {
    return (
      isNil(user?.subscriptionPlan?.details?.info?.areas) ||
      user?.subscriptionPlan?.details?.info?.areas.length === 0
    );
  }

  private checkIfUserExists(
    errorResponse: HttpErrorResponse
  ): Observable<never> {
    if (ApiErrorDiscriminator.isApiError(errorResponse)) {
      const customError = errorResponse.error.errors[0] as ApiError;

      if (customError.type === API_ERROR_TYPE.NO_SUBSCRIPTION_PLAN) {
        this.#redirectWithErrorMessage(
          'ERROR.NO_SUBSCRIPTION_PLAN',
          'ERROR.NO_SUBSCRIPTION_PLAN'
        );
        return EMPTY;
      } else if (
        customError.type === API_ERROR_TYPE.SUBSCRIPTION_PLAN_EXPIRED
      ) {
        this.redirectToPlanExpiredPage();
        return EMPTY;
      }
    }

    if (errorResponse.status === 403) {
      this.#authService.user$.pipe(take(1)).subscribe(user => {
        this.#redirectWithErrorMessage(
          'ERROR.USER_DOES_NOT_EXIST_TITLE',
          'ERROR.USER_DOES_NOT_EXIST_DESCRIPTION',
          {
            email: user?.email,
          }
        );
      });
    } else {
      const errorDetails: ErrorDetails = {
        title: 'ERROR.UNKNOWN_ERROR_TITLE',
        description: 'ERROR.UNKNOWN_ERROR_DESCRIPTION',
      };

      this.#router.navigate(['error'], {
        state: {
          errorDetails,
        },
      });
    }

    return EMPTY;
  }

  private redirectToPlanExpiredPage(): void {
    const errorDetails: ErrorDetails = {
      title: 'ERROR.PLAN_EXPIRED_TITLE',
      description: 'ERROR.PLAN_EXPIRED_DESCRIPTION',
    };

    this.#router.navigate(['error'], {
      state: {
        errorDetails,
      },
    });
  }

  #redirectWithErrorMessage(
    title: string,
    description: string,
    translateParameter?: object
  ): void {
    const errorDetails: ErrorDetails = {
      title,
      description,
      translateParameter,
    };
    if (this.#router.url !== '/error') {
      this.#router.navigate(['error'], {
        state: {
          errorDetails,
        },
      });
    }
  }
}
