import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MatChipSelectionChange,
  MatChipsModule,
} from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { SearchService } from '@app/core';
import { TranslateModule } from '@ngx-translate/core';
import { isNil, isObject } from 'lodash';
import { filter, take } from 'rxjs';
import { RankBy } from './rank-by.enum';
import { APP_ROUTES, SearchFormValue } from '@app/shared';

@Component({
  selector: 'app-spots-ranking',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './spots-ranking.component.html',
  styleUrls: ['./spots-ranking.component.scss'],
})
export class SpotsRankingComponent implements OnInit {
  readonly #searchService = inject(SearchService);
  readonly #router = inject(Router);
  readonly #fb = inject(FormBuilder);
  readonly #destroyRef = inject(DestroyRef);

  @Input()
  public freeToPlay = false;

  formGroup: FormGroup;
  options = Object.values(RankBy).filter(value => value !== undefined);

  constructor() {
    const control = this.#fb.control({});
    this.formGroup = this.#fb.group({
      rankBy: control,
    });

    control.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(value => {
        this.#triggerSearch(value as RankBy);
      });
  }

  public ngOnInit(): void {
    this.#searchService.lastSubmittedSearch$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(searchValues => !isNil(searchValues)),
        take(1)
      )
      .subscribe((searchValues: SearchFormValue | null) => {
        if (searchValues?.rankBy !== undefined) {
          this.formGroup.get('rankBy')?.setValue(searchValues?.rankBy);
        }
      });
  }

  #triggerSearch(rankBy: RankBy): void {
    this.#searchService.lastSubmittedSearch$
      .pipe(takeUntilDestroyed(this.#destroyRef), take(1))
      .subscribe(lastSubmittedSearch => {
        const newSearch = {
          ...lastSubmittedSearch,
          cityOrArea: lastSubmittedSearch?.cityOrArea,
          rankBy: rankBy,
        } as SearchFormValue;

        const route = !this.freeToPlay
          ? `/${APP_ROUTES.MAP}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`
          : `/${APP_ROUTES.FREE_TO_PLAY}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`;

        this.#router.navigate([route], {
          queryParams: {
            ...newSearch,
            cityOrArea: isObject(newSearch.cityOrArea)
              ? newSearch.cityOrArea.id
              : newSearch.cityOrArea,
          },
        });
      });
  }

  onChipSelectionChange(event: MatChipSelectionChange) {
    if (event.isUserInput && !event.selected) {
      event.source.select();
    }
  }
}
