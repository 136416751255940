import { isNil } from 'lodash';
import { COUNTRY_CODE } from '@app/shared';
import { AllowedRegion, Area } from '../dto';
import { CountrySelectionOption } from './components/country-selection/country-selection-option';
import { subdivision } from 'iso-3166-2';

export class CountrySelectionOptionMapper {
  public static readonly INSTANCE = new CountrySelectionOptionMapper();

  readonly #ASSETS_BY_COUNTRY_CODE: Map<COUNTRY_CODE, string> = new Map([
    [COUNTRY_CODE.GERMANY, 'assets/country-images/germany.svg'],
    [COUNTRY_CODE.ITALY, 'assets/country-images/italy.svg'],
  ]);

  readonly #TRANSLATION_IDENTIFIER_BY_COUNTRY_CODE: Map<COUNTRY_CODE, string> =
    new Map([
      [COUNTRY_CODE.GERMANY, 'SEARCH_CARD.COUNTRIES.GERMANY'],
      [COUNTRY_CODE.ITALY, 'SEARCH_CARD.COUNTRIES.ITALY'],
    ]);

  public map(allowedRegion: AllowedRegion): CountrySelectionOption {
    const subdivisionResult = subdivision(allowedRegion.region);

    if (!subdivisionResult) {
      throw new Error(
        `Country code ${allowedRegion.region} could not be mapped`
      );
    }

    const countryCode =
      subdivisionResult.countryCode.toUpperCase() as COUNTRY_CODE;

    return this.#buildCountrySelectionOption(countryCode);
  }

  public mapFromCountryCode(countryCode: COUNTRY_CODE): CountrySelectionOption {
    return this.#buildCountrySelectionOption(countryCode);
  }

  #buildCountrySelectionOption(
    countryCode: COUNTRY_CODE
  ): CountrySelectionOption {
    const imageURL = this.#ASSETS_BY_COUNTRY_CODE.get(countryCode);
    const displayTextTranslationIdentifier =
      this.#TRANSLATION_IDENTIFIER_BY_COUNTRY_CODE.get(countryCode);

    if (
      this.#isImageAndTranslationValid(
        imageURL,
        displayTextTranslationIdentifier
      )
    ) {
      return {
        imageURL: imageURL!,
        displayTextTranslationIdentifier: displayTextTranslationIdentifier!,
        countryCode,
      };
    }
    throw new Error(
      `Country code ${countryCode} could not be mapped to an image or translation`
    );
  }

  #isImageAndTranslationValid(
    imageUrl?: string,
    translation?: string
  ): boolean {
    return !isNil(imageUrl) && !isNil(translation);
  }

  public mapFromArea(area: Area): CountrySelectionOption {
    return this.#buildCountrySelectionOption(area.countryCode as COUNTRY_CODE);
  }
}
