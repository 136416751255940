<footer class="footer" data-testid="footer">
  <div class="footer-content">
    <div class="eon-brand">
      <img class="footer-logo" src="../assets/eon-logo.svg" alt="E.ON Logo" />
    </div>
    <div class="links">
      <ul class="links-list">
        @for (link of footerLinks(); track link.url) {
          <li class="links-list-element">
            <app-link [url]="link.url" [label]="link.label"></app-link>
          </li>
        }

        @if (userService.isFreeUser()) {
          <li class="links-list-element">
            <a class="link" (click)="this.onClick()">{{
              'DISCLAIMER.DISCLAIMER' | translate
            }}</a>
          </li>
        }
      </ul>
    </div>
  </div>
</footer>
