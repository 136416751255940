<a
  [routerLink]="link"
  routerLinkActive="active"
  [class.divider]="showDivider"
  (click)="openExternalLinkIfPresent()"
  class="menu-main-link"
  [attr.data-testid]="text + '_navBtn'">
  {{ text | translate }}
  <ng-content></ng-content>
</a>
