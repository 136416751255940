import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { isNil } from 'lodash-es';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FORM_NAME } from '@app/shared/search-card/form-name.enum';

@Component({
  selector: 'app-amount-of-charging-stations-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  templateUrl: './amount-of-charging-stations-form.component.html',
  styleUrls: ['./amount-of-charging-stations-form.component.scss'],
})
export class AmountOfChargingStationsFormComponent implements OnInit {
  @Input() public input?: AbstractControl | null;
  @Input() public maxAmountOfChargingStations!: number;
  @Input() public minAmountOfChargingStations!: number;
  public amountOfChargingStationsFormGroup!: FormGroup;
  public readonly FORM_NAME = FORM_NAME;

  public ngOnInit(): void {
    if (
      isNil(this.maxAmountOfChargingStations) ||
      isNil(this.minAmountOfChargingStations)
    ) {
      throw Error('Missing required input param.');
    }
    this.amountOfChargingStationsFormGroup = this.input as FormGroup;
  }
}
