import { CommonModule, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/core';

@Component({
  selector: 'app-subscription-plan-info',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    NgIf,
    TranslateModule,
  ],
  templateUrl: './subscription-plan-info.component.html',
  styleUrls: ['./subscription-plan-info.component.scss'],
})
export class SubscriptionPlanInfoComponent {
  readonly #userService = inject(UserService);
  user = this.#userService.user;
  translateService = inject(TranslateService);
}
