import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { LoadingService } from '@app/core';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  readonly #loadingService = inject(LoadingService);

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone();

    this.#loadingService.increaseCounter();
    return next.handle(clonedRequest).pipe(
      finalize(() => {
        this.#loadingService.decreaseCounter();
      })
    );
  }
}
