import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { IconLabelComponent } from '../icon-label/icon-label.component';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { screenLg } from '@app/app.constants';

@Component({
  selector: 'app-action-menu-button',
  standalone: true,
  templateUrl: './action-menu-button.component.html',
  styleUrls: ['./action-menu-button.component.scss'],
  imports: [IconLabelComponent, MatMenuModule, MatDividerModule],
})
export class ActionMenuButtonComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);

  @ViewChild('menu', { static: false }) public menu: MatMenu | null = null;

  @ViewChild(IconLabelComponent) public iconLabel: IconLabelComponent | null =
    null;

  @Input()
  public icon = '';

  @Input()
  public menuTrigger = '';

  @Input()
  public label = '';

  @Input()
  public isInSideMenu = false;

  readonly #breakpointObserver = inject(BreakpointObserver);

  public ngOnInit(): void {
    this.observeBreakpoints();
  }

  public openMenu(): void {
    this.iconLabel?.trigger?.openMenu();
  }

  private observeBreakpoints(): void {
    this.#breakpointObserver
      .observe(`(max-width: ${screenLg}px)`)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((result: BreakpointState) => {
        if (!result.matches) {
          this.iconLabel?.trigger?.closeMenu();
        }
      });

    this.#breakpointObserver
      .observe(`(min-width: ${screenLg}px)`)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((result: BreakpointState) => {
        if (!result.matches) {
          this.iconLabel?.trigger?.closeMenu();
        }
      });
  }
}
