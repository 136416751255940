import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  DeleteProjectDialogComponent,
  DIALOG_EVENT,
  DialogConfigFactory,
  DialogData,
  ProjectDto,
  UpdateProjectDialogComponent,
} from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-project-card-header',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
  ],
  templateUrl: './project-card-header.component.html',
  styleUrls: ['./project-card-header.component.scss'],
})
export class ProjectCardHeaderComponent {
  @Input() public input?: ProjectDto;
  @Output() public deleteProject = new EventEmitter<DialogData>();
  @Output() public updateProject = new EventEmitter<DialogData>();
  @Output() public createProject = new EventEmitter<DialogData>();

  public DIALOG_EVENT = DIALOG_EVENT;
  readonly #dialog = inject(MatDialog);

  public openDialog(dialog: DIALOG_EVENT): void {
    const config = DialogConfigFactory.INSTANCE.buildDialogConfig('small');
    let dialogRef:
      | MatDialogRef<DeleteProjectDialogComponent>
      | MatDialogRef<UpdateProjectDialogComponent>;

    switch (dialog) {
      case DIALOG_EVENT.DELETE: {
        dialogRef = this.#dialog.open(DeleteProjectDialogComponent, {
          ...config,
          data: {
            project: this.input,
          },
        });
        dialogRef.afterClosed().subscribe((dialogData: DialogData) => {
          if (dialogData.event) {
            this.deleteProject.emit(dialogData);
          }
        });
        break;
      }
      case DIALOG_EVENT.UPDATE: {
        dialogRef = this.#dialog.open(UpdateProjectDialogComponent, {
          ...config,
          data: {
            project: this.input,
          },
        });
        dialogRef.afterClosed().subscribe((dialogData: DialogData) => {
          if (dialogData.event) {
            this.updateProject.emit(dialogData);
          }
        });
        break;
      }
      default: {
        throw new Error(`No matching dialog type. Dialog param was ${dialog}`);
      }
    }
  }
}
