<div class="mat-dialog-header">
  <h2 [matTooltip]="headlineToolTip" mat-dialog-title class="modal-headline">
    <ng-content></ng-content>
  </h2>
  <div class="mat-dialog-header-icon">
    <button (click)="onCloseButtonClick()" tabindex="-1" mat-icon-button>
      <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
