import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SnackBarService } from '@app/core/services/snack-bar.service';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  readonly #snackBarService = inject(SnackBarService);

  public handleCustomError(labelTranslationIdentifier: string): void {
    this.#snackBarService.openSnackBar(labelTranslationIdentifier, false);
  }

  public handleError(
    error: HttpErrorResponse | Error,
    showError = true
  ): Observable<never> {
    let errorCode;
    let errorMessage;

    if (error instanceof HttpErrorResponse) {
      errorCode = error.status;
      errorMessage = error.message;
      // eslint-disable-next-line no-console
      console.error(
        `Backend returned code ${errorCode}, body was ${errorMessage}`
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('An error occurred:', error);
    }
    if (showError) {
      const messageKey = errorCode
        ? 'COMMON.GENERIC_ERROR_WITH_CODE'
        : 'COMMON.GENERIC_ERROR';
      const params = errorCode ? { errorCode } : {};

      this.#snackBarService.openSnackBar(messageKey, false, params);
    }
    return throwError(() => error);
  }
}
