import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { DialogData } from '@app/shared';

export abstract class DialogBase {
  public static readonly MAX_LENGTH_FOR_PROJECT_NAME = 50;

  protected constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public readonly dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public closeDialog(): void {
    this.dialogRef.close(this.data);
  }
}
