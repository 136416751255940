<div [class]="reverseLabel ? 'responsive-icon-label' : 'icon-label'">
  <mat-icon
    class="icon"
    fontSet="material-symbols-outlined"
    [matMenuTriggerFor]="menu">
    {{ icon }}
  </mat-icon>
  @if (label) {
    <span
      [matTooltip]="label"
      [matTooltipShowDelay]="1500"
      [class]="responsiveLabel ? 'responsive-label' : 'label'"
      *ngIf="!!label">
      {{ label }}
    </span>
  }
</div>
