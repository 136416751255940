import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FORM_NAME } from './form-name.enum';
import { isNil } from 'lodash-es';

export const resetCityOrArea = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const countrySelectValue = control.get(FORM_NAME.COUNTRY_CODE)?.value;
    const cityOrAresValue = control.get(FORM_NAME.CITY_OR_AREA)?.value;
    if (
      !isNil(countrySelectValue.countryCode) &&
      !isNil(cityOrAresValue[FORM_NAME.CITY_OR_AREA]?.address?.countryCode)
    ) {
      if (
        countrySelectValue.countryCode.toUpperCase() !==
        cityOrAresValue[
          FORM_NAME.CITY_OR_AREA
        ]?.address?.countryCode?.toUpperCase()
      ) {
        control.get(FORM_NAME.CITY_OR_AREA)?.setErrors({ test: 'dasda' });
        control
          .get(FORM_NAME.CITY_OR_AREA)
          ?.setValue({ [FORM_NAME.CITY_OR_AREA]: null });
      }
    }
    return null;
  };
};
