import { CurrencyPipe } from '@angular/common';
import {
  ApplicationRef,
  inject,
  Injectable,
  PipeTransform,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalIdService {
  #i18nPipes: PipeTransform[];
  readonly #currencyPipe = inject(CurrencyPipe);
  readonly #applicationRef = inject(ApplicationRef);

  constructor() {
    this.#i18nPipes = [this.#currencyPipe];
  }

  /**
   * Overrides pipe local on runtime - workaround
   * see https://stackoverflow.com/questions/44287827/dynamically-change-locale-for-datepipe-in-angular-2
   */
  public setLocale(lang: string): void {
    this.#i18nPipes.forEach(pipe => {
      if (pipe.hasOwnProperty('locale')) {
        // eslint-disable-next-line
        // @ts-ignore
        pipe.locale = lang;
      } else if (pipe.hasOwnProperty('_locale')) {
        // eslint-disable-next-line
        // @ts-ignore
        pipe._locale = lang;
      }
    });
    this.#applicationRef.tick();
  }
}
