import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-spots-skeleton-list',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatListModule],
  templateUrl: './spots-skeleton-list.component.html',
  styleUrls: ['./spots-skeleton-list.component.scss'],
})
export class SpotsSkeletonListComponent {}
