import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FORM_NAME } from '../../form-name.enum';
import { RestrictionService, UserService } from '@app/core';

@Component({
  selector: 'app-restricted-area-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './restricted-area-form.component.html',
  styleUrls: ['./restricted-area-form.component.scss'],
})
export class RestrictedAreaFormComponent implements OnInit {
  @Input() public input?: AbstractControl | null;

  public formGroup!: FormGroup;
  public FORM_NAME = FORM_NAME;

  readonly #userService = inject(UserService);
  readonly #restrictionService = inject(RestrictionService);
  readonly user = this.#userService.user;
  readonly areaRestrictions = this.#restrictionService.radiusRestrictions;

  public ngOnInit(): void {
    this.formGroup = this.input as FormGroup;
  }
}
