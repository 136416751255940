import { Injectable } from '@angular/core';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { IEnvironment } from '@environments/ienvironment';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  public get production(): boolean {
    return environment.production;
  }

  public get apiUrl(): string {
    return environment.apiUrl;
  }

  public get issuer(): string {
    return environment.issuer;
  }

  public get clientId(): string {
    return environment.clientId;
  }

  public get logoutUrl(): string {
    return environment.logoutUrl;
  }

  public get servicePortalUrl(): string {
    return environment.servicePortalUrl;
  }

  public get geoserverUrl(): string {
    return environment.geoserverUrl;
  }

  public get geoserverApiVersion(): string {
    return environment.geoserverApiVersion;
  }

  public get mapTilerToken(): string {
    return environment.mapTilerToken;
  }

  public get mapTilerBaseMapId(): string {
    return environment.mapTilerBaseMapId;
  }

  public get usercentricsSettingsId(): string {
    return environment.usercentricsSettingsId;
  }

  public get contentSquareId(): string {
    return environment.contentSquareId;
  }

  public get environment(): string {
    return environment.environment;
  }

  public get gtmId(): string {
    return environment.gtmId;
  }

  public get gtmAuth(): string | undefined {
    return environment.gtmAuth;
  }

  public get gtmPreview(): string | undefined {
    return environment.gtmPreview;
  }
}
