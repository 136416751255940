import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, switchMap } from 'rxjs';
import { EnvironmentService } from '@app/core/services';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@app/shared';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  readonly #authService = inject(AuthService);
  readonly #environment = inject(EnvironmentService);
  readonly #router = inject(Router);

  readonly #endpointsWithoutAuthorization = ['/assets/i18n/'];

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isWithoutAuthorization(request)) {
      return next.handle(request);
    }
    return this.#authService.getAccessTokenSilently().pipe(
      switchMap(authToken => {
        const isCurrentUrlWhitelisted = request.url.includes(
          this.#environment.apiUrl
        );
        if (isCurrentUrlWhitelisted) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${authToken}` },
          });
        }

        return next.handle(request);
      })
    );
  }

  public isWithoutAuthorization(request: HttpRequest<any>) {
    const currentRoute = this.#router.url;
    const isFreeMapRoute = currentRoute.includes(APP_ROUTES.FREE_TO_PLAY);
    const isEndpointWithoutAuth = this.#endpointsWithoutAuthorization.some(
      routeWithoutAuth => request.url.includes(routeWithoutAuth)
    );

    return isFreeMapRoute || isEndpointWithoutAuth;
  }
}

/* eslint-enable @typescript-eslint/no-explicit-any */
