import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@app/core';
import {
  ProjectDto,
  ProjectRequestDto,
  ProjectStatisticDto,
  ProjectWithLocationDto,
} from '@app/shared';
import { Observable } from 'rxjs';

export enum PROJECT_EXPANSION {
  LOCATIONS = 'locations',
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  readonly #httpClient = inject(HttpClient);
  readonly #environment = inject(EnvironmentService);

  public loadProjectById(id: string): Observable<ProjectWithLocationDto> {
    return this.#httpClient.get<ProjectWithLocationDto>(
      `${this.#environment.apiUrl}/projects/${id}`
    );
  }

  public getProjects(
    expand: PROJECT_EXPANSION[] = []
  ): Observable<ProjectDto[]> {
    let params = new HttpParams();
    if (expand) {
      expand.forEach(value => {
        params = params.append('expand', value);
      });
    }

    return this.#httpClient.get<ProjectDto[]>(
      `${this.#environment.apiUrl}/projects`,
      { params }
    );
  }

  public getProjectsWithStatistics(): Observable<ProjectStatisticDto[]> {
    return this.#httpClient.get<ProjectStatisticDto[]>(
      `${this.#environment.apiUrl}/projects/statistics`
    );
  }

  public getProjectWithLocations(
    id: string
  ): Observable<ProjectWithLocationDto> {
    return this.#httpClient.get<ProjectWithLocationDto>(
      `${this.#environment.apiUrl}/projects/${id}`,
      {
        params: { expand: PROJECT_EXPANSION.LOCATIONS },
      }
    );
  }

  public updateProject(
    id: string,
    request: ProjectRequestDto
  ): Observable<ProjectDto> {
    return this.#httpClient.put<ProjectDto>(
      `${this.#environment.apiUrl}/projects/${id}`,
      request
    );
  }

  public createProject(request: ProjectRequestDto): Observable<ProjectDto> {
    return this.#httpClient.post<ProjectDto>(
      `${this.#environment.apiUrl}/projects`,
      request
    );
  }

  public deleteProject(
    id: string,
    force: boolean = false
  ): Observable<ProjectDto> {
    return this.#httpClient.delete<ProjectDto>(
      `${this.#environment.apiUrl}/projects/${id}`,
      {
        params: { force },
      }
    );
  }
}
