<div class="action-button" (click)="openMenu()">
  <app-icon-label [icon]="icon" [label]="label" [menu]="menu"></app-icon-label>
</div>

<mat-menu
  class="menu"
  #menu="matMenu"
  yPosition="above"
  xPosition="before"
  [overlapTrigger]="true">
  <div class="menu-content">
    <div class="menu-header">
      <app-icon-label
        [reverseLabel]="!isInSideMenu"
        [icon]="icon"
        [label]="label"
        [responsiveLabel]="false"></app-icon-label>
    </div>
    <mat-divider></mat-divider>
    <ng-content></ng-content>
  </div>
</mat-menu>
