import { Pipe, PipeTransform } from '@angular/core';
import { TOOLTIP, TooltipProvider } from '@app/shared/util';

@Pipe({
  name: 'tooltip',
  standalone: true,
})
export class TooltipPipe implements PipeTransform {
  #tooltipProvider = TooltipProvider.INSTANCE;
  public transform(value: TOOLTIP): string {
    const potentialTooltip =
      this.#tooltipProvider.receiveTranslationIdentifier(value);

    if (potentialTooltip) {
      return potentialTooltip;
    }
    throw new Error(`No translation for: ${value}`);
  }
}
