export enum APP_ROUTES {
  HOME = 'home',
  TABLE = 'table',
  TABLE_PROJECT = 'table/project',
  TABLE_PROJECT_VIEW = 'table/project/:id',
  SHARED_PROJECT_VIEW = 'map/shared-project/:id',
  SHARED_PROJECT = 'map/shared-project',
  FREE_TO_PLAY = 'map/public',
  MAP = 'map',
  MAP_PROJECT = 'map/project',
  MAP_PROJECT_VIEW = 'map/project/:id',
  MAP_CHILDREN_SEARCH = 'search',
  ERROR = 'error',
  ADMIN = 'admin',
}
