import { DOCUMENT } from '@angular/common';
import { inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from './environment.service';
import { BehaviorSubject } from 'rxjs';

interface UserCentricsUI {
  updateLanguage(lang: string): void;
}

@Injectable({ providedIn: 'root' })
export class CookieConsentService implements OnDestroy {
  private static readonly USERCENTRICS_SCRIPT = {
    ID: 'usercentrics-cmp',
    SRC: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
    TYPE: 'application/javascript',
  };

  readonly #document = inject(DOCUMENT);
  readonly #translateService = inject(TranslateService);
  readonly #environmentService = inject(EnvironmentService);

  public ucEvents$ = new BehaviorSubject<unknown>(null);

  public init(renderer2: Renderer2): void {
    const script = renderer2.createElement('script');

    script.id = CookieConsentService.USERCENTRICS_SCRIPT.ID;
    script.type = CookieConsentService.USERCENTRICS_SCRIPT.TYPE;
    script.src = CookieConsentService.USERCENTRICS_SCRIPT.SRC;
    script.setAttribute(
      'data-settings-id',
      this.#environmentService.usercentricsSettingsId
    );
    script.setAttribute('data-language', this.#translateService.currentLang);
    script.async = true;
    renderer2.appendChild(this.#document.head, script);

    this.#watchConsentChange();
  }

  public updateLanguage(lang: string): void {
    // eslint-disable-next-line
    const ucUI = (window as any)?.UC_UI! as UserCentricsUI;
    if (ucUI) {
      ucUI.updateLanguage(lang);
    }
  }

  #watchConsentChange(): void {
    addEventListener('ucEvent', this.#updateUcEvents.bind(this));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #updateUcEvents(event: any): void {
    this.ucEvents$.next(event);
  }

  ngOnDestroy(): void {
    removeEventListener('ucEvent', this.#updateUcEvents.bind(this));
  }
}
