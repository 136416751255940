import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  readonly #authService = inject(AuthService);
  readonly #doc = inject(DOCUMENT);

  public logout(): void {
    this.#authService.logout({
      logoutParams: {
        returnTo: this.#doc.location.origin,
      },
    });
  }
}
