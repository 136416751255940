<div class="project-card-header">
  <button
    mat-icon-button
    id="open-menu-button"
    [matMenuTriggerFor]="menu"
    data-testid="project-card-open-menu-btn">
    <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button
      id="edit-button"
      data-testid="rename-project-menu-btn"
      (click)="openDialog(DIALOG_EVENT.UPDATE)"
      mat-menu-item>
      <mat-icon fontSet="material-symbols-outlined">edit<</mat-icon>
      <span>{{ 'COMMON.RENAME' | translate }}</span>
    </button>
    <button
      id="delete-button"
      data-testid="delete-project-menu-btn"
      (click)="openDialog(DIALOG_EVENT.DELETE)"
      mat-menu-item>
      <mat-icon fontSet="material-symbols-outlined">Delete</mat-icon>
      <span>{{ 'COMMON.DELETE' | translate }}</span>
    </button>
  </mat-menu>
</div>
