import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogHeaderComponent } from '@app/shared';

export interface Heading {
  icon?: string;
  header?: string;
  subHeading?: string;
  description?: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TranslateModule, NgIf, NgClass, DialogHeaderComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input()
  public heading: Heading = {};

  @Input()
  public showBreadcrumb = false;

  @Input()
  public small?: boolean = false;
}
