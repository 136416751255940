import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { AuthenticationService, LoadingService, UserService } from '@app/core';
import { APP_ROUTES, WindowRef } from '@app/shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import {
  APP_LANGUAGE,
  languageLocalStorageKey,
  screenLg,
} from 'src/app/app.constants';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { SubscriptionPlanInfoComponent } from './subscription-plan-info/subscription-plan-info.component';
import { MatChipsModule } from '@angular/material/chips';
import { isNil } from 'lodash-es';
import {
  NgIf,
  NgTemplateOutlet,
  NgForOf,
  AsyncPipe,
  NgOptimizedImage,
  JsonPipe,
  NgClass,
} from '@angular/common';
import { ActionButtonComponent } from './action-button/action-button.component';
import { ActionMenuButtonComponent } from './action-menu-button/action-menu-button.component';
import { BurgerButtonComponent } from './burger-button/burger-button.component';
import { NavigationMainLinkComponent } from './navigation-main-link/navigation-main-link.component';
import { ShareProjectBtnComponent } from './share-project-btn/share-project-btn.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationMainLink2Component } from './navigation-main-link-2/navigation-main-link-2.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [
    NgIf,
    MatToolbarModule,
    BurgerButtonComponent,
    RouterLink,
    MatSidenavModule,
    ActionMenuButtonComponent,
    MatRadioModule,
    TranslateModule,
    ActionButtonComponent,
    MatButtonModule,
    NavigationMainLinkComponent,
    NavigationMainLink2Component,
    NgTemplateOutlet,
    NgForOf,
    AsyncPipe,
    SubscriptionPlanInfoComponent,
    NgOptimizedImage,
    ShareProjectBtnComponent,
    MatChipsModule,
    JsonPipe,
    NgClass,
    MatIconModule,
    MatMenuModule,
  ],
})
export class NavbarComponent implements OnInit {
  @ViewChild('drawer') public drawer: MatDrawer | undefined = undefined;

  readonly #destroyRef = inject(DestroyRef);
  readonly #authenticationService = inject(AuthenticationService);
  readonly #translateService = inject(TranslateService);
  readonly #breakpointObserver = inject(BreakpointObserver);
  readonly #environment = inject(EnvironmentService);
  readonly #userService = inject(UserService);
  readonly router = inject(Router);
  readonly route = inject(ActivatedRoute);
  readonly #windowRef = inject(WindowRef);
  readonly loadingService = inject(LoadingService);
  public readonly APP_ROUTES = APP_ROUTES;
  public selectedLanguage!: string;
  public mobileMenuActive = false;
  public languages: string[];
  public user = this.#userService.user;
  public isNavigationDisplayed = computed(() => {
    return !isNil(this.#userService.user());
  });
  public isFreeUser = this.#userService.isFreeUser;
  public isShareProjectBtnDisplayed: WritableSignal<boolean> = signal(false);
  public projectId?: string;

  constructor() {
    this.languages = Object.values(APP_LANGUAGE);
  }

  public ngOnInit(): void {
    this.selectedLanguage = this.#translateService.currentLang;
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(event => {
        this.projectId = (event as NavigationEnd).url.split('/')[3];
        this.isShareProjectBtnDisplayed.set(
          this.shouldShareProjectBtnBeDisplayed(event as NavigationEnd)
        );

        this.closeMenu();
      });

    this.observeBreakpoints();
  }

  private observeBreakpoints(): void {
    this.#breakpointObserver
      .observe(`(max-width: ${screenLg}px)`)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((result: BreakpointState) => {
        if (!result.matches) {
          this.closeMenu();
        }
      });
  }

  public closeMenu(): void {
    this.drawer?.close();
  }

  public setLanguage(selectedLanguage: string): void {
    this.#translateService.use(selectedLanguage);
    localStorage.setItem(languageLocalStorageKey, selectedLanguage);
  }

  public logout(): void {
    this.#authenticationService.logout();
  }

  public getServicePortalLink(): string {
    return this.#environment.servicePortalUrl;
  }

  private shouldShareProjectBtnBeDisplayed(event: NavigationEnd): boolean {
    return (
      event.url.includes(APP_ROUTES.MAP_PROJECT) ||
      event.url.includes(APP_ROUTES.TABLE_PROJECT)
    );
  }

  public openSettings(): void {
    this.#windowRef.nativeWindow.location.href = `${this.getServicePortalLink()}/profile/settings`;
  }
}
