<div class="copy-box">
  <div class="copy-box-text-container">
    <p class="copy-box-text">
      {{ value }}
    </p>
  </div>

  <button
    (click)="copyValue()"
    [disabled]="disabled"
    [color]="disabled ? undefined : 'accent'"
    mat-icon-button>
    <mat-icon fontSet="material-symbols-outlined">{{ icon }}</mat-icon>
  </button>
</div>
