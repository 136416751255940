<div class="mat-dialog-header">
  <h2 mat-dialog-title class="modal-headline">
    {{ 'PROJECT_UPDATE_DIALOG.RENAME_PROJECT' | translate }}
  </h2>
  <div class="mat-dialog-header-icon">
    <button (click)="closeDialog()" tabindex="-1" mat-icon-button>
      <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>

<mat-dialog-content>
  <form [formGroup]="projectForm">
    <mat-form-field color="accent">
      <mat-label>{{ 'COMMON.PROJECT_NAME' | translate }}</mat-label>
      <input
        matInput
        data-testid="update-project-input"
        formControlName="name"
        tabindex="-1"
        type="text" />
      <mat-error *ngIf="projectForm.invalid">
        {{ projectForm | projectFromError | async }}
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    (click)="closeDialog()"
    [disabled]="isLoading"
    tabindex="-1"
    mat-stroked-button
    color="primary"
    class="small">
    {{ 'COMMON.CANCEL' | translate }}
  </button>
  <button
    id="save-button"
    data-testid="update-project-save-btn"
    (click)="updateProject()"
    [disabled]="projectForm.invalid || isLoading"
    tabindex="-1"
    mat-flat-button
    color="primary"
    class="small">
    {{ 'COMMON.SAVE' | translate }}
  </button>
</mat-dialog-actions>
