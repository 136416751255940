import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ErrorHandlerService, ProjectService } from '@app/core';
import { DialogBase } from '@app/shared/dialogs/dialog-base.class';
import { TranslateModule } from '@ngx-translate/core';
import { catchError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { Component, inject, DestroyRef, Inject } from '@angular/core';
import { DialogData } from '../dialog-data.interface';
import { DIALOG_EVENT } from '../dialog-event.enum';

@Component({
  selector: 'app-delete-project-dialog',
  standalone: true,
  imports: [
    DialogHeaderComponent,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './delete-project-dialog.component.html',
  styleUrls: [],
})
export class DeleteProjectDialogComponent extends DialogBase {
  readonly #destroyRef = inject(DestroyRef);
  public isLoading = false;
  readonly #projectService = inject(ProjectService);
  readonly #errorHandlerService = inject(ErrorHandlerService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public override data: DialogData,
    public override readonly dialogRef: MatDialogRef<DeleteProjectDialogComponent>
  ) {
    super(dialogRef, data);
  }

  public deleteProject(): void {
    this.isLoading = true;
    this.#projectService
      .deleteProject(this.data.project.id, true)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError(error => this.#errorHandlerService.handleError(error))
      )
      .subscribe({
        next: () => {
          this.data = { ...this.data, event: DIALOG_EVENT.DELETE };
          this.closeDialog();
        },
        error: () => {
          this.isLoading = false;
          this.closeDialog();
        },
      });
  }
}
