import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { FORM_NAME } from '../../form-name.enum';

@Component({
  selector: 'app-radius-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
  ],
  templateUrl: './radius-form.component.html',
  styleUrls: ['./radius-form.component.scss'],
})
export class RadiusFormComponent implements OnInit, OnChanges {
  @Input() public input?: AbstractControl | null;

  @Input() public radiusOptions!: number[];

  public radiusFormGroup!: FormGroup;
  public readonly FORM_NAME = FORM_NAME;

  public ngOnInit(): void {
    if (isNil(this.radiusOptions)) {
      throw Error('Missing required input param');
    }
    this.radiusFormGroup = this.input as FormGroup;
  }

  public ngOnChanges(): void {
    if (this.radiusFormGroup) {
      const radiusInMeterControl = this.radiusFormGroup.get(
        FORM_NAME.RADIUS_IN_METER
      );
      const radiusInMeterValue = radiusInMeterControl?.value;

      if (
        radiusInMeterValue &&
        !this.radiusOptions.includes(radiusInMeterValue)
      ) {
        radiusInMeterControl?.setValue(undefined);
      }
    }
  }
}
