import { Component, Input, OnInit } from '@angular/core';

import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { isNil } from 'lodash-es';
import { FORM_NAME } from '@app/shared/search-card/form-name.enum';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-max-capacity-form',
  standalone: true,
  templateUrl: './max-capacity-form.component.html',
  styleUrls: ['./max-capacity-form.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
})
export class MaxCapacityFormComponent implements OnInit {
  @Input() public input?: AbstractControl | null;
  @Input() public minMaxCapacityInKw!: number;
  @Input() public maxMaxCapacityInKw!: number;
  public maxCapacityFormGroup!: FormGroup;
  public readonly FORM_NAME = FORM_NAME;

  public ngOnInit(): void {
    if (isNil(this.maxMaxCapacityInKw) || isNil(this.minMaxCapacityInKw)) {
      throw Error('Missing required input param.');
    }
    this.maxCapacityFormGroup = this.input as FormGroup;
  }
}
