import { Component, effect, input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CountrySelectionOption } from './country-selection-option';
import { TranslateModule } from '@ngx-translate/core';
import { NgOptimizedImage } from '@angular/common';
import { FORM_NAME } from '../../form-name.enum';

@Component({
  selector: 'app-country-selection',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgOptimizedImage,
    TranslateModule,
  ],
  templateUrl: './country-selection.component.html',
  styleUrl: './country-selection.component.scss',
})
export class CountrySelectionComponent {
  public readonly selectOptions = input.required<CountrySelectionOption[]>();
  public readonly input = input.required<AbstractControl | null>();

  public readonly FORM_NAME = FORM_NAME;
  public countryControl!: FormControl;
  public form!: FormGroup;

  constructor() {
    effect(() => {
      this.form = this.input() as FormGroup;
      this.countryControl = this.form.get(
        FORM_NAME.COUNTRY_CODE
      ) as FormControl;
    });
  }
}
