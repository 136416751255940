import { LocationDto, Spot } from '@app/shared';

export class LocationRecommendationDiscriminator {
  private static readonly LOCATION_RECOMMENDATION_DISCRIMINATOR = 'projectId';

  public static isLocation(
    potentialLocation: Spot
  ): potentialLocation is LocationDto {
    return (
      LocationRecommendationDiscriminator.LOCATION_RECOMMENDATION_DISCRIMINATOR in
      potentialLocation
    );
  }
}
