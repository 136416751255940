import { inject, Pipe, PipeTransform } from '@angular/core';
import { RestrictionService } from '@app/core';

@Pipe({
  name: 'radiusRestrictionQuery',
  standalone: true,
})
export class RadiusRestrictionQueryPipe implements PipeTransform {
  readonly restrictionService = inject(RestrictionService);

  public transform(tiles: string[] | undefined): string[] | undefined {
    if (!this.restrictionService.isRestricted()) {
      return tiles;
    }

    const restrictions =
      this.restrictionService.radiusRestrictions()?.areaDetails;

    if (!restrictions) {
      return tiles;
    }

    const filters = restrictions.map(restriction => {
      const { mercatorCoordinates, maxRadius } = restriction;
      return `DWITHIN(geom,POINT(${mercatorCoordinates[0]} ${
        mercatorCoordinates[1]
      }),${maxRadius * 1.5},meters)`;
    });

    const combinedFilter = filters.join(' OR ');

    return tiles?.map(url => url + `&CQL_FILTER=${combinedFilter}`);
  }
}
