import { Pipe, PipeTransform } from '@angular/core';
import { RecommendationDtoWrapper } from '@app/map';
import { LocationDto, LocationRecommendationDiscriminator } from '@app/shared';

@Pipe({
  name: 'isLocation',
  standalone: true,
})
export class IsLocationPipe implements PipeTransform {
  public transform(loc: RecommendationDtoWrapper | LocationDto): boolean {
    return LocationRecommendationDiscriminator.isLocation(loc);
  }
}
