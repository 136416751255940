export const kWhFormatter = (value: number): string => {
  if (value >= 1000) {
    const mWh = Math.round(value / 10) / 100;
    if (Math.floor(mWh) === mWh) {
      return `${mWh} mWh`;
    } else {
      return `${mWh.toFixed(1)} mWh`;
    }
  }
  return `${Math.round(value)} kWh`;
};
