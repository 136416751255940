import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-burger-button',
  standalone: true,
  templateUrl: './burger-button.component.html',
  styleUrls: ['./burger-button.component.scss'],
})
export class BurgerButtonComponent {
  @Input()
  public active = false;

  @Output()
  public activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public toggle(): void {
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }
}
