import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, map, Observable } from 'rxjs';
import { UserService } from '@app/core';

@Injectable({ providedIn: 'root' })
export class ValidUserGuard {
  readonly #userService = inject(UserService);
  readonly #authService = inject(AuthService);

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.#userService.user$,
      this.#authService.isAuthenticated$,
    ]).pipe(
      map(([user, isAuthenticated]) => {
        // Check if the user is valid and authenticated before allowing access
        return !!user && isAuthenticated;
      })
    );
  }
}
