import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormBuilder,
  FormControlStatus,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ListFormMetaBase } from '@app/shared/list-form/interfaces/list-form-meta-base';
import { ListFormMetaSelect } from '@app/shared/list-form/interfaces/list-form-meta-select';
import { ToListFormMetaSelectPipe } from '@app/shared/list-form/util/to-list-form-meta-select.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { isNil } from 'lodash-es';

@Component({
  selector: 'app-flex-list-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    ToListFormMetaSelectPipe,
  ],
  templateUrl: './flex-list-form.component.html',
  styleUrls: ['./flex-list-form.component.scss'],
})
export class FlexListFormComponent<
  T extends { [key: string]: ListFormMetaBase },
> implements OnInit
{
  @Input() public listFormMeta!: T;
  @Input() public initialValues!: Array<unknown>;
  @Output() public isValid = new EventEmitter<boolean>(false);
  @Output() public validFormValue = new EventEmitter<Array<unknown>>();

  readonly #destroyRef = inject(DestroyRef);
  public readonly FORM_GROUP_NAME = 'listForm';

  public form!: FormGroup;

  readonly #fb = inject(FormBuilder);

  public readonly formNameByName = new Map<
    string,
    ListFormMetaBase | ListFormMetaSelect
  >();

  public controlNames?: string[];

  public ngOnInit(): void {
    if (isNil(this.listFormMeta) || isNil(this.initialValues)) {
      throw new Error(
        `Missing input value. listFormMeta: ${this.listFormMeta}, initialValue: ${this.initialValues}`
      );
    }
    this.controlNames = Object.keys(this.listFormMeta as object);
    this.controlNames.forEach(key => {
      this.formNameByName.set(
        this.listFormMeta[key].formControlName,
        this.listFormMeta[key]
      );
    });

    const array = this.initialValues.map(valueObj => {
      return this.buildFromGroup(
        Object.keys(this.listFormMeta as object),
        valueObj
      );
    });

    array.push(this.buildFromGroup(this.controlNames));

    this.form = this.#fb.group({
      [this.FORM_GROUP_NAME]: this.#fb.array(array, {}) as FormArray,
    });

    this.form
      .get(this.FORM_GROUP_NAME)
      ?.statusChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((status: FormControlStatus) => {
        this.isValid.emit(status === 'VALID');
        const formValueCopy = [...this.listForm.value];

        formValueCopy.pop();
        this.validFormValue.emit(formValueCopy);
      });

    this.form
      .get(this.FORM_GROUP_NAME)
      ?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        const lastFormGroup = this.listForm.at(this.listForm.length - 1);

        this.controlNames?.forEach(key => {
          lastFormGroup.get(key)?.setErrors(null);
        });
      });
  }

  public get listForm(): FormArray<FormGroup> {
    return this.form.controls[this.FORM_GROUP_NAME] as FormArray;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private buildFromGroup(objectKeys: string[], valueObject?: any): FormGroup {
    let group = {};

    objectKeys.forEach(key => {
      group = {
        ...group,
        [key]: [
          valueObject ? valueObject[key] : undefined,
          this.listFormMeta[key].validators,
        ],
      };
    });
    return this.#fb.group(group);
  }

  public addNewForm(isLast: boolean): void {
    if (isLast) {
      this[this.FORM_GROUP_NAME].push(
        this.buildFromGroup(Object.keys(this.listFormMeta as object))
      );
    }
  }

  public deleteForm(i: number): void {
    this[this.FORM_GROUP_NAME].removeAt(i);
  }
}
