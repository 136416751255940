<app-share-project-btn-skeleton
  *ngIf="sharingDetails === undefined; else listboxWithSharingDetails" />
<ng-template #listboxWithSharingDetails>
  <mat-chip-listbox (change)="onChange($event)">
    <mat-chip-option
      [value]="option"
      [selected]="option.isSelected"
      *ngFor="let option of options; index as index"
      [attr.data-testid]="'shared-project-chip-' + index"
      color="accent"
      [ngClass]="{
        skeleton: sharingDetails === undefined
      }">
      <mat-icon matChipAvatar fontSet="material-symbols-outlined">
        {{ option.icon }}
      </mat-icon>
      {{ option.value | translate }}
    </mat-chip-option>
  </mat-chip-listbox>
</ng-template>
