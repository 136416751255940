<ng-container *ngIf="user()?.subscriptionPlan as subscriptionPlan">
  <div class="banner" *ngIf="subscriptionPlan.expiry | dateExpiring">
    <span>
      {{
        'SUBSCRIPTION_PLAN_EXPIRING_BANNER.INFO'
          | translate
            : {
                expiryDate:
                  subscriptionPlan.expiry
                  | date: 'longDate' : undefined : translateService.currentLang
              }
      }}
    </span>

    <button color="primary" mat-flat-button (click)="onContactSupportClicked()">
      {{ 'COMMON.CONTACT_SUPPORT' | translate }}
    </button>
  </div>
</ng-container>
