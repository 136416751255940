import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FORM_NAME } from '@app/shared/search-card/form-name.enum';
import { TranslateModule } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GeocodingResponseDto } from '@app/shared';
import { EnrichedGeocodingResponseDto } from '../../enriched-geocoding-response';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-city-or-area-form',
  standalone: true,
  templateUrl: './city-or-area-form.component.html',
  styleUrls: ['./city-or-area-form.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatAutocompleteModule,
  ],
})
export class CityOrAreaFormComponent implements OnInit, AfterViewInit {
  private static readonly MIN_LENGTH_FOR_GEOCODING_REQUEST = 2;
  @Input() public input?: AbstractControl | null;
  @Input() public options?: EnrichedGeocodingResponseDto[];
  @Output() public searchGeolocation = new EventEmitter<string>();
  @ViewChild(MatAutocompleteTrigger) public trigger?: MatAutocompleteTrigger;

  readonly #destroyRef = inject(DestroyRef);

  public cityOrAreaFormGroup!: FormGroup;

  public readonly FORM_NAME = FORM_NAME;

  public ngOnInit(): void {
    this.cityOrAreaFormGroup = this.input as FormGroup;
  }

  public ngAfterViewInit(): void {
    this.trigger?.panelClosingActions
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(option => {
        const formGroup = this.cityOrAreaFormGroup.get(FORM_NAME.CITY_OR_AREA);
        const isFormValueIncludedInOptions = this.options
          ?.map(it => it.displayName)
          .includes(formGroup?.value.displayName);

        if (isNil(option)) {
          if (!isFormValueIncludedInOptions) {
            formGroup?.setErrors({
              ...formGroup?.errors,
              noOptionSelected: true,
            });
          }
        } else {
          this.cityOrAreaFormGroup
            .get(FORM_NAME.CITY_OR_AREA)
            ?.setErrors({ noOptionSelected: null });
        }
      });
  }

  public loadGeocodingResults(): void {
    const cityAreaValue = this.cityOrAreaFormGroup?.get(
      this.FORM_NAME.CITY_OR_AREA
    )?.value;

    if (
      cityAreaValue.length >
      CityOrAreaFormComponent.MIN_LENGTH_FOR_GEOCODING_REQUEST
    ) {
      this.searchGeolocation.emit(cityAreaValue);
    }
  }

  public displayName(value: GeocodingResponseDto): string {
    if (value) {
      return value.displayName;
    }
    return '';
  }
}
