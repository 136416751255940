<ng-container *ngIf="user()?.subscriptionPlan as subscriptionPlan">
  <span class="name">{{
    'COMMON.SUBSCRIPTION_PLANS.' + subscriptionPlan.name | translate
  }}</span>
  <span
    class="info-icon"
    color="accent"
    [matTooltip]="
      'COMMON.SUBSCRIPTION_PLANS.EXPIRES_AT'
        | translate
          : {
              expiryDate:
                subscriptionPlan.expiry
                | date: 'longDate' : undefined : translateService.currentLang
            }
    "
    >i</span
  >
</ng-container>
