import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  readonly #snackBar = inject(MatSnackBar);
  readonly #translateService = inject(TranslateService);

  public openSnackBar(
    messageKey: string,
    success: boolean,
    interpolateParams?: Record<string, unknown>,
    duration: number = 10000
  ): void {
    this.#translateService
      .get(messageKey, interpolateParams)
      .pipe(take(1))
      .subscribe(message => {
        this.#snackBar.open(message, 'X', {
          duration,
          panelClass: ['snackbar', `snackbar-${success ? 'success' : 'error'}`],
        });
      });
  }
}
