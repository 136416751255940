import { Injectable, inject } from '@angular/core';
import { Link } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  readonly #translateService = inject(TranslateService);

  getPrivacyNoticeLink(lang: string): Link {
    return {
      label: this.#translateService.instant('COMMON.PRIVACY_NOTICE'),
      url: `https://one.eon.com/${lang}/imprint_privacypolicy/privacy-policy.html`,
    };
  }

  getTermsAndConditionsLink(lang: string): Link {
    return {
      label: this.#translateService.instant('COMMON.TERMS_AND_CONDITIONS'),
      url: `https://www.eon.com/${lang}/terms-use.html`,
    };
  }

  getImprintLink(lang: string): Link {
    return {
      label: this.#translateService.instant('COMMON.IMPRINT'),
      url: `https://one.eon.com/${lang}/imprint_privacypolicy/imprint.html`,
    };
  }
}
