import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation-main-link',
  standalone: true,
  templateUrl: './navigation-main-link.component.html',
  styleUrls: ['./navigation-main-link.component.scss'],
  imports: [RouterLink, TranslateModule, RouterLinkActive, NgIf],
})
export class NavigationMainLinkComponent {
  @Input()
  public link: string | undefined;

  @Input({ required: true })
  public text!: string;

  @Input()
  public href: string | undefined;

  @Input()
  public showDivider = true;

  public openExternalLinkIfPresent(): void {
    if (this.href) {
      window.open(this.href, '_blank');
    }
  }
}
