import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { LinkButtonComponent, ProjectDto } from '@app/shared';
import { APP_ROUTES } from '@app/shared/app-routes.enum';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-project-card-footer',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    LinkButtonComponent,
  ],
  templateUrl: './project-card-footer.component.html',
  styleUrls: ['./project-card-footer.component.scss'],
})
export class ProjectCardFooterComponent {
  @Input() public project?: ProjectDto;

  public readonly APP_ROUTES = APP_ROUTES;
}
