import { HttpClient } from '@angular/common/http';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { APP_LANGUAGE } from '@app/app.constants';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin, map } from 'rxjs';

export class CustomTranslateLoader implements TranslateLoader {
  public static readonly JSON_PREFIX_POI_CATEGORIES = 'POI_CATEGORIES';
  constructor(
    private readonly http: HttpClient,
    private readonly prefix: string = '/assets/i18n'
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTranslation(lang: APP_LANGUAGE): Observable<any> {
    return forkJoin({
      POICategories: this.http.get(
        `${this.prefix}/poi-categories/${lang}.json`
      ),
      translations: this.http.get(`${this.prefix}/${lang}.json`),
    }).pipe(
      map(response =>
        this.#mergeTranslations(response.translations, {
          [CustomTranslateLoader.JSON_PREFIX_POI_CATEGORIES]:
            response.POICategories,
        })
      )
    );
  }

  #mergeTranslations(...args: unknown[]) {
    return Object.assign({}, ...args);
  }
}
